import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ReactiveFormsModule } from '@angular/forms';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

// Services/Providers
import { TranslateProvider, RestaurantService } from './providers';

// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { CartPageModule } from './pages/modal/cart/cart.module';
import { GuestLoginPageModule } from './pages/modal/guest-login/guest-login.module';
import { LocationPageModule } from './pages/modal/location/location.module';
import { SearchFilterPageModule } from './pages/modal/search-filter/search-filter.module';

// Environment
import { environment } from '../environments/environment';

// Components
import { NotificationsComponent } from './components/notifications/notifications.component';

// Pipes
import { PipesModule } from './pipes/pipes.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { InfoPageModule } from './pages/modal/info/info.module';
import { HomeLocationPage } from './pages/home-location/home-location.page';
import { FilterPipe } from './filter.pipe';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DropzoneDirective } from './dropzone.directive';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { UploaderComponent } from './uploader/uploader.component';
import { CustomerorderdetailComponent } from './customerorderdetail/customerorderdetail.component';
import { FormsModule } from '@angular/forms';
import { DishDetailPageModule } from './pages/dish-detail/dish-detail.module';

import { SettingsComponent } from './settings/settings.component';
import { NgxPrintModule } from 'ngx-print';
import { ReportComponent } from './report/report.component';
import { Report2Component } from './report2/report2.component';
import { CouponPageModule } from './pages/modal/coupon/coupon.module';
import { AdminmenudetPageModule } from './pages/modal/adminmenudet/adminmenudet.module';
import { CsvuploadComponent } from './pages/csvupload/csvupload.component';
import { WalkthroughPageModule } from './pages/walkthrough/walkthrough.module';
import { DatePipe } from '@angular/common';
import { ReviewPageModule } from './pages/modal/review/review.module';
import { EventsModule } from './modules/events/events.module';
import { ExtrasPageModule } from './pages/modal/extras/extras.module';
import { FairModule } from './modules/fair/fair.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    CustomerorderdetailComponent,
    SettingsComponent,
    ReportComponent,
    NotificationsComponent,
    UploadTaskComponent,
    Report2Component,
    UploaderComponent,
    FilterPipe,
    DropzoneDirective,
    CsvuploadComponent,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    NgxPrintModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFirestoreModule,
    GooglePlaceModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    RouterModule.forRoot([]),
    IonicModule.forRoot(environment.config),
    IonicModule.forRoot({
      swipeBackEnabled: false,
    }),
    IonicModule.forRoot({
      scrollAssist: false,
    }),

    AppRoutingModule,
    AngularFireStorageModule,
    HttpClientModule,
    ImagePageModule,
    CartPageModule,
    GuestLoginPageModule,
    AdminmenudetPageModule,
    CouponPageModule,
    DishDetailPageModule,
    LocationPageModule,
    SearchFilterPageModule,
    InfoPageModule,
    WalkthroughPageModule,
    ReviewPageModule,
    ExtrasPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDHoFGvkplLiVjF3Vv7Wl38eFiH020aND8',
    }),
    PipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    EventsModule,
    FairModule,
  ],
  entryComponents: [NotificationsComponent],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    HomeLocationPage,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslateProvider,
    RestaurantService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

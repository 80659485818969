var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import * as firebase from 'firebase';
import { TranslateProvider } from 'src/app/providers';
import { environment } from 'src/environments/environment';
import { FairService } from '../../providers/fair.service';
export class FairComponent {
    constructor(router, eventsService, location, loadingCtrl, http, translateService) {
        this.router = router;
        this.eventsService = eventsService;
        this.location = location;
        this.loadingCtrl = loadingCtrl;
        this.http = http;
        this.translateService = translateService;
        this.total = 0;
        this.allEmails = [];
        this.infoFlag = true;
        this.showFooter = false;
        this.eventsService.returnEventList().subscribe((res) => {
            this.allEmails = [];
            this.registeredList = res;
            console.log(JSON.stringify(res));
            if (res && res.length) {
                this.showFooter = true;
                res.forEach((data) => {
                    this.total += data.price;
                    this.allEmails.push(data.email);
                });
            }
            else {
                this.showFooter = false;
            }
        });
        this.eventsService.getEventDetails().subscribe((res) => {
            console.log(res);
            this.eventDetails = res[0];
            this.bizEmail = res[0].businessEmail;
            this.enableRegistration = res[0].enableRegistration;
        });
    }
    ngOnInit() { }
    remove(index) {
        this.eventsService.removeUser(index);
    }
    navigateToForm() {
        if (this.translateService.isLoggedIn === true) {
            this.router.navigate(['NYP2022/form']);
        }
        else {
            this.router.navigate(['login', { callBackUrl: 'NYP2022/form' }]);
        }
    }
    navigateToPayment() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.translateService.isLoggedIn === true) {
                this.allEmails.push(this.bizEmail);
                let d = new Date();
                let date = d.getDate();
                let month = d.getMonth() + 1;
                let year = d.getFullYear();
                this.orderId =
                    '' +
                        date +
                        '' +
                        month +
                        '' +
                        year +
                        ' ' +
                        Math.floor(Math.random() * 1000) +
                        '';
                this.loader = yield this.loadingCtrl.create({
                    message: 'please wait...',
                    duration: 60000,
                });
                this.loader.present();
                var that = this;
                yield firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(function (idToken) {
                    return __awaiter(this, void 0, void 0, function* () {
                        that.loader.dismiss();
                        that.eventsService.postOrders(JSON.parse(localStorage.getItem('fairRegisteredList')), that.orderId);
                        that.url =
                            'https://' + environment.url.link + '/eventsPayment/fairEmail';
                        const allEmails = that.allEmails;
                        const id = that.orderId;
                        const eventData = JSON.parse(localStorage.getItem('fairRegisteredList'));
                        let body = { allEmails, id, eventData };
                        let headers = { Authorization: 'Bearer ' + idToken + '' };
                        that.http.post(that.url, body, { headers }).subscribe((res) => {
                            console.log(res);
                        });
                    });
                });
            }
            else {
                this.router.navigate(['login', { callBackUrl: 'fair' }]);
            }
        });
    }
    back() {
        this.location.back();
    }
    moreInfo() {
        this.infoFlag = !this.infoFlag;
    }
    donate() {
        if (this.translateService.isLoggedIn === true) {
            this.router.navigate(['donate']);
        }
        else {
            this.router.navigate(['login', { callBackUrl: 'donate' }]);
        }
    }
}

import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { CartService, DishService, RestaurantService } from 'src/app/providers';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.page.html',
  styleUrls: ['./extras.page.scss'],
})
export class ExtrasPage implements OnInit {
  sizes = [{ type: 'Regular' }, { type: 'Large' }, { type: 'Super' }];
  styles = [{ type: 'White' }, { type: 'Wheat' }];
  qtd = 1;
  @Input() item;
  checkedList;
  @Input() bizName;
  itemPrice = 0;
  public addOns = [];
  favourite: any[] = [];
  editorder: {};
  user: any[] = [];
  tax;
  menu: any;
  editorder1;
  block;
  subscriptiont: Subscription;
  addOnsString = '';
  breadStyle = 'White';
  breadSize = 'Regular';
  displayExtras = false;

  constructor(
    public modalCtrl: ModalController,
    private restaurantService: RestaurantService,
    public alertController: AlertController,
    private firestore: AngularFirestore,
    private dishService: DishService,
    private cartService: CartService,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController
  ) {}
  ngOnDestroy() {
    if (JSON.parse(localStorage.getItem('user')) != null) {
      this.subscriptiont.unsubscribe();
    }
  }
  ngOnInit() {
    this.tax = this.item.tax;
    console.log(this.item);
    if (this.item.category === 'Sandwiches' || this.item.category === 'BYO') {
      this.displayExtras = true;
    } else {
      this.breadStyle = '';
      this.breadSize = '';
    }

    this.restaurantService
      .getBusinessByName(this.bizName)
      .valueChanges()
      .subscribe((res) => {
        this.addOns = res[0].addOns;
        this.filterCheckedList();
      });
    this.itemPrice = parseInt(this.item.amount);

    // if (item > -1) {
    //   this.addOns[item].isChecked = true;
    // }
  }

  ionViewDidEnter() {
    if (JSON.parse(localStorage.getItem('user')) != null) {
      this.dishService.read_CartList().subscribe((data) => {
        this.menu = data.map((e) => {
          return {
            id: e.payload.doc.id,
            // isEdit: false,
            amount: e.payload.doc.data()['amount'],
            // description: e.payload.doc.data()['description'],
            img: e.payload.doc.data()['img'],
            name: e.payload.doc.data()['name'],
            quantity: e.payload.doc.data()['quantity'],
          };
        });
        //  console.log(this.menu)
      });
    }
    if (JSON.parse(localStorage.getItem('user')) != null) {
      this.subscriptiont = this.restaurantService
        .getCartList()
        .valueChanges()
        .subscribe((res) => {
          this.block = res;
          // console.log(res.length)
        });
    }

    if (JSON.parse(localStorage.getItem('favourite')) == null) {
      this.user = [];
    } else {
      this.user = JSON.parse(localStorage.getItem('favourite'));
    }
  }
  // minus adult when click minus button
  filterCheckedList() {
    if (this.item.addOns && this.item.addOns.length > 0) {
      this.item.addOns.forEach((data) => {
        const item = this.addOns.findIndex((res) => res.val === data);
        if (item > -1) {
          this.addOns[item].isChecked = true;
        }
      });
    }
  }
  getAddOnString(): string {
    console.log(this.breadSize, this.breadStyle);
    this.addOnsString = this.breadSize + ' ,' + this.breadStyle;
    this.addOns.forEach((res) => {
      if (res.isChecked === true) {
        this.addOnsString += `, ${res.val}`;
      }
    });
    console.log(this.addOnsString);
    return this.addOnsString;
  }
  emitAddOn(val: any) {
    const res = val.detail.value;
    console.log(res, this.itemPrice);

    if (res.hasOwnProperty('price') && res.isChecked === true) {
      this.itemPrice += res.price;
    } else if (res.hasOwnProperty('price') && res.isChecked === false) {
      this.itemPrice -= res.price;
    }
  }
  minusQtd() {
    if (this.qtd <= 1) {
      return;
    }
    this.qtd--;
  }
  // plus adult when click plus button
  plusQtd() {
    this.qtd++;
  }

  private calculateItemPrice() {
    // calculating price for checked list
    this.addOns.forEach((res) => {
      if (res.hasOwnProperty('price') && res.isChecked === true) {
        this.itemPrice += res.price;
      }
    });
    this.itemPrice *= this.qtd;
  }

  back() {
    this.modalCtrl.dismiss();
  }
  emitSize(x) {
    console.log(x.detail.value);
    this.breadSize = x.detail.value.type;
    this.itemPrice = x.detail.value.price;
    this.calculateItemPrice();
  }
  emitStyle(x) {
    console.log(x.detail.value);
    this.breadStyle = x.detail.value.type;
  }

  async addcart(qtd) {
    //NULLLLL UUUUSERR
    if (JSON.parse(localStorage.getItem('user')) == null) {
      if (JSON.parse(localStorage.getItem('favourite')) == null) {
        this.favourite = [];
      } else {
        this.favourite = JSON.parse(localStorage.getItem('favourite'));
      }

      // console.log(this.favourite.length)
      // console.log(this.item.businessName)

      ///this checks if same restaurants exist in cart in local storage

      if (this.favourite.length != 0) {
        let fresh = JSON.parse(localStorage.getItem('favourite'));
        // console.log(fresh[0].businessName)

        if (fresh[0].businessName == this.item.businessName) {
          this.addtolocalstorage(qtd);
        } else {
          const alert = await this.alertController.create({
            header: 'Items already in cart',
            message:
              'Your cart contains items from a different store. Please reset your cart before browsing this restaurant',
            buttons: ['OK'],
          });

          await alert.present();
        }
      }

      /// restaurants exist ends here
      else {
        this.addtolocalstorage(qtd);
      }
    }
    //LoCALSTORAGE ENDS HERE
    else {
      if (this.block.length != 0) {
        if (this.block[0].businessName == this.item.businessName) {
          this.loader1();
          let menu1 = this.menu.find((p) => p.name == this.item.item);
          console.log('from menu1');
          console.log(menu1);
          if (menu1 === undefined) {
            this.addToFirestore(
              'this.instructions',
              this.item.item,
              this.itemPrice,
              this.qtd,
              localStorage.getItem('img'),
              this.bizName,
              'IBimage',
              this.item.category
            );
          } else {
            this.editorder1 = JSON.parse(localStorage.getItem('manage'));

            let record = {};
            record['quantity'] = menu1.quantity + qtd;
            // menu1.quantity=menu1.quantity+qtd
            this.firestore.doc('LocalBookletCart/' + menu1.id).update(record);
          }
        } else {
          const alert = await this.alertController.create({
            header: 'Items already in cart',
            message:
              'Your cart contains items from a different store. Please reset your cart before browsing this restaurant',
            buttons: ['OK'],
          });

          await alert.present();
        }
      } else {
        //send
        this.loader1();
        let menu1 = this.menu.find((p) => p.name == this.item.item);
        //  console.log("from menu1")
        console.log(menu1);
        if (menu1 === undefined) {
          this.addToFirestore(
            'this.instructions',
            this.item.item,
            this.itemPrice,
            this.qtd,
            localStorage.getItem('img'),
            this.bizName,
            'IBimage',
            this.item.category
          );
        } else {
          this.editorder1 = JSON.parse(localStorage.getItem('manage'));

          let record = {};
          record['quantity'] = menu1.quantity + qtd;
          // menu1.quantity=menu1.quantity+qtd
          this.firestore.doc('LocalBookletCart/' + menu1.id).update(record);
        }
      }
    }
  }

  async loader1() {
    await this.loadingCtrl
      .create({
        duration: 500,
      })
      .then((res) => {
        res.present();
        res.onDidDismiss().then((dis) => {
          // console.log('Loading dismissed! after 2 Seconds');
          this.modalCtrl.dismiss();
          this.toastCtrl
            .create({
              message: 'added to Cart',
              duration: 500,
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'primary',
            })
            .then((obj) => {
              obj.present();
            });
        });
      });
  }
  addToFirestore(
    ins,
    name,
    amount,
    quantity,
    img,
    businessName,
    IBimage,
    category
  ) {
    //  console.log(menuid)
    const Id = this.firestore.createId();
    const addOnString = this.getAddOnString();
    let email = this.cartService.returnCustomerEmail();
    let tax = this.tax;
    let menuid = this.item.Id;

    console.log({
      tax,
      ins,
      menuid,
      name,
      amount,
      quantity,
      img,
      businessName,
      email,
      Id,
      IBimage,
      category,
    });

    return this.firestore.doc(`LocalBookletCart/${Id}`).set({
      tax,
      ins,
      menuid,
      name,
      amount,
      quantity,
      img,
      businessName,
      email,
      Id,
      IBimage,
      category,
      addOnString,
    });
  }
  addtolocalstorage(qtd) {
    this.loader1();

    let item = this.user.find((p) => p.name == this.item.item);
    // console.log(this.item.tax)
    const addOnString = this.getAddOnString();

    if (item === undefined) {
      //set local
      this.favourite.push({
        name: this.item.item,
        amount: this.itemPrice,
        quantity: qtd,
        id: this.item.Id,
        img: localStorage.getItem('img'),
        businessName: this.item.businessName,
        ins: 'this.instructions',
        tax: this.tax,
        IBimage: 'this.IBimage',
        category: this.item.category,
        addOnString,
      });

      localStorage.setItem('favourite', JSON.stringify(this.favourite));
    } else {
      item.quantity = item.quantity + qtd;

      localStorage.setItem('favourite', JSON.stringify(this.user));
    }
  }
}

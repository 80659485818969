import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateProvider } from 'src/app/providers';
import { EventOrdersService } from '../../providers/event-orders.service';
import { EventsService } from '../../providers/events.service';
@Component({
  selector: 'app-admin-ui',
  templateUrl: './admin-ui.component.html',
  styleUrls: ['./admin-ui.component.scss'],
})
export class AdminUiComponent implements OnInit {
  registeredList: Array<any>;
  total = 0;
  eventDetails: any;
  allEmails = [];
  orderId;
  constructor(
    private router: Router,
    private eventsService: EventsService,
    private location: Location,
    private translateService: TranslateProvider,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private orderService: EventOrdersService
  ) {
    this.eventsService.returnEventList().subscribe((res) => {
      this.total = 0;
      this.registeredList = res;
      console.log(JSON.stringify(res));
      if (res && res.length) {
        res.forEach((data) => {
          this.total += data.price;
          this.allEmails.push(data.email);
        });
      }
    });

    this.eventsService.getEventDetails().subscribe((res) => {
      console.log(res);
      this.eventDetails = res[0];
    });
  }
  ngOnInit() {}

  remove(index: number): void {
    this.eventsService.removeUser(index);
  }
  navigateToForm(): void {
    this.router.navigate([`events/form`]);
  }
  navigateToPayment(): void {
    if (this.translateService.isLoggedIn === true) {
      this.alertController
        .create({
          header: 'Continue With out payment?',
          buttons: [
            {
              text: 'yes',
              handler: (data: any) => {
                this.placeOrder();
              },
            },
            {
              text: 'No',
              handler: (data: any) => {
                this.router.navigate([`events/payment-methods`]);
              },
            },
          ],
        })
        .then((res) => {
          res.present();
        });
    } else {
      this.router.navigate(['login', { callBackUrl: 'events' }]);
    }
  }

  placeOrder(): void {
    let d = new Date();

    let date = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    const id =
      '' +
      date +
      '' +
      month +
      '' +
      year +
      ' ' +
      Math.floor(Math.random() * 1000) +
      '';
    this.orderService.postOrders(
      JSON.parse(localStorage.getItem('eventRegisteredList')),
      this.total,
      id
    );
  }
  back(): void {
    this.location.back();
  }
}

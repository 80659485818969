import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../providers/cart.service';

@Component({
  selector: 'app-ticket-confirmation',
  templateUrl: './ticket-confirmation.component.html',
  styleUrls: ['./ticket-confirmation.component.scss'],
})
export class TicketConfirmationComponent implements OnInit {
  constructor(private router: Router, private nycart: CartService) {
    this.nycart.clearCart();
  }

  ngOnInit() {}

  home() {
    this.router.navigate(['NYP2022']);
  }
}

import { Injectable } from '@angular/core';
import restaurants from './mock-restaurants';
import { HttpClient } from '@angular/common/http';
import * as algoliasearch from 'algoliasearch';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import 'firebase/firestore';

import { BehaviorSubject } from 'rxjs';
import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  client: any;
  index: any;
  favoriteCounter = 0;
  favorites: Array<any> = [];
  restaurants: Array<any> = restaurants;
  public lat1;
  public lng1;
  public geoLatitude: number;
  geoLongitude: number;
  str22: any;
  itemCollection: any;
  str21: any;
  radius = 3;
  x = 15;
  hero12: any;
  google;
  subscribe;

  google1;
  admin;
  customerEmail: string;
  private message = new BehaviorSubject('First Message');
  sharedMessage = this.message.asObservable();
  private restaurant = new BehaviorSubject('First Message');
  restaurantname = this.restaurant.asObservable();

  private taxplustotal = new BehaviorSubject('First Message2');
  taxplustotal1 = this.taxplustotal.asObservable();

  constructor(
    public http: HttpClient,
    private firestore: AngularFirestore,
    private cartService: CartService
  ) {
    this.google = JSON.parse(localStorage.getItem('user'));
    if (this.google != null) {
      if (this.google.isAnonymous === true) {
        this.customerEmail = localStorage.getItem('customerEmail');
      } else {
        for (let i = 0; i < this.google.providerData.length; i++) {
          //console.log(this.google.providerData[i].email)
          if (this.google.email == null) {
            this.customerEmail = this.google.providerData[i].email;
          } else {
            this.customerEmail = this.google.email;
          }
        }
      }
    }

    // navigator.geolocation.getCurrentPosition(position=>{
    //   this.lat1=position.coords.latitude
    //   this.lng1 = position.coords.longitude;
    //   console.log("from fresh"+this.lat1);

    // })

    this.google1 = JSON.parse(localStorage.getItem('user'));
    if (this.google1) {
      if (this.google1.providerData == null) {
        this.google1.providerData = [];
      }
      for (let i = 0; i < this.google1.providerData.length; i++) {
        //console.log(this.google.providerData[i].email)
        if (this.google1.email == null) {
          this.admin = this.google1.providerData[i].email;
        } else {
          this.admin = this.google1.email;
        }
      }
    }
  }

  updateRecordAlgo(record, resname) {
    this.client = algoliasearch(
      'BXEKV65C9U',
      'bd3fe0728329c363a96d1f6d8da66229',
      { protocol: 'https:' }
    );
    this.index = this.client.initIndex(resname);
    this.index
      .partialUpdateObject({
        display: JSON.stringify(record['display']).toUpperCase(),
        objectID: record['Id'],
      })
      .then(({ x }) => {});
  }

  resname(message: string) {
    this.restaurant.next(message);
  }
  nextMessage(message: string) {
    this.message.next(message);
  }
  nexttaxplustotal(message: string) {
    this.taxplustotal.next(message);
  }

  get isAdmin(): boolean {
    if (this.admin == 'localbookletdev@gmail.com') {
      return true;
    } else {
      return false;
    }
  }

  getBusiness() {
    this.itemCollection = this.firestore.collection(`AAPIFALL22`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref;
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getEmails() {
    return this.firestore
      .collection(`CartList`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      })
      .valueChanges();
  }

  getAllBusiness() {
    this.itemCollection = this.firestore.collection(`Business`, (ref) => {
      return ref;
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getBusinessByLocation(x, category) {
    this.itemCollection = this.firestore.collection(`Business`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('IsActive', '==', 'true')
        .where('locality', '==', x)
        .where('category', '==', category);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getallbusiness(x) {
    this.itemCollection = this.firestore.collection(`Business`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('IsActive', '==', 'true').where('locality', '==', x);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  beforezip() {
    this.itemCollection = this.firestore.collection(`TotalServices`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('display', '==', true);
      // .where('locality', '==', x)
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  afterzip(loc) {
    this.itemCollection = this.firestore.collection(`BusinessTypes`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('isActive', '==', true).where('locality', '==', loc);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getLocation() {
    this.itemCollection = this.firestore.collection(`Locations`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('display', '==', true);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getlistofemails() {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref;
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getBusinessByName(name) {
    this.itemCollection = this.firestore.collection(`Business`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('IsActive', '==', 'true')
        .where('BusinessName', '==', name);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getbc() {
    this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('businessName', '==', 'Beyond Curry');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getshittt() {
    this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('businessName', '==', 'Cloves Groceries Phoenix')
        .limit(1000);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getOwners() {
    this.itemCollection = this.firestore.collection(`BusinessOwners`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref;
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getOwnersforaddingmembers(res) {
    this.itemCollection = this.firestore.collection(`BusinessOwners`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('role', '==', 'member').where('business', '==', res);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getdeliveryagents(res) {
    this.itemCollection = this.firestore.collection(`DeliveryAgents`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('business', '==', res);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getOwnerguard() {
    this.itemCollection = this.firestore.collection(`ReportAdmin`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref;
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getdeliveryagentguard() {
    this.itemCollection = this.firestore.collection(`DeliveryAgents`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref;
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getownerrole() {
    var email;
    this.google = JSON.parse(localStorage.getItem('user'));
    for (let i = 0; i < this.google.providerData.length; i++) {
      //console.log(this.google.providerData[i].email)
      if (this.google.email == null) {
        email = this.google.providerData[i].email;
      } else {
        email = this.google.email;
      }
      console.log('from this only' + email);
    }

    this.itemCollection = this.firestore.collection(`BusinessOwners`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('email', '==', email);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getOwnersbyemail(email) {
    var email;
    this.google = JSON.parse(localStorage.getItem('user'));
    for (let i = 0; i < this.google.providerData.length; i++) {
      //console.log(this.google.providerData[i].email)
      if (this.google.email == null) {
        email = this.google.providerData[i].email;
      } else {
        email = this.google.email;
      }
    }

    this.itemCollection = this.firestore.collection(`BusinessOwners`, (ref) => {
      // Compose a query using multiple .where() methods

      return ref.where('email', '==', email);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getBusinesstax(name) {
    this.itemCollection = this.firestore.collection(`Business`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('BusinessName', '==', name);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getordertype(name) {
    this.itemCollection = this.firestore.collection(`orderType`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('name', '==', name);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getTimeslots(id, time) {
    let d = new Date();
    let k = d.getHours() * 60 + d.getMinutes();
    let h = k + time;
    this.itemCollection = this.firestore.collection(`TimeSlots`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('name', '==', id)
        .where('mins', '>', h)

        .orderBy('mins', 'asc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getAllTimeslots(id) {
    this.itemCollection = this.firestore.collection(`TimeSlots`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('name', '==', id).orderBy('mins', 'asc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  categorySlider1() {
    this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('category', '==', 'Diwali Sale');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  categorySlider(name) {
    this.itemCollection = this.firestore.collection(`categorySlider`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('businessName', '==', name)
        .where('isActive', '==', true)
        .orderBy('order', 'asc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  updatecategory(recordID, record) {
    this.firestore.doc('categorySlider/' + recordID).update(record);
  }
  categorySlidermapped(name) {
    this.itemCollection = this.firestore
      .collection(`categorySlider`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref
          .where('businessName', '==', name)
          .where('isActive', '==', true)
          .orderBy('order', 'asc');
      })
      .snapshotChanges();
    return this.itemCollection;
  }

  categorySlidermappedadmin(name) {
    this.itemCollection = this.firestore
      .collection(`categorySlider`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref.where('businessName', '==', name).orderBy('order', 'asc');
      })
      .snapshotChanges();
    return this.itemCollection;
  }

  read_Students() {
    this.itemCollection = this.firestore
      .collection(
        '/orderType',
        (ref) => ref
        // .where('businessName','==',name)
      )
      .snapshotChanges();
    return this.itemCollection;
  }

  menugroc() {
    this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('RestaurantName', '==', 'Beyond Curry');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getLatestOrders() {
    var email;
    this.google = JSON.parse(localStorage.getItem('user'));
    for (let i = 0; i < this.google.providerData.length; i++) {
      //console.log(this.google.providerData[i].email)
      if (this.google.email == null) {
        email = this.google.providerData[i].email;
      } else {
        email = this.google.email;
      }
    }

    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('email', '==', email).orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getCartList() {
    this.itemCollection = this.firestore.collection(
      `LocalBookletCart`,
      (ref) => {
        // Compose a query using multiple .where() methods
        let email = this.cartService.returnEmail();
        return ref.where('email', '==', email);
      }
    );
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getCategory(cat) {
    this.itemCollection = this.firestore.collection(
      `LocalBookletCart`,
      (ref) => {
        // Compose a query using multiple .where() methods
        return ref.where('category', '==', cat);
      }
    );
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  biryanis(): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref;
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  image(imagename): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`Images`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('filename', '==', imagename);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  groc(): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection('menuItem', (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('businessName', '==', 'India Bazaar').limit(50);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  rea() {
    let item = this.firestore
      .collection('/menuItem', (ref) =>
        ref.where('businessName', '==', 'India Bazaar').limit(500)
      )
      .snapshotChanges();
    return item;
  }

  soupsd123(res, cat): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection('menuItem', (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('category', '==', cat)
        .where('display', '==', true)
        .where('businessName', '==', res)
        .orderBy('item', 'asc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  soupsd123snapshot(res, cat) {
    this.itemCollection = this.firestore.collection('/menuItem', (ref) =>
      // Compose a query using multiple .where() methods
      ref
        .where('category', '==', cat)
        .where('display', '==', true)
        .where('isActive', '==', true)
        .where('businessName', '==', res)
        .orderBy('item', 'asc')
        .limit(10)
    );
    return this.itemCollection.snapshotChanges();
  }

  getAllCustomerOrderssnap(name) {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('businessName', '==', name)
        .orderBy('timestamp', 'desc')
        .limit(10);
    });
    return this.itemCollection.snapshotChanges();
  }
  soupsdsnapshot(res, songId) {
    if (songId == 'Indo Chineese') {
      this.itemCollection = this.firestore.collection(`menuItem`, (ref) =>
        // Compose a query using multiple .where() methods
        ref
          .where('category', '==', songId)
          .where('display', '==', true)
          .where('businessName', '==', res)
          .orderBy('order', 'asc')
          .limit(15)
      );

      return this.itemCollection.snapshotChanges();
    } else {
      this.itemCollection = this.firestore.collection(`menuItem`, (ref) =>
        // Compose a query using multiple .where() methods
        ref
          .where('category', '==', songId)
          .where('display', '==', true)
          .where('businessName', '==', res)
          .orderBy('item', 'asc')
          .limit(15)
      );
      return this.itemCollection.snapshotChanges();
    }
  }

  testimage(res, cat) {
    this.itemCollection = this.firestore
      .collection('menuItem', (ref) => {
        // Compose a query using multiple .where() methods
        return ref
          .where('category', '==', cat)
          .where('display', '==', true)
          .where('businessName', '==', res);
      })
      .snapshotChanges();
    return this.itemCollection;
  }

  getbusinessbyzip(zip) {
    this.itemCollection = this.firestore.collection(`Business`, (ref) => {
      return ref.where('IsActive', '==', 'true').where('zipcode', '==', zip);
    });

    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  soupsd(res, songId): AngularFirestoreCollection {
    if (songId == 'Indo Chineese') {
      this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref
          .where('category', '==', songId)
          .where('display', '==', true)
          .where('businessName', '==', res)
          .orderBy('order', 'asc');
      });
      this.itemCollection.valueChanges();
      return this.itemCollection;
    } else {
      this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref
          .where('category', '==', songId)
          .where('display', '==', true)
          .where('businessName', '==', res)
          .orderBy('item', 'asc');
      });
      this.itemCollection.valueChanges();
      return this.itemCollection;
    }
  }

  menutype(songId): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`menutype`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('resname', '==', songId);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getMenuSearch(songId, cat): AngularFirestoreCollection {
    if (cat) {
      this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref
          .where('businessName', '==', songId)
          .where('category', '==', cat)
          .where('display', '==', true)
          .where('isActive', '==', true);
      });
    }

    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  configsearch(): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(
      `ConfigurableSearch`,
      (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      }
    );
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getCoupons(bsname) {
    this.itemCollection = this.firestore
      .collection('/Coupons', (ref) => ref.where('businessName', '==', bsname))
      .snapshotChanges();

    return this.itemCollection;
  }
  updatecoupon(recordID, record) {
    this.firestore.doc('/Coupons/' + recordID).update(record);
  }
  couponmapped(name) {
    this.itemCollection = this.firestore
      .collection(`/Coupons`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref
          .where('businessName', '==', name)
          .where('isActive', '==', true);
      })
      .snapshotChanges();
    return this.itemCollection;
  }
  getorders(songId) {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      return ref.where('email', '==', songId).orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();

    return this.itemCollection;
  }

  nonposresponse(id): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(
      `squarePaymentResponse`,
      (ref) => {
        // Compose a query using multiple .where() methods
        return ref.where('id', '==', id);
        //.orderBy('items','desc')
      }
    );
    this.itemCollection.valueChanges();

    return this.itemCollection;
  }
  refunddetail(songId): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('id', '==', songId);
      //.orderBy('items','desc')
    });
    this.itemCollection.valueChanges();

    return this.itemCollection;
  }

  getItemToCart(id): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('id', '==', id);
      //.orderBy('items','desc')
    });
    this.itemCollection.valueChanges();

    return this.itemCollection;
  }

  businessfromdpage(id: string) {
    this.itemCollection = this.firestore.collection(`Business`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('BusinessName', '==', id);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getCustomerOrders(name, date): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('businessName', '==', name)
        .where('timestamp', '>', date)
        .orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getdeliveriesafterfilter(name, date): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('businessName', '==', name)
        .where('ordertype', '==', 'DELIVERY')

        .where('timestamp', '>', date)
        .orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getadminCustomerOrdersfilter(date): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('timestamp', '>', date).orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getadminfilter(to, from): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('timestamp', '<=', to)

        .where('timestamp', '>=', from)

        .orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getdeliveryorders(to, from): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('timestamp', '<=', to)

        .where('timestamp', '>=', from)
        .where('ordertype', '==', 'DELIVERY')

        .orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getadminfilterforIB(to, from, biz): AngularFirestoreCollection {
    if (biz == 0) {
      this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
        return ref
          .where('timestamp', '<=', to)
          .where('timestamp', '>=', from)
          .orderBy('timestamp', 'desc');
      });
    } else {
      this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
        return ref
          .where('timestamp', '<=', to)
          .where('timestamp', '>=', from)
          .where('businessName', '==', biz)

          .orderBy('timestamp', 'desc');
      });
    }

    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getAllCustomerOrders(name): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('businessName', '==', name).orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getalldeliveries(): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return (
        ref
          // .where('businessName','==',name)
          .where('ordertype', '==', 'DELIVERY')

          .orderBy('timestamp', 'desc')
      );
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getAllOrders(): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.orderBy('timestamp', 'desc');
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getCustomerOrdersById(songId): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('id', '==', songId);
    });
    this.itemCollection.valueChanges();

    return this.itemCollection;
  }

  cartt(): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref;
    });
    this.itemCollection.valueChanges();

    return this.itemCollection;
  }

  getSongDetail(songId: string): AngularFirestoreDocument {
    //  console.log(this.firestore.collection(`menuItem`).doc(songId))
    return this.firestore.collection(`menuItem`).doc(songId);
  }

  getadminmenudoc(songId: string): AngularFirestoreDocument {
    //  console.log(this.firestore.collection(`menuItem`).doc(songId))
    return this.firestore.collection(`menuItem`).doc(songId);
  }
  getOrderSummary(songId: string): AngularFirestoreDocument {
    //  console.log(this.firestore.collection(`menuItem`).doc(songId))
    return this.firestore.collection(`CartList`).doc(songId);
  }

  // reviews
  getreviews(id): AngularFirestoreCollection {
    this.itemCollection = this.firestore.collection(`Reviews`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('restaurantname', '==', id);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  findAll() {
    return Promise.resolve(restaurants);
  }

  getRestaurants() {
    return this.restaurants;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  RestaurantService,
  TranslateProvider,
  CartService,
  DishService,
} from 'src/app/providers';
import {
  ModalController,
  ActionSheetController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { IonSlides } from '@ionic/angular';
import { ImagePage } from '../image/image.page';
import { CartPage } from '../cart/cart.page';

@Component({
  selector: 'app-info',
  templateUrl: './info.page.html',
  styleUrls: ['./info.page.scss'],
})
export class InfoPage {
  @ViewChild('slides') slider: IonSlides;

  restaurantID: any;
  list: any;
  restaurantopts = 'veg';
  dishes: Array<any>;
  itemname: any;
  results1: any;
  query: any;
  restaurant: any;
  song1: any;
  lengthoffirebaseelements: any;
  reviews: any;
  reviewslength: any;
  latt: number = 17.4317;
  lngg: number = 78.372;
  lattt: any;
  lnggg: any;
  hero12: any;
  sliderConfig = {
    slidesPerView: 1,
    spaceBetween: 10,
    centeredSlides: true,
  };
  opts = 0;
  nonveg: any;
  constructor(
    private service: RestaurantService,
    public asCtrl: ActionSheetController,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public route: ActivatedRoute,
    public router: Router,
    private translate: TranslateProvider,
    private cartService: CartService,
    private dishService: DishService,
    public db: AngularFirestore,
    private firestore: AngularFirestore
  ) {}

  ionViewWillEnter() {
    this.hero12 = this.service.hero12;
    console.log('resservice' + this.hero12);
    const songId: string = this.route.snapshot.paramMap.get('id');
    this.reviews = this.service.getreviews(songId).valueChanges();

    //business collection
    this.song1 = this.service
      .businessfromdpage(localStorage.getItem('id'))
      .valueChanges();

    //console.log( "from detail"+this.restaurantID)
    //menu collection
    // this.list=this.service.getSongList(songId).valueChanges();

    // this.nonveg=this.service.getSongListNonVeg(songId).valueChanges();

    this.dishes = this.dishService.findAll();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}

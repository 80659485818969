import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartService, RestaurantService } from 'src/app/providers';
import * as algoliasearch from 'algoliasearch';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.page.html',
  styleUrls: ['./search-filter.page.scss'],
})
export class SearchFilterPage implements OnInit {
  organizeby: any;
  dishtype: any;
  dishnationality: any;
  str22:any;
  client: any;
  index: any;
  lower1:10;
  public volume:any=50


  public radiusmiles = 1;
  public minmaxprice = {
    upper: 500,
    lower: 10
  };


  radius:any
  radius1:any
  filterstring:any

  constructor(private modalCtrl: ModalController,public service:RestaurantService) { }

  ngOnInit() {

    if(localStorage.getItem('radius')==null){
      this.volume=50
    }
    else{
      this.volume=localStorage.getItem('radius')
    
    }  
  }

  closeModal() {
    this.modalCtrl.dismiss();
    localStorage.setItem('radius',this.volume)
  }
  click(string:any){
    console.log(string.detail.value)
    this.volume=string.detail.value
  // this.service.search(this.radius)
  // localStorage.setItem('radius',this.volume)
    
  
    
    
  }
  onKey(bar){
    console.log(bar.srcElement.value)
  }
  
  
} 

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { CartService } from 'src/app/providers';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root',
})
export class EventOrdersService {
  email;
  constructor(
    private firestore: AngularFirestore,
    private router: Router,
    private cartService: CartService,
    private eventsService: EventsService
  ) {}

  postOrders(event, total, id) {
    const email = this.cartService.returnCustomerEmail();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    this.storeEntry(event, total, id);
    return this.firestore
      .doc(`EventOrders/${id}`)
      .set({
        id,
        email,
        event,
        timestamp,
        total,
      })
      .then(() => this.goconfrm(id));
  }
  private storeEntry(event, total, id) {
    let isgoogleLogin = false;
    if (
      localStorage.getItem('isAnonymous') &&
      localStorage.getItem('isAnonymous') === 'false'
    ) {
      isgoogleLogin = true;
    }
    const email = this.cartService.returnCustomerEmail();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.firestore.doc(`aapiEntry/${id}`).set({
      id,
      email,
      event,
      timestamp,
      total,
      isgoogleLogin,
    });
  }
  goconfrm(id): void {
    this.eventsService.clean();
    this.router.navigate(['event-confirmation/' + id + '']);
  }

  getOrderDetails(id: string) {
    return this.firestore
      .collection(`EventOrders`, (ref) => {
        return ref.where('id', '==', id);
      })
      .valueChanges();
  }
  getOrderDetailsEmail(email: string) {
    return this.firestore
      .collection(`EventOrders`, (ref) => {
        return ref.where('email', '==', email);
      })
      .valueChanges();
  }
  getAllOrders() {
    return this.firestore
      .collection(`EventOrders`, (ref) => {
        return ref;
        // .where('email', '==', email);
      })
      .valueChanges();
  }
}

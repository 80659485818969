<div *ngIf="percentage | async as pct">
  <progress [value]="pct" max="100"></progress>
  {{ pct | number }}%
</div>



<ion-card *ngIf="snapshot | async as snap">

  {{ snap.bytesTransferred }} {{'app.upload.task.of' | translate }} {{ snap.totalBytes }} 

  <ion-item *ngIf="downloadURL as url">
    <h3>{{'app.upload.task.results' | translate }}!</h3>
    <img [src]="url"><br>
    <a [href]="url" target="_blank" rel="noopener">{{'app.upload.task.download' | translate }}!</a>
  </ion-item> 

  <button (click)="task.pause()" [disabled]="!isActive(snap)">{{'app.upload.task.pause' | translate }}</button>
  <button (click)="task.cancel()" [disabled]="!isActive(snap)">{{'app.upload.task.cancel' | translate }}</button>
  <button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">{{'app.upload.task.resume' | translate }}</button>
</ion-card>

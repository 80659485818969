<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="eventDetails">
  <ion-card>
    <h1 class="text-primary">1. Food Not Included</h1>
    <ion-card-content *ngFor="let item of ticketOptions1">
      {{ item.name }}

      <ion-item class="plusminusgrid" lines="none" *ngIf="isAdded(item)">
        <ion-icon
          name="remove-circle"
          class="iconsplusminus"
          tappable
          (click)="decrement(item)"
          color="primary"
        ></ion-icon>
        &nbsp;&nbsp; &nbsp;

        <h2 ion-text class="text-20 fw500">{{ returnQty(item) }}</h2>
        &nbsp;&nbsp; &nbsp;

        <ion-icon
          name="add-circle"
          class="iconsplusminus"
          tappable
          (click)="increment(item)"
          color="primary"
        ></ion-icon>
      </ion-item>
      <ion-item class="plusminusgrid" lines="none" *ngIf="!isAdded(item)">
        <ion-button size="small" color="primary" (click)="addCart(item)">
          <ion-icon slot="start" name="cart" color="light"></ion-icon>
          <ion-text color="light">Add</ion-text>
        </ion-button>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <h1>2. Food (Boxed) Included</h1>

    <ion-card-content *ngFor="let item of ticketOptions2">
      {{ item.name }}

      <ion-item class="plusminusgrid" lines="none" *ngIf="isAdded(item)">
        <ion-icon
          name="remove-circle"
          class="iconsplusminus"
          tappable
          (click)="decrement(item)"
          color="primary"
        ></ion-icon>
        &nbsp;&nbsp; &nbsp;

        <h2 ion-text class="text-20 fw500">{{ returnQty(item) }}</h2>
        &nbsp;&nbsp; &nbsp;
        <ion-icon
          name="add-circle"
          class="iconsplusminus"
          tappable
          (click)="increment(item)"
          color="primary"
        ></ion-icon>
      </ion-item>
      <ion-item class="plusminusgrid" lines="none" *ngIf="!isAdded(item)">
        <ion-button size="small" color="primary" (click)="addCart(item)">
          <ion-icon slot="start" name="cart" color="light"></ion-icon>
          <ion-text color="light">Add</ion-text>
        </ion-button>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <h1>3. Food (Boxed) And Drinks Included</h1>

    <ion-card-content *ngFor="let item of ticketOptions3">
      {{ item.name }}

      <ion-item class="plusminusgrid" lines="none" *ngIf="isAdded(item)">
        <ion-icon
          name="remove-circle"
          class="iconsplusminus"
          tappable
          (click)="decrement(item)"
          color="primary"
        ></ion-icon>
        &nbsp;&nbsp; &nbsp;

        <h2 ion-text class="text-20 fw500">{{ returnQty(item) }}</h2>
        &nbsp;&nbsp; &nbsp;
        <ion-icon
          name="add-circle"
          class="iconsplusminus"
          tappable
          (click)="increment(item)"
          color="primary"
        ></ion-icon>
      </ion-item>
      <ion-item class="plusminusgrid" lines="none" *ngIf="!isAdded(item)">
        <ion-button size="small" color="primary" (click)="addCart(item)">
          <ion-icon slot="start" name="cart" color="light"></ion-icon>
          <ion-text color="light">Add</ion-text>
        </ion-button>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <h1>4. Kids</h1>

    <ion-card-content *ngFor="let item of kids">
      {{ item.name }}

      <ion-item class="plusminusgrid" lines="none" *ngIf="isAdded(item)">
        <ion-icon
          name="remove-circle"
          class="iconsplusminus"
          tappable
          (click)="decrement(item)"
          color="primary"
        ></ion-icon>
        &nbsp;&nbsp; &nbsp;

        <h2 ion-text class="text-20 fw500">{{ returnQty(item) }}</h2>
        &nbsp;&nbsp; &nbsp;
        <ion-icon
          name="add-circle"
          class="iconsplusminus"
          tappable
          (click)="increment(item)"
          color="primary"
        ></ion-icon>
      </ion-item>
      <ion-item class="plusminusgrid" lines="none" *ngIf="!isAdded(item)">
        <ion-button size="small" color="primary" (click)="addCart(item)">
          <ion-icon slot="start" name="cart" color="light"></ion-icon>
          <ion-text color="light">Add</ion-text>
        </ion-button>
      </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer>
  <ion-fab vertical="bottom" horizontal="center" slot="fixed">
    <div *ngIf="count() > 0">
      <ion-badge color="warning" slot="end" class="ionbadge">{{
        count()
      }}</ion-badge>
    </div>

    <ion-fab-button color="dark" (click)="openCart()">
      <ion-icon name="cart"></ion-icon>
    </ion-fab-button>
    <!-- <div *ngIf="pageEnd">
      <ion-badge color="secondary" class='scrollBadge'>
        <ion-text color="light">scroll down</ion-text>
      </ion-badge>
    </div> -->
  </ion-fab>
</ion-footer>

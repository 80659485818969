<div class="dropzone" dropzone

(hovered)="toggleHover($event)"
(dropped)="startUpload($event)"
[class.hovering]="isHovering">
<p>Drag and Drop a CSV file</p>
<div class="file">
 <label class="file-label">
  <input type="file" (change)="startUpload($event.target.files)" style="display: none;" accept=".csv">
  <span class="file-cta">
  <span class="file-icon">
  </span>
  <span class="file-label">or choose a file...</span>
  </span>
 </label>
</div>
</div>
<ion-content>
 <h2 class="h2">{{document}}</h2><br>
 <h2 class="h2"> Total Records: {{count}}</h2>
</ion-content>
import { Component, Output, EventEmitter } from '@angular/core';
import {
  NavController,
  MenuController,
  LoadingController,
  Platform,
  AlertController,
} from '@ionic/angular';
import { RestaurantService, TranslateProvider } from '../../providers';
// import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
// import {
//   BarcodeScannerOptions,
//   BarcodeScanner
// } from "@ionic-native/barcode-scanner/ngx";
import { environment } from '../../../environments/environment';

import {
  trigger,
  style,
  animate,
  transition,
  query,
  stagger,
} from '@angular/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-location',
  templateUrl: './home-location.page.html',
  styleUrls: ['./home-location.page.scss'],
  animations: [
    trigger('staggerIn', [
      transition('* => *', [
        query(
          ':enter',
          style({ opacity: 0, transform: `translate3d(0,10px,0)` }),
          { optional: true }
        ),
        query(
          ':enter',
          stagger('300ms', [
            animate(
              '600ms',
              style({ opacity: 1, transform: `translate3d(0,0,0)` })
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class HomeLocationPage {
  @Output() getDisplayName: EventEmitter<any> = new EventEmitter();
  scannedData: {};

  list;
  setlocation: any;
  loader;
  items: string[];
  showItems: Boolean = false;
  locations;
  servicetype;
  businessservice = 'Restaurants';
  zip;
  geolocation;
  operating: boolean = false;
  listofstores;
  constructor(
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    public loadingCtrl: LoadingController,
    private translate: TranslateProvider,
    public service: RestaurantService,
    // public qrScanner: QRScanner,
    public platform: Platform,
    private firestore: AngularFirestore,
    public http: HttpClient,
    public alertController: AlertController,
    public router: Router
  ) // private barcodeScanner: BarcodeScanner
  {
    //     this.platform.ready().then(() => {
    //       this.qrScanner.prepare()
    //   .then((status: QRScannerStatus) => {
    //      if (status.authorized) {
    //        // camera permission was granted
    // console.log(
    //   "yes"
    // )
    //        // start scanning
    //      } else if (status.denied) {
    //        // camera permission was permanently denied
    //        // you must use QRScanner.openSettings() method to guide the user to the settings page
    //        // then they can grant the permission from there
    //      } else {
    //        // permission was denied, but not permanently. You can ask for permission again at a later time.
    //      }
    //   })
    //   .catch((e: any) => console.log('Error is', e));
    //       }).catch(err => {
    //         alert("1-> "+JSON.stringify(err));
    //       });
    //
  }

  ionViewWillEnter() {
    this.firestore
      .collection('Api-Keys')
      .doc('2KbMrcIHAs7fCSvNKkcl')
      .get()
      .subscribe((res) => {
        this.zip = res.data().gkey;
        // console.log(res.data().gkey)

        console.log(this.servicetype);
      });
    console.log(localStorage.getItem('setlocationbyzip'));
    this.menuCtrl.enable(false);
    const user = JSON.parse(localStorage.getItem('user'));

    if (localStorage.getItem('setlocationbyzip')) {
      this.setlocation = localStorage.getItem('setlocationbyzip');
    } else {
      this.setlocation = '';
    }
  }

  emitordertype(data) {
    this.businessservice = data.detail.value;
  }
  editprofile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  settings() {
    this.navCtrl.navigateForward('settings');
  }

  logout() {
    this.navCtrl.navigateRoot('login');
  }

  messages() {
    this.navCtrl.navigateForward('messages');
  }

  eventHandler(keyCode) {
    if (keyCode == 13) this.getZip();
  }
  async getZip() {
    this.http
      .get(
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          this.setlocation +
          '&sensor=true&key=' +
          this.zip +
          ''
      )
      .subscribe((res) => {
        this.geolocation = res;
        let len = this.geolocation.results[0].address_components.length;
        console.log(
          this.geolocation.results[0].address_components[len - 2].long_name
        );

        this.service
          .afterzip(
            this.geolocation.results[0].address_components[len - 2].long_name
          )
          .valueChanges()
          .subscribe(async (res) => {
            if (res.length <= 0) {
              // this.list=[]
              console.log('could not find any restaurants');

              const alert = await this.alertController.create({
                header: 'Coming soon to your area',
                message: 'Sorry, we are not operating in this area',
                buttons: ['OK'],
              });

              await alert.present();
            } else {
              this.operating = true;
              localStorage.setItem('setlocationbyzip', this.setlocation);

              this.list = this.service
                .afterzip(
                  this.geolocation.results[0].address_components[len - 2]
                    .long_name
                )
                .valueChanges();
              this.service
                .afterzip(
                  this.geolocation.results[0].address_components[len - 2]
                    .long_name
                )
                .valueChanges()
                .subscribe((res) => [
                  (this.listofstores = res),
                  this.goToHomeResults(),
                ]);
            }
          });
      });

    // localStorage.setItem('setlocationbyzip',this.setlocation)
    // localStorage.setItem('businesstype',type)

    //   this.loader = await this.loadingCtrl.create({
    //     message:'please wait...',
    //     duration: 500

    //   });

    //   this.loader.present();

    //     setTimeout(()=> this.go(),1000)
  }
  async goToHomeResults() {
    console.log(this.listofstores);
    console.log(JSON.stringify(this.listofstores));
    localStorage.setItem('listofstores', JSON.stringify(this.listofstores));
    localStorage.setItem('setlocationbyzip', this.setlocation);

    this.loader = await this.loadingCtrl.create({
      message: 'please wait...',
      duration: 500,
    });

    this.loader.present();

    setTimeout(() => this.go(), 1000);
  }
  go() {
    this.navCtrl.navigateRoot('home-results');
  }
}

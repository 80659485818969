import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NavController,
  MenuController,
  Platform,
  ToastController,
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import * as firebase from 'firebase/app';
import { timer } from 'rxjs/observable/timer';
import { take } from 'rxjs/operators';
import { CartService, TranslateProvider } from 'src/app/providers';
import { CartPage } from '../cart/cart.page';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.page.html',
  styleUrls: ['./guest-login.page.scss'],
})
export class GuestLoginPage implements OnInit {
  public onLoginForm: FormGroup;
  user: User;
  email: string = '';
  spin: boolean = false;
  facebookk: boolean = false;
  elements: boolean = false;
  cartData;
  disable: boolean = false;
  loading;
  customerEmail: string;
  google: any;
  loader: any;
  executed: boolean;
  guestuser: boolean = false;
  constructor(
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    private translate: TranslateProvider,
    private formBuilder: FormBuilder,
    public afAuth: AngularFireAuth,
    public router: Router,
    public service: TranslateProvider,
    public modalCtrl: ModalController,
    public cartservice: CartService,
    private platform: Platform,
    private route: ActivatedRoute
  ) {
    this.onLoginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
          ),
        ],
      ],
    });
  }
  ionViewWillEnter() {
    console.log(localStorage.getItem('cart'));
    this.disable = false;
    if (
      localStorage.getItem('google') == 'google' ||
      localStorage.getItem('facebook') == 'facebook'
    ) {
      this.elements = true;
      this.disable = true;
    }
  }

  ionViewDidEnter() {
    this.menuCtrl.enable(false);

    this.user = JSON.parse(localStorage.getItem('favourite'));
    console.log(this.user);
    if (
      localStorage.getItem('google') == 'google' ||
      localStorage.getItem('facebook') == 'facebook'
    ) {
      this.elements = true;
      this.disable = true;
    }
  }

  async opencart() {
    const modal = await this.modalCtrl.create({
      component: CartPage,
    });
    return await modal.present();
  }

  async anonymousLogin() {
    firebase.auth().signInAnonymously();
    localStorage.setItem('customerEmail', this.onLoginForm.value.email);
    console.log('set and get' + localStorage.getItem('customerEmail'));

    this.loader = await this.loadingCtrl.create({
      message: 'please wait...',
      duration: 4000,
    });

    this.loader.present();
    this.modalCtrl.dismiss();
    // setTimeout(()=> this.storeInFirestore(this.onLoginForm.value.email),2000)
  }

  storeInFirestore(email) {
    console.log(email);
    this.cartData = JSON.parse(localStorage.getItem('favourite'));
    if (this.cartData && this.cartData.length) {
      for (let i = 0; i < this.cartData.length; i++) {
        this.cartservice.afterLoginwithtax(
          this.cartData[i].businessName,
          this.cartData[i].name,
          this.cartData[i].amount,
          this.cartData[i].quantity,
          this.cartData[i].img,
          this.cartData[i].id,
          this.cartData[i].ins,
          this.cartData[i].tax,
          this.cartData[i].category,
          email
        );
      }
    }

    if (localStorage.getItem('guard')) {
      this.router.navigate(['' + localStorage.getItem('guard') + '']);
      localStorage.removeItem('guard');
    } else {
      if (localStorage.getItem('cart') == 'cart') {
        setTimeout(() => {
          console.log('the number');
          this.router.navigate(['home-results']);
          this.opencart();
        }, 3000);
      } else if (localStorage.getItem('cart') == 'orders') {
        this.router.navigate(['latest-orders']);
      } else if (localStorage.getItem('cart') == 'profile') {
        this.router.navigate(['edit-profile']);
      } else {
        this.router.navigate(['home-results']);
      }
    }
    localStorage.removeItem('favourite');
  }
  async ngOnInit() {
    this.cartData = JSON.parse(localStorage.getItem('favourite'));

    this.afAuth.authState.pipe(take(1)).subscribe(async (user) => {
      if (user) {
        await this.showLoading();
        console.log('called twice');
        this.executed = false;
        if (!this.executed) {
          this.executed = true;
          this.storeInFirestore(this.cartservice.returnEmail());
          console.log('called once');
        }
        this.loading.dismiss();
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
        if (this.cartData == null) {
          this.cartData = [];
        }
      } else {
        localStorage.setItem('user', null);
      }
    });
  }
  // async cart(){
  //   const modal =  await this.modalCtrl.create({
  //     component: CartPage
  //   });
  //   return  await modal.present();
  // }
  closeModal() {
    this.modalCtrl.dismiss();
    //this.location.back()
  }
  async googleLogin() {
    if (this.platform.is('capacitor')) {
      this.disable = true;
      this.elements = true;
    }
    localStorage.setItem('google', 'google');
    await this.showLoading();

    this.service.google();
  }
  async guestlogin() {
    this.guestuser = true;
  }
  async facebook() {
    // localStorage.setItem("facebook", "facebook");
    // if (this.platform.is("capacitor")) {
    //   this.disable = true;
    //   this.elements = true;
    // }
    // await this.showLoading();
    // this.service.facebook1();
  }

  async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: 'Authenticating...',
    });

    this.loading.present();
  }
  async openGuestlogin() {
    const modal = await this.modalCtrl.create({
      component: GuestLoginPage,
    });
    return await modal.present();
  }
  signOut() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
        console.log('success');
      })
      .catch(function (error) {
        // An error happened.
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { RestaurantService } from 'src/app/providers';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { FormControlName } from '@angular/forms';
import * as XLSX from 'xlsx';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  list;
  list123;
  length;
  fromdate;
  todate;
  contentload: boolean = true;
  defaultbiz = '--ALL--';
  getactivebiz;
  bizz;

  fileName = 'ExcelSheet.xlsx';

  public checkin = {
    // date: new Date().toISOString()
    date: new Date().toISOString(),
  };
  format = moment().subtract(30, 'days').format('YYYY-MM-DD');

  constructor(
    private rservice: RestaurantService,
    public menuCtrl: MenuController,

    private location: Location
  ) {}
  back() {
    this.location.back();
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  from(z) {
    console.log('date', moment(z.detail.value).format('YYYY-MM-DD')); // 2019-04-22

    this.fromdate = firebase.firestore.Timestamp.fromDate(
      new Date(moment(z.detail.value).format('YYYY-MM-DD'))
    );
    console.log(this.todate);
    this.filter(this.todate, this.fromdate, this.bizz);
  }
  to(z) {
    console.log(
      'date',
      moment(z.detail.value).add(1, 'days').format('YYYY-MM-DD')
    ); // 2019-04-22

    this.fromdate = firebase.firestore.Timestamp.fromDate(
      new Date(this.format)
    );

    let d = new Date(this.format);
    this.todate = firebase.firestore.Timestamp.fromDate(
      new Date(moment(z.detail.value).add(1, 'days').format('YYYY-MM-DD'))
    );
    console.log(this.todate);

    this.filter(this.todate, this.fromdate, this.bizz);
  }
  ngOnInit() {
    this.menuCtrl.enable(false);

    this.defaultbiz = '--ALL--';

    this.rservice
      .getBusiness()
      .valueChanges()
      .subscribe((res) => {
        this.list = res;
        console.log(res);
      });

    //     this.list123=this.rservice.getBusiness().valueChanges();
    //     this.list123.subscribe(lis=>{
    // console.log(lis)

    // this.defaultbiz="All"
    //     })

    // const format = moment().subtract(30, 'days').format('YYYY-MM-DD');
    // this.fromdate = firebase.firestore.Timestamp.fromDate(new Date(format));
    // this.bizz = 0;

    // console.log(this.checkin.date);
    // this.todate = firebase.firestore.Timestamp.fromDate(
    //   new Date(moment(this.checkin.date).add(1, 'days').format('YYYY-MM-DD'))
    // );

    // this.list = this.rservice
    //   .getadminfilterforIB(this.todate, this.fromdate, 0)
    //   .valueChanges();
    // this.rservice
    //   .getadminfilterforIB(this.todate, this.fromdate, 0)
    //   .valueChanges()
    //   .subscribe((res) => {
    //     this.contentload = false;

    //     console.log(res);
    //   });
  }
  filter(to, from, biz) {
    this.list = this.rservice.getadminfilterforIB(to, from, biz).valueChanges();
    this.rservice
      .getadminfilterforIB(to, from, biz)
      .valueChanges()
      .subscribe((res) => {
        this.length = res.length;
        console.log(res);
      });
  }

  emitbiz(k) {
    console.log(k.detail.value);
    this.filter(this.todate, this.fromdate, k.detail.value);
  }

  checkbox(id) {
    console.log(id);
  }
}

import { Subject } from 'rxjs';
import { TranslateProvider, RestaurantService } from '../providers';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "../providers/translate/translate.service";
import * as i2 from "@ionic/angular";
import * as i3 from "../providers/restaurant/restaurant.service";
import * as i4 from "@ngx-translate/core";
export class DeliveryagentGuard {
    constructor(service, navCtrl, rservice, tprovider) {
        this.service = service;
        this.navCtrl = navCtrl;
        this.rservice = rservice;
        this.tprovider = tprovider;
        this.result = new Subject();
    }
    canActivate(next, state) {
        if (this.service) {
            const user = JSON.parse(localStorage.getItem('user'));
            this.rservice.getdeliveryagentguard().valueChanges().subscribe(res => {
                console.log(res);
                this.owners = res;
                let item = this.owners.find(p => p.email == user.email);
                console.log(item);
                if (item == undefined) {
                    this.result.next(false);
                    // window.alert("You are not allowed to access this URL!");
                    // this.navCtrl.navigateRoot('/home-results');
                }
                else {
                    this.result.next(true);
                }
            });
        }
        console.log(this.result.asObservable());
        return this.result.asObservable();
    }
}
DeliveryagentGuard.ngInjectableDef = i0.defineInjectable({ factory: function DeliveryagentGuard_Factory() { return new DeliveryagentGuard(i0.inject(i1.TranslateProvider), i0.inject(i2.NavController), i0.inject(i3.RestaurantService), i0.inject(i4.TranslateService)); }, token: DeliveryagentGuard, providedIn: "root" });

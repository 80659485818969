<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button color="light"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card *ngIf="verified; else elseContent">
    <img
      class="image"
      src="https://firebasestorage.googleapis.com/v0/b/localbooklet.appspot.com/o/check-mark.png?alt=media&token=07a38d15-8d8c-4deb-9a7e-2cba6b33a8a5"
    />
    <h1 style="text-align: center">checked in successfully. Tickets G1,G2</h1>
  </ion-card>

  <ng-template #elseContent>
    <ion-card style="text-align: center">
      <ion-card-header>
        You have'nt booked your tickets Yet, get started!
      </ion-card-header>
      <ion-card-content>
        <ion-button (click)="goToAapi()">Get Started</ion-button>
      </ion-card-content>
    </ion-card>
  </ng-template>
</ion-content>

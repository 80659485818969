import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { TranslateProvider, RestaurantService } from '../providers';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SwyloordersGuard implements CanActivate {
  owners;
  result = new Subject<boolean>();

  constructor(
    public service: TranslateProvider,
    public navCtrl: NavController,
    public rservice: RestaurantService,
    public tprovider: TranslateService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.service) {
      const user = JSON.parse(localStorage.getItem('user'));

      this.rservice
        .getOwnerguard()
        .valueChanges()
        .subscribe((res) => {
          this.owners = res;
          let item = this.owners.find((p) => p.email == user.email);
          console.log(item);
          if (item == undefined) {
            this.result.next(false);
            // window.alert("You are not allowed to access this URL!");
            // this.navCtrl.navigateRoot('/home-results');
          } else {
            this.result.next(true);
          }
        });
    }
    console.log(this.result.asObservable());
    return this.result.asObservable();
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import {
  LoadingController,
  ToastController,
  NavController,
} from '@ionic/angular';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  CartService,
  OrdersService,
  RestaurantService,
} from 'src/app/providers';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import * as moment from 'moment';
declare var google; // import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.page.html',
  styleUrls: ['./check-in.page.scss'],
})
export class CheckInPage implements OnInit {
  // @Input('selectable') option:any;

  // @Input('first') f;

  public onLoginForm: FormGroup;
  dinein: boolean = false;
  discountbool: boolean = false;
  togo: boolean = false;
  delivery: boolean = false;
  checkintime;
  time1: boolean = true;
  count;
  guestCount;
  gcount = 1;
  list;
  results;
  showlist: boolean = false;
  datenow;
  monthnow;
  yearnow;
  hoursnow;
  Freedelivery: boolean = false;
  enable: boolean = true;
  datum1;
  groc: boolean = false;
  loader: any;
  minutesnow;
  t;
  hideforbc: boolean = false;
  paramId;
  k123: boolean = false;
  tablenumber: any;
  subscriptiond: Subscription;
  list1;
  public volumetemp: any = 0;
  volume = 0;
  tip: any;
  tipplustotal;
  isChecked: boolean = false;
  allowEmptySelection: boolean = false;
  customper: boolean = false;
  custompercentage: number;
  customamount;
  onlydayforrestaurants;
  hidefordeliveries: boolean = false;
  under5miles;
  under10miles;
  under15miles;
  under12miles;
  under35miles;
  under125miles;
  businessservice;
  checkintimeincst;
  alternatebrands = 'true';
  showalternate: boolean = false;
  value;
  storeorigin;
  storelat: number;
  storelng: number;
  deliverycharges;
  @ViewChild('map') mapElement: ElementRef;
  @ViewChild('search') search: any;
  map: any;
  address: string;
  lat: string;
  long: string;
  autocomplete: { input: string };
  autocompleteItems: any[];
  location123: any;
  placeid: any;
  GoogleAutocomplete: any;
  geocoder123: any;
  DistanceMatrixService: any;
  deliverycoverage;
  subtotal;
  businessType;
  indiabazaar;
  mintip = 0;
  indiabazdel: boolean = true;
  applieddiscount;
  description: any;
  address1: any;
  defaultTime: any;
  referalCode = false;
  isAddressAdded = false;
  customerLongitude: number;
  customerLatitude: number;
  distanceinmeters: number;
  couponpercentage = 0;
  menu: any;
  couponcode;
  constructor(
    public alertController: AlertController,
    private formBuilder: FormBuilder,
    public loadingCtrl: LoadingController,
    private location: Location,
    public toastCtrl: ToastController,
    public router: Router,
    public navCtrl: NavController,
    public rservice: RestaurantService,
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    public zone: NgZone,
    public cartService: CartService,
    public ordersService: OrdersService,
    public datepipe: DatePipe
  ) {
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.geocoder123 = new google.maps.Geocoder();
    this.DistanceMatrixService = new google.maps.DistanceMatrixService();
    this.autocomplete = { input: '' };
    this.autocompleteItems = [];
    this.paramId = this.route.snapshot.paramMap.get('id');

    this.rservice.getCoupons(this.paramId).subscribe((data) => {
      // console.log(data)
      this.menu = data.map((e) => {
        return {
          id: e.payload.doc.id,
          couponname: e.payload.doc.data()['couponname'],
          isActive: e.payload.doc.data()['isActive'],
          percentage: e.payload.doc.data()['percentage'],
          min: e.payload.doc.data()['min'],
          exp: e.payload.doc.data()['exp'],
        };
      });
    });
  }

  public checkin = {
    // date: new Date().toISOString()
    date: new Date().toDateString(),
  };
  public minDate = moment().format();

  public maxDate = moment().add(6, 'd').format();
  ngOnDestroy() {
    if (this.k123 == true) {
      this.subscriptiond.unsubscribe();
    }
  }

  showPrompt() {
    this.alertController
      .create({
        header: 'Update!',
        message:
          'Due to labor shortage we are not taking any deliveries this week. We will update as we start taking orders for deliveries, sorry for inconvenience.<br><br>-Swylo team',
        backdropDismiss: false,
        cssClass: 'secondary',

        buttons: [
          {
            text: 'Ok',
            handler: (data: any) => {
              this.router.navigate(['home-results']);
            },
          },
        ],
      })
      .then((res: any) => {
        res.present();
      });
  }
  click(string: any) {
    this.customper = false;
    this.isChecked = false;
    this.allowEmptySelection = true;
    this.volume = this.volumetemp;
    this.volume = string.detail.value;
    this.cal(this.volume);
  }
  cal(x) {
    this.tipplustotal = 0;
    this.tip = Math.round((x / 100) * this.subtotal * 100) / 100;
    if (this.tip > 100) {
      this.alertController
        .create({
          header: 'Confirm Alert',
          subHeader: 'Tip amount is $' + this.tip,
          buttons: [
            {
              text: 'ok',
              handler: () => {},
            },
          ],
        })
        .then((res) => {
          res.present();
        });
    }
    this.tipplustotal = this.tip + this.subtotal;
  }
  custompercentagefun() {
    this.cal(this.custompercentage);
  }
  customfun() {
    this.volume = 0;
    // this.volumetemp=0
    this.customper = true;
    if (this.customamount == undefined) {
      this.caldollars(this.tip);
    } else {
      this.caldollars(this.customamount);
    }
  }
  popUp(header, subHeader) {
    this.alertController
      .create({
        header: header,
        subHeader: subHeader,
        buttons: [
          {
            text: 'ok',
            handler: () => {},
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }
  referalEvent(event) {
    const email = this.cartService.returnCustomerEmail();
    const code = event.detail.value;
    if (code.length === 8) {
      let checkCouponType = code.substring(0, 2);
      if (checkCouponType === 'SE') {
        this.ordersService
          .getSenderReferalCode(code, email)
          .valueChanges()
          .pipe(take(1))
          .subscribe((res) => {
            if (res && res.length) {
              localStorage.setItem('referalCodeId', res[0].id);
              localStorage.setItem('referalCode', res[0].referalCode);
              this.deliverycharges = 0;
              this.popUp('Coupon applied', 'You got free delivery');
            }
          });
      } else {
        this.ordersService
          .getReceiverReferalCode(code, email)
          .valueChanges()
          .pipe(take(1))
          .subscribe((res) => {
            if (res && res.length) {
              localStorage.setItem('referalCodeId', res[0].id);
              localStorage.setItem('referalCode', res[0].referalCode);
              this.deliverycharges = 0;
              this.popUp('Coupon applied', 'You got free delivery');
            } else {
              this.popUp('InvalidCode', 'Code cannot be applied');
            }
          });
      }
    } else if (code.length > 3) {
      this.couponcode = code;
      if (JSON.parse(localStorage.getItem('user')) != null) {
        let menu1 = this.menu.find((p) => p.couponname == this.couponcode);
        const date = new Date();
        let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');

        if (menu1 == undefined || latest_date > menu1.exp) {
          console.log('undefined');
          this.toastCtrl
            .create({
              message: 'Coupon Expired or Invalid',
              duration: 500,
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'primary',
            })
            .then((obj) => {
              obj.present();
            });
        } else {
          if (this.subtotal > menu1.min) {
            this.discountbool = true;
            this.couponpercentage = menu1.percentage;
            this.toastCtrl
              .create({
                message: 'Coupon ' + menu1.couponname + ' Applied',
                duration: 500,
                position: 'middle',
                cssClass: 'my-custom-class',
                color: 'success',
              })
              .then((obj) => {
                obj.present();
              });
            console.log('apply discount');
            this.applieddiscount = (menu1.percentage / 100) * this.subtotal;
            console.log(this.applieddiscount);
          } else {
            this.toastCtrl
              .create({
                message: 'Minimum order value should be ' + menu1.min + ' ',
                duration: 500,
                position: 'middle',
                cssClass: 'my-custom-class',
                color: 'success',
              })
              .then((obj) => {
                obj.present();
              });
          }
        } //elseclose
      } //ifclose
      else {
        this.toastCtrl
          .create({
            message: 'Login to use the Coupon',
            duration: 500,
            position: 'middle',
            cssClass: 'my-custom-class',
            color: 'success',
          })
          .then((obj) => {
            obj.present();
          });
      }
    }
  }
  caldollars(x) {
    this.tipplustotal = 0;

    let y = parseFloat(x);
    this.tip = y;
    if (this.tip > 100) {
      this.alertController
        .create({
          header: 'Confirm Alert',
          subHeader: 'Tip amount is $' + this.tip,
          buttons: [
            {
              text: 'ok',
              handler: () => {},
            },
          ],
        })
        .then((res) => {
          res.present();
        });
    }

    this.tipplustotal = this.tip + this.subtotal;
  }
  customdollarfn() {
    if (this.customamount == undefined && this.customamount == null) {
      this.caldollars(0);
    } else {
      this.caldollars(this.customamount);
    }
  }

  inputfocus() {
    document.getElementById('auto-address').focus();
  }

  // UpdateSearchResults() {
  //   if (this.autocomplete.input == '') {
  //     this.autocompleteItems = [];
  //     return;
  //   }

  //   this.GoogleAutocomplete.getPlacePredictions(
  //     { input: this.autocomplete.input },
  //     (predictions, status) => {
  //       console.log(predictions);

  //       this.autocompleteItems = [];
  //       this.zone.run(() => {
  //         predictions.forEach((prediction) => {
  //           this.autocompleteItems.push(prediction);
  //         });
  //       });
  //     }
  //   );
  // }
  handleAddressChange() {
    const options = {
      fields: ['place_id'],
    };
    const input = document.getElementById('auto-address') as HTMLInputElement;
    let autocomplete;
    autocomplete = new google.maps.places.Autocomplete(input, options);

    console.log(autocomplete);
    let child = this;

    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      autocomplete.setFields(['place_id', 'geometry', 'name']);
      const place = autocomplete.getPlace();
      child.getdistanceinkm(place.place_id, input.value);
    });
  }

  // SelectSearchResult(item) {
  //   console.log(item);
  //   this.autocomplete = { input: '' };
  //   this.autocompleteItems = [];
  //   this.placeid = item.place_id;
  //   this.getdistanceinkm(this.placeid, item.description);
  // }
  getdistanceinkm(id, description) {
    let vm = this;

    this.geocoder123.geocode({ placeId: id }, function (results, status) {
      if (status === 'OK') {
        if (results[0]) {
          console.log(results[0]);
          var origin1 = new google.maps.LatLng(vm.storelat, vm.storelng);
          var origin2 = vm.storeorigin;
          var destinationA = description;
          vm.customerLatitude = results[0].geometry.location.lat();
          vm.customerLongitude = results[0].geometry.location.lng();
          var destinationB = new google.maps.LatLng(
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          );
          var service = new google.maps.DistanceMatrixService();

          service.getDistanceMatrix(
            {
              origins: [origin1, origin2],
              destinations: [destinationA, destinationB],
              travelMode: 'DRIVING',

              avoidHighways: false,
              avoidTolls: true,
            },
            callback
          );

          function callback(response, status) {
            if (response.rows[0].elements[0].distance) {
              var distance = response.rows[0].elements[0].distance.text;
              var duration = response.rows[0].elements[0].duration.text;
              vm.distanceinmeters = response.rows[0].elements[0].distance.value;

              // console.log(distance,duration)

              vm.calculateDeliveryCharges({
                description: description,
                distance: distance,
                duration: duration,
                distanceinmeters: vm.distanceinmeters,
              });

              this.isAddressAdded = true;
            } else {
              vm.presentAlert();
            }
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }
  calculateDeliveryCharges(obj) {
    if (obj.distanceinmeters > this.deliverycoverage) {
      this.presentAlert();
    } else {
      if (this.businessType == 'Restaurant') {
        if (obj.distanceinmeters < 8046.72) {
          this.deliverycharges = this.under5miles;
        } else if (
          obj.distanceinmeters > 8046.72 &&
          obj.distanceinmeters < 16093.4
        ) {
          this.deliverycharges = this.under10miles;
        } else if (
          obj.distanceinmeters > 16093.4 &&
          obj.distanceinmeters < 24140.2
        ) {
          this.deliverycharges = this.under15miles;
        } else if (obj.distanceinmeters > 24140.2) {
          this.deliverycharges = this.under125miles;
        }
      }
      // groceries
      else {
        if (obj.distanceinmeters < 19312.1) {
          this.deliverycharges = this.under12miles;
        } else if (
          obj.distanceinmeters > 19312.1 &&
          obj.distanceinmeters < 56327
        ) {
          this.deliverycharges = this.under35miles;
        } else if (obj.distanceinmeters > 56327) {
          this.deliverycharges = this.under125miles;
        }
      }

      this.onLoginForm.get('address').setValue(obj.description);
    }
    this.cal(10);
    if (this.deliverycharges == 0) {
      this.Freedelivery = true;
    }
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      // header: 'Thanks for your Order!',
      subHeader:
        'The location is too far away from the store for swylo to deliver. Please pick a valid location',
      // message: '* Please call us as soon as possible to update any details on this Order.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            this.searchfocus();
          },
        },
      ],
    });

    await alert.present();
  }
  searchfocus() {
    const html = document.getElementById('auto-address') as HTMLInputElement;
    html.value = '';
    html.focus();
  }
  //lET'S BE CLEAN! THIS WILL JUST CLEAN THE LIST WHEN WE CLOSE THE SEARCH BAR.
  ClearAutocomplete() {
    this.autocompleteItems = [];
    this.autocomplete.input = '';
  }

  checkInTime(time) {
    console.log(time);
    console.log(time.detail.value);
    this.showlist = false;
    this.checkintime = time.detail.value;
    let k = new Date(this.checkintime);
    let p = new Date();

    this.datenow = k.getDate();
    this.monthnow = k.getMonth();
    this.yearnow = k.getFullYear();

    if (k.getDate() > p.getDate()) {
      this.list = this.rservice
        .getAllTimeslots(this.paramId)
        .valueChanges()
        .subscribe((res) => {
          console.log(res[0].time);
          this.results = res;
          this.defaultTime = res[0].time;
          let val = {
            detail: {
              value: res[0].id,
            },
          };
          this.getTime(val);
        });
    } else if (k.getMonth() > p.getMonth()) {
      this.list = this.rservice
        .getAllTimeslots(this.paramId)
        .valueChanges()
        .subscribe((res) => {
          this.results = res;
          console.log(res[0].time);
          let val = {
            detail: {
              value: res[0].id,
            },
          };
          this.getTime(val);
          this.defaultTime = res[0].time;
        });
    } else {
      // checkinng for delivery add 15 more mins for delivery time
      if (this.delivery) {
        this.list = this.rservice
          .getTimeslots(this.paramId, 45)
          .valueChanges()
          .subscribe((res) => {
            this.results = res;
            this.defaultTime = res[0].time;
            if (res.length == 0) {
              this.showlist = true;
            }
          });
      } else {
        this.list = this.rservice
          .getTimeslots(this.paramId, 45)
          .valueChanges()
          .subscribe((res) => {
            this.results = res;
            this.defaultTime = res[0].time;
            if (res.length == 0) {
              this.showlist = true;
            }
          });
      }
    }
    if (this.paramId == 'India Bazaar') {
      this.showalternate = true;
      this.indiabazaar = true;

      this.showlist = false;
      if (k.getDate() == p.getDate() && k.getMonth() == p.getMonth()) {
        if (p.getHours() >= 13) {
          console.log('exceeds 1');
          this.showlist = true;
        }
      }
    }
    if (this.paramId == 'Catering From Beyond Curry') {
      if (k.getDate() == p.getDate() && k.getMonth() == p.getMonth()) {
        this.showlist = true;
      }
    }

    if (k.getDate() == p.getDate() && k.getMonth() == p.getMonth()) {
      if (this.delivery) {
        this.list = this.rservice
          .getTimeslots(this.paramId, 45)
          .valueChanges()
          .subscribe((res) => {
            this.results = res;
            if (res.length == 0) {
              this.showlist = true;
            }
          });
      } else {
        this.list = this.rservice
          .getTimeslots(this.paramId, 45)
          .valueChanges()
          .subscribe((res) => {
            this.results = res;
            if (res.length == 0) {
              this.showlist = true;
            }
          });
      }
    }
  }
  toggle(x) {
    this.alternatebrands = '' + x.detail.checked + '';
  }
  ionViewWillEnter() {
    if (this.paramId === 'India Bazaar') {
      this.showPrompt();
    }

    console.log(localStorage.getItem('discountbool'));
    console.log(JSON.parse(localStorage.getItem('applieddiscount')));

    this.applieddiscount = JSON.parse(localStorage.getItem('applieddiscount'));

    let p = new Date();
    if (this.paramId == 'India Bazaar') {
      console.log(p.getHours());
      if (p.getHours() >= 13) {
        this.checkin.date = moment().add(1, 'd').format();

        // this.minDate=this.checkin.date
      }
    }
    localStorage.removeItem('alternatebrands');
  }

  checkorder(value) {
    if (value == 'TO GO' || value == 'PICK UP') {
      this.deliverycharges = 0;
      this.togo = true;
      this.dinein = false;
      this.delivery = false;
    } else if (value == 'DINE IN') {
      this.deliverycharges = 0;
      this.dinein = true;
      this.togo = false;
      this.delivery = false;
    } else if (value == 'DELIVERY') {
      this.delivery = true;
      this.dinein = false;
      this.togo = false;
    }

    localStorage.setItem('ordertype', value);
  }
  ngOnInit() {
    this.tip = this.cal(10);
    this.subtotal =
      Math.round(parseFloat(localStorage.getItem('taxplustotal')) * 100) / 100;

    this.tipplustotal = this.subtotal;
    this.deliverycharges = 0;
    this.rservice
      .getBusinessByName(this.paramId)
      .valueChanges()
      .subscribe((res) => {
        this.list1 = res;
        this.storelat = res[0].lat;
        this.storelng = res[0].lng;
        this.storeorigin = res[0].storeorigin;
        this.deliverycoverage = res[0].deliverycoverage;
        this.deliverycharges = res[0].deliverycharges;
        this.businessType = res[0].businessType;
        if (this.businessType == 'Restaurant') {
          this.under5miles = res[0].under5miles;
          this.under10miles = res[0].under10miles;
          this.under15miles = res[0].under15miles;
          this.under125miles = res[0].under125miles;
        } else {
          this.under12miles = res[0].under12miles;
          this.under35miles = res[0].under35miles;
          this.under125miles = res[0].under125miles;
          this.showalternate = true;
        }
      });
    // this.list1=this.rservice.getordertype(paramId).valueChanges()

    this.rservice
      .getordertype(this.paramId)
      .valueChanges()
      .subscribe((res) => {
        // console.log(res)
        this.list1 = res;
        console.log(res[0].type);
        this.businessservice = res[0].type;
        this.checkorder(this.businessservice);
        this.enable = false;
      });

    let p = new Date();

    if (this.paramId == 'India Bazaar') {
      this.showalternate = true;
      if (p.getHours() >= 13) {
        console.log(p.getHours());

        this.checkin.date = moment().add(1, 'd').format();

        // this.showlist=true
      }
    }

    if (this.paramId == 'Catering From Beyond Curry') {
      this.showlist = true;
    }

    this.datenow = p.getDate();
    this.monthnow = p.getMonth();
    this.yearnow = p.getFullYear();

    console.log(localStorage.getItem('resname'));

    // checkinng for delivery add 15 more mins for delivery time come here
    this.list = this.rservice
      .getTimeslots(this.paramId, 45)
      .valueChanges()
      .subscribe((res) => {
        console.log(res);
        if (res && res.length) {
          console.log(res[0].id);
          let val = {
            detail: {
              value: res[0].id,
            },
          };
          this.getTime(val);
          this.results = res;
          this.defaultTime = res[0].time;
        } else {
          this.checkin.date = moment().add(1, 'd').format();
          let res = {
            detail: {
              value: this.checkin.date,
            },
          };

          console.log(res.detail.value);
          console.log(res.detail);
          this.checkInTime(res);
        }
      });

    localStorage.removeItem('facebook');
    localStorage.removeItem('google');
    localStorage.removeItem('cart');

    this.onLoginForm = this.formBuilder.group({
      guestName: [null, Validators.compose([Validators.required])],
      address: [null, Validators.compose([Validators.required])],
      tableNumber: [null, Validators.compose([Validators.required])],

      guestMobile: [null, Validators.compose([Validators.required])],

      guestCount: [null, Validators.compose([Validators.required])],

      houseNo: [null, Validators.compose([])],
      landmark: [null, Validators.compose([])],
    });
  }
  emitordertype(k) {
    // checkinng for delivery add 15 more mins for delivery time
    console.log('emitted');
    let input = new Date(this.checkintime);
    let today = new Date();
    if (
      input.getDate() == today.getDate() &&
      input.getMonth() == today.getMonth()
    ) {
      if (this.delivery) {
        console.log('this is delivery');
        this.list = this.rservice
          .getTimeslots(this.paramId, 45)
          .valueChanges()
          .subscribe((res) => {
            console.log(res[0].time);
            this.results = res;
            this.defaultTime = res[0].time;

            if (res.length == 0) {
              this.showlist = true;
            }
          });
      } else {
        console.log('this aint delivery');
        this.list = this.rservice
          .getTimeslots(this.paramId, 45)
          .valueChanges()
          .subscribe((res) => {
            console.log(res[0].time);
            this.results = res;
            this.defaultTime = res[0].time;
            let val = {
              detail: {
                value: res[0].id,
              },
            };
            this.getTime(val);
            if (res.length == 0) {
              this.showlist = true;
            }
          });
      }
    }

    let p = new Date();

    if (this.paramId == 'India Bazaar') {
      this.showalternate = true;
      if (p.getHours() >= 13) {
        console.log(p.getHours());

        this.checkin.date = moment().add(1, 'd').format();

        let k = {
          detail: {
            value: this.checkin.date,
          },
        };
        this.checkInTime(k);

        // this.showlist=true
      }
    }

    if (this.paramId == 'Cloves Chandler' || this.paramId == 'Cloves Phoenix') {
      console.log(this.paramId);
      this.showalternate = true;

      if (today.getHours() >= 18) {
        console.log(today.getHours());
        this.checkin.date = moment().add(2, 'd').format();
        this.checkintime = moment().add(2, 'd').format();
        this.minDate = moment().add(2, 'd').format();

        let k = {
          detail: {
            value: this.checkintime,
          },
        };

        this.checkInTime(k);
      } else {
        this.checkin.date = moment().add(1, 'd').format();
        this.checkintime = moment().add(1, 'd').format();
        this.minDate = moment().add(1, 'd').format();

        let k = {
          detail: {
            value: this.checkintime,
          },
        };

        this.checkInTime(k);
      }
    }

    console.log(k.detail.value);
    if (k.detail.value == 'PICK UP' || 'TO GO') {
      this.deliverycharges = 0;
      if (this.paramId == 'India Bazaar') {
        this.indiabazdel = true;
        this.enable = true;
      }
      this.onLoginForm = this.formBuilder.group({
        guestName: [null, Validators.compose([Validators.required])],
        guestMobile: [null, Validators.compose([Validators.required])],
        address: [null, Validators.compose([])],
        guestCount: [null, Validators.compose([])],

        houseNo: [null, Validators.compose([])],
        landmark: [null, Validators.compose([])],
        tableNumber: [null, Validators.compose([])],
      });
    }
    if (k.detail.value == 'DINE IN') {
      this.enable = false;
      this.checkintime = this.checkin.date;
      this.onLoginForm.get('guestCount').setValue(1);

      this.onLoginForm = this.formBuilder.group({
        guestName: [null, Validators.compose([Validators.required])],
        tableNumber: [null, Validators.compose([Validators.required])],

        // 'tableNumber': [null, Validators.compose([
        //   // Validators.required
        // ])],

        guestMobile: [null, Validators.compose([Validators.required])],

        address: [null, Validators.compose([])],

        houseNo: [null, Validators.compose([])],
        landmark: [null, Validators.compose([])],

        guestCount: [null, Validators.compose([])],
      });
    }

    if (k.detail.value == 'DELIVERY') {
      this.isAddressAdded = true;
      let email = this.cartService.returnEmail();
      this.cartService
        .getCustomerAddress(email)
        .valueChanges()
        .pipe(take(1))
        .subscribe((res) => {
          console.log({ res });
          if (res.length) {
            let obj = { distanceinmeters: res[0].distanceinmeters };
            this.calculateDeliveryCharges(obj);
            this.customerLongitude = res[0].longitude;
            this.customerLatitude = res[0].latitude;
            this.distanceinmeters = res[0].distanceinmeters;
            this.onLoginForm.patchValue({
              address: res[0].address,
            });
          }

          console.log(res);
        });
      if (this.paramId == 'India Bazaar') {
        console.log('should not enter');
        this.indiabazdel = false;
        this.enable = false;
      }
      // this.deliverycharges=5
      this.onLoginForm = this.formBuilder.group({
        guestName: [null, Validators.compose([Validators.required])],

        tableNumber: [
          null,
          Validators.compose([
            // Validators.required
          ]),
        ],

        guestMobile: [null, Validators.compose([Validators.required])],
        address: [null, Validators.compose([Validators.required])],
        guestCount: [null, Validators.compose([])],

        houseNo: [null, Validators.compose([])],
        landmark: [null, Validators.compose([])],
      });
    }

    if (k.detail.value == 'CATERING') {
      this.onLoginForm = this.formBuilder.group({
        guestName: [null, Validators.compose([Validators.required])],

        // 'tableNumber': [null, Validators.compose([
        //   // Validators.required
        // ])],

        guestMobile: [null, Validators.compose([Validators.required])],
        address: [null, Validators.compose([])],
        guestCount: [null, Validators.compose([])],

        houseNo: [null, Validators.compose([])],
        landmark: [null, Validators.compose([])],
      });
    }

    this.businessservice = k.detail.value;

    // this.onLoginForm.get('guestCount').clearValidators();
    this.checkorder(this.businessservice);
  }
  getTime(k) {
    console.log('emit date');
    // this.defaultTime=null;
    console.log(k.detail.value);
    this.firestore
      .collection('TimeSlots')
      .doc(k.detail.value)
      .get()
      .subscribe((res) => {
        console.log(res);
        this.minutesnow = res.data().minutes;
        this.hoursnow = res.data().hours;
        console.log(this.minutesnow, this.hoursnow);
      });
    this.enable = false;
  }
  back() {
    this.location.back();
  }

  async proceed() {
    if (this.discountbool) {
      localStorage.setItem('discountbool', 'applied');
      localStorage.setItem(
        'applieddiscount',
        JSON.stringify(this.applieddiscount)
      );

      localStorage.setItem(
        'couponpercentage',
        JSON.stringify(this.couponpercentage)
      );
    } else {
      localStorage.setItem('discountbool', 'notapplied');
      localStorage.setItem('applieddiscount', JSON.stringify(0));
    }
    localStorage.setItem('tip', this.tip);
    localStorage.setItem('tiptaxtotal', this.tipplustotal);

    if (this.togo) {
      this.deliverycharges = 0;

      localStorage.setItem('deliverycharges', this.deliverycharges);

      var today = new Date(
        this.yearnow,
        this.monthnow,
        this.datenow,
        this.hoursnow,
        this.minutesnow,
        0,
        0
      );

      console.log(
        this.yearnow,
        this.monthnow,
        this.datenow,
        this.hoursnow,
        this.minutesnow
      );
      console.log(today);
      var UTCDt = today.toISOString();
      console.log(today.toLocaleString());

      this.checkintimeincst = today.toLocaleString();
      console.log(UTCDt);

      localStorage.setItem('alternatebrands', this.alternatebrands);

      localStorage.setItem('checkintime', UTCDt);
      localStorage.setItem('guestname', this.onLoginForm.value.guestName);
      localStorage.setItem('guestmobile', this.onLoginForm.value.guestMobile);
      localStorage.setItem('checkintimeincst', this.checkintimeincst);

      this.navCtrl.navigateForward('payment-options');
    } else {
      if (this.count == null) {
        this.count = 1;
      } else {
        this.count = this.onLoginForm.value.guestCount;
      }

      if (this.delivery) {
        console.log(this.deliverycharges),
          localStorage.setItem('deliverycharges', this.deliverycharges);
        let clong = JSON.stringify(this.customerLongitude);
        let clat = JSON.stringify(this.customerLatitude);
        let dist = JSON.stringify(this.distanceinmeters);
        localStorage.setItem('clong', clong);
        localStorage.setItem('clat', clat);
        localStorage.setItem('dist', dist);
        if (this.paramId == 'India Bazaar') {
          var today = new Date(
            this.yearnow,
            this.monthnow,
            this.datenow,
            16,
            0,
            0,
            0
          );
        } else {
          var today = new Date(
            this.yearnow,
            this.monthnow,
            this.datenow,
            this.hoursnow,
            this.minutesnow,
            0,
            0
          );
        }
        var UTCDt = today.toISOString();
        console.log(today.toLocaleString());
        // var onlyday=new Date(this.yearnow, this.monthnow, this.datenow)
        this.onlydayforrestaurants = today.toLocaleString();
        this.checkintimeincst = today.toLocaleString();
        console.log(UTCDt);

        localStorage.setItem('checkintime', UTCDt);

        localStorage.setItem('checkintimeincst', this.checkintimeincst);
        localStorage.setItem(
          'onlydayforrestaurants',
          this.onlydayforrestaurants
        );
        // console.log(today)
      }
      console.log(localStorage.getItem('deliverycharges')); // if(this.tablenumber==undefined){
      localStorage.setItem('alternatebrands', this.alternatebrands);

      localStorage.setItem('tablenumber', this.onLoginForm.value.tableNumber);
      localStorage.setItem('guestname', this.onLoginForm.value.guestName);
      localStorage.setItem('guestmobile', this.onLoginForm.value.guestMobile);
      localStorage.setItem('guestcount', this.count);

      localStorage.setItem('address', this.onLoginForm.value.address);
      localStorage.setItem('houseno', this.onLoginForm.value.houseNo);
      localStorage.setItem('landmark', this.onLoginForm.value.landmark);

      this.navCtrl.navigateForward('payment-options');

      // console.log(localStorage.getItem('tablenumber'))
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RestaurantService } from "src/app/providers";

@Component({
  selector: "app-coupon",
  templateUrl: "./coupon.page.html",
  styleUrls: ["./coupon.page.scss"],
})
export class CouponPage implements OnInit {
  list;
  emailFormArray: any = [];
  temp: any = [];
  constructor(
    private modalCtrl: ModalController,
    private rservice: RestaurantService
  ) {}

  ngOnInit() {
    // console.log(this.temp)
    // this.list=this.rservice.getcoupons().valueChanges()

    console.log(JSON.parse(localStorage.getItem("coupon")));
  }

  selectMember(email: string, isChecked: boolean, i) {
    if (isChecked) {
      this.emailFormArray.push(email);

      this.temp[i].isChecked = "true";
    } else {
      let index = this.emailFormArray.indexOf(email);
      this.emailFormArray.splice(index, 1);
      this.temp[i].isChecked = "false";
    }
    console.log(this.temp);
  }

  // getcoup(){
  //   console.log(this.emailFormArray);

  // }
  async closeModal() {
    await this.modalCtrl.dismiss(this.temp);
    localStorage.setItem("coupon", JSON.stringify(this.temp));
  }
}

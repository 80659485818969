<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="doneStuff">
    <h2>Book A Table</h2>
  </div>

  <form [formGroup]="eventForm" class="m-l-10">
    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Full Name *
      </ion-label>
      <ion-input formControlName="name" class="google-input" type="text">
      </ion-input>
      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('name').touched &&
          eventForm.get('name').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Email *
      </ion-label>
      <ion-input formControlName="email" class="google-input" type="text">
      </ion-input>
    </ion-item>

    <p
      ion-text
      class="text08"
      *ngIf="
        eventForm.get('email').touched && eventForm.get('email').errors?.email
      "
    >
      <ion-text color="primary">
        *This field is required in correct format for notifications
      </ion-text>
    </p>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Phone Number *
      </ion-label>
      <ion-input formControlName="phoneNumber" class="google-input" type="text">
      </ion-input>

      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('phoneNumber').touched &&
          eventForm.get('phoneNumber').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        No of Seats *
      </ion-label>
      <ion-input
        formControlName="seats"
        class="google-input"
        type="number"
        min="1"
        max="10"
      >
      </ion-input>

      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('seats').touched &&
          eventForm.get('seats').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>
  </form>

  <div>
    <!-- <ion-label padding class="available2"> Sponsor </ion-label>
    <br /> -->
  </div>
</ion-content>
<ion-footer>
  <ion-button
    size="large"
    expand="full"
    [disabled]="!eventForm.valid"
    (click)="bookSeats()"
    color="success"
    >Book Now</ion-button
  >
</ion-footer>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventOrdersService } from '../../providers/event-orders.service';

@Component({
  selector: 'app-event-confirmation',
  templateUrl: './event-confirmation.component.html',
  styleUrls: ['./event-confirmation.component.scss'],
})
export class EventConfirmationComponent implements OnInit {
  orderDetails;
  id;
  constructor(
    private ordersService: EventOrdersService,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.ordersService.getOrderDetails(this.id).subscribe((res: any) => {
      this.orderDetails = res[0].event;
    });
  }

  ngOnInit() {}
}

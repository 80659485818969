import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SwyloordersGuard } from 'src/app/guard/swyloorders.guard';
import { AdminUiComponent } from './pages/admin-ui/admin-ui.component';
import { CheckedInComponent } from './pages/checked-in/checked-in.component';
import { DonationsReportComponent } from './pages/donations-report/donations-report.component';
import { EventConfirmationComponent } from './pages/event-confirmation/event-confirmation.component';
import { EventFormComponent } from './pages/event-form/event-form.component';
import { EventsAdminComponent } from './pages/events-admin/events-admin.component';
import { EventsComponent } from './pages/events/events.component';
import { NewyearReportComponent } from './pages/newyear-report/newyear-report.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PlacedEventsComponent } from './pages/placed-events/placed-events.component';
import { RegistrationsReportComponent } from './pages/registrations-report/registrations-report.component';
import { SponsorReportComponent } from './pages/sponsor-report/sponsor-report.component';
import { SponsorComponent } from './pages/sponsor/sponsor.component';
import { TableBookingComponent } from './pages/table-booking/table-booking.component';
import { TicketsBookingComponent } from './pages/tickets-booking/tickets-booking.component';

const routes: Routes = [
  { path: '', component: EventsComponent },
  { path: 'form', component: EventFormComponent },
  { path: 'edit-form/:id', component: EventFormComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'sponsor', component: SponsorComponent },
  { path: 'event-confirmation/:id', component: EventConfirmationComponent },

  {
    path: 'admin-ui',
    component: AdminUiComponent,
    canActivate: [SwyloordersGuard],
  },
  {
    path: 'events-admin',
    component: EventsAdminComponent,
    canActivate: [SwyloordersGuard],
  },
  { path: 'sponsor-report', component: SponsorReportComponent },
  { path: 'registrations-report', component: RegistrationsReportComponent },
  { path: 'donations-report', component: DonationsReportComponent },
  { path: 'ny-report', component: NewyearReportComponent },

  { path: 'placed-events', component: PlacedEventsComponent },
  { path: 'aapiBooking', component: CheckedInComponent },
  { path: 'tickets-booking', component: TicketsBookingComponent },
  { path: 'table-booking', component: TableBookingComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsRoutingModule {}

import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { CartService } from 'src/app/providers';
import { EventsService } from './events.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/router";
import * as i3 from "../../../providers/cart/cart.service";
import * as i4 from "./events.service";
export class EventOrdersService {
    constructor(firestore, router, cartService, eventsService) {
        this.firestore = firestore;
        this.router = router;
        this.cartService = cartService;
        this.eventsService = eventsService;
    }
    postOrders(event, total, id) {
        const email = this.cartService.returnCustomerEmail();
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        this.storeEntry(event, total, id);
        return this.firestore
            .doc(`EventOrders/${id}`)
            .set({
            id,
            email,
            event,
            timestamp,
            total,
        })
            .then(() => this.goconfrm(id));
    }
    storeEntry(event, total, id) {
        let isgoogleLogin = false;
        if (localStorage.getItem('isAnonymous') &&
            localStorage.getItem('isAnonymous') === 'false') {
            isgoogleLogin = true;
        }
        const email = this.cartService.returnCustomerEmail();
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        return this.firestore.doc(`aapiEntry/${id}`).set({
            id,
            email,
            event,
            timestamp,
            total,
            isgoogleLogin,
        });
    }
    goconfrm(id) {
        this.eventsService.clean();
        this.router.navigate(['event-confirmation/' + id + '']);
    }
    getOrderDetails(id) {
        return this.firestore
            .collection(`EventOrders`, (ref) => {
            return ref.where('id', '==', id);
        })
            .valueChanges();
    }
    getOrderDetailsEmail(email) {
        return this.firestore
            .collection(`EventOrders`, (ref) => {
            return ref.where('email', '==', email);
        })
            .valueChanges();
    }
    getAllOrders() {
        return this.firestore
            .collection(`EventOrders`, (ref) => {
            return ref;
            // .where('email', '==', email);
        })
            .valueChanges();
    }
}
EventOrdersService.ngInjectableDef = i0.defineInjectable({ factory: function EventOrdersService_Factory() { return new EventOrdersService(i0.inject(i1.AngularFirestore), i0.inject(i2.Router), i0.inject(i3.CartService), i0.inject(i4.EventsService)); }, token: EventOrdersService, providedIn: "root" });

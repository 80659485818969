import { Injectable, NgZone, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavController, Platform, LoadingController } from '@ionic/angular';
import { User } from 'firebase';
import * as firebase from 'firebase/app';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { RestaurantService } from '../restaurant/restaurant.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateProvider {
  private translations: any;
  public user: User;

  @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>();
  public isadmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Output() url: EventEmitter<any> = new EventEmitter<any>();
  @Output() email1: EventEmitter<any> = new EventEmitter<any>();

  facebookName;
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  swyloemail;
  item;
  google1;
  admin;
  itemCollection;
  owners;
  constructor(
    public afAuth: AngularFireAuth,

    public navCtrl: NavController,
    public ngZone: NgZone,
    // private gplus:GooglePlus,
    private platform: Platform,
    private loadingController: LoadingController,
    private location: Location,
    private zone: NgZone,
    private firestore: AngularFirestore,
    private rservice: RestaurantService
  ) {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      this.zone.run(() => {
        firebaseUser ? this.loggedIn.next(true) : this.loggedIn.next(false);
      });
    });

    if (this.afAuth) {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.user = user;
          localStorage.setItem('user', JSON.stringify(this.user));

          this.fireIsLoggedIn.emit(user.displayName);
          this.url.emit(user.photoURL);
          this.email1.emit(user.email);
          this.swyloemail = user.email;
          //this.navCtrl.navigateRoot('/payment-options');
        } else {
          localStorage.setItem('user', null);
        }
      });
    }
    const user: boolean = JSON.parse(localStorage.getItem('user'));

    /* if (user!=null){
                      this.navCtrl.navigateRoot('/home-location');
                    } 
                    if(user==null){
                      this.navCtrl.navigateRoot('/login');
                
                    }*/
  }

  getEmitter() {
    return this.fireIsLoggedIn;
  }
  getEmitter2() {
    return this.url;
  }
  getEmitter3() {
    return this.email1;
  }
  async getswyloemail() {
    return await this.swyloemail;
  }
  ionViewWillEnter() {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user.email);
    this.rservice
      .getOwners()
      .valueChanges()
      .subscribe((res) => {
        console.log(res);
        this.owners = res;
        let item = this.owners.find((p) => p.email == user.email);
        if (item == undefined) {
          this.admin = false;
        } else {
          this.admin = true;
        }
      });
  }

  ngOnInit() {}

  // Set the translations of the app.
  public setTranslations(translations: any): void {
    this.translations = translations;
  }

  public getTranslations(): any {
    return this.translations;
  }

  // Get the translated string given the key based on the i18n .json file.
  public get(key: string): string {
    return this.translations[key];
  }

  async presentLoading(loading) {
    return await loading.present();
  }

  async native() {
    // try {
    //   const gpuser = await this.gog.login({
    //     webClientId:
    //       '37639046366-pol88ucihq56c0pbdn5d0tqimg0ujr3e.apps.googleusercontent.com',
    //     offline: true,
    //     scopes: 'profile email',
    //   });
    //   return await this.afAuth.auth.signInWithCredential(
    //     firebase.auth.GoogleAuthProvider.credential(gpuser.idToken)
    //   );
    // } catch (err) {
    //   console.log(err);
    // }
  }
  async google() {
    if (this.platform.is('capacitor')) {
      this.native();
      console.log('tru');
    } else {
      var provider = new firebase.auth.GoogleAuthProvider();

      provider.setCustomParameters({
        prompt: 'select_account',
      });

      firebase.auth().signInWithRedirect(provider);

      firebase
        .auth()
        .getRedirectResult()
        .then(function (result) {
          if (result.credential) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // ...
          }
          // The signed-in user info.
          var user = result.user;
          console.log('from translate provider' + user);
        })
        .catch(function (error) {
          // Handle Errors here.
          console.log('from translate provider', error);
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
    }
  }
  isUserEqual(facebookAuthResponse, firebaseUser): boolean {
    if (firebaseUser) {
      const providerData = firebaseUser.providerData;

      providerData.forEach((data) => {
        if (
          data.providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
          data.uid === facebookAuthResponse.userID
        ) {
          // We don't need to re-auth the Firebase connection.
          return true;
        }
      });
    }

    return false;
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    if (user !== null) {
      return true;
    } else {
      return false;
    }
    //return (user !== null && user.emailVerified !== false) ? true : false;
  }

  set isadminn(isadmin: boolean) {
    this.admin = isadmin;
  }
  get isadminn(): boolean {
    return this.admin;
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checked-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./checked-in.component";
import * as i6 from "../../providers/events.service";
import * as i7 from "../../../../providers/cart/cart.service";
import * as i8 from "@angular/router";
var styles_CheckedInComponent = [i0.styles];
var RenderType_CheckedInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckedInComponent, data: {} });
export { RenderType_CheckedInComponent as RenderType_CheckedInComponent };
function View_CheckedInComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-card", [], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "img", [["class", "image"], ["src", "https://firebasestorage.googleapis.com/v0/b/localbooklet.appspot.com/o/check-mark.png?alt=media&token=07a38d15-8d8c-4deb-9a7e-2cba6b33a8a5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "h1", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["checked in successfully. Tickets G1,G2"]))], null, null); }
function View_CheckedInComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-card", [["style", "text-align: center"]], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-card-header", [], null, null, null, i2.View_IonCardHeader_0, i2.RenderType_IonCardHeader)), i1.ɵdid(3, 49152, null, 0, i3.IonCardHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, [" You have'nt booked your tickets Yet, get started! "])), (_l()(), i1.ɵeld(5, 0, null, 0, 4, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(6, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToAapi() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Get Started"]))], null, null); }
export function View_CheckedInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(5, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "ion-menu-button", [["color", "light"]], null, null, null, i2.View_IonMenuButton_0, i2.RenderType_IonMenuButton)), i1.ɵdid(7, 49152, null, 0, i3.IonMenuButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(9, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CheckedInComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseContent", 2]], 0, 0, null, View_CheckedInComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "light"; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.verified; var currVal_3 = i1.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_2, currVal_3); }, null); }
export function View_CheckedInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-checked-in", [], null, null, null, View_CheckedInComponent_0, RenderType_CheckedInComponent)), i1.ɵdid(1, 114688, null, 0, i5.CheckedInComponent, [i3.MenuController, i6.EventsService, i7.CartService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckedInComponentNgFactory = i1.ɵccf("app-checked-in", i5.CheckedInComponent, View_CheckedInComponent_Host_0, {}, {}, []);
export { CheckedInComponentNgFactory as CheckedInComponentNgFactory };

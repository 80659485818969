import { Component, ViewChild } from '@angular/core';
import {
  NavController,
  ModalController,
  IonItemSliding,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare const onGooglePayLoaded: any;
declare const getGoogleTransactionInfo: any;
import { timer } from 'rxjs/observable/timer';
import { List } from 'src/app/pages/list';
import {
  DishService,
  CartService,
  TranslateProvider,
  RestaurantService,
} from '../../../providers';
import { Location } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
})
export class CartPage {
  deleteid: any[] = [];
  dissap = true;
  orders: Array<any>;
  totalVal = 0;
  user: any[] = [];
  total2: any = 0;
  length: boolean;
  test: any;
  test1: any;
  data: any;
  user22: any;
  order_type = 2;
  revenue_center = 1;
  employee = 200;
  quantity: number;
  song1;
  fquantity: number;
  plus: any = [];
  minus: any = [];
  deleteorder1: {};
  deleteorder2: any[];
  //items=JSON.parse(localStorage.getItem('favourite22'))
  timel;
  res2: any[] = [];
  subscriptionretrive: Subscription;
  fires;
  firea: any[] = [];
  squarea: any[] = [];
  IB: boolean = true;
  @ViewChild('slidingList') slidingList: IonItemSliding;
  min: any;
  exp: any;
  constructor(
    public navCtrl: NavController,
    // public dishService: DishService,
    public cartService: CartService,
    public route: Router,
    private modalCtrl: ModalController,
    // private http:HttpClient,
    private rservice: RestaurantService,
    // private location:Location,
    private firestore: AngularFirestore,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    public datepipe: DatePipe
  ) {}
  imgload: boolean = false;
  list: List[];
  heroes: List[];
  total3: any = 0;
  okay;
  okay2;
  k;
  lengthoffirebaseelements;
  result123;
  squareserver;
  tax: any = 0;
  taxplustotal;
  businessName;
  specifictax;
  nowcalculate;
  subscriptiont: Subscription;
  subscribecart: boolean = false;
  couponcode;
  menu;
  applieddiscount = 0;
  couponname;
  discountbool: boolean = false;
  couponpercentage = 0;
  date;
  onClick() {
    // myTest();
    onGooglePayLoaded(this.total2);

    // getGoogleTransactionInfo(this.total2)
  }

  usecode() {
    console.log(this.couponcode);
    if (JSON.parse(localStorage.getItem('user')) != null) {
      let menu1 = this.menu.find((p) => p.couponname == this.couponcode);
      console.log(menu1);
      this.date = new Date();
      let latest_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');

      if (menu1 == undefined || latest_date > menu1.exp) {
        console.log('undefined');
        this.toastCtrl
          .create({
            message: 'Coupon Expired or Invalid',
            duration: 500,
            position: 'middle',
            cssClass: 'my-custom-class',
            color: 'primary',
          })
          .then((obj) => {
            obj.present();
          });
      } else {
        this.discountbool = true;
        if (this.taxplustotal > menu1.min) {
          this.toastCtrl
            .create({
              message: 'Coupon ' + menu1.couponname + ' Applied',
              duration: 500,
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'success',
            })
            .then((obj) => {
              obj.present();
            });
          console.log('apply discount');
          this.couponname = menu1.couponname;
          this.couponpercentage = menu1.percentage;
          this.applieddiscount = (menu1.percentage / 100) * this.taxplustotal;
          this.min = menu1.min;
          this.exp = menu1.exp;
          console.log(this.applieddiscount);
        } else {
          this.toastCtrl
            .create({
              message: 'Minimum order value should be ' + menu1.min + ' ',
              duration: 500,
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'success',
            })
            .then((obj) => {
              obj.present();
            });
        }
      } //elseclose
    } //ifclose
    else {
      this.toastCtrl
        .create({
          message: 'Login to use the Coupon',
          duration: 500,
          position: 'middle',
          cssClass: 'my-custom-class',
          color: 'success',
        })
        .then((obj) => {
          obj.present();
        });
    } //elseclose
  } //usecode

  ngOnDestroy() {
    if (this.subscribecart) {
      this.subscriptiont.unsubscribe();
    }
  }

  ngOnInit() {}
  //  getCartList(){
  //  this.list= this.rservice.getCartList().valueChanges()
  //  this.okay=this.list.length
  //  }
  calculate() {
    localStorage.removeItem('facebook');
    localStorage.removeItem('google');

    // this.song1=this.rservice.businessfromdpage(localStorage.getItem("id")).valueChanges();

    if (JSON.parse(localStorage.getItem('user')) != null) {
      this.subscriptiont = this.rservice
        .getCartList()
        .valueChanges()
        .subscribe((res) => {
          console.log(res);

          this.subscribecart = true;
          this.list = res;
          this.okay = res.length;

          if (res.length) {
            // console.log(res[0].businessName)
            this.businessName = res[0].businessName;
            this.rservice.getCoupons(this.businessName).subscribe((data) => {
              // console.log(data)
              this.menu = data.map((e) => {
                return {
                  id: e.payload.doc.id,
                  couponname: e.payload.doc.data()['couponname'],
                  isActive: e.payload.doc.data()['isActive'],
                  percentage: e.payload.doc.data()['percentage'],
                  min: e.payload.doc.data()['min'],
                  exp: e.payload.doc.data()['exp'],
                };
              });
            });
            if (res[0].businessName == 'Beyond Curry') {
              this.IB = false;
            }

            console.log(res[0].tax);
            if (!isNaN(res[0].tax)) {
              console.log('isnan');
              for (let i = 0; i < res.length; i++) {
                // console.log(res[i].tax)
                // console.log((res[i].tax/100)*res[i].amount)

                this.total3 =
                  this.total3 +
                  res[i].amount * res[i].quantity +
                  (res[i].tax / 100) * (res[i].amount * res[i].quantity);
                this.tax =
                  this.tax +
                  (res[i].tax / 100) * (res[i].amount * res[i].quantity);
              }

              console.log(Math.round(this.total3 * 100) / 100);
              console.log(Math.round(this.tax * 100) / 100);

              let t: any = Math.round(this.tax * 100) / 100;
              // console.log(t)

              this.taxplustotal = this.total3;
              if (this.discountbool) {
                if (this.taxplustotal > this.menu.min) {
                  this.applieddiscount =
                    (this.couponpercentage / 100) * this.taxplustotal;
                } else {
                  this.applieddiscount = 0;
                }
              }
            } else {
              console.log('else statement');
              this.businessName = res[0].businessName;

              this.okay = res.length;
              for (let i = 0; i < res.length; i++) {
                // this.businessName=res[0].businessName
                // console.log(res[0].businessName)

                this.total3 = this.total3 + res[i].amount * res[i].quantity;
              }

              this.rservice
                .getBusinesstax(this.businessName)
                .valueChanges()
                .subscribe((res) => {
                  this.nowcalculate = res;
                  // console.log(res[0].tax)
                  this.specifictax = res[0].tax;

                  localStorage.setItem('squaretotal', this.total3);
                  // console.log(this.total3)

                  this.tax = (this.specifictax / 100) * this.total3;

                  let t = 0;
                  t = parseFloat(this.tax) + parseFloat(this.total3);

                  this.taxplustotal = Math.round(t * 100) / 100;

                  // console.log(Math.round(this.taxplustotal*100)/100)
                });
            }

            console.log(this.taxplustotal);
          }
        });
    } else {
      this.list = JSON.parse(localStorage.getItem('favourite'));

      if (this.list) {
        this.okay2 = JSON.parse(localStorage.getItem('favourite')).length;
        // console.log(this.okay2)
      }
      if (this.okay2) {
        // console.log(localStorage.getItem('favourite'))
        this.list = JSON.parse(localStorage.getItem('favourite'));

        let fresh = JSON.parse(localStorage.getItem('favourite'));

        // console.log(fresh[0].businessName)
        this.businessName = fresh[0].businessName;
        if (fresh[0].businessName == 'Beyond Curry') {
          this.IB = false;
        }
        if (!isNaN(fresh[0].tax)) {
          // if(!isNaN(res[0].tax)){

          console.log('didnot enterd');
          for (let j = 0; j < fresh.length; j++) {
            // this.total3=this.total3+res[i].amount*res[i].quantity+((res[i].tax/100)*(res[i].amount*res[i].quantity))
            // this.tax=this.tax+(res[i].tax/100)*(res[i].amount*res[i].quantity)

            this.total3 =
              this.total3 +
              fresh[j].amount * fresh[j].quantity +
              (fresh[j].tax / 100) * fresh[j].amount * fresh[j].quantity;
            this.tax =
              this.tax +
              (fresh[j].tax / 100) * (fresh[j].amount * fresh[j].quantity);
          }
          this.taxplustotal = this.total3;
        } else {
          for (let j = 0; j < this.list.length; j++) {
            this.total3 =
              this.total3 + this.list[j].amount * this.list[j].quantity;
            // console.log("actual thing"+this.total3)
          }
          //  console.log(this.total3)
          if (JSON.parse(localStorage.getItem('favourite')).length) {
            this.businessName = fresh[0].businessName;

            this.rservice
              .getBusinesstax(this.businessName)
              .valueChanges()
              .subscribe((res) => {
                this.nowcalculate = res;
                //  console.log(res[0].tax)
                this.specifictax = res[0].tax;

                //  console.log((this.specifictax/100)*this.total3)

                this.tax = (this.specifictax / 100) * this.total3;

                //  let x:string= (this.specifictax)
                //   console.log(Math.round(x + "e+2")  + "e-2")
                console.log(this.tax);
                // console.log(this.tax.roundToTwo(2))
                let t = 0;
                t = parseFloat(this.tax) + parseFloat(this.total3);

                this.taxplustotal = Math.round(t * 100) / 100;
              });
          }
        }
      }
    }

    if (JSON.parse(localStorage.getItem('favourite')) == null) {
      this.user = [];
    } else {
      //from localStorage
      this.user = JSON.parse(localStorage.getItem('favourite'));
      // console.log(this.user)
    }
  }

  ionViewWillEnter() {
    // localStorage.removeItem('delete')
    this.calculate();
  }
  async removeOrder(order, id, menuid) {
    if (JSON.parse(localStorage.getItem('user')) != null) {
      const loader = await this.loadingCtrl.create({
        // duration: 900
      });
      loader.present();
      // console.log(id)
      this.total3 = 0;
      // this.taxplustotal=0
      this.tax = 0;
      this.firestore.doc('LocalBookletCart/' + id).delete();

      timer(500).subscribe(() => {
        loader.dismiss();
        /* this.noresults="no google places results found";*/
      });
    } else {
      this.user.splice(order, 1);
      //this.user22.splice(order,1)
      // localStorage.setItem('favourite22',JSON.stringify(this.user22))
      localStorage.setItem('favourite', JSON.stringify(this.user));
      this.total3 = 0;
      this.tax = 0;

      // console.log(this.user)
      //console.log(this.user22)
      this.calculate();
    }

    //console.log(this.user)
    //console.log(this.user22)
    // this.ngOnInit()
    //this.ngOnInit()
  }

  // minus adult when click minus button
  async minusQtd(order, id) {
    if (JSON.parse(localStorage.getItem('user')) != null) {
      this.total3 = 0;
      this.tax = 0;
      const loader = await this.loadingCtrl.create({
        // duration: 900
      });
      loader.present();
      this.firestore
        .collection('LocalBookletCart')
        .doc(id)
        .get()
        .subscribe((res) => {
          // console.log(res.data().quantity)
          this.fquantity = res.data().quantity;
          let record = {};
          record['quantity'] = res.data().quantity - 1;

          this.firestore.doc('LocalBookletCart/' + id).update(record);
        });

      timer(1000).subscribe(() => {
        loader.dismiss();
        /* this.noresults="no google places results found";*/
      });
    } else {
      this.user[order].quantity--;
      // console.log(this.user)
      //this.user22.splice(order,1)
      // localStorage.setItem('favourite22',JSON.stringify(this.user22))
      localStorage.setItem('favourite', JSON.stringify(this.user));
      this.total3 = 0;
      this.tax = 0;

      // console.log(this.user)
      //console.log(this.user22)
      this.calculate();
    }
  }
  errorHandler(event) {
    var defaultimg;
    this.rservice
      .getBusinessByName(this.businessName)
      .valueChanges()
      .subscribe((res) => {
        // console.log(this.businessName)
        defaultimg = res[0].defaultimg;
        // console.log(defaultimg)
        event.target.src = res[0].defaultimg;
      });
    console.debug(event);
  }
  // plus adult when click plus button
  async plusQtd(order, id) {
    // console.log(JSON.parse(localStorage.getItem('manage')))
    // this.plus=JSON.parse(localStorage.getItem('manage'))
    // for(let i=0;i<1;i++){
    //   console.log(this.plus[i].quantity++)
    //   localStorage.setItem('manage',JSON.stringify(this.plus))

    // }
    // console.log(JSON.parse(localStorage.getItem('manage')))

    if (JSON.parse(localStorage.getItem('user')) != null) {
      this.total3 = 0;
      this.tax = 0;
      const loader = await this.loadingCtrl.create({
        duration: 3000,
      });
      loader.present();

      this.firestore
        .collection('LocalBookletCart')
        .doc(id)
        .get()
        .subscribe((res) => {
          // console.log(res.data().quantity)
          // this.fquantity=res.data().quantity
          let record = {};
          record['quantity'] = ++res.data().quantity;

          this.firestore.doc('LocalBookletCart/' + id).update(record);
        });

      timer(1000).subscribe(() => {
        loader.dismiss();
        /* this.noresults="no google places results found";*/
      });
    } else {
      this.user[order].quantity++;
      // console.log(this.user[order].quantity)
      localStorage.setItem('favourite', JSON.stringify(this.user));
      this.total3 = 0;
      this.tax = 0;
      this.calculate();
    }
  }

  onimgload() {
    this.imgload = true;
  }
  openCheckout() {
    if (this.discountbool) {
      localStorage.setItem('discountbool', 'applied');
      localStorage.setItem(
        'applieddiscount',
        JSON.stringify(this.applieddiscount)
      );

      localStorage.setItem(
        'couponpercentage',
        JSON.stringify(this.couponpercentage)
      );
    } else {
      localStorage.setItem('discountbool', 'notapplied');
      localStorage.setItem('applieddiscount', JSON.stringify(0));
    }
    localStorage.setItem('favourite', JSON.stringify(this.user));
    localStorage.setItem('tax', this.specifictax);

    let subtotal = this.taxplustotal - this.tax;
    // console.log(subtotal)
    localStorage.setItem('subtotal', JSON.stringify(subtotal));

    localStorage.setItem('taxplustotal', this.taxplustotal);

    localStorage.setItem('subtotaltax', this.tax);

    var x: any = localStorage.getItem('squaretotal');
    // console.log("from open chek"+x*100)
    this.cartService.send(this.total3);
    //console.log(this.total2)
    const vari = JSON.parse(localStorage.getItem('user'));

    if (JSON.parse(localStorage.getItem('user')) != null) {
      this.route.navigate(['check-in/' + this.businessName + '']);
    } else {
      this.route.navigate(['login']);
      localStorage.setItem('cart', 'cart');
    }
    this.modalCtrl.dismiss();
  }

  closeModal() {
    this.modalCtrl.dismiss();
    //this.location.back()
  }
}

<ion-header>
  <ion-toolbar color="primary">
    <ion-title color="light"
      >{{'app.pages.cart.title.header' | translate}}</ion-title
    >
    <ion-buttons slot="end">
      <ion-button
        size="small"
        shape="round"
        (click)="closeModal()"
        color="light"
      >
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid no-padding fixed>
    <ion-row no-padding>
      <ion-col no-padding>
        <ion-card *ngIf="!(okay||okay2)" color="success" margin-top>
          <ion-card-content>
            <p text-center class="text-white">
              {{'app.pages.cart.label.error' | translate}}
            </p>
          </ion-card-content>
        </ion-card>

        <!-- <div *ngIf="!lengthoffirebaseelements" class="firebasespinner" >
          <ion-spinner  name="circles"></ion-spinner>
        </div> -->

        <ion-list no-margin *ngIf="list">
          <ion-item-sliding
            *ngFor="let order of list; let i=index"
            #slidingList
          >
            <ion-item class="bg-white" lines="none">
              <ion-thumbnail slot="start">
                <img [src]="order.img" *ngIf="!IB" />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/localbooklet.appspot.com/o/{{order?.businessName}}%2F{{order?.name}}.jpg?alt=media&token"
                  *ngIf="IB"
                  class="ionimage"
                  (error)="errorHandler($event)"
                />
              </ion-thumbnail>
              <ion-label>
                <h2 class="fw700">
                  <ion-text color="dark">{{order.name}}</ion-text>
                </h2>
                <ion-text class="addOns" *ngIf="order.addOnString"
                  >{{order.addOnString}}</ion-text
                >

                <ion-grid class="filters" no-padding *ngIf="list">
                  <ion-row>
                    <ion-col text-center col-2>
                      <ion-icon
                        name="remove-circle"
                        class="text15"
                        tappable
                        (click)="minusQtd(i,order.Id)"
                        [hidden]="order.quantity < 2"
                        color="secondary"
                      ></ion-icon>
                    </ion-col>
                    <ion-col text-center col-3>
                      <h2 class="text15">
                        <ion-text color="primary"
                          >{{ order.quantity }}</ion-text
                        >
                      </h2>
                    </ion-col>

                    <ion-col text-center col-2>
                      <ion-icon
                        name="add-circle"
                        class="text15"
                        tappable
                        (click)="plusQtd(i,order.Id)"
                        color="secondary"
                      ></ion-icon>
                    </ion-col>
                    <ion-col text-center col-3>
                      <ion-icon
                        name="trash"
                        color="primary"
                        class="text15"
                        (click)="removeOrder(i,order.Id,order.menuid)"
                      ></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <div item-right>
                  <ion-badge
                    >{{ order.amount * order.quantity | currency }}</ion-badge
                  >
                </div>
                <!-- <div item-left>
                    <ion-badge>{{ order.amount * order.quantity | currency }}</ion-badge>
                  </div> -->
              </ion-label>
            </ion-item>
            <ion-item-options>
              <ion-item-option
                color="danger"
                (click)="removeOrder(i,order.Id,order.men)"
              >
                {{'app.cart.delete' | translate }}
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>

        <hr />

        <!-- <ion-list>
          <ion-list-header class='couponheader'>
            Coupon
          </ion-list-header>
          <ion-item *ngIf="(okay||okay2)" class="bg-white">
            <ion-input placeholder='Enter Coupon Code' [(ngModel)]='couponcode' class="couponcss"></ion-input>
            <ion-button expand='block ' class='couponbutton' color="secondary" (click)="usecode()">Apply</ion-button>
          </ion-item>
        </ion-list> -->

        <ion-card class="mycolour" no-margin *ngIf="(okay||okay2)">
          <ion-card-content>
            <hr />
            <p>
              <ion-text color="light"
                >{{'app.cart.subtotal' | translate}}: {{ taxplustotal-tax |
                currency }}</ion-text
              >
            </p>
            <!-- <p>
          <ion-text color="light" >{{ taxplustotal-tax | currency }}</ion-text>
          </p> -->
            <p>
              <ion-text color="light"
                >{{'app.cart.tax' | translate}}: {{ tax | currency }}</ion-text
              >
            </p>
            <p *ngIf="discountbool">
              <ion-text color="light"
                >Coupon Amount: {{ applieddiscount | currency }}</ion-text
              >
            </p>
            <!-- <ion-text color="light" >{{ tax | currency }}</ion-text> -->
            <p>
              <ion-text color="light"
                >{{'app.cart.total' | translate}} : {{
                taxplustotal-applieddiscount | currency }}
              </ion-text>
            </p>
            <!-- <ion-text color="light" >{{ taxplustotal | currency }}</ion-text> -->
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!--
  <ion-button id="buy-now" class='button1' (click)='onClick()' *ngIf="total2" color="success" no-margin size ="large">Buy With Google Pay

  </ion-button>
  <ion-label id ='buy-later' class="buy-later" ></ion-label>

-->
</ion-content>

<ion-footer *ngIf="list" class="no-padding">
  <!-- <ion-card >
  <ion-card-content no-padding class="selectatip" color="dark">  
    select a tip
  </ion-card-content>
  <ion-segment (ionChange)="segmentChanged($event)"  mode="ios" color="secondary" padding>
    <ion-segment-button value="1">
      <ion-label>$1</ion-label>
    </ion-segment-button>
    <ion-segment-button value="2">
      <ion-label>$2</ion-label>
    </ion-segment-button>
    <ion-segment-button value="3">
      <ion-label>$3</ion-label>
    </ion-segment-button>
    <ion-segment-button value="4">
      <ion-label>$4</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-card> -->
  <!--
  <ion-button size="large" expand="full" color="success" no-margin (click)="openPatcounter()" *ngIf="total2">
      <ion-text color="light">Pay At The Counter</ion-text>
      <ion-icon slot="start" name="card" color="light"></ion-icon>
    </ion-button><br>-->
  <ion-button
    size="large"
    expand="full"
    color="success"
    no-margin
    (click)="openCheckout()"
    *ngIf="total3"
  >
    <ion-text color="light">{{'app.cart.checkout' | translate}}</ion-text>
    <ion-icon slot="start" name="card" color="light"></ion-icon>
  </ion-button>
  <!-- 
      <ion-button size="large" expand="full" color="success" no-margin (click)="get()" >
        <ion-text color="light">get</ion-text>
        <ion-icon slot="start" name="card" color="light"></ion-icon>
      </ion-button> -->
</ion-footer>

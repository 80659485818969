<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
      ></ion-icon>
    </ion-buttons>
    <!-- <h3>{{item.item}}</h3> -->
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="margin-left: 12px">
    <h3>{{item.item}}</h3>
    <label>{{item.description}}</label>
  </div>
  <div *ngIf="displayExtras">
    <ion-radio-group (ionChange)="emitSize($event)" *ngIf="item.price">
      <ion-list-header> Choose size </ion-list-header>
      <ion-item *ngFor="let item of item.price; let idx = index" lines="none">
        <ion-label
          >{{ item.type | uppercase}} &nbsp;&nbsp; ${{item.price}}</ion-label
        >
        <ion-radio slot="start" [checked]="idx===0" [value]="item"></ion-radio>
      </ion-item>
    </ion-radio-group>

    <ion-radio-group (ionChange)="emitStyle($event)">
      <ion-list-header> Choose styles </ion-list-header>
      <ion-item *ngFor="let item of styles; let idx = index" lines="none">
        <ion-label>{{ item.type }}</ion-label>
        <ion-radio slot="start" [checked]="idx===0" [value]="item"></ion-radio>
      </ion-item>
    </ion-radio-group>

    <ion-list>
      <ion-list-header>Wich Add-ons</ion-list-header>
      <ion-item *ngFor="let entry of addOns">
        <ion-label>{{entry.val}}</ion-label
        ><ion-label *ngIf="entry.price"> $ {{entry.price}}</ion-label>
        <ion-checkbox
          slot="end"
          [(ngModel)]="entry.isChecked"
          (ionChange)="emitAddOn($event)"
          [value]="entry"
        ></ion-checkbox>
      </ion-item>
    </ion-list>
  </div>
</ion-content>

<ion-footer style="background: lightgray">
  <ion-item class="plusminusgrid" lines="none">
    <!-- <ion-icon
      name="remove-circle"
      class="fs-35"
      tappable
      (click)="minusQtd()"
      color="secondary"
    ></ion-icon>
    &nbsp;&nbsp;
    <h2 ion-text>{{ qtd }}</h2>
    &nbsp;&nbsp;
    <ion-icon
      name="add-circle"
      class="fs-35"
      tappable
      (click)="plusQtd()"
      color="secondary"
    ></ion-icon>
    &nbsp;&nbsp;&nbsp;&nbsp; -->
    <ion-button
      style="width: 100%"
      size="medium"
      color="secondary"
      (click)="addcart(qtd)"
    >
      <ion-icon slot="start" name="cart" color="light"></ion-icon>
      <ion-text color="light">Add({{ itemPrice * qtd | currency }})</ion-text>
    </ion-button>
  </ion-item>
</ion-footer>

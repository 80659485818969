import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { Location } from '@angular/common';
import { EventsService } from '../../providers/events.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-newyear-report',
  templateUrl: './newyear-report.component.html',
  styleUrls: ['./newyear-report.component.scss'],
})
export class NewyearReportComponent implements OnInit {
  report: any;
  fileName = 'ExcelSheet.xlsx';

  constructor(
    private eventsService: EventsService,
    private location: Location,
    private menuCtrl: MenuController
  ) {
    this.menuCtrl.enable(false);

    this.eventsService.getNewYearReport().subscribe((res: any) => {
      this.report = res;
      console.log(res);
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  ngOnInit() {}
  back() {
    this.location.back();
  }
}

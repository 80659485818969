<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon name="arrow-round-back" size="large" (click)="back()" color="light"></ion-icon>

    </ion-buttons>
    <ion-title>{{ 'app.pages.settings.title.header' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <ion-item class="bg-profile">
   
    <ion-label>
      <ion-text color="light">
        <h2>
          <strong>{{'app.pages.settings.name'| translate}}</strong>
        </h2>
      </ion-text>
     
      <ion-button icon-left size="small" shape="round" color="dark" (click)="editProfile()">
        <ion-icon name="contact"></ion-icon>
        {{ 'app.pages.settings.profile' | translate }}
      </ion-button>
       
    </ion-label>
  </ion-item> -->

  <ion-list no-border>
    <ion-list-header>
      <ion-text color="dark">
        <h5> {{ 'app.pages.settings.general' | translate }}
        </h5>
      </ion-text>
    </ion-list-header>
    <!-- <ion-item>
      <ion-icon name="settings" slot="start" color="primary"></ion-icon>
      <ion-label color="primary">App Language</ion-label>
      <ion-select [(ngModel)]="lang">
        <ion-select-option *ngFor="let language of languages" [value]="language">{{language}}</ion-select-option>
      </ion-select>
    </ion-item> -->

    <ion-item>
      <ion-label class="label" color="primary" (click)="checkForUpdate()" *ngIf="update"> {{
        'app.pages.settings.checkforupdates' | translate }} </ion-label>
      <ion-label *ngIf="checkupdate">
        {{ 'app.pages.settings.checkingforupdates' | translate }} </ion-label>
    </ion-item>



  </ion-list>

  <!--  


  <ion-list>
    <ion-list-header>
      <ion-text color="dark">
        <h5>Currency</h5>
      </ion-text>
    </ion-list-header>
    <ion-item>
      <ion-icon name="card" slot="start" color="primary"></ion-icon>
      <ion-label color="primary">Payment Method</ion-label>
      <ion-select [(ngModel)]="paymentMethod">
        <ion-select-option *ngFor="let method of paymentMethods" [value]="method">{{method}}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-icon name="logo-usd" slot="start" color="dark"></ion-icon>
      <ion-label color="primary">Currency</ion-label>
      <ion-select [(ngModel)]="currency">
        <ion-select-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list> -->


  <!-- <ion-list>
    <ion-list-header>
      <ion-text color="dark">
        <h5>Other</h5>
      </ion-text>
    </ion-list-header>
    <ion-item>
      <ion-icon name="mail" slot="start" color="primary"></ion-icon>
      <ion-label class="label" color="primary">Enable Promotions</ion-label>
      <ion-toggle [(ngModel)]="enablePromo"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-icon name="mail" slot="start" color="primary"></ion-icon>
      <ion-label class="label" color="primary">Enable History</ion-label>
      <ion-toggle [(ngModel)]="enableHistory"></ion-toggle>
    </ion-item>
  </ion-list>
  -->
  <ion-item>
    <ion-icon name="settings" slot="start" color="primary"></ion-icon>
    <ion-label color="primary">{{ 'app.pages.settings.App Language' | translate }} </ion-label>
    <ion-select [(ngModel)]="lang" (ionChange)="useLanguage($event)">
      <ion-select-option *ngFor="let language of languages" [value]="language.value">{{language.language}}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <!-- translation: translation pipe -->
  <!-- translation: directive (key as attribute) -->

  <!-- translation: directive (key as content of element)-->

  <!-- <p>{{ 'demo.text' | translate:{'name':'Andreas'} }}</p>

<p [translate]="'demo.text'" [translateParams]="{name: 'Andreas'}"></p>

<p translate [translateParams]="{name: 'Andreas'}">demo.title</p> -->
</ion-content>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NavController, MenuController, Platform, ToastController, AlertController, LoadingController, ModalController, } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { take } from 'rxjs/operators';
import { CartService, TranslateProvider } from 'src/app/providers';
import { CartPage } from '../cart/cart.page';
export class GuestLoginPage {
    constructor(navCtrl, menuCtrl, toastCtrl, alertCtrl, loadingCtrl, translate, formBuilder, afAuth, router, service, modalCtrl, cartservice, platform, route) {
        this.navCtrl = navCtrl;
        this.menuCtrl = menuCtrl;
        this.toastCtrl = toastCtrl;
        this.alertCtrl = alertCtrl;
        this.loadingCtrl = loadingCtrl;
        this.translate = translate;
        this.formBuilder = formBuilder;
        this.afAuth = afAuth;
        this.router = router;
        this.service = service;
        this.modalCtrl = modalCtrl;
        this.cartservice = cartservice;
        this.platform = platform;
        this.route = route;
        this.email = '';
        this.spin = false;
        this.facebookk = false;
        this.elements = false;
        this.disable = false;
        this.guestuser = false;
        this.onLoginForm = this.formBuilder.group({
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
                ],
            ],
        });
    }
    ionViewWillEnter() {
        console.log(localStorage.getItem('cart'));
        this.disable = false;
        if (localStorage.getItem('google') == 'google' ||
            localStorage.getItem('facebook') == 'facebook') {
            this.elements = true;
            this.disable = true;
        }
    }
    ionViewDidEnter() {
        this.menuCtrl.enable(false);
        this.user = JSON.parse(localStorage.getItem('favourite'));
        console.log(this.user);
        if (localStorage.getItem('google') == 'google' ||
            localStorage.getItem('facebook') == 'facebook') {
            this.elements = true;
            this.disable = true;
        }
    }
    opencart() {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create({
                component: CartPage,
            });
            return yield modal.present();
        });
    }
    anonymousLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            firebase.auth().signInAnonymously();
            localStorage.setItem('customerEmail', this.onLoginForm.value.email);
            console.log('set and get' + localStorage.getItem('customerEmail'));
            this.loader = yield this.loadingCtrl.create({
                message: 'please wait...',
                duration: 4000,
            });
            this.loader.present();
            this.modalCtrl.dismiss();
            // setTimeout(()=> this.storeInFirestore(this.onLoginForm.value.email),2000)
        });
    }
    storeInFirestore(email) {
        console.log(email);
        this.cartData = JSON.parse(localStorage.getItem('favourite'));
        if (this.cartData && this.cartData.length) {
            for (let i = 0; i < this.cartData.length; i++) {
                this.cartservice.afterLoginwithtax(this.cartData[i].businessName, this.cartData[i].name, this.cartData[i].amount, this.cartData[i].quantity, this.cartData[i].img, this.cartData[i].id, this.cartData[i].ins, this.cartData[i].tax, this.cartData[i].category, email);
            }
        }
        if (localStorage.getItem('guard')) {
            this.router.navigate(['' + localStorage.getItem('guard') + '']);
            localStorage.removeItem('guard');
        }
        else {
            if (localStorage.getItem('cart') == 'cart') {
                setTimeout(() => {
                    console.log('the number');
                    this.router.navigate(['home-results']);
                    this.opencart();
                }, 3000);
            }
            else if (localStorage.getItem('cart') == 'orders') {
                this.router.navigate(['latest-orders']);
            }
            else if (localStorage.getItem('cart') == 'profile') {
                this.router.navigate(['edit-profile']);
            }
            else {
                this.router.navigate(['home-results']);
            }
        }
        localStorage.removeItem('favourite');
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.cartData = JSON.parse(localStorage.getItem('favourite'));
            this.afAuth.authState.pipe(take(1)).subscribe((user) => __awaiter(this, void 0, void 0, function* () {
                if (user) {
                    yield this.showLoading();
                    console.log('called twice');
                    this.executed = false;
                    if (!this.executed) {
                        this.executed = true;
                        this.storeInFirestore(this.cartservice.returnEmail());
                        console.log('called once');
                    }
                    this.loading.dismiss();
                    this.user = user;
                    localStorage.setItem('user', JSON.stringify(this.user));
                    if (this.cartData == null) {
                        this.cartData = [];
                    }
                }
                else {
                    localStorage.setItem('user', null);
                }
            }));
        });
    }
    // async cart(){
    //   const modal =  await this.modalCtrl.create({
    //     component: CartPage
    //   });
    //   return  await modal.present();
    // }
    closeModal() {
        this.modalCtrl.dismiss();
        //this.location.back()
    }
    googleLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.platform.is('capacitor')) {
                this.disable = true;
                this.elements = true;
            }
            localStorage.setItem('google', 'google');
            yield this.showLoading();
            this.service.google();
        });
    }
    guestlogin() {
        return __awaiter(this, void 0, void 0, function* () {
            this.guestuser = true;
        });
    }
    facebook() {
        return __awaiter(this, void 0, void 0, function* () {
            // localStorage.setItem("facebook", "facebook");
            // if (this.platform.is("capacitor")) {
            //   this.disable = true;
            //   this.elements = true;
            // }
            // await this.showLoading();
            // this.service.facebook1();
        });
    }
    showLoading() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingCtrl.create({
                message: 'Authenticating...',
            });
            this.loading.present();
        });
    }
    openGuestlogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create({
                component: GuestLoginPage,
            });
            return yield modal.present();
        });
    }
    signOut() {
        firebase
            .auth()
            .signOut()
            .then(function () {
            // Sign-out successful.
            console.log('success');
        })
            .catch(function (error) {
            // An error happened.
        });
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { EventOrdersService } from '../../providers/event-orders.service';
import { EventsService } from '../../providers/events.service';
export class PaymentComponent {
    constructor(http, eventsService, location, orderService, loadingCtrl, router, toastCtrl) {
        this.http = http;
        this.eventsService = eventsService;
        this.location = location;
        this.orderService = orderService;
        this.loadingCtrl = loadingCtrl;
        this.router = router;
        this.toastCtrl = toastCtrl;
        this.elements = true;
        this.total = 0;
        this.allEmails = [];
        this.applicationId = environment.swypelocal.appId;
        this.locationId = environment.swypelocal.locationId;
        let d = new Date();
        this.eventsService.getEventDetails().subscribe((res) => {
            this.allEmails.push(res[0].businessEmail);
        });
        let date = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        this.orderId =
            '' +
                date +
                '' +
                month +
                '' +
                year +
                ' ' +
                Math.floor(Math.random() * 1000) +
                '';
        // this.eventsService.returnEventList().subscribe((res) => {
        //   this.total = 0;
        //   this.list = res;
        //   if (res && res.length) {
        //     res.forEach((data) => {
        //       this.total += data.price;
        //       this.allEmails.push(data.email);
        //     });
        //   }
        //   this.fairService.getEventDetailsAAPI().subscribe((res) => {
        //     this.allEmails.push(res[0].businessEmail);
        //     console.log(this.allEmails);
        //   });
        // });
        this.total = parseInt(localStorage.getItem('ticketsBookingAmount'));
    }
    ngOnInit() {
        this.loadSquarePayment();
    }
    loadSquarePayment() {
        return __awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                this.elements = false;
            }, 3000);
            const payments = Square.payments(this.applicationId, this.locationId);
            const card = yield payments.card();
            let vm = this;
            this.loadGooglePay();
            yield card.attach('#card-container');
            function eventHandler(event) {
                return __awaiter(this, void 0, void 0, function* () {
                    event.preventDefault();
                    try {
                        vm.bringLoader();
                        const result = yield card.tokenize();
                        if (result.status === 'OK') {
                            console.log(`Payment token is ${result.token}`);
                            vm.sendSqPayment({ nonce: result.token });
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                });
            }
            const cardButton = document.getElementById('card-button');
            if (cardButton)
                cardButton.addEventListener('click', eventHandler);
        });
    }
    dismissloader() {
        this.elements = false;
    }
    loadGooglePay() {
        return __awaiter(this, void 0, void 0, function* () {
            let googlePay;
            let vm = this;
            const payments = Square.payments(this.applicationId, this.locationId);
            try {
                googlePay = yield initializeGooglePay(payments);
            }
            catch (e) {
                console.error('Initializing Google Pay failed', e);
                // There are a number of reason why Google Pay may not be supported
                // (e.g. Browser Support, Device Support, Account). Therefore you
                // should handle initialization failures, while still loading other
                // applicable payment methods.
            }
            function buildPaymentRequest(payments) {
                return payments.paymentRequest({
                    countryCode: 'US',
                    currencyCode: 'USD',
                    total: {
                        amount: JSON.stringify(vm.total),
                        label: 'Total',
                    },
                });
            }
            function initializeGooglePay(payments) {
                return __awaiter(this, void 0, void 0, function* () {
                    const paymentRequest = buildPaymentRequest(payments);
                    const googlePay = yield payments.googlePay(paymentRequest);
                    yield googlePay.attach('#google-pay-button');
                    return googlePay;
                });
            }
            function tokenize(paymentMethod) {
                return __awaiter(this, void 0, void 0, function* () {
                    vm.bringLoader();
                    const tokenResult = yield paymentMethod.tokenize();
                    if (tokenResult.status === 'OK') {
                        console.log(tokenResult.token);
                        vm.sendSqPayment({ nonce: tokenResult.token });
                    }
                });
            }
            if (googlePay !== undefined) {
                const googlePayButton = document.getElementById('google-pay-button');
                googlePayButton.addEventListener('click', function (event) {
                    return __awaiter(this, void 0, void 0, function* () {
                        // await handlePaymentMethodSubmission(event, googlePay);
                        const token = yield tokenize(googlePay);
                    });
                });
            }
        });
    }
    bringLoader() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingCtrl.create({
                message: 'please wait...',
                duration: 60000,
            });
            this.loader.present();
        });
    }
    sendSqPayment(oneTime) {
        return __awaiter(this, void 0, void 0, function* () {
            var that = this;
            yield firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                return __awaiter(this, void 0, void 0, function* () {
                    that.url = 'https://' + environment.url.link + '/eventsPayment/';
                    // const allEmails = that.allEmails;
                    // const id = that.orderId;
                    const nonce = oneTime.nonce;
                    // const eventData = JSON.parse(
                    //   localStorage.getItem('eventRegisteredList')
                    // );
                    const details = JSON.parse(localStorage.getItem('eventRegisteredList'));
                    const name = details.name;
                    const id = details.orderId;
                    const guest = details.guest;
                    const isGuestOn = details.isGuestOn;
                    const allEmails = that.allEmails;
                    const amountPaid = '100';
                    const isNotMember = true;
                    const aapiRegistration = true;
                    let body = {
                        totalAmount: that.total,
                        nonce,
                        allEmails,
                        name,
                        id,
                        guest,
                        isGuestOn,
                        amountPaid,
                        isNotMember,
                    };
                    let headers = { Authorization: 'Bearer ' + idToken + '' };
                    that.http
                        .post(that.url, body, { headers })
                        .subscribe((data) => __awaiter(this, void 0, void 0, function* () {
                        if (data.payment.status == 'COMPLETED') {
                            that.loader.dismiss();
                            const toast = yield that.toastCtrl.create({
                                position: 'middle',
                                message: 'You have successfully registered to the AAPI event!',
                                duration: 2000,
                                color: 'secondary',
                            });
                            toast.present();
                        }
                        if (data.payment.status == 'COMPLETED') {
                            const url = 'https://' +
                                environment.url.link +
                                '/eventsPayment/registrationEmail';
                            that.http.post(url, body, { headers }).subscribe((res) => {
                                console.log(res);
                            });
                            that.loader.dismiss();
                            that.eventsService.postRegistrationDetails(details.name, details.guest, details.email, details.phoneNumber, details.orderId, false);
                            that.router.navigate(['/aapifalltn2022']);
                        }
                    }));
                });
            });
        });
    }
    back() {
        this.location.back();
    }
}

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="doneStuff">
    <div>
      <label class="fw-700">Event: </label>
      <ion-text>
        AAPI TN Chapter Annual Meeting Educational Seminar Entertainment
        2022.</ion-text
      >
    </div>
    <div>
      <label class="fw-700">When: </label>
      <ion-text>SEP. 10, 2022.</ion-text>
    </div>
    <div>
      <label class="fw-700">Where: </label>
      <ion-text
        >Embassy Suites 820 Crescent Center Drive, Franklin, TN.</ion-text
      >
    </div>
    <!-- <div>
      <label class="fw-700"> In support of : </label>
      <ion-text
        >FIGHT HUMAN TRAFFICKING AND END SLAVERY IN INDIA FREE FOR
        LIFE</ion-text
      >
    </div>
    <div>
      <label class="fw-700">ATTIRE: </label>
      <ion-text>FORMAL</ion-text>
    </div> -->

    <!-- <ion-card>
      <img
        class="image"
        src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/aapi%2Fdonate-top.JPG?alt=media&token=062b064b-0115-4d19-9c3d-b86025a71c06"
        alt="ion"
      />
      <img
        class="image"
        src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/WhatsApp%20Image%202021-10-19%20at%209.57.19%20PM.jpeg?alt=media&token=e4696cbe-850b-438e-bca6-7fd809462f68"
        alt="ion"
      />
    </ion-card> -->
  </div>
  <form [formGroup]="eventForm" class="m-l-10">
    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Full Name *
      </ion-label>
      <ion-input formControlName="name" class="google-input" type="text">
      </ion-input>
      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('name').touched &&
          eventForm.get('name').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Email *
      </ion-label>
      <ion-input formControlName="email" class="google-input" type="text">
      </ion-input>
    </ion-item>

    <p
      ion-text
      class="text08"
      *ngIf="
        eventForm.get('email').touched && eventForm.get('email').errors?.email
      "
    >
      <ion-text color="primary">
        *This field is required in correct format for notifications
      </ion-text>
    </p>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Phone Number *
      </ion-label>
      <ion-input formControlName="phoneNumber" class="google-input" type="text">
      </ion-input>

      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('phoneNumber').touched &&
          eventForm.get('phoneNumber').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>
  </form>

  <div>
    <ion-label padding class="available2"> Donate </ion-label>
    <br />

    <div class="segment-mrgn">
      <ion-segment class="segment">
        <ion-segment-button
          *ngFor="let item of donations"
          [checked]="item.isChecked"
          (click)="donate(item.amount, item.a)"
        >
          <ion-label style="color: white">$ {{ item.amount }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
    <br />
    <div class="segment-mrgn">
      <ion-segment class="segment">
        <ion-segment-button
          *ngFor="let item of donations2"
          [checked]="item.isChecked"
          (click)="donate(item.amount, item.a)"
        >
          <ion-label style="color: white">$ {{ item.amount }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
    <br />
    <div class="segment-mrgn">
      <ion-segment class="segment">
        <ion-segment-button
          *ngFor="let item of donations3"
          [checked]="item.isChecked"
          (click)="donate(item.amount, item.a)"
        >
          <ion-label style="color: white">$ {{ item.amount }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
    <br />
    <ion-item class="available2" lines="none">
      <ion-label
        class="google-label"
        position="floating"
        color="dark"
        id="moveleft"
      >
        Enter custom Amount :
      </ion-label>
      <ion-input
        type="number"
        class="google-input"
        placeholder="Enter amount here"
        [(ngModel)]="customAmount"
        (ionChange)="customdollarfn()"
        maxlength="4"
      >
      </ion-input>
    </ion-item>
  </div>

  <!-- <ion-card>
    <img
      class="image"
      src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/aapi%2Ffreeforlife-content.JPG?alt=media&token=e35a55a8-c498-43c2-9ed9-52379df24d7e"
      alt="ion"
    />
  </ion-card> -->
</ion-content>
<ion-footer>
  <ion-button
    size="large"
    expand="full"
    [disabled]="!eventForm.valid"
    (click)="navigateToPayment()"
    color="success"
    >Continue Donating $ {{ selectedAmount }}</ion-button
  >
</ion-footer>

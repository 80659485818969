import * as i0 from "@angular/core";
export class CartService {
    constructor() {
        this.cartItems = [];
        if (localStorage.getItem('nyCart') !== null) {
            this.cartItems = JSON.parse(localStorage.getItem('nyCart'));
        }
    }
    updateCart(data, qty) {
        const index = this.cartItems.findIndex((res) => res.id === data.id);
        if (index > -1) {
            // update
            this.cartItems[index].qty += qty;
            if (this.cartItems[index].qty < 1) {
                this.cartItems.splice(index, 1);
            }
        }
        else {
            const qty = { qty: 1 };
            const bag = Object.assign(data, qty);
            this.cartItems.push(bag);
        }
        localStorage.setItem('nyCart', JSON.stringify(this.cartItems));
    }
    qty(item) {
        return this.cartItems.find((res) => res.id === item.id).qty;
    }
    isAdded(id) {
        return this.cartItems.findIndex((res) => res.id === id) > -1;
    }
    count() {
        return this.cartItems.length;
    }
    clearCart() {
        this.cartItems = [];
    }
    returnItems() {
        return this.cartItems;
    }
}
CartService.ngInjectableDef = i0.defineInjectable({ factory: function CartService_Factory() { return new CartService(); }, token: CartService, providedIn: "root" });

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { TranslateProvider } from '../providers';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SsfGuard implements CanActivate {
  constructor(
    public service: TranslateProvider,
    public navCtrl: NavController,
    public location: Location,
    public route: ActivatedRoute
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('code', route);

    if (this.service.isLoggedIn) {
      const callBackUrl = route.params.callBackUrl;

      if (
        null !== callBackUrl &&
        (callBackUrl === 'aapiBooking' || 'aapifall21')
      ) {
        this.navCtrl.navigateRoot(callBackUrl);
      } else {
        this.navCtrl.navigateRoot('/home-results');
      }
    }
    return true;
  }
}

<ion-header>
  <ion-toolbar color="success">
    <h3 style="color: white; text-align: center">
      Thank you for your order in SwypeLocal
    </h3>

    <ion-buttons slot="end"> </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid no-padding fixed>
    <ion-row no-padding>
      <ion-col no-padding>
        <ion-item>
          <h4 class="orderno">Registration No - {{ id }}</h4>
        </ion-item>

        <ion-list *ngIf="orderDetails">
          <ion-list-header>Registeration Successfull</ion-list-header>
          <ion-item class="bg-white" lines="none">
            <ion-label>
              <h2 class="fw700">
                <ion-text color="dark">Name: {{ orderDetails.name }}</ion-text>
              </h2>
            </ion-label>
          </ion-item>

          <ion-item class="bg-white" lines="none">
            <ion-label>
              <h2 class="fw700">
                <ion-text color="dark"
                  >No of Guests: {{ orderDetails.noOfGuests }}</ion-text
                >
              </h2>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-button color="primary" (click)="donate()">
    <div>Donate Now</div>
  </ion-button>
</ion-content>
<ion-footer class="no-padding">
  <ion-button
    size="large"
    expand="full"
    color="success"
    no-margin
    routerLink="/home-results"
    routerDirection="root"
  >
    <ion-text color="light">GO to home</ion-text>
    <ion-icon slot="start" name="card" color="light"></ion-icon>
  </ion-button>
</ion-footer>

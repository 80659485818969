<ion-header>
  <ion-toolbar color="success">
    <h3 style="color: white; text-align: center">
      Thank you for your order in SwypeLocal
    </h3>

    <ion-buttons slot="end"> </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid no-padding fixed>
    <ion-row no-padding>
      <ion-col no-padding>
        <ion-item>
          <h4 class="orderno">Order No - {{ id }}</h4>
        </ion-item>

        <ion-list *ngFor="let order of orderDetails; let i = index">
          <ion-item class="bg-white" lines="none">
            <ion-label>
              <h2 class="fw700">
                <ion-text color="dark">{{ order.name }}</ion-text>
              </h2>
              <ion-grid class="filters" no-padding>
                <ion-row>
                  <ion-col text-center col-2> </ion-col>
                  <ion-col text-center col-3>
                    <h2 class="text12 fw700">
                      <ion-text color="primary">{{
                        order.selectedDesignation
                      }}</ion-text>
                    </h2>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <div item-right>
                <ion-badge>{{ order.price | currency }}</ion-badge>
              </div>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="no-padding">
  <ion-button
    size="large"
    expand="full"
    color="success"
    no-margin
    routerLink="/home-results"
    routerDirection="root"
  >
    <ion-text color="light">GO to home</ion-text>
    <ion-icon slot="start" name="card" color="light"></ion-icon>
  </ion-button>
</ion-footer>

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card *ngIf="eventDetails">
    <img
      class="header-img"
      src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/AAPI%20Header.jpeg?alt=media&token=2325ab50-b8e2-4f12-8794-bdbddcd0cf3a"
    />

    <ion-card-header>
      <div class="landingText">
        <ion-text class="fw-700"
          >AAPI TN Chapter Annual Meeting Educational Seminar Entertainment
          2022.</ion-text
        >
      </div></ion-card-header
    >
    <ion-card-content class="fs-18" style="padding-top: 0px">
      <div>
        <label class="fw-700">Location: </label>
        <ion-text
          >Embassy Suites 820 Crescent Center Drive, Franklin, TN.</ion-text
        >
      </div>
      <div>
        <label class="fw-700">Date and time: </label>
        <ion-text>SEP. 10, 2022.</ion-text>
      </div>
      <div *ngIf="!infoFlag">
        <label class="fw-700">Agenda: </label>

        <ion-text
          >12pm - 1PM - NON CME TALK (Lunch Provided) ...
          <a (click)="moreInfo()" style="cursor: pointer">more info</a
          >.</ion-text
        >
      </div>

      <div *ngIf="infoFlag">
        <div>
          <label class="fw-700">Agenda: </label>

          <ion-text
            >12pm - 1PM - NON CME TALK (Lunch Provided) <br />
            1PM - 4PM - CME <br />
            4PM - 5 PM - GENERAL BODY MEETING
          </ion-text>
        </div>
        <div>
          <label class="fw-700">CME topics: </label>
          <ion-text>
            1. Sudden death risk & Heart disease in Indians/ South Asians - Dr.
            Srini Gangasani <br />
            2. Lifestyle Medicine - Dr. Radharamanamurthy Gokula <br />
            3. Immunization and Vaccines for Adult - Dr. Jayesh Patel
          </ion-text>
        </div>
        <div>
          <label class="fw-700">Evening details: </label>
          <ion-text>
            6pm cocktail hour <br />
            7-11 Dinner & Entertainment
          </ion-text>
        </div>
        <!-- <div>
          <label class="fw-700">The Charity: </label>
          <ion-text
            >All the proceeds go to FREE FOR LIFE INTERNATIONAL Charity
            organization which is dedicated to "FIGHT HUMAN TRAFFICKING and End
            Slavery in India.</ion-text
          >
        </div> -->
        <h2 class="fw-700">
          <!-- Please join us for fun filled gala with bollywood music, delicious
          food. -->
          <a (click)="moreInfo()" style="cursor: pointer">show Less</a>.
        </h2>
      </div>

      <form [formGroup]="eventForm" class="m-l-10">
        <ion-radio-group
          (ionChange)="memberEvent($event)"
          formControlName="isMember"
        >
          <ion-list-header>
            <h3 class="available fw-700">Are you an AAPI member?:</h3>
          </ion-list-header>

          <ion-item *ngFor="let item of cme; let idx = index" lines="none">
            <ion-label>{{ item }}</ion-label>
            <ion-radio slot="start" [value]="item"></ion-radio>
          </ion-item>
        </ion-radio-group>
        <ion-button
          *ngIf="isAapiMember"
          color="primary"
          (click)="navigateToForm()"
        >
          <ion-icon name="text"></ion-icon>
          <p>Register Here For Free(AAPI Mem only)</p>
        </ion-button>

        <section *ngIf="isAapiMember === false">
          <ion-radio-group
            (ionChange)="becomeAapiMember($event)"
            formControlName="becomeAapiMember"
          >
            <ion-list-header>
              <h3 class="available fw-700">
                Are you interested in AAPI Life time Patron Membership for $100?
              </h3>
            </ion-list-header>

            <ion-item *ngFor="let item of cme; let idx = index" lines="none">
              <ion-label>{{ item }}</ion-label>
              <ion-radio slot="start" [value]="item"></ion-radio>
            </ion-item>
          </ion-radio-group>
          <div *ngIf="becomeMember">
            <p class="fw-700">
              Click the link below and become AAPI Member. After registering,
              please complete your registration for this AAPI-TN Annual Seminar
              2022 for free of cost.
            </p>
            <a href="https://membership.aapiusa.org//" target="_blank"
              >Click Here to become a Member</a
            >
          </div>
          <div>
            <ion-button
              *ngIf="becomeMember === false"
              color="primary"
              (click)="nonAapiPayment()"
            >
              <ion-icon name="text"></ion-icon>
              <div>Register Here</div>
            </ion-button>
          </div>
        </section>
      </form>
    </ion-card-content>
    <img
      class="image"
      style="margin-top: 51px"
      src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/aapi22.jpeg?alt=media&token=aec3c9a8-bf43-420d-9b1e-ba915683a475"
    />

    <ion-footer>
      <ion-row>
        <!-- <ion-col center text-center>
          <ion-button
            [disabled]="!enableRegistration"
            color="primary"
            (click)="navigateToForm()"
          >
            <ion-icon name="text"></ion-icon>
            <div>Register</div>
          </ion-button>
        </ion-col>
        <ion-col center text-center>
          <ion-button color="primary" (click)="bookATable()">
            <div>Book a Table</div>
          </ion-button>
        </ion-col> -->
        <!-- <ion-col center text-center>
          <ion-button color="primary" (click)="sponsor()">
            <div>Sponsor Now</div>
          </ion-button>
        </ion-col> -->
      </ion-row>
    </ion-footer>
  </ion-card>
  <!-- 
  <ion-card *ngFor="let item of registeredList; let i = index" class="userCard">
    <ion-row style="margin-left: 8px">
      <ion-col size="5">
        <ion-label>{{ item.name }}</ion-label>
      </ion-col>
      <ion-col size="3">
        <ion-label>$ {{ item.price }}</ion-label>
      </ion-col>
      <ion-col size="2">
        <ion-icon
          name="create"
          size="large"
          routerLink="edit-form/{{ i }}"
        ></ion-icon>
      </ion-col>
      <ion-col size="2">
        <ion-icon name="close" size="large" (click)="remove(i)"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-card> -->
  <!-- <ion-button
    *ngIf="total > 0"
    (click)="navigateToForm()"
    size="small"
    class="addGuest"
    >Add Guest</ion-button
  > -->
</ion-content>

<ion-footer>
  <div class="center">
    <ion-button expand="full" color="primary" (click)="sponsor()">
      <div>Donate Now</div>
    </ion-button>
  </div>

  <!-- <ion-card class="mycolour" no-margin>
    <p>
      <ion-text class="total" color="light">Total: ${{ total }}</ion-text>
    </p>
  </ion-card> -->
  <!-- <ion-button
    size="large"
    expand="full"
    color="success"
    no-margin
    (click)="navigateToPayment()"
  >
    <ion-text color="light">Proceed to pay</ion-text>
    <ion-icon slot="start" name="card" color="light"></ion-icon>
  </ion-button> -->
</ion-footer>

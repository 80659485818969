var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RestaurantService } from "src/app/providers";
export class CouponPage {
    constructor(modalCtrl, rservice) {
        this.modalCtrl = modalCtrl;
        this.rservice = rservice;
        this.emailFormArray = [];
        this.temp = [];
    }
    ngOnInit() {
        // console.log(this.temp)
        // this.list=this.rservice.getcoupons().valueChanges()
        console.log(JSON.parse(localStorage.getItem("coupon")));
    }
    selectMember(email, isChecked, i) {
        if (isChecked) {
            this.emailFormArray.push(email);
            this.temp[i].isChecked = "true";
        }
        else {
            let index = this.emailFormArray.indexOf(email);
            this.emailFormArray.splice(index, 1);
            this.temp[i].isChecked = "false";
        }
        console.log(this.temp);
    }
    // getcoup(){
    //   console.log(this.emailFormArray);
    // }
    closeModal() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.modalCtrl.dismiss(this.temp);
            localStorage.setItem("coupon", JSON.stringify(this.temp));
        });
    }
}

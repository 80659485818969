import { AngularFirestore, } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class EventsService {
    constructor(firestore) {
        this.firestore = firestore;
        this.$eventList = new BehaviorSubject(null);
        this.list = [];
        if (localStorage.getItem('eventRegisteredList') !== null) {
            this.$eventList.next(JSON.parse(localStorage.getItem('eventRegisteredList')));
            this.list = JSON.parse(localStorage.getItem('eventRegisteredList'));
        }
    }
    addUser(name, email, designation, phoneNumber, eventName) {
        this.list.push({
            name,
            email,
            designation,
            phoneNumber,
            eventName,
        });
        localStorage.setItem('eventRegisteredList', JSON.stringify(this.list));
        this.$eventList.next(this.list);
    }
    deleteAndAddUser(name, email, designation, address, phoneNumber, selectedDesignation, cmeCredits, stateLicenceNumber, state, price, eventName, id) {
        this.list.splice(id, 1);
        this.list.push({
            name,
            email,
            price,
            designation,
            address,
            phoneNumber,
            stateLicenceNumber,
            selectedDesignation,
            state,
            eventName,
            cmeCredits,
        });
        localStorage.setItem('eventRegisteredList', JSON.stringify(this.list));
        this.$eventList.next(this.list);
    }
    returnEventList() {
        return this.$eventList;
    }
    clean() {
        this.list = [];
        this.$eventList.next(null);
        localStorage.removeItem('eventRegisteredList');
    }
    removeUser(index) {
        this.list.splice(index, 1);
        localStorage.setItem('eventRegisteredList', JSON.stringify(this.list));
        this.$eventList.next(this.list);
    }
    getEventDetails() {
        return this.firestore
            .collection(`Business`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('BusinessName', '==', 'AAPI Fall 2022');
        })
            .valueChanges();
    }
    getSponsorReport() {
        return this.firestore
            .collection(`SponsorShip`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref;
        })
            .valueChanges();
    }
    getNewYearReport() {
        return this.firestore
            .collection(`NewYearParty`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref;
        })
            .valueChanges();
    }
    getDonatinosReport() {
        return this.firestore
            .collection(`Donations`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref;
        })
            .valueChanges();
    }
    getRegistrations() {
        return this.firestore
            .collection(`EventOrders`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref;
        })
            .valueChanges();
    }
    getUsers() {
        return this.firestore
            .collection(`aapiEntry`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref;
        })
            .valueChanges();
    }
    postRegistrationDetails(name, guest, email, phoneNumber, id, isMember = true, eventName = 'AAPIFALL22') {
        this.firestore.doc(`AAPIFALL22/${id}`).set({
            name,
            guest,
            email,
            phoneNumber,
            eventName,
            isMember,
            id,
        });
    }
}
EventsService.ngInjectableDef = i0.defineInjectable({ factory: function EventsService_Factory() { return new EventsService(i0.inject(i1.AngularFirestore)); }, token: EventsService, providedIn: "root" });

import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../providers/events.service';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-registrations-report',
  templateUrl: './registrations-report.component.html',
  styleUrls: ['./registrations-report.component.scss'],
})
export class RegistrationsReportComponent implements OnInit {
  report: any = [];
  fileName = 'ExcelSheet.xlsx';

  constructor(
    private eventsService: EventsService,
    private location: Location
  ) {
    this.eventsService.getRegistrations().subscribe((res) => {
      // this.report = res;
      console.log(res);
    });

    this.getMappedResponse().subscribe((res) => {
      console.log(res);
    });
  }
  private getMappedResponse(): Observable<any> {
    this.report = [];
    return this.eventsService.getRegistrations().map((res: any) => {
      return res.map((data) => {
        return data.event.forEach((res) => {
          return this.mapEmails(res, data);
        });
      });
    });
  }
  private mapEmails(res, data) {
    console.log(data);
    this.report.push({
      email: res.email,
      name: res.name,
      price: res.price,
      timestamp: data.timestamp,
      designation: res.selectedDesignation,
      state: res.state,
      phoneNumber: res.phoneNumber,
    });
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  ngOnInit() {}
  back() {
    this.location.back();
  }
}

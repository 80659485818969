var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NavController, ModalController, ToastController, LoadingController, AlertController, } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DishService, CartService, RestaurantService, } from '../../providers';
import { CartPage } from './../modal/cart/cart.page';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { IonSlides } from '@ionic/angular';
export class DishDetailPage {
    constructor(navCtrl, toastCtrl, modalCtrl, route, router, cartService, dishService, db, service, http, location, firestore, service2, loadingCtrl, alertController // public navParams: NavParams
    ) {
        this.navCtrl = navCtrl;
        this.toastCtrl = toastCtrl;
        this.modalCtrl = modalCtrl;
        this.route = route;
        this.router = router;
        this.cartService = cartService;
        this.dishService = dishService;
        this.db = db;
        this.service = service;
        this.http = http;
        this.location = location;
        this.firestore = firestore;
        this.service2 = service2;
        this.loadingCtrl = loadingCtrl;
        this.alertController = alertController;
        this.qtd = 1;
        this.favourite = [];
        this.editorder1 = [];
        this.user = [];
        this.stack = [];
        this.item = [];
        this.squareFormat = [];
        this.enable1 = false;
        this.multipleitems = [];
        this.instructions = '';
        this.description = false;
        this.loadib = false;
        this.loadbc = false;
        this.slideOptsTwo = {
            initialSlide: 0,
            slidesPerView: 3,
            autoplay: true,
        };
        this.showRecommendations = false;
        //Item object for Food
        this.sliderTwo = {
            isBeginningSlide: true,
            isEndSlide: false,
        };
        // this.getRecommendations();
        console.log(this.bizName);
    }
    getRecommendations(name, itemId) {
        this.dishService
            .getRecommendations(name)
            .valueChanges()
            .subscribe((res) => {
            console.log(res);
            this.recommendations = res;
            const item = this.recommendations.findIndex((item) => item.Id === itemId);
            console.log(item);
            if (item > -1) {
                this.recommendations.splice(item, 1);
            }
            this.recommendations = this.recommendations.map((res) => {
                return {
                    amount: res.amount,
                    Id: res.Id,
                    businessName: res.businessName,
                    item: res.item,
                    img: `https://firebasestorage.googleapis.com/v0/b/localbooklet.appspot.com/o/${res.businessName}%2F${res.item}.jpg?alt=media&token`,
                };
            });
            this.showRecommendations = true;
        });
    }
    //Method called when slide is changed by drag or navigation
    SlideDidChange(object, slideView) {
        this.checkIfNavDisabled(object, slideView);
    }
    //Call methods to check if slide is first or last to enable disbale navigation
    checkIfNavDisabled(object, slideView) {
        this.checkisBeginning(object, slideView);
        this.checkisEnd(object, slideView);
    }
    checkisBeginning(object, slideView) {
        slideView.isBeginning().then((istrue) => {
            object.isBeginningSlide = istrue;
        });
    }
    checkisEnd(object, slideView) {
        slideView.isEnd().then((istrue) => {
            object.isEndSlide = istrue;
        });
    }
    //Move to Next slide
    slideNext(object, slideView) {
        slideView.slideNext(500).then(() => {
            this.checkIfNavDisabled(object, slideView);
        });
    }
    //Move to previous slide
    slidePrev(object, slideView) {
        slideView.slidePrev(500).then(() => {
            this.checkIfNavDisabled(object, slideView);
        });
    }
    share() {
        return __awaiter(this, void 0, void 0, function* () {
            const productId = this.value;
            const bizName = this.results1.data().businessName;
            const productName = this.results1.data().item;
            let shareApi;
            shareApi = window.navigator;
            const productImg = 'https://firebasestorage.googleapis.com/v0/b/localbooklet.appspot.com/o/' +
                bizName +
                '%2F' +
                productName +
                '.jpg?alt=media&token';
            var img = new Image();
            img.crossOrigin = '';
            img.src = this.IBimage;
            const response = yield fetch(img.src);
            const blob = yield response.blob();
            const file = new File([blob], 'rick.jpg', { type: blob.type });
            const shareData = {
                url: `resdetail/${bizName}/${productId}/${productName}`,
                files: [file],
                text: `Click on the below link to purchase ${productName} from ${bizName}`,
            };
            shareApi
                .share(shareData)
                .then(function () {
                console.log('Successful share');
            })
                .catch(function (error) {
                console.log('Error sharing:', error);
            });
        });
    }
    ionViewDidEnter() {
        if (JSON.parse(localStorage.getItem('user')) != null) {
            this.service2.read_CartList().subscribe((data) => {
                this.menu = data.map((e) => {
                    return {
                        id: e.payload.doc.id,
                        // isEdit: false,
                        amount: e.payload.doc.data()['amount'],
                        // description: e.payload.doc.data()['description'],
                        img: e.payload.doc.data()['img'],
                        name: e.payload.doc.data()['name'],
                        quantity: e.payload.doc.data()['quantity'],
                    };
                });
                //  console.log(this.menu)
            });
        }
        // console.log( this.navParams.get('value'))
        const songId = this.value;
        console.log(songId);
        // console.log(songId)
        if (songId) {
            this.service
                .getSongDetail(songId)
                .valueChanges()
                .subscribe((res) => {
                this.song = res;
                this.getRecommendations(res.businessName, songId);
            });
            if (JSON.parse(localStorage.getItem('user')) != null) {
                this.subscriptiont = this.service
                    .getCartList()
                    .valueChanges()
                    .subscribe((res) => {
                    this.block = res;
                    // console.log(res.length)
                });
            }
            this.db
                .collection('menuItem')
                .doc(songId)
                .get()
                .subscribe((res) => {
                this.itemid = songId;
                this.results1 = res;
                console.log(res);
                this.enable1 = true;
                // console.log(res.data())
                if (res.data().tax) {
                    this.description = true;
                    this.tax = res.data().tax;
                }
            });
        }
        this.image = localStorage.getItem('img');
        if (JSON.parse(localStorage.getItem('favourite')) == null) {
            this.user = [];
        }
        else {
            this.user = JSON.parse(localStorage.getItem('favourite'));
            // console.log(this.user)
        }
    }
    ngOnDestroy() {
        if (JSON.parse(localStorage.getItem('user')) != null) {
            this.subscriptiont.unsubscribe();
        }
    }
    ionViewWillEnter() {
        // console.log(this.value)
        this.IBimage = localStorage.getItem('IBimage');
        this.service
            .businessfromdpage(localStorage.getItem('resname'))
            .valueChanges()
            .subscribe((res) => {
            // console.log(res[0].defaultimg)
            this.defaultimg = res[0].defaultimg;
        });
        if (localStorage.getItem('resname') == 'Beyond Curry') {
            this.loadbc = true;
        }
        else {
            this.loadib = true;
        }
    }
    errorHandler(event) {
        console.debug(event);
        event.target.src = this.defaultimg;
        this.IBimage = this.defaultimg;
    }
    addToFirestore(ins, name, amount, quantity = this.quantity, img, businessName, IBimage, category) {
        localStorage.setItem('id', this.Id);
        //  console.log(menuid)
        const Id = this.firestore.createId();
        let email = this.cartService.returnCustomerEmail();
        let tax = this.tax;
        let menuid = this.itemid;
        // console.log(this.tax)
        return this.firestore.doc(`LocalBookletCart/${Id}`).set({
            tax,
            ins,
            menuid,
            name,
            amount,
            quantity,
            img,
            businessName,
            email,
            Id,
            IBimage,
            category,
        });
    }
    onimgload() {
        this.imgload = true;
    }
    // minus adult when click minus button
    minusQtd() {
        if (this.qtd <= 1) {
            return;
        }
        this.qtd--;
    }
    // plus adult when click plus button
    plusQtd() {
        this.qtd++;
    }
    loader1() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingCtrl
                .create({
                duration: 500,
            })
                .then((res) => {
                res.present();
                res.onDidDismiss().then((dis) => {
                    // console.log('Loading dismissed! after 2 Seconds');
                    this.modalCtrl.dismiss();
                    this.toastCtrl
                        .create({
                        message: 'added to Cart',
                        duration: 500,
                        position: 'middle',
                        cssClass: 'my-custom-class',
                        color: 'primary',
                    })
                        .then((obj) => {
                        obj.present();
                    });
                });
            });
        });
    }
    addtolocalstorage(qtd) {
        this.loader1();
        let item = this.user.find((p) => p.name == this.results1.data().item);
        // console.log(this.results1.tax)
        if (item === undefined) {
            //set local
            this.favourite.push({
                name: this.results1.data().item,
                amount: this.results1.data().amount,
                quantity: qtd,
                id: this.results1.data().Id,
                img: localStorage.getItem('img'),
                businessName: this.results1.data().businessName,
                ins: this.instructions,
                tax: this.tax,
                IBimage: this.IBimage,
                category: this.results1.data().category,
            });
            localStorage.setItem('favourite', JSON.stringify(this.favourite));
        }
        else {
            item.quantity = item.quantity + qtd;
            localStorage.setItem('favourite', JSON.stringify(this.user));
        }
    }
    addcart(qtd) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(this.instructions);
            //NULLLLL UUUUSERR
            if (JSON.parse(localStorage.getItem('user')) == null) {
                if (JSON.parse(localStorage.getItem('favourite')) == null) {
                    this.favourite = [];
                }
                else {
                    this.favourite = JSON.parse(localStorage.getItem('favourite'));
                }
                // console.log(this.favourite.length)
                // console.log(this.results1.data().businessName)
                ///this checks if same restaurants exist in cart in local storage
                if (this.favourite.length != 0) {
                    let fresh = JSON.parse(localStorage.getItem('favourite'));
                    // console.log(fresh[0].businessName)
                    if (fresh[0].businessName == this.results1.data().businessName) {
                        this.addtolocalstorage(qtd);
                    }
                    else {
                        const alert = yield this.alertController.create({
                            header: 'Items already in cart',
                            message: 'Your cart contains items from a different store. Please reset your cart before browsing this restaurant',
                            buttons: ['OK'],
                        });
                        yield alert.present();
                    }
                }
                /// restaurants exist ends here
                else {
                    this.addtolocalstorage(qtd);
                }
            }
            //LoCALSTORAGE ENDS HERE
            else {
                if (this.block.length != 0) {
                    if (this.block[0].businessName == this.results1.data().businessName) {
                        this.loader1();
                        let IBimage = this.IBimage;
                        let menu1 = this.menu.find((p) => p.name == this.results1.data().item);
                        console.log('from menu1');
                        console.log(menu1);
                        if (menu1 === undefined) {
                            this.addToFirestore(this.instructions, this.results1.data().item, this.results1.data().amount, (this.quantity = qtd), localStorage.getItem('img'), (this.businessName = this.results1.data().businessName), IBimage, this.results1.data().category);
                        }
                        else {
                            this.editorder1 = JSON.parse(localStorage.getItem('manage'));
                            console.log('quantity work' + JSON.stringify(this.editorder1));
                            let record = {};
                            record['quantity'] = menu1.quantity + qtd;
                            // menu1.quantity=menu1.quantity+qtd
                            this.firestore.doc('LocalBookletCart/' + menu1.id).update(record);
                        }
                    }
                    else {
                        const alert = yield this.alertController.create({
                            header: 'Items already in cart',
                            message: 'Your cart contains items from a different store. Please reset your cart before browsing this restaurant',
                            buttons: ['OK'],
                        });
                        yield alert.present();
                    }
                }
                else {
                    //send
                    this.loader1();
                    let menu1 = this.menu.find((p) => p.name == this.results1.data().item);
                    //  console.log("from menu1")
                    console.log(menu1);
                    if (menu1 === undefined) {
                        this.addToFirestore(this.instructions, this.results1.data().item, this.results1.data().amount, (this.quantity = qtd), localStorage.getItem('img'), (this.businessName = this.results1.data().businessName), this.IBimage, this.results1.data().category);
                    }
                    else {
                        this.editorder1 = JSON.parse(localStorage.getItem('manage'));
                        console.log('quantity work' + JSON.stringify(this.editorder1));
                        let record = {};
                        record['quantity'] = menu1.quantity + qtd;
                        // menu1.quantity=menu1.quantity+qtd
                        this.firestore.doc('LocalBookletCart/' + menu1.id).update(record);
                    }
                }
            }
        });
    }
    openCart() {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create({
                component: CartPage,
            });
            return yield modal.present();
        });
    }
    back() {
        this.modalCtrl.dismiss();
    }
    openModal(id, item, businessName) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(id, item);
            this.modalCtrl.dismiss();
            localStorage.setItem('IBimage', `https://firebasestorage.googleapis.com/v0/b/localbooklet.appspot.com/o/${businessName}%2F${item}.jpg?alt=media&token`);
            const modal = yield this.modalCtrl.create({
                component: DishDetailPage,
                componentProps: { value: id },
            });
            return yield modal.present();
        });
    }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/providers';
import { EventOrdersService } from '../../providers/event-orders.service';

@Component({
  selector: 'app-placed-events',
  templateUrl: './placed-events.component.html',
  styleUrls: ['./placed-events.component.scss'],
})
export class PlacedEventsComponent implements OnInit {
  orderDetails;
  totalTickets;
  eventName;
  Total;
  constructor(
    private ordersService: EventOrdersService,
    private cartService: CartService,
    private router: Router,
    private location: Location
  ) {
    const email = this.cartService.returnEmail();
    this.ordersService.getOrderDetailsEmail(email).subscribe((res: any) => {
      console.log(res);
      this.orderDetails = res;
      console.log(this.orderDetails);
      this.totalTickets = this.orderDetails[0].event.length;
    });
  }

  summary(id) {
    this.router.navigate([`events/event-confirmation/${id}`]);
  }
  ngOnInit() {}
  back() {
    this.location.back();
  }
}

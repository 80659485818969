<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start" color="primary">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
      ></ion-icon>
    </ion-buttons>
    <ion-title>{{'app.pages.checkout.title.header'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scroll="true">
  <ion-item *ngIf="showalternate" lines="none">
    <ion-text class="allowalternate"
      >{{'app.checkin.allow'|translate}}</ion-text
    >
    <ion-toggle (ionChange)="toggle($event)" checked class="md"></ion-toggle>
  </ion-item>
  <br />

  <!-- <ion-list>
 
 <ion-list-header>
   <ion-label class="available">
     {{'app.checkin.service'|translate}}      </ion-label>

 </ion-list-header>

 <ion-item *ngFor="let item of list1" >
   <ion-label>{{item.type}}</ion-label>
   <ion-checkbox slot="end"></ion-checkbox>
 </ion-item>
</ion-list> -->

  <ion-list class="chooseservice">
    <ion-radio-group
      (ionChange)="emitordertype($event)"
      [(ngModel)]="businessservice"
    >
      <ion-list-header>
        <ion-label class="available">
          {{'app.checkin.service'|translate}}
        </ion-label>
      </ion-list-header>

      <ion-item *ngFor="let item of list1" lines="none">
        <ion-label>{{item.type}}</ion-label>
        <ion-radio slot="start" [value]="item.type"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <!-- <ion-list>
 
   <ion-list-header>
     <ion-label class="available">
       {{'app.checkin.service'|translate}}      </ion-label>

   </ion-list-header>
 
   <ion-item >
     <ion-label>{{'app.checkin.select'|translate}}</ion-label>
     <ion-select placeholder="Select One"(ionChange)="emitordertype($event)"  [(ngModel)]="businessservice" class="available">
 <div *ngFor="let item of list1"  >

   <ion-select-option [value]="item.type" *ngIf="item?.isActive" >
     
     <ion-label class="available" >{{item.type}}</ion-label>
   </ion-select-option>
 </div>
     

     </ion-select>
   </ion-item>
   <br>
   <ion-item *ngIf='showalternate'>
     <ion-text class="allowalternate" >{{'app.checkin.allow'|translate}}</ion-text>
     <ion-toggle (ionChange)='toggle($event)' class="md"></ion-toggle>
   </ion-item>
 </ion-list> -->
  <!-- <ion-list>
 <ion-list-header>
   <ion-label class="available">
     Choose delivery date
   </ion-label>
 </ion-list-header>
</ion-list> -->
  <div *ngIf="delivery">
    <ion-list class="deliverydate">
      <ion-item lines="none">
        <ion-label class="available"> CHOOSE DATE : </ion-label>
        <div class="time">
          <ion-col size="6" class="bb datetime-btn\">
            <ion-item no-padding text-center lines="none">
              <!-- <ion-label position="stacked" class="fw700 text-dark\">
             </ion-label> -->
              <div>
                <ion-datetime
                  no-padding
                  class="text-primary"
                  id="date"
                  displayFormat="MM/DD/YYYY"
                  pickerFormat="YYYY-MM-DD"
                  [min]="minDate"
                  [max]="maxDate"
                  [(ngModel)]="checkin.date"
                  (ionChange)="checkInTime($event)"
                >
                </ion-datetime>
                <!-- <ion-icon name="arrow-dropdown" id="down"></ion-icon> -->
              </div>
            </ion-item>
          </ion-col>
        </div>
      </ion-item>
      <h3 *ngIf="showlist&&delivery" class="allowalternate" padding>
        No delivery slots available for Today, Please choose a different date
      </h3>
    </ion-list>

    <ion-item *ngIf="!indiabazdel" lines="none">
      <ion-text
        >*orders will be delivered between 3pm to 6pm on the selected
        date.</ion-text
      >
    </ion-item>
  </div>
  <div *ngIf="togo">
    <!-- <ion-label class="date">Service Date</ion-label> -->
    <!-- <ion-label class="date" >Pickup Date</ion-label> -->
    <!-- <ion-label class="servicedate">Service Date:</ion-label> -->
    <ion-list class="choosedate">
      <ion-item lines="none">
        <ion-label class="available"> CHOOSE DATE : </ion-label>
        <div class="time">
          <ion-col size="6" class="bb datetime-btn\">
            <ion-item no-padding text-center lines="none">
              <!-- 
             <ion-label position="stacked" class="fw700 text-dark\">
             </ion-label> -->
              <div>
                <ion-datetime
                  no-padding
                  class="text-primary"
                  id="date"
                  displayFormat="MM/DD/YYYY"
                  pickerFormat="YYYY-MM-DD"
                  [min]="minDate"
                  [max]="maxDate"
                  [(ngModel)]="checkin.date"
                  (ionChange)="checkInTime($event)"
                >
                </ion-datetime>
                <!-- <ion-icon name="arrow-dropdown" id="down"></ion-icon> -->
              </div>
            </ion-item>
          </ion-col>
        </div>
      </ion-item>
    </ion-list>
  </div>

  <ion-list *ngIf="indiabazdel" class="marginfortime">
    <ion-list-header>
      <ion-label class="available" *ngIf="!delivery&&!dinein">
        CHOOSE PICKUP TIME :
      </ion-label>
      <ion-label class="available" *ngIf="delivery">
        DELIVERY TIME :
      </ion-label>
      <ion-item *ngIf="!showlist&&!dinein" class="choosetime" lines="none">
        <ion-label position="stacked"></ion-label>
        <ion-select
          (ionChange)="getTime($event)"
          [selectedText]="defaultTime"
          class="available"
        >
          <ion-select-option [value]="item.id" *ngFor="let item of results"
            >{{item.time}}</ion-select-option
          >
        </ion-select>
      </ion-item>
    </ion-list-header>
  </ion-list>
  <ion-list>
    <h3 *ngIf="showlist" padding>{{'app.checkin.noslots'|translate}}</h3>
  </ion-list>

  <div>
    <ion-label padding class="available2"> TIP </ion-label>
    <br />

    <div class="segment-mrgn">
      <ion-segment class="segment">
        <ion-segment-button (click)="cal(5)">
          <ion-label color="white">5%</ion-label> </ion-segment-button
        >&nbsp;
        <ion-segment-button (click)="cal(10)" checked>
          <ion-label>10%</ion-label> </ion-segment-button
        >&nbsp;
        <ion-segment-button (click)="cal(15)">
          <ion-label>15%</ion-label> </ion-segment-button
        >&nbsp;
        <ion-segment-button (click)="cal(20)">
          <ion-label>20%</ion-label> </ion-segment-button
        >&nbsp;
      </ion-segment>
    </div>
    <br />
    <!-- <ion-item class="available2" *ngIf="customper" lines="none">
      <ion-label class="google-label" position="floating" color="dark" >
        Enter tip :
      </ion-label>
      <ion-input type="number"  max-length="6" class="google-input" [(ngModel)]="customamount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" (keyup)="customdollarfn()"  placeholder="enter tip" scroll="true">
      </ion-input>
    </ion-item> -->
    <ion-item class="available2" lines="none">
      <ion-label
        class="google-label"
        position="floating"
        color="dark"
        id="moveleft"
      >
        Enter Tip :
      </ion-label>
      <ion-input
        type="number"
        class="google-input"
        placeholder="{{tip}}"
        [(ngModel)]="customamount"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
        (ionChange)="customdollarfn()"
      >
      </ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-text class="allowalternate" *ngIf="delivery"
        >Thank you for your generous tip. 100% of Tips go to drivers.
      </ion-text>
      <ion-text class="allowalternate" *ngIf="togo || dinein"
        >Thank you for your generous tip. We appreciate your business.</ion-text
      >
      <!-- 
 <ion-label *ngIf="delivery" padding>Thank you for your generous tip. 100% of Tips go to drivers.</ion-label>
 <ion-label *ngIf="togo || dinein" padding>Thank you for your generous tip. We appreciate your business.</ion-label> -->
    </ion-item>
  </div>
  <div class="googlesearch">
    <div *ngIf="delivery">
      <ion-label *ngIf="delivery" position="floating" class="available">
        &nbsp;&nbsp; Address:
      </ion-label>
      <input
        class="searchBar"
        #search
        id="auto-address"
        (focus)="handleAddressChange($event)"
        placeholder="Enter street or area..."
      />
    </div>
    <!-- 
    <div *ngIf="delivery">
      <ion-list [hidden]="autocompleteItems.length == 0">
        <div style="display: flex; justify-content: end; margin-right: 21px">
          <img style="width: 100px" src="assets/img/google.png" />
        </div>
        <ion-item
          *ngFor="let item of autocompleteItems"
          tappable
          (click)="SelectSearchResult(item)"
          lines="none"
        >
          {{ item.description }}
        </ion-item>
      </ion-list>
    </div> -->
  </div>

  <!-- <ion-label color="dark" class="available" padding>
     Please enter your table number :
    </ion-label><br>
    <ion-item>
    <ion-input  [(ngModel)]="tablenumber" type="number"  id="name" placeholder="enter your table number here..."></ion-input>
  </ion-item> 
 </div> -->
  &nbsp;&nbsp;<ion-text class="allowalternate1"
    >* Indicates Mandatory fields. </ion-text
  ><br />

  <form [formGroup]="onLoginForm" class="list-form" id="formlist" padding>
    <!-- <div *ngIf='delivery'>
    
   </div> -->
    <div *ngIf="delivery">
      <ion-item lines="none" class="available2">
        <ion-label position="floating" class="google-label" color="dark">
          {{'app.label.address'|translate}}:
        </ion-label>
        <ion-input
          type="name"
          class="google-input"
          formControlName="address"
          (ionFocus)="inputfocus()"
        ></ion-input>
      </ion-item>
      <ion-label *ngIf="!Freedelivery" class="allowalternate1">
        * ${{deliverycharges}} standard delivery charges
      </ion-label>
      <ion-label *ngIf="Freedelivery" class="allowalternate1">
        * Free delivery
      </ion-label>
      <br />
    </div>
    <div>
      <ion-item class="available2" lines="none">
        <a (click)="referalCode=true">Have a Coupon code?</a>&nbsp;
        <ion-input class="code" (ionChange)="referalEvent($event)"></ion-input>
      </ion-item>
    </div>
    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        {{'app.checkin.guest.name'|translate}} :
      </ion-label>
      <ion-input
        type="name"
        class="google-input"
        formControlName="guestName"
        id="name"
        scroll="true"
      >
      </ion-input>
    </ion-item>
    <!-- [value]="name" -->
    <div *ngIf="dinein">
      <ion-item class="available2" lines="none">
        <ion-label class="google-label" position="floating" color="dark">
          {{'app.checkin.table'|translate}}:
        </ion-label>
        <ion-input
          formControlName="tableNumber"
          class="google-input"
          type="number"
          id="name"
        >
        </ion-input>
      </ion-item>

      <ion-item lines="none" class="available2">
        <ion-label position="floating" class="google-label" color="dark">
          {{'app.checkin.guest.count'|translate}}:
        </ion-label>
        <ion-input
          type="number"
          class="google-input"
          formControlName="guestCount"
          min="1"
          id="count"
          [value]="gcount"
          placeholder="enter the guest count here..."
          scroll="true"
        ></ion-input>
      </ion-item>
    </div>

    <ion-item class="available2" lines="none">
      <ion-label
        class="google-label"
        position="floating"
        color="dark"
        id="moveleft"
      >
        {{'app.checkin.mobile'|translate}} :
      </ion-label>
      <ion-input
        type="name"
        class="google-input"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
        formControlName="guestMobile"
        id="mobile"
      >
      </ion-input>
    </ion-item>

    <div *ngIf="delivery">
      <ion-item class="available2" lines="none">
        <ion-label
          class="google-label"
          position="floating"
          id="moveleft"
          color="dark"
        >
          Unit/Apt No:
        </ion-label>
        <ion-input
          type="name"
          class="google-input"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          formControlName="houseNo"
          id="mobile"
        >
        </ion-input>
      </ion-item>
      <ion-item class="available2" lines="none">
        <ion-label
          class="google-label"
          position="floating"
          id="moveleft"
          color="dark"
        >
          Enter instructions for delivery:
        </ion-label>
        <ion-input
          type="name"
          maxlength="6"
          class="google-input"
          formControlName="landmark"
          id="mobile"
        >
        </ion-input>
      </ion-item>
    </div>
  </form>
</ion-content>
<ion-footer class="no-padding">
  <ion-card class="mycolour" no-margin>
    <ion-card-content>
      <div class="cardMargin">
        <p>
          <ion-text color="light"
            >{{'app.tip.total'|translate}}: {{ subtotal | currency }}</ion-text
          >
        </p>

        <p *ngIf="delivery">
          <ion-text color="light"
            >{{'app.tip.deliverycharges'|translate}}: {{ deliverycharges |
            currency }}</ion-text
          >
        </p>
        <p>
          <ion-text color="light"
            >{{'app.tip'|translate}}: {{ tip | currency }}</ion-text
          >
        </p>
        <p>
          <ion-text color="light"
            >Coupon Discount: {{ applieddiscount | currency }}</ion-text
          >
        </p>
        <p *ngIf="delivery">
          <ion-text color="light"
            >{{'app.tip.grand.total'|translate}}: {{
            tipplustotal+deliverycharges-applieddiscount | currency }}</ion-text
          >
        </p>

        <p *ngIf="!delivery">
          <ion-text color="light"
            >{{'app.tip.grand.total'|translate}}: {{
            tipplustotal-applieddiscount | currency }}
          </ion-text>
        </p>
      </div>
    </ion-card-content>
  </ion-card>
  <ion-button
    size="large"
    expand="full"
    color="success"
    no-margin
    [disabled]="!onLoginForm.valid||enable"
    (click)="proceed()"
    *ngIf="togo||delivery||dinein"
  >
    <ion-text color="light"> {{'app.checkin.proceed'|translate}}</ion-text>
    <ion-icon slot="start" name="card" color="light"></ion-icon>
  </ion-button>
</ion-footer>

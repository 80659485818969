import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss'],
})
export class DonateComponent implements OnInit {
  eventForm: FormGroup;
  selectedAmount = 21;
  customAmount;
  enableWhenSelected = false;
  donations = [
    { amount: 11, isChecked: false, a: 1 },
    { amount: 21, isChecked: true, a: 1 },
    { amount: 51, isChecked: false, a: 1 },
    { amount: 101, isChecked: false, a: 1 },
  ];
  donations2 = [
    { amount: 151, isChecked: false, a: 2 },
    { amount: 201, isChecked: false, a: 2 },
    { amount: 501, isChecked: false, a: 2 },
    { amount: 1001, isChecked: false, a: 2 },
  ];
  donations3 = [
    { amount: 1501, isChecked: false, a: 3 },
    { amount: 2001, isChecked: false, a: 3 },
    { amount: 2501, isChecked: false, a: 3 },
  ];
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router
  ) {
    this.eventForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
    });
    this.selectedAmount = 21;
    const email = localStorage.getItem('customerEmail');
    if (email !== null) {
      this.eventForm.patchValue({
        email,
      });
    }
  }

  ngOnInit() {}

  customdollarfn() {
    this.enableWhenSelected = true;
    console.log(this.customAmount);
    this.selectedAmount = this.customAmount;
  }
  donate(amount, a) {
    console.log(a);
    if (a === 1) {
      this.donations2.forEach((res2) => {
        res2.isChecked = false;
      });
      this.donations3.forEach((res3) => {
        res3.isChecked = false;
      });
      this.donations.forEach((res3) => {
        if (res3.amount === amount) res3.isChecked = true;
      });
    } else if (a === 2) {
      this.donations.forEach((res1) => {
        res1.isChecked = false;
      });
      this.donations3.forEach((res3) => {
        res3.isChecked = false;
      });
      this.donations2.forEach((res3) => {
        if (res3.amount === amount) res3.isChecked = true;
      });
    } else if (a === 3) {
      this.donations.forEach((res1) => {
        res1.isChecked = false;
      });
      this.donations2.forEach((res2) => {
        res2.isChecked = false;
      });
      this.donations3.forEach((res3) => {
        if (res3.amount === amount) res3.isChecked = true;
      });
    }
    console.log(this.donations, this.donations2, this.donations3);
    this.enableWhenSelected = true;
    this.selectedAmount = amount;
  }
  navigateToPayment() {
    localStorage.setItem('guestNameAapi', this.eventForm.value.name);
    localStorage.setItem('guestEmailAapi', this.eventForm.value.email);
    localStorage.setItem('donationAmount', JSON.stringify(this.selectedAmount));
    this.router.navigate([`payment/${this.selectedAmount}`]);
  }
  back() {
    this.location.back();
  }
}

<ion-header >
    <ion-toolbar color="primary" >
        <ion-title color="light">{{'app.info' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button size="small" shape="round" (click)="closeModal()" color="light">
          <ion-icon slot="icon-only" name="close-circle"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

<ion-content>
    <ion-card no-margin class="bg-white animated fadeIn">
        <ion-card-content text-center>
          <h3>
            <ion-text color="primary">{{'app.info.average' | translate }}</ion-text>
          </h3>
          <h1 no-margin>
            <ion-text color="primary">
              <strong>3.25</strong>
            </ion-text>
          </h1>
        </ion-card-content>
      </ion-card>
    <ion-col size="8" no-padding>
      <ion-card no-margin class="bg-profile animated fadeIn">
          <ion-card-content>
            <h1>
              <ion-text color="light">3.25 / <strong>5</strong></ion-text>
            </h1>
            <h3>
              <ion-text color="light">
                <!-- totally {{restaurant.reviews.length}} people reviewed -->
              </ion-text>
            </h3>
          </ion-card-content>
        </ion-card>
    </ion-col>

</ion-content>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SwyloordersGuard } from 'src/app/guard/swyloorders.guard';
import { DonateComponent } from './pages/donate/donate.component';
import { FairConfirmationComponent } from './pages/fair-confirmation/fair-confirmation.component';
import { FairFormComponent } from './pages/fair-form/fair-form.component';
import { FairReportComponent } from './pages/fair-report/fair-report.component';
import { FairComponent } from './pages/fair/fair.component';
import { PaymentFairComponent } from './pages/payment-fair/payment-fair.component';
import { TicketConfirmationComponent } from './pages/ticket-confirmation/ticket-confirmation.component';

const routes: Routes = [
  { path: '', component: FairComponent },
  { path: 'form', component: FairFormComponent },
  { path: 'edit-form/:id', component: FairFormComponent },
  { path: 'fair-confirmation/:id', component: FairConfirmationComponent },
  { path: 'donate', component: DonateComponent },
  {
    path: 'fair-report',
    component: FairReportComponent,
    canActivate: [SwyloordersGuard],
  },
  { path: 'payment/:id', component: PaymentFairComponent },
  { path: 'ticket-confirmation', component: TicketConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FairRoutingModule {}

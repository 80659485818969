<ion-header>
  <ion-toolbar color="primary">
    <ion-title color="light">Summary</ion-title>
    <ion-buttons slot="end">
      <ion-button
        size="small"
        shape="round"
        (click)="closeModal()"
        color="light"
      >
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-card *ngIf="items.length < 1" color="success" margin-top>
  <ion-card-content>
    <p text-center class="text-white">You have'nt added any item yet!</p>
  </ion-card-content>
</ion-card>
<ion-content *ngIf="items.length > 0">
  <ion-list>
    <ion-item class="bg-white" *ngFor="let item of items">
      <ion-label
        >{{ item.name }}({{ item.type }})
        <p>Qty: {{ item.qty }}</p>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list>
    <ion-list-header class="couponheader" style="font-weight: bold">
      Coupon
    </ion-list-header>
    <ion-item class="bg-white">
      <ion-input
        placeholder="Enter Coupon Code"
        [(ngModel)]="couponCode"
        class="couponcss"
      ></ion-input>
      <ion-button
        expand="block "
        class="couponbutton"
        color="secondary"
        (click)="useCode()"
        >Apply</ion-button
      >
    </ion-item>
  </ion-list>

  <form [formGroup]="eventForm" class="m-l-10">
    <ion-item class="available2" lines="none">
      <ion-label class="google-label" color="dark">
        Full Name
        <p class="star">*</p>
      </ion-label>
      <ion-input formControlName="name" class="google-input" type="text">
      </ion-input>
      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('name').touched &&
          eventForm.get('name').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" color="dark">
        Email
        <p class="star" *ngIf="!showFooter">*</p>
      </ion-label>
      <ion-input formControlName="email" class="google-input" type="text">
      </ion-input>
    </ion-item>

    <p
      ion-text
      class="text08"
      *ngIf="
        eventForm.get('email').touched && eventForm.get('email').errors?.email
      "
    >
      <ion-text color="primary">
        *This field is required in correct format for notifications
      </ion-text>
    </p>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" color="dark">
        Phone Number
        <p class="star" *ngIf="!showFooter">*</p>
      </ion-label>
      <ion-input formControlName="phoneNumber" class="google-input" type="text">
      </ion-input>

      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('phoneNumber').touched &&
          eventForm.get('phoneNumber').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>
  </form>
</ion-content>
<ion-footer *ngIf="items.length > 0">
  <ion-card class="mycolour" no-margin>
    <p class="m-l-10">
      <ion-text class="total" color="light"
        >Sub Total: {{ subTotal | currency }}</ion-text
      >
    </p>
    <p class="m-l-10" *ngIf="isCouponApplied">
      <ion-text class="total" color="light"
        >CouponAmount: {{ couponAmount | currency }}</ion-text
      >
    </p>
    <p class="m-l-10">
      <ion-text class="total" color="light"
        >Service fee(5%): {{ serviceTax | currency }}</ion-text
      >
    </p>
    <p class="m-l-10">
      <ion-text class="total" color="light"
        >Total: {{ totalAmount | currency }}</ion-text
      >
    </p>
  </ion-card>

  <ion-button
    *ngIf="items.length > 0"
    size="large"
    expand="full"
    (click)="navigateToPayment()"
    color="success"
    [disabled]="!eventForm.valid"
    >Book now</ion-button
  >
</ion-footer>

import { TranslateProvider } from '../providers';
import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "../providers/translate/translate.service";
import * as i2 from "@ionic/angular";
export class LbguardGuard {
    constructor(service, navCtrl) {
        this.service = service;
        this.navCtrl = navCtrl;
    }
    canActivate(next, state) {
        if (this.service) {
            if (this.service.isLoggedIn !== true) {
                window.alert("You are not allowed to access this URL! Login First");
                this.navCtrl.navigateRoot('/login');
                localStorage.setItem('guard', state.url);
            }
        }
        return true;
    }
}
LbguardGuard.ngInjectableDef = i0.defineInjectable({ factory: function LbguardGuard_Factory() { return new LbguardGuard(i0.inject(i1.TranslateProvider), i0.inject(i2.NavController)); }, token: LbguardGuard, providedIn: "root" });

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div style="display: flex">
    <div style="margin-left: 25px">
      <p>Available</p>
      <div class="available"></div>
    </div>
    <div style="margin-left: 25px">
      <p>Sold</p>
      <div class="booked"></div>
    </div>
  </div>
  <table>
    <tr *ngFor="let row of rows">
      <td *ngFor="let seat of seats">
        <div
          [ngClass]="{
            'seat-select': !checkSeatStatus(row + '' + seat),
            'seat-selected': checkSeatStatus(row + '' + seat),
            'seat-reserved': isSeatReserved(row + '' + seat)
          }"
          (click)="seatSelect(row + '' + seat)"
        >
          {{ row + '' + seat }}
        </div>
      </td>
    </tr>
  </table>

  <div style="text-align: center">
    <p>------all eyes this way please!------</p>
  </div>
</ion-content>

<ion-footer>
  <ion-button
    size="large"
    expand="full"
    (click)="payNow()"
    [disabled]="total === 0"
    color="success"
    >Pay ${{ total }}</ion-button
  >
</ion-footer>
<!-- 'seat-disabled': isSeatReserved(row + '' + seat) -->

<ion-header>
  <ion-toolbar color="primary">
    <ion-title color="light">Guest Login</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="light">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <div class="theme-bg"></div>
  <br><br><br><br>
  <div>

    <div class="auto">

      <br>
      <form [formGroup]="onLoginForm" class="list-form">

        <ion-label class="google-label" color="black">
          <ion-icon name="mail" color="black" item-start></ion-icon>
          {{ 'app.label.email' | translate }}&nbsp;:
        </ion-label>
        <ion-input class="google-input" color="black" type="email" formControlName="email"></ion-input>
        <p ion-text class="text08"
          *ngIf="onLoginForm.get('email').touched && onLoginForm.get('email').hasError('required')">
          <ion-text color="black">
            {{ 'app.label.errors.field' | translate }}
          </ion-text>
        </p>
      </form>
      <br>
      <div>
        <ion-button icon-left size="medium" expand="block" shape="round" color="dark" (click)="anonymousLogin()"
          [disabled]="!onLoginForm.valid" tappable>
          <ion-icon name="log-in"></ion-icon>
          Continue
        </ion-button>
      </div>
    </div>

  </div>




</ion-content>
<ion-footer>

</ion-footer>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NgZone, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavController, Platform, LoadingController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { RestaurantService } from '../restaurant/restaurant.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/common";
import * as i4 from "@angular/fire/firestore";
import * as i5 from "../restaurant/restaurant.service";
export class TranslateProvider {
    constructor(afAuth, navCtrl, ngZone, 
    // private gplus:GooglePlus,
    platform, loadingController, location, zone, firestore, rservice) {
        this.afAuth = afAuth;
        this.navCtrl = navCtrl;
        this.ngZone = ngZone;
        this.platform = platform;
        this.loadingController = loadingController;
        this.location = location;
        this.zone = zone;
        this.firestore = firestore;
        this.rservice = rservice;
        this.fireIsLoggedIn = new EventEmitter();
        this.isadmin = new BehaviorSubject(false);
        this.url = new EventEmitter();
        this.email1 = new EventEmitter();
        this.loggedIn = new BehaviorSubject(false);
        firebase.auth().onAuthStateChanged((firebaseUser) => {
            this.zone.run(() => {
                firebaseUser ? this.loggedIn.next(true) : this.loggedIn.next(false);
            });
        });
        if (this.afAuth) {
            this.afAuth.authState.subscribe((user) => {
                if (user) {
                    this.user = user;
                    localStorage.setItem('user', JSON.stringify(this.user));
                    this.fireIsLoggedIn.emit(user.displayName);
                    this.url.emit(user.photoURL);
                    this.email1.emit(user.email);
                    this.swyloemail = user.email;
                    //this.navCtrl.navigateRoot('/payment-options');
                }
                else {
                    localStorage.setItem('user', null);
                }
            });
        }
        const user = JSON.parse(localStorage.getItem('user'));
        /* if (user!=null){
                          this.navCtrl.navigateRoot('/home-location');
                        }
                        if(user==null){
                          this.navCtrl.navigateRoot('/login');
                    
                        }*/
    }
    getEmitter() {
        return this.fireIsLoggedIn;
    }
    getEmitter2() {
        return this.url;
    }
    getEmitter3() {
        return this.email1;
    }
    getswyloemail() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.swyloemail;
        });
    }
    ionViewWillEnter() {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user.email);
        this.rservice
            .getOwners()
            .valueChanges()
            .subscribe((res) => {
            console.log(res);
            this.owners = res;
            let item = this.owners.find((p) => p.email == user.email);
            if (item == undefined) {
                this.admin = false;
            }
            else {
                this.admin = true;
            }
        });
    }
    ngOnInit() { }
    // Set the translations of the app.
    setTranslations(translations) {
        this.translations = translations;
    }
    getTranslations() {
        return this.translations;
    }
    // Get the translated string given the key based on the i18n .json file.
    get(key) {
        return this.translations[key];
    }
    presentLoading(loading) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield loading.present();
        });
    }
    native() {
        return __awaiter(this, void 0, void 0, function* () {
            // try {
            //   const gpuser = await this.gog.login({
            //     webClientId:
            //       '37639046366-pol88ucihq56c0pbdn5d0tqimg0ujr3e.apps.googleusercontent.com',
            //     offline: true,
            //     scopes: 'profile email',
            //   });
            //   return await this.afAuth.auth.signInWithCredential(
            //     firebase.auth.GoogleAuthProvider.credential(gpuser.idToken)
            //   );
            // } catch (err) {
            //   console.log(err);
            // }
        });
    }
    google() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.platform.is('capacitor')) {
                this.native();
                console.log('tru');
            }
            else {
                var provider = new firebase.auth.GoogleAuthProvider();
                provider.setCustomParameters({
                    prompt: 'select_account',
                });
                firebase.auth().signInWithRedirect(provider);
                firebase
                    .auth()
                    .getRedirectResult()
                    .then(function (result) {
                    if (result.credential) {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        // ...
                    }
                    // The signed-in user info.
                    var user = result.user;
                    console.log('from translate provider' + user);
                })
                    .catch(function (error) {
                    // Handle Errors here.
                    console.log('from translate provider', error);
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;
                    // ...
                });
            }
        });
    }
    isUserEqual(facebookAuthResponse, firebaseUser) {
        if (firebaseUser) {
            const providerData = firebaseUser.providerData;
            providerData.forEach((data) => {
                if (data.providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
                    data.uid === facebookAuthResponse.userID) {
                    // We don't need to re-auth the Firebase connection.
                    return true;
                }
            });
        }
        return false;
    }
    get isLoggedIn() {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user);
        if (user !== null) {
            return true;
        }
        else {
            return false;
        }
        //return (user !== null && user.emailVerified !== false) ? true : false;
    }
    set isadminn(isadmin) {
        this.admin = isadmin;
    }
    get isadminn() {
        return this.admin;
    }
}
TranslateProvider.ngInjectableDef = i0.defineInjectable({ factory: function TranslateProvider_Factory() { return new TranslateProvider(i0.inject(i1.AngularFireAuth), i0.inject(i2.NavController), i0.inject(i0.NgZone), i0.inject(i2.Platform), i0.inject(i2.LoadingController), i0.inject(i3.Location), i0.inject(i0.NgZone), i0.inject(i4.AngularFirestore), i0.inject(i5.RestaurantService)); }, token: TranslateProvider, providedIn: "root" });

<ion-header>
  <ion-toolbar color="primary">
      <ion-buttons slot="start">
          <ion-icon name="arrow-round-back" size="large" (click)="back()" color="light"></ion-icon>
            </ion-buttons>
    <ion-title>{{ 'app.cod' | translate }}</ion-title>
  </ion-toolbar>
  
</ion-header>


<ion-content>
<button  printTitle="MyTitle"  
printSectionId="print-section"  
ngxPrint class='button'>{{ 'app.cod.print' | translate }}</button>

<!-- <div *ngIf='!delivery'>{{ 'app.cod.pickup' | translate }} &nbsp; <ion-label>{{checkintime | date:'MM/dd h:mm a'}}</ion-label> </div><br> -->

<ion-item *ngFor="let song of details">

  <ion-badge  color="primary" class='badge'>
    <ion-label  color='light' *ngIf='song.cancelled'>{{ 'app.cod.cancel' | translate }}</ion-label>

  </ion-badge>
 
</ion-item>
<div id="print-section" style="margin-left: 12px;"> 

  <h1>SWYLO</h1>

  <label style="font-size: 18px;
  font-weight: 700;">Store: 

    {{storename}}
  </label>

  <div *ngFor="let song of details">
 
    <ion-badge color='success' [hidden]="!song?.refundamount">A refund of {{song?.refundamount/100| currency }} has sent</ion-badge>
    
    <br>
        <!-- <label>
          {{ 'app.cod.amount' | translate }}:   &nbsp;{{originaltaxplustotal/100| currency }} 
    
        </label><br> -->
        <div>
            <label>{{orderType}}</label><br>
            <label>{{checkintime | date:'MM/dd/yyyy h:mm a'}}</label><br><br>
          </div>

           <label>
            {{ 'app.cod.guest' | translate }}:   &nbsp;{{song?.guestname}} 
       
           </label><br>
           <label>
            {{ 'app.cod.mobile' | translate }}:   &nbsp;{{song?.guestmobile}} 
         
             </label><br>
          <div *ngIf="delivery">
            <ion-label>
              address: {{song?.address}}
            </ion-label>
            <div *ngIf="unit">
              <ion-label >
                Unit/Apt No:&nbsp; {{unit}}
               </ion-label></div>
            <div *ngIf="ins">
             <ion-label >
             instructions:&nbsp;  {{ins}}
             </ion-label><br></div>
            </div>  
          
           <label *ngIf="alternatebrands">
            {{ 'app.cod.allow' | translate }} :&nbsp; {{alternatebrands}}
    
           </label><br>
    
           <ion-label>
            Subtotal:&nbsp;  {{subtotal/100| currency }}
           </ion-label><br>
           <ion-label>
            Tax:&nbsp;  {{handlebartax }}
           </ion-label><br>
           <ion-label *ngIf="applieddiscount!=0">
            Coupon Discount:&nbsp;  {{ applieddiscount | currency }}
           </ion-label><br>
    
          </div>
    
    <div *ngFor="let song of results1">
    
         <!-- <label>
            {{song?.name}}  -- qty --  &nbsp; {{song?.quantity}}  </label><br> -->
            
        


        </div>
        <hr>

<div *ngFor="let song of products">
  <h3>
{{song.category}}
  </h3> 
   <div *ngFor="let task of song.products">
    {{task.name}}-- qty --  &nbsp; {{task.quantity}}  
  </div>
</div>


  
  
     
      

       


       <!-- <ion-label *ngIf="song.refundamount">A refund of {{song?.refundamount/100| currency }} has sent</ion-label> -->

</div>
<!--  
  <ag-grid-angular
  style="width: 500px; height: 300px;"
  class="ag-theme-alpine"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  (gridReady)="onGridReady($event)"
  (cellValueChanged)="onCellValueChanged($event)">
</ag-grid-angular> -->
<!-- <ion-button (click)="get()">get</ion-button> -->

 <!-- <ion-label>
  PaymentRequest sent:&nbsp;  {{taxplustotal/100| currency }}
 </ion-label> -->
<!-- <ion-button (click)="sendreq()"*ngIf="positive">{{ 'app.cod.request' | translate }}  &nbsp; {{difference/100| currency }}
</ion-button> -->
<div *ngFor="let res of details">

  <ion-button (click)="sendref()"*ngIf="negative" [disabled]='res.refundamount'>{{ 'app.cod.refund' | translate }} &nbsp;  {{difference/100| currency }}
  

  </ion-button>
</div>
<!-- <ion-button (click)="amountsmatchupdate()"*ngIf="showupdatebutton && !negative && !positive">{{ 'app.cod.update' | translate }}
</ion-button> -->
<!-- <ion-button (click)="push()">push an item</ion-button> -->

<!-- <ion-label class="taxplustotal">
  {{difference/100| currency }}
</ion-label> -->
<!-- <ion-searchbar type="search" class="editsearch" placeholder="Search And add an Item" (ionChange)="search($event)"
(ionFocus)="inFocus()" >
</ion-searchbar> -->
<div *ngIf="showloader" class="firebasespinner" >
  <ion-spinner  name="circles"></ion-spinner>
</div>
<ion-list *ngIf="menuresults">
  <ion-toolbar>
    <ion-buttons slot="end" class="closeButton" (click)="closeResults()" id="closebutton">

    <ion-icon name="close-circle" ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
  <ion-card *ngFor="let data of temp " tappable class="cardcontent" >
      
    <ion-card-content >{{data.item}} &nbsp;&nbsp; {{data.amount}}$</ion-card-content>

    <ion-chip color="secondary" outline="true" (click)="additem(data.item,data.amount,data.tax,data.img)">
      <ion-icon name="book" color="secondary"></ion-icon>
      <ion-label>{{ 'app.cod.add' | translate }}</ion-label>
    </ion-chip>

  </ion-card>
</ion-list>
<!-- <ion-item>
  {{taxc}}
</ion-item> -->

<ion-list *ngIf="noResultsFound">
  {{ 'app.cod.noresult' | translate }}
</ion-list>
<ion-list no-margin >
  <ion-item-sliding *ngFor="let order of favourite; let i=index" #slidingList>
    <ion-item class="bg-white" lines="none">

      <!-- <ion-thumbnail slot="start">
          <img [src]="order.img" />
      </ion-thumbnail>   -->
        <ion-label>
          <h2 class="fw700">
            <ion-text color="dark">{{order.name}}</ion-text>
          </h2>
         
          <ion-grid class="filters" no-padding >
            <ion-row>
              <ion-col text-center col-2>
                <ion-icon name="remove-circle" class="text15" tappable (click)="minusQtd(i,order.Id,order)" [hidden]="order.quantity < 2"
                          color="secondary"></ion-icon>
              </ion-col>
              <ion-col text-center col-3>
                <h2 class="text15">
                  <ion-text color="primary">{{ order.quantity }}</ion-text>
                </h2>
              </ion-col>
             
              <!-- <ion-col text-center col-2>
                <ion-icon name="add-circle" class="text15" tappable (click)="plusQtd(i,order.Id)" color="secondary"></ion-icon>
              </ion-col> -->
              <ion-col text-center col-3>
                  <ion-icon name="trash" color="primary" class="text15" (click)="removeOrder(i,order.Id,order.menuid,order)"></ion-icon>                 
              </ion-col>
            </ion-row>
          </ion-grid>
          <div item-right>
            <ion-badge>{{ order.base_price_money.amount* order.quantity/100 | currency }}</ion-badge>
          </div>
          <!-- <div item-left>
            <ion-badge>{{ order.amount * order.quantity | currency }}</ion-badge>
          </div> -->
        </ion-label>
      </ion-item>
  
  </ion-item-sliding>
</ion-list>
 


</ion-content>

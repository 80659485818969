<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card *ngIf="eventDetails">
    <img class="image" [src]="eventDetails.landingImage" alt="ion" />

    <ion-card-content>
      <ion-label>{{ eventDetails.registerNotes }}</ion-label>
      <ion-row>
        <ion-col>
          <button>
            <ion-icon name="time"></ion-icon>
            <div style="font-size: 18px">Expires {{ eventDetails.expiry }}</div>
          </button>
        </ion-col>
      </ion-row>
    </ion-card-content>

    <ion-footer *ngIf="total === 0">
      <ion-row>
        <ion-col center text-center>
          <ion-button color="primary" (click)="navigateToForm()">
            <ion-icon name="text"></ion-icon>
            <div>Register Now</div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </ion-card>

  <ion-card *ngFor="let item of registeredList; let i = index" class="userCard">
    <ion-row style="margin-left: 8px">
      <ion-col size="5">
        <ion-label>{{ item.name }}</ion-label>
      </ion-col>
      <ion-col size="3">
        <ion-label>$ {{ item.price }}</ion-label>
      </ion-col>
      <ion-col size="2">
        <ion-icon
          name="create"
          size="large"
          routerLink="edit-form/{{ i }}"
        ></ion-icon>
      </ion-col>
      <ion-col size="2">
        <ion-icon name="close" size="large" (click)="remove(i)"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-card>
  <ion-button
    *ngIf="total > 0"
    (click)="navigateToForm()"
    size="small"
    class="addGuest"
    >Add Guest</ion-button
  >
</ion-content>

<ion-footer *ngIf="total > 0">
  <ion-card class="mycolour" no-margin>
    <p>
      <ion-text class="total" color="light">Total: ${{ total }}</ion-text>
    </p>
  </ion-card>
  <ion-button
    size="large"
    expand="full"
    color="success"
    no-margin
    (click)="navigateToPayment()"
  >
    <ion-text color="light">Proceed to pay</ion-text>
    <ion-icon slot="start" name="card" color="light"></ion-icon>
  </ion-button>
</ion-footer>

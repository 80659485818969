var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from '@angular/common';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
export class TableBookingComponent {
    constructor(formBuilder, router, location, toastCtrl) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.location = location;
        this.toastCtrl = toastCtrl;
        this.eventForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phoneNumber: ['', Validators.required],
            seats: ['', Validators.required],
        });
    }
    ngOnInit() { }
    back() {
        this.router.navigate(['/aapifalltn2022']);
    }
    bookSeats() {
        return __awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastCtrl.create({
                position: 'middle',
                message: 'Table Booked successfully!',
                duration: 1500,
                color: 'primary',
            });
            toast.present();
            this.router.navigate(['/aapifalltn2022']);
        });
    }
}

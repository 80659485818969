import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LbguardGuard } from './guard/lbguard.guard';
import { SsfGuard } from './guard/ssf.guard';
import { CanActivate } from '@angular/router';
import { AdminGuardGuard } from './guard/admin-guard.guard';
import { UploaderComponent } from './uploader/uploader.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { CustomerorderdetailComponent } from './customerorderdetail/customerorderdetail.component';
import { SettingsComponent } from './settings/settings.component';
import { SwyloordersGuard } from './guard/swyloorders.guard';
import { ReportComponent } from './report/report.component';
import { DeliveryagentGuard } from './guard/deliveryagent.guard';
import { Report2Component } from './report2/report2.component';
import { CsvuploadComponent } from './pages/csvupload/csvupload.component';

const routes: Routes = [
  // { path: '', loadChildren: './pages/walkthrough/walkthrough.module#WalkthroughPageModule', canActivate: [SsfGuard] },
  {
    path: '',
    loadChildren:
      './pages/home-location/home-location.module#HomeLocationPageModule',
  },

  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule',
    canActivate: [SsfGuard],
  },
  {
    path: 'register',
    loadChildren: './pages/register/register.module#RegisterPageModule',
    canActivate: [SsfGuard],
  },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  {
    path: 'support',
    loadChildren: './pages/support/support.module#SupportPageModule',
  },
  {
    path: 'settings',
    loadChildren: './pages/settings/settings.module#SettingsPageModule',
  },
  {
    path: 'edit-profile',
    loadChildren:
      './pages/edit-profile/edit-profile.module#EditProfilePageModule',
  },
  {
    path: 'home-results',
    loadChildren:
      './pages/home-results/home-results.module#HomeResultsPageModule',
  },
  {
    path: 'search-filter',
    loadChildren:
      './pages/modal/search-filter/search-filter.module#SearchFilterPageModule',
  },

  {
    path: 'restaurant-detail/:id',
    loadChildren:
      './pages/restaurant-detail/restaurant-detail.module#RestaurantDetailPageModule',
  },

  {
    path: 'bycategory',
    loadChildren: './pages/bycategory/bycategory.module#BycategoryPageModule',
  },
  {
    path: 'googleplaces-detail/:id',
    loadChildren:
      './pages/googleplaces-detail/googleplaces-detail.module#GoogleplacesDetailPageModule',
  },

  {
    path: 'favorites',
    loadChildren: './pages/favorites/favorites.module#FavoritesPageModule',
  },
  {
    path: 'latest-orders',
    loadChildren:
      './pages/latest-orders/latest-orders.module#LatestOrdersPageModule',
  },
  {
    path: 'cart',
    loadChildren: './pages/modal/cart/cart.module#CartPageModule',
  },

  {
    path: 'customer-orders',
    loadChildren:
      './pages/customer-orders/customer-orders.module#CustomerOrdersPageModule',
  },
  {
    path: 'info',
    loadChildren: './pages/modal/info/info.module#InfoPageModule',
  },
  {
    path: 'resdetail/:id',
    loadChildren: './pages/resdetail/resdetail.module#ResdetailPageModule',
  },
  {
    path: 'resdetail/:id/:productId/:productName',
    loadChildren: './pages/resdetail/resdetail.module#ResdetailPageModule',
  },
  {
    path: 'order-type/:id',
    loadChildren: './pages/menu-type/menu-type.module#MenuTypePageModule',
  },
  {
    path: 'catering-menu/:id',
    loadChildren:
      './pages/catering-menu/catering-menu.module#CateringMenuPageModule',
  },
  {
    path: 'togo-menu/:id',
    loadChildren: './pages/togo-menu/togo-menu.module#TogoMenuPageModule',
  },
  {
    path: 'payment-options',
    loadChildren:
      './pages/payment-options/payment-options.module#PaymentOptionsPageModule',
    canActivate: [LbguardGuard],
  },
  {
    path: 'admin-menu/:id',
    loadChildren: './pages/admin-menu/admin-menu.module#AdminMenuPageModule',
  },
  {
    path: 'create-record',
    loadChildren:
      './pages/create-record/create-record.module#CreateRecordPageModule',
  },
  {
    path: 'update-record',
    loadChildren:
      './pages/update-record/update-record.module#UpdateRecordPageModule',
  },
  {
    path: 'cancel-order/:id',
    loadChildren:
      './pages/cancel-order/cancel-order.module#CancelOrderPageModule',
  },
  {
    path: 'check-in/:id',
    loadChildren: './pages/check-in/check-in.module#CheckInPageModule',
  },
  {
    path: 'edit-order',
    loadChildren: './pages/edit-order/edit-order.module#EditOrderPageModule',
  },
  {
    path: 'order-confirmation/:id',
    loadChildren:
      './pages/order-confirmation/order-confirmation.module#OrderConfirmationPageModule',
  },
  {
    path: 'book-a-table',
    loadChildren:
      './pages/book-a-table/book-a-table.module#BookATablePageModule',
  },
  {
    path: 'catering',
    loadChildren: './pages/catering/catering.module#CateringPageModule',
  },
  {
    path: 'refund-requests/:id',
    loadChildren:
      './pages/refund-requests/refund-requests.module#RefundRequestsPageModule',
  },
  {
    path: 'daily-specials/:id',
    loadChildren:
      './pages/daily-specials/daily-specials.module#DailySpecialsPageModule',
  },
  {
    path: 'refundorder-detail/:id',
    loadChildren:
      './pages/refundorder-detail/refundorder-detail.module#RefundorderDetailPageModule',
  },
  {
    path: 'workin-progress',
    loadChildren:
      './pages/workin-progress/workin-progress.module#WorkinProgressPageModule',
  },
  {
    path: 'add-member/:id',
    loadChildren: './pages/add-member/add-member.module#AddMemberPageModule',
  },
  {
    path: 'category-slider/:id',
    loadChildren:
      './pages/category-slider/category-slider.module#CategorySliderPageModule',
  },
  {
    path: 'time-slots/:id',
    loadChildren: './pages/time-slots/time-slots.module#TimeSlotsPageModule',
    canActivate: [SwyloordersGuard],
  },
  {
    path: 'terms-of-service',
    loadChildren:
      './pages/terms-of-service/terms-of-service.module#TermsOfServicePageModule',
  },
  {
    path: 'privacy-policy',
    loadChildren:
      './pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule',
  },
  {
    path: 'u-businessimages',
    loadChildren:
      './pages/u-businessimages/u-businessimages.module#UBusinessimagesPageModule',
    canActivate: [SwyloordersGuard],
  },
  { path: 'uploader', component: UploaderComponent },
  { path: 'appsettings', component: SettingsComponent },
  { path: 'csvupload', component: CsvuploadComponent },

  {
    path: 'upload-task',
    component: UploadTaskComponent,
    canActivate: [SwyloordersGuard],
  },
  { path: 'codetail/:id', component: CustomerorderdetailComponent },
  {
    path: 'payment-link',
    loadChildren:
      './pages/payment-link/payment-link.module#PaymentLinkPageModule',
    canActivate: [LbguardGuard],
  },
  {
    path: 'order-summary/:id',
    loadChildren:
      './pages/order-summary/order-summary.module#OrderSummaryPageModule',
  },
  { path: 'tax', loadChildren: './pages/tax/tax.module#TaxPageModule' },
  { path: 'tip/:id', loadChildren: './pages/tip/tip.module#TipPageModule' },
  {
    path: 'admin-orders',
    loadChildren:
      './pages/admin-orders/admin-orders.module#AdminOrdersPageModule',
    canActivate: [SwyloordersGuard],
  },
  {
    path: 'add-deliveryagent/:id',
    loadChildren:
      './pages/add-deliveryagent/add-deliveryagent.module#AddDeliveryagentPageModule',
  },
  {
    path: 'deliveries',
    loadChildren: './pages/deliveries/deliveries.module#DeliveriesPageModule',
    canActivate: [DeliveryagentGuard],
  },
  {
    path: 'deliveries-detail/:id',
    loadChildren:
      './pages/deliveries-detail/deliveries-detail.module#DeliveriesDetailPageModule',
    canActivate: [DeliveryagentGuard],
  },
  {
    path: 'reportpage',
    loadChildren: './pages/report/report.module#ReportPageModule',
    canActivate: [SwyloordersGuard],
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [SwyloordersGuard],
  },
  {
    path: 'report2',
    component: Report2Component,
    canActivate: [SwyloordersGuard],
  },
  {
    path: 'coupon',
    loadChildren: './pages/modal/coupon/coupon.module#CouponPageModule',
  },
  {
    path: 'help/userguide',
    loadChildren: './pages/help/help.module#HelpPageModule',
  },
  {
    path: 'adminmenudet',
    loadChildren:
      './pages/modal/adminmenudet/adminmenudet.module#AdminmenudetPageModule',
  },
  {
    path: 'choose-city',
    loadChildren: './pages/choose-city/choose-city.module#ChooseCityPageModule',
  },
  {
    path: 'coupons/:id',
    loadChildren: './pages/coupons/coupons.module#CouponsPageModule',
  },
  {
    path: 'guest-login',
    loadChildren:
      './pages/modal/guest-login/guest-login.module#GuestLoginPageModule',
  },
  {
    path: 'review',
    loadChildren: './pages/modal/review/review.module#ReviewPageModule',
  },
  {
    path: 'platform-access',
    loadChildren:
      './pages/platform-access/platform-access.module#PlatformAccessPageModule',
  },
  {
    path: 'store-management/:id',
    loadChildren:
      './pages/store-management/store-management.module#StoreManagementPageModule',
  },
  {
    path: 'refer-afriend',
    loadChildren:
      './pages/refer-afriend/refer-afriend.module#ReferAfriendPageModule',
  },
  {
    path: 'admin-reviews/:id',
    loadChildren:
      './pages/reviews-admin/reviews-admin.module#ReviewsAdminPageModule',
  },

  {
    path: 'aapifalltn2022' || 'AAPIFALLTN2022',
    loadChildren: './modules/events/events.module#EventsModule',
  },
  {
    path: 'NYP2022',
    loadChildren: './modules/fair/fair.module#FairModule',
  },
  {
    path: 'extras',
    loadChildren: './pages/modal/extras/extras.module#ExtrasPageModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
      ></ion-icon>
    </ion-buttons>
    <ion-title> Event orders </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <button printTitle="MyTitle" printSectionId="ex" ngxPrint class="button">
    Print Report
  </button>
  <a style="cursor: pointer" (click)="exportexcel()"> Download excel file </a>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <br />
  <br /><br />
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>Total orders:</ion-label><br />
        <ion-label *ngIf="orderDetails">{{ orderDetails.length }}</ion-label>
      </ion-col>
      <!-- <ion-col>
    <ion-label>without processing fee:</ion-label><br>
    <ion-label>{{withoutprocessingfee/100|currency}}</ion-label>

  </ion-col> -->
      <ion-col>
        <ion-label>Total amount:</ion-label><br />
        <ion-label>$ {{ total }}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div id="ex">
    <table id="excel-table">
      <tr>
        <th>Placed On</th>
        <th>Ordered By</th>
        <th>Order Id</th>
        <th>Tickets Count</th>
        <th>Total</th>
      </tr>
      <tr *ngFor="let song of orderDetails">
        <td class="commonPadding">
          {{ song.timestamp.toDate() | date: 'MM/dd h:mm a' }}
        </td>
        <td class="commonPadding">{{ song.email }}</td>
        <td class="commonPadding">{{ song.id }}</td>
        <td class="commonPadding">{{ song.event.length }}</td>
        <!-- add td <tr>
            <th>name</th>
            <th>designation</th>
            <th>stateLicenceNumber</th>
            <th>address</th>
          </tr> -->

        <!-- <tr *ngFor="let item of song.event">
            <td class="width-40">{{ item.name }}</td>
            <td class="width-40">
              {{ item.selectedDesignation }}
            </td>
            <td class="width-40">
              {{ item.stateLicenceNumber }}
            </td>
            <td class="width-40">{{ item.address }}</td>
          </tr> -->
        <td class="commonPadding">$ {{ song.total }}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="orderDetails">
    <div *ngFor="let song of orderDetails">
      <ion-item id="itempadding" lines="none">
        <ion-label>
          <h2>Order Id: {{ song.id }}</h2>
          <h2>
            {{ song.timestamp.toDate() | date: 'MMM d, y, h:MM a' }}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </h2>
        </ion-label>
      </ion-item>
      <ion-item class="bg-white" (click)="summary(song.id)">
        <ion-label>
          <h2>
            <ion-text color="dark"
              ><strong>{{ song.event[0].eventName }}</strong> &nbsp;&nbsp;
            </ion-text>
          </h2>
          <h2>
            <ion-text color="dark">
              Tickets Count &nbsp;: &nbsp; {{ song.event.length }}</ion-text
            >
          </h2>
          <p class="text-12x">
            <ion-text color="secondary" class="fw700"
              >Total:&nbsp; {{ song?.total }}</ion-text
            >
          </p>
        </ion-label>
      </ion-item>
    </div>
  </div>
</ion-content>

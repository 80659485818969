import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { RestaurantService } from 'src/app/providers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tickets-booking',
  templateUrl: './tickets-booking.component.html',
  styleUrls: ['./tickets-booking.component.scss'],
})
export class TicketsBookingComponent implements OnInit {
  public rows: Array<String>;
  public seats: Array<Number>;
  public seatsAvailable: Array<any>;
  private reservedSeats: Array<String>;
  public total: number=0;

  constructor(
    private menuCtrl: MenuController,
    private location: Location,
    private restaurantService: RestaurantService,
    private router: Router
  ) {
    this.menuCtrl.enable(false);
    this.restaurantService
      .getBusinessByName('AAPI Fall 2021')
      .valueChanges()
      .subscribe((res) => {
        console.log(res);
        console.log(res[0].seatsAvailable);
        // this.seatsAvailable = res[0].seatsAvailable;
      });
  }

  ngOnInit() {
    // Hardcoded for now to create seat arrangement for the first time , 'B', 'C', 'D'
    this.rows = ['K', 'J', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
    this.seats = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    // this.loadData();
    // used to refresh screen
    // this.refresh.subscribe(() => {
    //   this.loadData();
    // });
    this.seatsAvailable = [
      { seatNum: 'A1', isReserved: true },
      { seatNum: 'A2', isReserved: true },
      { seatNum: 'A3', isReserved: true },
      { seatNum: 'A4', isReserved: true },
      { seatNum: 'A5', isReserved: true },
      { seatNum: 'A6', isReserved: true },
      { seatNum: 'A7', isReserved: true },
      { seatNum: 'A8', isReserved: true },
      { seatNum: 'A9', isReserved: true },
      { seatNum: 'A10', isReserved: true },
      { seatNum: 'B1', isReserved: false },
      { seatNum: 'B2', isReserved: false },
      { seatNum: 'B3', isReserved: false },
      { seatNum: 'B4', isReserved: false },
      { seatNum: 'B5', isReserved: false },
      { seatNum: 'B6', isReserved: false },
      { seatNum: 'B7', isReserved: false },
      { seatNum: 'B8', isReserved: false },
      { seatNum: 'B9', isReserved: false },
      { seatNum: 'B10', isReserved: false },
      { seatNum: 'C1', isReserved: false },
      { seatNum: 'C2', isReserved: false },
      { seatNum: 'C3', isReserved: false },
      { seatNum: 'C4', isReserved: false },
      { seatNum: 'C5', isReserved: false },
      { seatNum: 'C6', isReserved: false },
      { seatNum: 'C7', isReserved: false },
      { seatNum: 'C8', isReserved: false },
      { seatNum: 'C9', isReserved: false },
      { seatNum: 'C10', isReserved: false },
      { seatNum: 'D1', isReserved: false },
      { seatNum: 'D2', isReserved: false },
      { seatNum: 'D3', isReserved: false },
      { seatNum: 'D4', isReserved: false },
      { seatNum: 'D5', isReserved: false },
      { seatNum: 'D6', isReserved: false },
      { seatNum: 'D7', isReserved: false },
      { seatNum: 'D8', isReserved: false },
      { seatNum: 'D9', isReserved: false },
      { seatNum: 'D10', isReserved: false },
      { seatNum: 'E1', isReserved: false },
      { seatNum: 'E2', isReserved: false },
      { seatNum: 'E3', isReserved: false },
      { seatNum: 'E4', isReserved: false },
      { seatNum: 'E5', isReserved: false },
      { seatNum: 'E6', isReserved: false },
      { seatNum: 'E7', isReserved: false },
      { seatNum: 'E8', isReserved: false },
      { seatNum: 'E9', isReserved: false },
      { seatNum: 'E10', isReserved: false },
      { seatNum: 'F1', isReserved: false },
      { seatNum: 'F2', isReserved: false },
      { seatNum: 'F3', isReserved: false },
      { seatNum: 'F4', isReserved: false },
      { seatNum: 'F5', isReserved: false },
      { seatNum: 'F6', isReserved: false },
      { seatNum: 'F7', isReserved: false },
      { seatNum: 'F8', isReserved: false },
      { seatNum: 'F9', isReserved: false },
      { seatNum: 'F10', isReserved: false },
      { seatNum: 'G1', isReserved: false },
      { seatNum: 'G2', isReserved: false },
      { seatNum: 'G3', isReserved: false },
      { seatNum: 'G4', isReserved: false },
      { seatNum: 'G5', isReserved: false },
      { seatNum: 'G6', isReserved: false },
      { seatNum: 'G7', isReserved: false },
      { seatNum: 'G8', isReserved: false },
      { seatNum: 'G9', isReserved: false },
      { seatNum: 'G10', isReserved: false },
      { seatNum: 'H1', isReserved: false },
      { seatNum: 'H2', isReserved: false },
      { seatNum: 'H3', isReserved: false },
      { seatNum: 'H4', isReserved: false },
      { seatNum: 'H5', isReserved: false },
      { seatNum: 'H6', isReserved: false },
      { seatNum: 'H7', isReserved: false },
      { seatNum: 'H8', isReserved: false },
      { seatNum: 'H9', isReserved: false },
      { seatNum: 'H10', isReserved: false },
      { seatNum: 'I1', isReserved: false },
      { seatNum: 'I2', isReserved: false },
      { seatNum: 'I3', isReserved: false },
      { seatNum: 'I4', isReserved: false },
      { seatNum: 'I5', isReserved: false },
      { seatNum: 'I6', isReserved: false },
      { seatNum: 'I7', isReserved: false },
      { seatNum: 'I8', isReserved: false },
      { seatNum: 'I9', isReserved: false },
      { seatNum: 'I10', isReserved: false },
      { seatNum: 'J1', isReserved: false },
      { seatNum: 'J2', isReserved: false },
      { seatNum: 'J3', isReserved: false },
      { seatNum: 'J4', isReserved: false },
      { seatNum: 'J5', isReserved: false },
      { seatNum: 'J6', isReserved: false },
      { seatNum: 'J7', isReserved: false },
      { seatNum: 'J8', isReserved: false },
      { seatNum: 'J9', isReserved: false },
      { seatNum: 'J10', isReserved: false },
      { seatNum: 'K1', isReserved: false },
      { seatNum: 'K2', isReserved: false },
      { seatNum: 'K3', isReserved: false },
      { seatNum: 'K4', isReserved: false },
      { seatNum: 'K5', isReserved: false },
      { seatNum: 'K6', isReserved: false },
      { seatNum: 'K7', isReserved: false },
      { seatNum: 'K8', isReserved: false },
      { seatNum: 'K9', isReserved: false },
      { seatNum: 'K10', isReserved: false },
    ];
  }

  /** Used to load seat details */
  // public loadData() {
  //   this.seatService.getAllSeats().subscribe((data: any) => {
  //     if (data && data.length !== 0) {
  //       this.seatsAvailable = data;
  //       this.getReservedSeats();
  //     } else {
  //       this.addSeatDetails();
  //     }
  //   },
  //     (err) => console.log('some error occured'));
  // }

  /**
   * Gets all the seats which are already reserved
   */
  // private getReservedSeats() {
  //   this.reservedSeats = this.seatsAvailable
  //     .filter((seat) => seat.isSelected === true)
  //     .map((seat) => seat.seatNum)
  //     .slice();
  // }

  /** used to check whether seat is reserved or not */
  public isSeatReserved(seatNum: String) {
    // if (this.reservedSeats) {
    //   return this.reservedSeats.includes(seatNum);s
    // }
    // return null;

    return this.seatsAvailable.find((seat) => seat.seatNum === seatNum)
      .isReserved;
  }

  /**
   * Add seats details
   */
  // public addSeatDetails() {
  //   const seatsNumber = [];

  //   this.rows.forEach((eachRow) => {
  //     this.seats.forEach((eachSeat) => {
  //       seatsNumber.push(new Seats(eachRow.concat(eachSeat.toString()), false));
  //     })
  //   })
  //   this.seatService.addSeatsDetail(seatsNumber).subscribe((data: Array<Seats>) => {
  //     this.seatsAvailable = data;
  //   });
  // }

  /** Used to check seat staus(reserved, available) */
  public checkSeatStatus(seatNum: String) {
    if (this.seatsAvailable) {
      for (let eachSeat of this.seatsAvailable) {
        if (eachSeat.seatNum === seatNum) {
          return eachSeat.isSelected;
        }
      }
    }
    return false;
  }

  /**
   * used to select seat whenever user clicks on seat
   */
  public seatSelect(seatNo: String) {
    if (!this.isSeatReserved(seatNo)) this.toggleSeatSelect(seatNo);
  }

  /** Used to togle seat selection on click */
  public toggleSeatSelect(seatNo: String) {
    if (seatNo) {
      let toggleSeat = this.seatsAvailable.filter(
        (value) => value.seatNum === seatNo
      );
      toggleSeat[0].isSelected = !toggleSeat[0].isSelected;
      this.calculateTotal();
    }
  }
  private calculateTotal() {
    this.total = 0;
    this.seatsAvailable.forEach((eachSeat) => {
      if (
        eachSeat.hasOwnProperty('isSelected') &&
        eachSeat.isSelected === true
      ) {
        this.total += 100;
      }
    });
  }
  /** Used to submit the booking details */
  // private submitBooking(selectedSeats) {
  //   console.log('submitted');
  //   this.seatService.bookSeat(selectedSeats).subscribe(() => {
  //     this.refresh.emit();
  //   });
  // }
  back() {
    this.router.navigate(['/aapifall22']);
  }
  payNow() {
    localStorage.setItem('ticketsBookingAmount', JSON.stringify(this.total));
    this.router.navigate(['payment-methods']);
  }
}

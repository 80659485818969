/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./coupon.page";
import * as i7 from "../../../providers/restaurant/restaurant.service";
var styles_CouponPage = [i0.styles];
var RenderType_CouponPage = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponPage, data: {} });
export { RenderType_CouponPage as RenderType_CouponPage };
function View_CouponPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "ion-checkbox", [["color", "dark"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleIonChange($event.target.checked) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.selectMember(_v.context.$implicit.percentage, $event.target.checked, _v.context.index) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonCheckbox_0, i2.RenderType_IonCheckbox)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.BooleanValueAccessor]), i1.ɵdid(7, 49152, null, 0, i3.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], checked: [1, "checked"], disabled: [2, "disabled"] }, null), i1.ɵdid(8, 16384, null, 0, i3.BooleanValueAccessor, [i1.ElementRef], null, null)], function (_ck, _v) { var currVal_1 = "dark"; var currVal_2 = _v.context.$implicit.isChecked; var currVal_3 = !_v.context.$implicit.isActive; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.couponname; _ck(_v, 4, 0, currVal_0); }); }
export function View_CouponPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [["color", "light"]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["coupon"])), (_l()(), i1.ɵeld(7, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(8, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "ion-button", [["color", "light"], ["shape", "round"], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], shape: [1, "shape"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "ion-icon", [["name", "close-circle"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(12, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 7, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(14, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 5, "ion-card", [], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(16, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 3, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(18, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CouponPage_1)), i1.ɵdid(20, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 4, "ion-footer", [], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(22, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(23, 0, null, 0, 2, "ion-button", [["expand", "full"], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(24, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { expand: [0, "expand"], size: [1, "size"] }, null), (_l()(), i1.ɵted(-1, 0, ["\napply "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "light"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "light"; var currVal_3 = "round"; var currVal_4 = "small"; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = "close-circle"; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.temp; _ck(_v, 20, 0, currVal_6); var currVal_7 = "full"; var currVal_8 = "small"; _ck(_v, 24, 0, currVal_7, currVal_8); }, null); }
export function View_CouponPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-coupon", [], null, null, null, View_CouponPage_0, RenderType_CouponPage)), i1.ɵdid(1, 114688, null, 0, i6.CouponPage, [i3.ModalController, i7.RestaurantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CouponPageNgFactory = i1.ɵccf("app-coupon", i6.CouponPage, View_CouponPage_Host_0, {}, {}, []);
export { CouponPageNgFactory as CouponPageNgFactory };

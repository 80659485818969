var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter } from '@angular/core';
import { NavController, MenuController, LoadingController, Platform, AlertController, } from '@ionic/angular';
import { RestaurantService, TranslateProvider } from '../../providers';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
export class HomeLocationPage {
    constructor(navCtrl, menuCtrl, loadingCtrl, translate, service, 
    // public qrScanner: QRScanner,
    platform, firestore, http, alertController, router) {
        this.navCtrl = navCtrl;
        this.menuCtrl = menuCtrl;
        this.loadingCtrl = loadingCtrl;
        this.translate = translate;
        this.service = service;
        this.platform = platform;
        this.firestore = firestore;
        this.http = http;
        this.alertController = alertController;
        this.router = router;
        this.getDisplayName = new EventEmitter();
        this.showItems = false;
        this.businessservice = 'Restaurants';
        this.operating = false;
        //     this.platform.ready().then(() => {
        //       this.qrScanner.prepare()
        //   .then((status: QRScannerStatus) => {
        //      if (status.authorized) {
        //        // camera permission was granted
        // console.log(
        //   "yes"
        // )
        //        // start scanning
        //      } else if (status.denied) {
        //        // camera permission was permanently denied
        //        // you must use QRScanner.openSettings() method to guide the user to the settings page
        //        // then they can grant the permission from there
        //      } else {
        //        // permission was denied, but not permanently. You can ask for permission again at a later time.
        //      }
        //   })
        //   .catch((e: any) => console.log('Error is', e));
        //       }).catch(err => {
        //         alert("1-> "+JSON.stringify(err));
        //       });
        //
    }
    ionViewWillEnter() {
        this.firestore
            .collection('Api-Keys')
            .doc('2KbMrcIHAs7fCSvNKkcl')
            .get()
            .subscribe((res) => {
            this.zip = res.data().gkey;
            // console.log(res.data().gkey)
            console.log(this.servicetype);
        });
        console.log(localStorage.getItem('setlocationbyzip'));
        this.menuCtrl.enable(false);
        const user = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem('setlocationbyzip')) {
            this.setlocation = localStorage.getItem('setlocationbyzip');
        }
        else {
            this.setlocation = '';
        }
    }
    emitordertype(data) {
        this.businessservice = data.detail.value;
    }
    editprofile() {
        this.navCtrl.navigateForward('edit-profile');
    }
    settings() {
        this.navCtrl.navigateForward('settings');
    }
    logout() {
        this.navCtrl.navigateRoot('login');
    }
    messages() {
        this.navCtrl.navigateForward('messages');
    }
    eventHandler(keyCode) {
        if (keyCode == 13)
            this.getZip();
    }
    getZip() {
        return __awaiter(this, void 0, void 0, function* () {
            this.http
                .get('https://maps.googleapis.com/maps/api/geocode/json?address=' +
                this.setlocation +
                '&sensor=true&key=' +
                this.zip +
                '')
                .subscribe((res) => {
                this.geolocation = res;
                let len = this.geolocation.results[0].address_components.length;
                console.log(this.geolocation.results[0].address_components[len - 2].long_name);
                this.service
                    .afterzip(this.geolocation.results[0].address_components[len - 2].long_name)
                    .valueChanges()
                    .subscribe((res) => __awaiter(this, void 0, void 0, function* () {
                    if (res.length <= 0) {
                        // this.list=[]
                        console.log('could not find any restaurants');
                        const alert = yield this.alertController.create({
                            header: 'Coming soon to your area',
                            message: 'Sorry, we are not operating in this area',
                            buttons: ['OK'],
                        });
                        yield alert.present();
                    }
                    else {
                        this.operating = true;
                        localStorage.setItem('setlocationbyzip', this.setlocation);
                        this.list = this.service
                            .afterzip(this.geolocation.results[0].address_components[len - 2]
                            .long_name)
                            .valueChanges();
                        this.service
                            .afterzip(this.geolocation.results[0].address_components[len - 2]
                            .long_name)
                            .valueChanges()
                            .subscribe((res) => [
                            (this.listofstores = res),
                            this.goToHomeResults(),
                        ]);
                    }
                }));
            });
            // localStorage.setItem('setlocationbyzip',this.setlocation)
            // localStorage.setItem('businesstype',type)
            //   this.loader = await this.loadingCtrl.create({
            //     message:'please wait...',
            //     duration: 500
            //   });
            //   this.loader.present();
            //     setTimeout(()=> this.go(),1000)
        });
    }
    goToHomeResults() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(this.listofstores);
            console.log(JSON.stringify(this.listofstores));
            localStorage.setItem('listofstores', JSON.stringify(this.listofstores));
            localStorage.setItem('setlocationbyzip', this.setlocation);
            this.loader = yield this.loadingCtrl.create({
                message: 'please wait...',
                duration: 500,
            });
            this.loader.present();
            setTimeout(() => this.go(), 1000);
        });
    }
    go() {
        this.navCtrl.navigateRoot('home-results');
    }
}

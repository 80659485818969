/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./location.page";
var styles_LocationPage = [i0.styles];
var RenderType_LocationPage = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationPage, data: {} });
export { RenderType_LocationPage as RenderType_LocationPage };
function View_LocationPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-item", [["color", "secondary"], ["tappable", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchBy(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["color", "primary"], ["name", "time"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-text", [["color", "light"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(5, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "secondary"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "primary"; var currVal_2 = "time"; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = "light"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_4); }); }
function View_LocationPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-item", [["tappable", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchBy(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["color", "primary"], ["name", "locate"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-text", [["color", "primary"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(5, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "primary"; var currVal_1 = "locate"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "primary"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_3); }); }
export function View_LocationPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Get Location"])), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(8, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 4, "ion-button", [["color", "tertiary"], ["shape", "round"], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], shape: [1, "shape"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "ion-icon", [["name", "close"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(12, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, 0, [" Close "])), (_l()(), i1.ɵeld(14, 0, null, 0, 5, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(15, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 3, "ion-searchbar", [["animated", "true"], ["placeholder", "Find location"], ["type", "text"]], null, [[null, "ionInput"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19)._handleInputEvent($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("ionInput" === en)) {
        var pd_2 = (_co.setFilteredItems($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonSearchbar_0, i2.RenderType_IonSearchbar)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(18, 49152, null, 0, i3.IonSearchbar, [i1.ChangeDetectorRef, i1.ElementRef], { animated: [0, "animated"], placeholder: [1, "placeholder"], type: [2, "type"] }, null), i1.ɵdid(19, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 5, "ion-content", [["class", "animated fadeIn"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(21, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LocationPage_1)), i1.ɵdid(23, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LocationPage_2)), i1.ɵdid(25, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "tertiary"; var currVal_2 = "round"; var currVal_3 = "small"; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "close"; _ck(_v, 12, 0, currVal_4); var currVal_5 = "primary"; _ck(_v, 15, 0, currVal_5); var currVal_6 = "true"; var currVal_7 = "Find location"; var currVal_8 = "text"; _ck(_v, 18, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.places.recent; _ck(_v, 23, 0, currVal_9); var currVal_10 = _co.results; _ck(_v, 25, 0, currVal_10); }, null); }
export function View_LocationPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location", [], null, null, null, View_LocationPage_0, RenderType_LocationPage)), i1.ɵdid(1, 114688, null, 0, i6.LocationPage, [i3.NavController, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationPageNgFactory = i1.ɵccf("app-location", i6.LocationPage, View_LocationPage_Host_0, { fromto: "fromto", search: "search" }, {}, []);
export { LocationPageNgFactory as LocationPageNgFactory };

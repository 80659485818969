var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from '@angular/common';
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonSelect, MenuController, ModalController, ToastController, } from '@ionic/angular';
import { SummaryComponent } from '../../modals/summary/summary.component';
import { CartService } from '../../providers/cart.service';
import { FairService } from '../../providers/fair.service';
export class FairFormComponent {
    constructor(menuCtrl, location, route, eventsService, router, alertController, cartService, modalCtrl, toastCtrl) {
        this.menuCtrl = menuCtrl;
        this.location = location;
        this.route = route;
        this.eventsService = eventsService;
        this.router = router;
        this.alertController = alertController;
        this.cartService = cartService;
        this.modalCtrl = modalCtrl;
        this.toastCtrl = toastCtrl;
        this.registeredList = [];
        this.allEmails = [];
        this.designationType = 'Physician';
        this.isStateDirty = false;
        this.isDesDirty = false;
        this.isPhysician = true;
        this.cme = ['Yes', 'No'];
        this.cmeCredits = 'Yes';
        this.showFooter = false;
        this.noOfAdults = 1;
        this.noOfKids = 0;
        this.typeIndex = 0;
        this.addChildFlag = false;
        this.serviceTax = 0;
        this.ticketOptions1 = [
            {
                id: 1,
                name: 'Individual - $50',
                price: '50',
                isChecked: false,
                type: 'Food Not Included',
            },
            {
                id: 2,
                name: 'Couple (2 tickets)  - $90',
                price: '90',
                isChecked: false,
                type: 'Food Not Included',
            },
            {
                id: 3,
                name: 'GAMT, GCA, TNMM Members - $43 *',
                price: '43',
                isChecked: false,
                member: true,
                type: 'Food Not Included',
            },
            {
                id: 4,
                name: 'Group(8 people)- $320 ($40 per person)',
                price: '320',
                isChecked: false,
                type: 'Food Not Included',
            },
        ];
        this.ticketOptions2 = [
            {
                id: 5,
                name: 'Individual - $65',
                price: '65',
                isChecked: false,
                type: 'Food (Boxed) Included',
            },
            {
                id: 6,
                name: 'Couple (2 tickets)  - $124',
                price: '124',
                isChecked: false,
                type: 'Food (Boxed) Included',
            },
            {
                id: 7,
                name: 'GAMT, GCA, TNMM Members - $60 *',
                price: '60',
                isChecked: false,
                member: true,
                type: 'Food (Boxed) Included',
            },
            {
                id: 8,
                name: 'Group(8 people)- $464 ($58 per person)',
                price: '464',
                isChecked: false,
                type: 'Food (Boxed) Included',
            },
        ];
        this.ticketOptions3 = [
            { id: 9, name: 'Individual - $99', price: '99', isChecked: false },
            {
                id: 10,
                name: 'Couple (2 tickets)  - $180',
                price: '180',
                isChecked: false,
                type: 'Food (Boxed) And Drinks Included',
            },
            {
                id: 11,
                name: 'GAMT, GCA, TNMM Members - $85 *',
                price: '85',
                isChecked: false,
                member: true,
                type: 'Food (Boxed) And Drinks Included',
            },
            {
                id: 12,
                name: 'Group(8 people)- $640 ($80 per person)',
                price: '640',
                isChecked: false,
                type: 'Food (Boxed) And Drinks Included',
            },
        ];
        this.kids = [
            {
                name: 'child with food ($25)',
                price: 25,
                id: 13,
                type: 'Kids',
            },
            {
                name: 'child basic ($20)',
                price: 20,
                id: 14,
                type: 'Kids',
            },
            {
                name: 'child care ($30)',
                price: 30,
                id: 15,
                type: 'Kids',
            },
        ];
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.total = 0;
        this.childBasic = 0;
        this.childCare = 0;
        console.log(localStorage.getItem('customerEmail'));
        this.id = this.route.snapshot.paramMap.get('id');
        this.isAddMode = !this.id;
        this.eventsService.getEventDetails().subscribe((res) => {
            this.eventDetails = res[0];
            this.eventName = res[0].eventName;
            this.bizEmail = res[0].businessEmail;
            this.couponCode = res[0].couponCode.toUpperCase();
        });
        this.price = parseInt(this.route.snapshot.paramMap.get('price'));
        this.menuCtrl.enable(false);
        // const email = localStorage.getItem('customerEmail');
        // if (email !== null) {
        //   this.eventForm.patchValue({
        //     email,
        //   });
        // }
        if (!this.isAddMode) {
            const user = this.eventsService.$eventList.getValue()[this.id];
            console.log(this.eventsService.$eventList.getValue()[this.id]);
            this.eventForm.patchValue(user);
        }
        this.eventsService.returnEventList().subscribe((res) => {
            this.allEmails = [];
            this.registeredList = res;
            console.log(JSON.stringify(res));
            if (res && res.length) {
                this.showFooter = true;
                this.eventForm.get('email').clearValidators();
                this.eventForm.get('phoneNumber').clearValidators();
                res.forEach((data) => {
                    this.allEmails.push(data.email);
                });
            }
            else {
                this.showFooter = false;
            }
        });
    }
    ngOnInit() { }
    addCart(item) {
        if (!item.hasOwnProperty('member')) {
            this.cartService.updateCart(item, 1);
            this.toast('added to cart');
        }
        else {
            this.showPrompt(item);
        }
    }
    increment(item) {
        this.cartService.updateCart(item, 1);
    }
    decrement(item) {
        this.cartService.updateCart(item, -1);
    }
    returnQty(item) {
        return this.cartService.qty(item);
    }
    isAdded(item) {
        return this.cartService.isAdded(item.id);
    }
    count() {
        return this.cartService.count();
    }
    openCart() {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create({
                component: SummaryComponent,
            });
            return yield modal.present();
        });
    }
    showPrompt(item) {
        this.alertController
            .create({
            header: 'Please enter coupon code to avail discount',
            backdropDismiss: false,
            inputs: [
                {
                    name: 'coupon',
                    placeholder: 'Enter coupon code here',
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    handler: (data) => {
                        console.log('Canceled', data);
                        // if (data.coupon.toUpperCase() == this.couponCode) {
                        //   this.increment(item);
                        // }
                    },
                },
                {
                    text: 'Done!',
                    handler: (data) => {
                        console.log('Saved Information', data);
                        console.log(data.coupon);
                        if (data.coupon.toUpperCase() == this.couponCode) {
                            this.increment(item);
                            this.toast('discount applied and added to cart');
                        }
                        else {
                            this.toast('Invalid Code');
                        }
                    },
                },
            ],
        })
            .then((res) => {
            res.present();
        });
    }
    toast(msg) {
        this.toastCtrl
            .create({
            message: msg,
            duration: 500,
            position: 'middle',
            cssClass: 'my-custom-class',
            color: 'secondary',
        })
            .then((obj) => {
            obj.present();
        });
    }
    navigateToPayment() {
        return __awaiter(this, void 0, void 0, function* () {
            let childText;
            if (this.childBasic === 20) {
                childText = 'child basic ($20)';
            }
            else {
                childText = 'child with food ($25)';
            }
            const totalAmount = this.total + this.serviceTax;
            this.eventData = {
                name: this.eventForm.value.name,
                email: this.eventForm.value.email,
                number: this.eventForm.value.phoneNumber,
                noOfKids: this.noOfKids,
                total: totalAmount,
                ticket: this.ticket,
                childCare: this.childCare,
                childBasic: this.childBasic,
                type: this.optionsPre,
                childFlag: this.addChildFlag,
                childText,
            };
            console.log(JSON.stringify(this.eventData));
            this.allEmails.push(this.bizEmail);
            const newYearData = {
                data: this.eventData,
                allEmails: this.allEmails,
            };
            localStorage.setItem('newYearAmount', JSON.stringify(totalAmount));
            localStorage.setItem('newYearData', JSON.stringify(newYearData));
            this.router.navigate([`payment/${totalAmount}`, { isNewYear: true }]);
        });
    }
    back() {
        this.location.back();
    }
    calculateTotal(x = this.adultsTotal) {
        this.adultsTotal = x;
        this.total = 0;
        this.serviceTax = 0;
        this.total = parseInt(x) + this.childBasic + this.childCare;
        this.serviceTax = (5 / 100) * this.total;
        this.serviceTax = Math.round(this.serviceTax * 100) / 100;
    }
}

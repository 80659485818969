/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./uploader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../upload-task/upload-task.component.ngfactory";
import * as i5 from "../upload-task/upload-task.component";
import * as i6 from "@angular/fire/storage";
import * as i7 from "../providers/restaurant/restaurant.service";
import * as i8 from "@angular/fire/firestore";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../dropzone.directive";
import * as i11 from "@angular/common";
import * as i12 from "./uploader.component";
var styles_UploaderComponent = [i0.styles];
var RenderType_UploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploaderComponent, data: {} });
export { RenderType_UploaderComponent as RenderType_UploaderComponent };
function View_UploaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-upload-task", [], null, null, null, i4.View_UploadTaskComponent_0, i4.RenderType_UploadTaskComponent)), i1.ɵdid(3, 114688, null, 0, i5.UploadTaskComponent, [i6.AngularFireStorage, i7.RestaurantService, i8.AngularFirestore], { file: [0, "file"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_UploaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(5, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "arrow-round-back"], ["size", "large"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(7, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(9, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(10, 0, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 15, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(13, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 11, "div", [["class", "dropzone"], ["dropzone", ""]], [[2, "hovering", null]], [[null, "hovered"], [null, "dropped"], [null, "drop"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onDragOver($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15).onDragLeave($event) !== false);
        ad = (pd_2 && ad);
    } if (("hovered" === en)) {
        var pd_3 = (_co.toggleHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("dropped" === en)) {
        var pd_4 = (_co.onDrop($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i10.DropzoneDirective, [], null, { dropped: "dropped", hovered: "hovered" }), (_l()(), i1.ɵeld(16, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" ", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(22, 0, null, null, 3, "div", [["class", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "ion-label", [["class", "file-label"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(24, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(25, 0, null, 0, 0, "input", [["class", "file-input"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onDrop($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UploaderComponent_1)), i1.ɵdid(27, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "light"; var currVal_2 = "arrow-round-back"; var currVal_3 = "large"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_8 = _co.files; _ck(_v, 27, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("app.uploader.upload")); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.isHovering; _ck(_v, 14, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("app.uploader.image")); _ck(_v, 17, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("app.uploader.file")); _ck(_v, 20, 0, currVal_7); }); }
export function View_UploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-uploader", [], null, null, null, View_UploaderComponent_0, RenderType_UploaderComponent)), i1.ɵdid(1, 114688, null, 0, i12.UploaderComponent, [i11.Location, i3.MenuController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploaderComponentNgFactory = i1.ɵccf("app-uploader", i12.UploaderComponent, View_UploaderComponent_Host_0, {}, {}, []);
export { UploaderComponentNgFactory as UploaderComponentNgFactory };

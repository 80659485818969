import messages from './mock-messages';
import * as i0 from "@angular/core";
export class MessageService {
    constructor() {
        this.messageCounter = 0;
        this.messages = messages;
    }
    findById(id) {
        return Promise.resolve(this.messages[id - 1]);
    }
    getMessages() {
        return this.messages;
    }
    message(message) {
        this.messageCounter = this.messageCounter + 1;
        this.messages.push({ id: this.messageCounter, message: message });
        return Promise.resolve();
    }
    getItem(id) {
        for (var i = 0; i < this.messages.length; i++) {
            if (this.messages[i].id === parseInt(id)) {
                return this.messages[i];
            }
        }
        return null;
    }
    delMessage(message) {
        this.messages.splice(this.messages.indexOf(message), 1);
    }
}
MessageService.ngInjectableDef = i0.defineInjectable({ factory: function MessageService_Factory() { return new MessageService(); }, token: MessageService, providedIn: "root" });

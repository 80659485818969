import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartService } from 'src/app/providers';

@Injectable({
  providedIn: 'root',
})
export class FairService {
  $eventList = new BehaviorSubject(null);
  list: Array<any> = [];
  constructor(
    private firestore: AngularFirestore,
    private router: Router,
    private cartService: CartService
  ) {
    if (localStorage.getItem('fairRegisteredList') !== null) {
      this.$eventList.next(
        JSON.parse(localStorage.getItem('fairRegisteredList'))
      );
      this.list = JSON.parse(localStorage.getItem('fairRegisteredList'));
    }
  }

  addUser(name: string, email: string, phoneNumber: string) {
    this.list.push({
      name,
      email,
      phoneNumber,
    });
    localStorage.setItem('fairRegisteredList', JSON.stringify(this.list));
    this.$eventList.next(this.list);
  }

  deleteAndAddUser(
    name: string,
    email: string,
    phoneNumber: string,
    id: any
  ): void {
    this.list.splice(id, 1);
    this.list.push({
      name,
      email,
      phoneNumber,
    });
    localStorage.setItem('fairRegisteredList', JSON.stringify(this.list));
    this.$eventList.next(this.list);
  }
  returnEventList(): Observable<any> {
    return this.$eventList;
  }
  clean(): void {
    this.list = [];
    this.$eventList.next(null);
    localStorage.removeItem('fairRegisteredList');
  }
  removeUser(index): void {
    this.list.splice(index, 1);
    localStorage.setItem('fairRegisteredList', JSON.stringify(this.list));
    this.$eventList.next(this.list);
  }

  getEventDetails(): Observable<any> {
    return this.firestore
      .collection(`Business`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref.where('BusinessName', '==', 'New year party 2022');
      })
      .valueChanges();
  }

  getEventDetailsAAPI(): Observable<any> {
    return this.firestore
      .collection(`Business`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref.where('BusinessName', '==', 'AAPI Fall 2021');
      })
      .valueChanges();
  }
  getRegisteredList(): Observable<any> {
    return this.firestore
      .collection(`FairOrders`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      })
      .valueChanges();
  }

  postOrders(event, id) {
    const email = this.cartService.returnCustomerEmail();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.firestore
      .doc(`FairOrders/${id}`)
      .set({
        id,
        email,
        event,
        timestamp,
      })
      .then(() => this.goconfrm(id));
  }
  goconfrm(id): void {
    this.clean();
    this.router.navigate(['fair/fair-confirmation/' + id + '']);
  }

  getOrderDetails(id: string) {
    return this.firestore
      .collection(`FairOrders`, (ref) => {
        return ref.where('id', '==', id);
      })
      .valueChanges();
  }
  postDonations(email: string, name: string, amount) {
    const id = Math.floor(Math.random() * 1000);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    return this.firestore.doc(`Donations/${id}`).set({
      email,
      name,
      timestamp,
      amount,
    });
  }
  postSponsor(email: string, name: string, amount) {
    const id = Math.floor(Math.random() * 1000);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    return this.firestore.doc(`SponsorShip/${id}`).set({
      email,
      name,
      timestamp,
      amount,
    });
  }

  newYearEntry(data: any) {
    const id = Math.floor(Math.random() * 1000);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    return this.firestore.doc(`NewYearParty/${id}`).set({
      id,
      timestamp,
      data,
    });
  }
}

import { ActivatedRoute, } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateProvider } from '../providers';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "../providers/translate/translate.service";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
export class SsfGuard {
    constructor(service, navCtrl, location, route) {
        this.service = service;
        this.navCtrl = navCtrl;
        this.location = location;
        this.route = route;
    }
    canActivate(route, state) {
        console.log('code', route);
        if (this.service.isLoggedIn) {
            const callBackUrl = route.params.callBackUrl;
            if (null !== callBackUrl &&
                (callBackUrl === 'aapiBooking' || 'aapifall21')) {
                this.navCtrl.navigateRoot(callBackUrl);
            }
            else {
                this.navCtrl.navigateRoot('/home-results');
            }
        }
        return true;
    }
}
SsfGuard.ngInjectableDef = i0.defineInjectable({ factory: function SsfGuard_Factory() { return new SsfGuard(i0.inject(i1.TranslateProvider), i0.inject(i2.NavController), i0.inject(i3.Location), i0.inject(i4.ActivatedRoute)); }, token: SsfGuard, providedIn: "root" });

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FairRoutingModule } from './fair-routing.module';
import { FairComponent } from './pages/fair/fair.component';
import { FairFormComponent } from './pages/fair-form/fair-form.component';
import { FairConfirmationComponent } from './pages/fair-confirmation/fair-confirmation.component';
import { environment } from 'src/environments/environment';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
import { DonateComponent } from './pages/donate/donate.component';
import { PaymentFairComponent } from './pages/payment-fair/payment-fair.component';
import { FairReportComponent } from './pages/fair-report/fair-report.component';
import { TicketConfirmationComponent } from './pages/ticket-confirmation/ticket-confirmation.component';
import { SummaryComponent } from './modals/summary/summary.component';

@NgModule({
  declarations: [
    FairComponent,
    FairFormComponent,
    FairConfirmationComponent,
    DonateComponent,
    PaymentFairComponent,
    FairReportComponent,
    TicketConfirmationComponent,
    SummaryComponent,
  ],
  imports: [
    CommonModule,
    FairRoutingModule,
    IonicModule.forRoot(environment.config),
    ReactiveFormsModule,
    FormsModule,
    NgxPrintModule,
  ],
  entryComponents: [SummaryComponent],
})
export class FairModule {}

import { Component, OnInit } from '@angular/core';
import { OrdersService } from 'src/app/providers';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import * as Papa from 'papaparse';
import { AngularFirestore } from '@angular/fire/firestore';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-csvupload',
  templateUrl: './csvupload.component.html',
  styleUrls: ['./csvupload.component.scss'],
})
export class CsvuploadComponent implements OnInit {
  message = 'Uploading';
  showMessage: boolean = false;
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  isHovering: boolean;
  isUploading: boolean = false;
  isUploaded: boolean;
  csv_rec: any[] = [];
  document;

  constructor(
    private orderservice: OrdersService,
    private firestore: AngularFirestore,
    public menuCtrl: MenuController,

    private storage: AngularFireStorage
  ) {
    this.isUploading = false;
    this.isUploaded = false;
  }

  ngOnInit() {
    this.menuCtrl.enable(false);
  }
  count = 0;

  startUpload(event: FileList) {
    const file = event.item(0);

    const path = 'csvup/test';
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        console.log('done');
      })
    );
    // if(file.type.split('/')[1] !== 'csv') {
    //   console.log(file.type.split('/')[1])
    // console.error('Unsupported file type!!');
    // }
    this.isUploading = true;
    this.isUploaded = false;
    // this.process(file, 'data');
  }

  process(file, collection) {
    Papa.parse(file, {
      complete: (res) => {
        this.csv_rec = res;
        this.firethis(this.csv_rec['data'], collection);
      },
      header: true,
    });
  }
  firethis(json, collection) {
    console.log(json);
    var jsonfile = JSON.stringify(json);

    const path = 'csvup/test';
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, jsonfile);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        console.log('done');
      })
    );

    // this.count=0
    //         json.forEach(element => {
    //           console.log(element)
    //           const Id = this.firestore.createId();

    //          this.firestore.collection(collection).doc(Id).set(element)
    //          .then(()=>{
    //            this.count++
    //            this.document=`document ${Id} successfully written`
    //           console.log(`document ${Id} successfully written`)})
    //           .catch((err)=>{
    //          this.document=`error writing document ${err}`
    //          console.log(`error writing document ${err}`)
    //           })

    //         })
  }
  toggleHover(event: boolean) {
    this.isHovering = event;
  }
}

import { Component, OnInit } from '@angular/core';
import { NavController, ToastController, Platform } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { SwUpdate } from '@angular/service-worker';
import { Location } from '@angular/common';
import { TranslateProvider } from 'src/app/providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  lang: any;
  enableNotifications: any;
  paymentMethod: any;
  currency: any;
  enablePromo: any;
  enableHistory: any;
  update=true;
  checkupdate=false;

  languages: any =[{

language:'hindi',
value:'hi'

  },{
  language:'english',
  value:'en'
}
  
] 
   paymentMethods: any = ['Paypal', 'Credit Card'];
  currencies: any = ['USD', 'BRL', 'EUR'];

  constructor(public navCtrl: NavController,   
     public  afAuth:AngularFireAuth,
     private swUpdate: SwUpdate,
     public toastCtrl: ToastController,
     private location:Location,
     private platform:Platform,
     private translate: TranslateProvider,
     private translateService: TranslateService
    //  private gplus:GooglePlus,

     



     ) { }


     useLanguage(language) {
      console.log(language.detail.value)
      this.translateService.use(language.detail.value)

      // this.translateService.setDefaultLang(language.detail.value);
  // this.translate.setTranslations('de')
  

  
    }
  ngOnInit() {
  }

  ionViewWillEnter(){
     /*
    this.swUpdate.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      console.log('New update available');
    });*/

    

     this.swUpdate.available.subscribe(() => {

          if(confirm("New version available. Load New Version?")) {

            this.updateToLatest();
            this.toast1('loaded')

          }
      });


 /*
      this.swUpdate.available.subscribe(event => {
        console.log('New update available');
        this.updateToLatest();
      });*/
  }

  back(){
    this.location.back()
  }
  checkForUpdate() {
    this.update=false
    this.checkupdate=true;


    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(() => {
        this.checkupdate=false

          console.log('Checking for updates...');
      }).catch((err) => {
          console.error('Error when checking for update', err);
      });
    }
    else{
    this.toast1('App is upto date')
    }
    
  }
   async toast1(msg){
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      position: 'top',
 
  });
 
  toast.present();
  this.checkupdate=false;
  }

  updateToLatest(): void {
    console.log('Updating to latest version.');
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }


  editProfile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  async logout() {
    await this.afAuth.auth.signOut();

    
    // if(this.platform.is('cordova')){
    //   this.gplus.logout()
    // }
    localStorage.removeItem('user');

    console.log("logged out"+JSON.stringify(localStorage.getItem('user')))
    this.navCtrl.navigateRoot('home-results');
  }

}

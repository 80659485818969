<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="doneStuff">
    <h2>sponsorship</h2>
    <ion-text>
      The gala is widely attended by physicians and healthcare leaders of Indian
      origin. Your tax deductible sponsorship is a great way to gain visibility
      for your company, reward your staff, or have a wonderful time with friends
      and family</ion-text
    >
    <ion-card>
      <img
        class="image"
        src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/aapi%2FsporsorLanding.JPG?alt=media&token=cd0ce958-e16c-4d14-b48b-1fb7ca42affb"
        alt="ion"
      />
    </ion-card>
  </div>

  <form [formGroup]="eventForm" class="m-l-10">
    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Full Name *
      </ion-label>
      <ion-input formControlName="name" class="google-input" type="text">
      </ion-input>
      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('name').touched &&
          eventForm.get('name').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Email *
      </ion-label>
      <ion-input formControlName="email" class="google-input" type="text">
      </ion-input>
    </ion-item>

    <p
      ion-text
      class="text08"
      *ngIf="
        eventForm.get('email').touched && eventForm.get('email').errors?.email
      "
    >
      <ion-text color="primary">
        *This field is required in correct format for notifications
      </ion-text>
    </p>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Phone Number *
      </ion-label>
      <ion-input formControlName="phoneNumber" class="google-input" type="text">
      </ion-input>

      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('phoneNumber').touched &&
          eventForm.get('phoneNumber').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>
  </form>

  <div>
    <!-- <ion-label padding class="available2"> Sponsor </ion-label>
    <br /> -->

    <div>
      <h3 class="m-l-10">Choose an amount to Sponsor</h3>
      <ion-select
        placeholder="Select One"
        [(ngModel)]="sponsorShip[0]"
        (ionChange)="emitDonation($event)"
      >
        <ion-select-option [value]="item" *ngFor="let item of sponsorShip"
          >{{ item.name }}-${{ item.price }}</ion-select-option
        >
      </ion-select>
    </div>
    <br />
  </div>

  <div class="doneStuff">
    Dear Fall Gala Sponsor,<br />
    As the humanity is going through the most challenging period of our time,
    resilience and compassion of business owners and leaders such as you has
    been the beacon of hope for our communities. On behalf of the AAPI Tennessee
    Chapter, we would like to invite you to a funfilled Bollywood music night
    and support the charitable cause of Free For Life International Charity
    which is making great strides in ending human slavery and fight human
    trafficking. <br />
    Free For Life International(FFLI) is a nonprofit (501 C) organization
    located in Tennessee. Its mission is the identification, assistance and
    restoration of individuals affected by human trafficking and exploitation.
    FFLI'S cornerstone program is Border and Transit Monitoring in India.
    Through this highly ethical form of interception, transit monitor teams
    identify red flags of human trafficking and intervene for those who are most
    at-risk. To date, nearly 2000 women, girls and boys have been rescued in
    this way. Currently, the program is supported in two locations but increased
    funding would protect many more in India. COVID 19 is leaving many
    throughout India at greater risk for exploitation and abuse. Please
    contribute to Free For Life by joining us and sponsoring this gala. <br />
    The event is widely attended by physicians of Indian origin, health care
    leaders and other business leaders such as you. Your tax deductible
    sponsorship is a great way to reward your staff, families and friends with a
    fun filled gala, promote your business, as well as contribute to a
    meaningful charity. <br />
    We appreciate your consideration. <br />
    AAPI TN Chapter
  </div>
  <ion-card>
    <img
      class="image"
      src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/aapi%2Fsponsor-plans.png?alt=media&token=f296fa8d-963e-4b6e-bd88-efac3f71455b"
      alt="ion"
    />
  </ion-card>
</ion-content>
<ion-footer>
  <ion-button
    size="large"
    expand="full"
    [disabled]="!eventForm.valid"
    (click)="navigateToPayment()"
    color="success"
    >Continue Donating $ {{ selectedAmount }}</ion-button
  >
</ion-footer>

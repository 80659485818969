import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
})
export class SponsorComponent implements OnInit {
  eventForm: FormGroup;
  selectedAmount = 1000;
  customAmount;
  enableWhenSelected = false;
  isBooth = false;
  boothName = '';
  sponsorShip = [
    { name: 'Bronze', isChecked: true, price: 1000 },
    { name: 'Table', isChecked: false, price: 1250 },
    { name: 'Sliver', isChecked: false, price: 2500 },
    { name: 'Exhibit booth 1', isChecked: false, price: 2500, isBooth: true },
    { name: 'Exhibit booth 2', isChecked: false, price: 4000, isBooth: true },
    { name: 'Gold', isChecked: false, price: 5000 },
    { name: 'Platinum', isChecked: false, price: 10000 },
    { name: 'Diamond', isChecked: false, price: 15000 },
    { name: 'Grand', isChecked: false, price: 25000 },
    { name: 'Grand', isChecked: false, price: 25000 },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router
  ) {
    this.eventForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
    });
    this.selectedAmount = 1000;
    const email = localStorage.getItem('customerEmail');
    if (email !== null) {
      this.eventForm.patchValue({
        email,
      });
    }
  }

  ngOnInit() {}

  customdollarfn() {
    this.enableWhenSelected = true;
    console.log(this.customAmount);
    this.selectedAmount = this.customAmount;
  }
  emitDonation(amount) {
    this.isBooth = false;
    console.log(amount);
    if (amount.detail.value.isBooth) {
      this.isBooth = true;
      this.boothName = amount.detail.value.name;
    }
    this.selectedAmount = amount.detail.value.price;
  }
  navigateToPayment() {
    let data;
    if (this.isBooth === true) {
      data = { isBooth: true, boothName: this.boothName };
    } else {
      data = { isSponsor: true };
    }
    localStorage.setItem('guestNameAapi', this.eventForm.value.name);
    localStorage.setItem('guestEmailAapi', this.eventForm.value.email);
    this.router.navigate([`payment/${this.selectedAmount}`, data]);
  }
  back() {
    this.location.back();
  }
}

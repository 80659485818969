<ion-content>
  <button printTitle="MyTitle" printSectionId="ex" ngxPrint class="button">
    Print Report
  </button>
  <a style="cursor: pointer" (click)="exportexcel()"> Download excel file </a>
  <br />
  <br /><br />

  <div id="ex">
    <table id="excel-table">
      <tr>
        <th>Placed On</th>
        <th>Registeration No</th>
        <th>Guest Name</th>
        <th>Email</th>
        <th>Number</th>
        <th>No of Guests</th>
      </tr>
      <tr *ngFor="let song of list; let i = index">
        <td style="padding: 5px 10px 5px 5px">
          {{ song.timestamp.toDate() | date: 'MM/dd h:mm a' }}
        </td>
        <td style="padding: 5px 10px 5px 5px">{{ song.id }}</td>
        <td style="padding: 5px 10px 5px 5px">
          <ion-label>{{ song.event.name }}</ion-label>
          <div *ngIf="!song.event.name">
            <div *ngFor="let item of song.event">
              <ion-label>{{ item.name }}</ion-label>
            </div>
          </div>
        </td>
        <td style="padding: 5px 10px 5px 5px">
          <ion-label>{{ song.event.email }}</ion-label>
          <div *ngIf="!song.event.name">
            <ion-label>{{ song.email }}</ion-label>
          </div>
        </td>
        <td style="padding: 5px 10px 5px 5px">
          <ion-label>{{ song.event.number }}</ion-label>
          <div *ngIf="!song.event.name">
            <ion-label>{{ song.event[0].phoneNumber }}</ion-label>
          </div>
        </td>
        <td style="padding: 5px 10px 5px 5px">
          {{ song.event.noOfGuests }}
        </td>
      </tr>
    </table>
  </div>
</ion-content>

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
        <ion-icon name="arrow-round-back" size="large" (click)="back()" color="light"></ion-icon>
      </ion-buttons>
    <ion-title>{{'app.uploader.upload' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content >


<div class="dropzone" 
     dropzone
     (hovered)="toggleHover($event)"
     (dropped)="onDrop($event)"
     [class.hovering]="isHovering">


     <h3> {{'app.uploader.image' | translate }}</h3>
     <p>{{'app.uploader.file' | translate }}</p>


     <div class="file">
<ion-label class="file-label">


  <input class="file-input" type="file" (change)="onDrop($event.target.files)">
</ion-label>



     </div>
</div>


<ion-item *ngFor="let file of files">
  <app-upload-task [file]="file" ></app-upload-task>

</ion-item>

</ion-content >

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
      ></ion-icon>
    </ion-buttons>
    <ion-title>Report</ion-title>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label position="floating">Choose From Date</ion-label>
        <ion-datetime
          displayFormat="MM/DD/YYYY"
          min="2020-01-01"
          max="2024-01-01"
          [(ngModel)]="format"
          (ionChange)="from($event)"
        ></ion-datetime>
      </ion-col>

      <ion-col>
        <ion-label position="floating">Choose To Date</ion-label>
        <ion-datetime
          displayFormat="MM/DD/YYYY"
          [(ngModel)]="checkin.date"
          min="2020-01-01"
          max="2024-01-01"
          (ionChange)="to($event)"
        ></ion-datetime>
      </ion-col>

      <ion-col>
        <ion-label position="floating">Filter By Business</ion-label>
        <ion-select
          placeholder="Select One"
          (ionChange)="emitbiz($event)"
          [(ngModel)]="defaultbiz"
          class="available"
        >
          <ion-select-option value="0"> --All-- </ion-select-option>

          <ion-select-option
            [value]="item.BusinessName"
            *ngFor="let item of getactivebiz"
          >
            <ion-label class="available">{{ item.BusinessName }}</ion-label>
          </ion-select-option>
        </ion-select>
      </ion-col>
      <!-- <ion-col>
      <ion-label position="floating">Filter By Business</ion-label>
      <ion-select placeholder="Choose business"(ionChange)="emit($event)" class="available"  [(ngModel)]="defaultbiz">
  
        <ion-select-option [value]="item?.BusinessName" 
  
         *ngFor="let item of list123 | async" >{{item?.BusinessName}}</ion-select-option>
      </ion-select>
    </ion-col> -->
    </ion-row>
  </ion-grid>

  <!-- <ion-item >
  <ion-label>Filter</ion-label>
  <ion-select placeholder="Select One"(ionChange)="filter($event)" >
    <ion-select-option [value]="0" >filter by today</ion-select-option>
    <ion-select-option [value]="1" >filter by yesterday</ion-select-option>
    <ion-select-option [value]="3" >filter by last 3 days</ion-select-option>

    <ion-select-option [value]="7" >filter by week</ion-select-option>

    <ion-select-option [value]="30" >filter by month</ion-select-option>
  


  </ion-select>
</ion-item> -->
</ion-header>

<ion-content>
  <div *ngIf="contentload" class="firebasespinner">
    <ion-spinner name="circles"></ion-spinner>
  </div>

  <button printTitle="MyTitle" printSectionId="ex" ngxPrint class="button">
    Print Report
  </button>

  &nbsp;&nbsp;&nbsp;&nbsp;
  <a style="cursor: pointer" (click)="exportexcel()">
    <mat-icon> Download excel file</mat-icon>
  </a>
  <br />
  <br /><br />

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>Total orders:</ion-label><br />
        <ion-label>{{ length }}</ion-label>
      </ion-col>
      <!-- <ion-col>
    <ion-label>without processing fee:</ion-label><br>
    <ion-label>{{withoutprocessingfee/100|currency}}</ion-label>

  </ion-col> -->
      <ion-col>
        <ion-label>Total amount charged(processing fee included):</ion-label
        ><br />
        <ion-label>{{ amountcharged / 100 | currency }}</ion-label>
      </ion-col>
      <ion-col>
        <ion-label> Full refunds:</ion-label><br />
        <ion-label>{{ fullrefunds / 100 | currency }}</ion-label>
      </ion-col>
      <ion-col>
        <ion-label> partial refunds: </ion-label><br />
        <ion-label>{{ partialrefunds / 100 }}</ion-label>
      </ion-col>
      <ion-col>
        <ion-label> deliverycharges: </ion-label><br />
        <ion-label>{{ deliverycharges }}</ion-label>
      </ion-col>
      <ion-col>
        <ion-label> tips: </ion-label><br />
        <ion-label>{{ tip / 100 }}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
  <br />
  <br />
  <!-- <div *ngFor="let item of list123 | async" >
  <ion-label>
    {{item?.BusinessName}}
  
  </ion-label>
  
   </div> -->

  <!-- 
   <tr *ngFor='let song of list|async;let i=index'>

    <td><input type="checkbox" (change)="checkbox(song.id)"></td>
    <td>{{song.id}}</td>
    <td>{{song.guestname}}</td>
    <td>{{song.ordertype}}</td>
    <td>{{song.subtotaltax}}</td>


</tr> -->

  <div id="ex">
    <table id="excel-table">
      <tr>
        <th>Placed On</th>
        <th>Order Id</th>
        <th>Business Name</th>
        <th>Guest Name</th>
        <th>Order Type</th>
        <th>Subtotal</th>
        <th>Tax</th>
        <th>Tip</th>
        <th>Delivery Charges</th>
        <th>Applied Discount</th>

        <th>Total</th>
        <th>Processing Fee(2.9%+30c)</th>
        <!-- <th>Platform Fee(1%)</th> -->
        <th>Partial Refund</th>
        <th>Total Refund</th>
      </tr>
      <tr *ngFor="let song of list | async; let i = index">
        <td style="padding: 5px 10px 5px 5px">
          {{ song.timestamp.toDate() | date: 'MM/dd h:mm a' }}
        </td>
        <td style="padding: 5px 10px 5px 5px">{{ song.id }}</td>
        <td style="padding: 5px 10px 5px 5px">{{ song.businessName }}</td>
        <td style="padding: 5px 10px 5px 5px">{{ song.guestname }}</td>
        <td style="padding: 5px 10px 5px 5px">{{ song.ordertype }}</td>
        <td style="padding: 5px 10px 5px 5px">
          {{ song.squaretotal / 100 | currency }}
        </td>
        <td style="padding: 5px 10px 5px 5px">
          {{ song.subtotaltax | currency }}
        </td>
        <td style="padding: 5px 10px 5px 5px">
          {{ song.tip / 100 | currency }}
        </td>
        <td style="padding: 5px 10px 5px 5px">
          {{ song.deliverycharges | currency }}
        </td>
        <td style="padding: 5px 10px 5px 5px">
          {{ song.applieddiscount | currency }}
        </td>

        <td style="padding: 5px 10px 5px 5px">
          {{ song.everycent / 100 | currency }}
        </td>

        <td style="padding: 5px 10px 5px 5px">
          {{ (2.9 / 100) * (song.everycent / 100) + 0.3 | currency }}
        </td>
        <!-- <td style="padding: 5px 10px 5px 5px;">0</td> -->

        <td style="padding: 5px 10px 5px 5px">
          {{ song.refundamount / 100 | currency }}
        </td>
        <td style="padding: 5px 10px 5px 5px">
          <div *ngIf="song.cancelled && song.refundid">
            {{ song.taxdelivery / 100 + song.tip / 100 | currency }}
          </div>
        </td>
      </tr>
    </table>
  </div>
  <!-- <div id="print-section">
    <ion-card  class="mycolour"   *ngFor="let song of list| async;let i=index" >
      <ion-item>
        <ion-label>{{song.timestamp.toDate() | date:'MM/dd h:mm a'}}</ion-label>
        <ion-badge slot="end" color="primary" class='badge'>
          <ion-label  color='light' *ngIf='song.cancelled'>order cancelled</ion-label>
  
        </ion-badge>
  
      </ion-item>
      <ion-card-content>
        
       Guestname: {{song.guestname}} &nbsp; Servicetype: {{song.ordertype}} <br>
       Orderid: {{song.id}}

        <h2 class="fw400">
          <ion-text color="primary">{{'app.order.confirm.subtotal' | translate }} : &nbsp;{{song.squaretotal/100| currency}}</ion-text>
        </h2>
       

        
    <h2 class="fw700">
      <ion-text color="primary">{{'app.order.confirm.tax' | translate }} : &nbsp;{{song.subtotaltax| currency}}</ion-text>
    </h2>
    <h2 class="fw700" >
      <ion-text color="primary">{{'app.order.confirm.delivery' | translate }}: &nbsp;{{song.deliverycharges| currency}}</ion-text>
    </h2>
    <h2 class="fw700">
      <ion-text color="primary">{{'app.order.confirm.tip' | translate }} : &nbsp;{{song.tip/100| currency}}</ion-text>
    </h2>
    <h2 class="fw700">
      <ion-text color="primary">{{'app.order.confirm.total' | translate }} : &nbsp;{{song.taxplustotal/100+song.tip/100+song.deliverycharges| currency}}</ion-text>
    </h2>
    <h2 class="fw700" *ngIf='song.refundamount'>
      <ion-text color="primary">Partial Refund : &nbsp;{{song.refundamount/100| currency}}</ion-text>
    </h2>
    <h2 class="fw700" *ngIf='song.cancelled'>
      <ion-text color="primary">Total Refund : &nbsp;{{song.taxplustotal/100+song.tip/100+song.deliverycharges| currency}}</ion-text>
    </h2>
    
    

   


          </ion-card-content>
    </ion-card>
  </div> -->
</ion-content>

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  $eventList = new BehaviorSubject(null);
  list: Array<any> = [];
  constructor(private firestore: AngularFirestore) {
    if (localStorage.getItem('eventRegisteredList') !== null) {
      this.$eventList.next(
        JSON.parse(localStorage.getItem('eventRegisteredList'))
      );
      this.list = JSON.parse(localStorage.getItem('eventRegisteredList'));
    }
  }

  addUser(
    name: string,
    email: string,
    designation: string,
    phoneNumber: string,
    eventName: string
  ) {
    this.list.push({
      name,
      email,
      designation,
      phoneNumber,
      eventName,
    });
    localStorage.setItem('eventRegisteredList', JSON.stringify(this.list));
    this.$eventList.next(this.list);
  }

  deleteAndAddUser(
    name: string,
    email: string,
    designation: string,
    address: string,
    phoneNumber: string,
    selectedDesignation: string,
    cmeCredits: string,
    stateLicenceNumber: string,
    state: string,
    price: number,
    eventName: string,
    id: any
  ): void {
    this.list.splice(id, 1);
    this.list.push({
      name,
      email,
      price,
      designation,
      address,
      phoneNumber,
      stateLicenceNumber,
      selectedDesignation,
      state,
      eventName,
      cmeCredits,
    });
    localStorage.setItem('eventRegisteredList', JSON.stringify(this.list));
    this.$eventList.next(this.list);
  }
  returnEventList(): Observable<any> {
    return this.$eventList;
  }
  clean(): void {
    this.list = [];
    this.$eventList.next(null);
    localStorage.removeItem('eventRegisteredList');
  }
  removeUser(index): void {
    this.list.splice(index, 1);
    localStorage.setItem('eventRegisteredList', JSON.stringify(this.list));
    this.$eventList.next(this.list);
  }

  getEventDetails(): Observable<any> {
    return this.firestore
      .collection(`Business`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref.where('BusinessName', '==', 'AAPI Fall 2022');
      })
      .valueChanges();
  }
  getSponsorReport() {
    return this.firestore
      .collection(`SponsorShip`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      })
      .valueChanges();
  }
  getNewYearReport() {
    return this.firestore
      .collection(`NewYearParty`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      })
      .valueChanges();
  }
  getDonatinosReport() {
    return this.firestore
      .collection(`Donations`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      })
      .valueChanges();
  }
  getRegistrations() {
    return this.firestore
      .collection(`EventOrders`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      })
      .valueChanges();
  }

  getUsers() {
    return this.firestore
      .collection(`aapiEntry`, (ref) => {
        // Compose a query using multiple .where() methods
        return ref;
      })
      .valueChanges();
  }

  postRegistrationDetails(
    name,
    guest,
    email,
    phoneNumber,
    id,
    isMember = true,
    eventName = 'AAPIFALL22'
  ) {
    this.firestore.doc(`AAPIFALL22/${id}`).set({
      name,
      guest,
      email,
      phoneNumber,
      eventName,
      isMember,
      id,
    });
  }
}

import { Injectable } from '@angular/core';
import dishes from './mock-dishes';
import { AngularFirestore } from '@angular/fire/firestore';
import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root',
})
export class DishService {
  favoriteCounter = 0;
  favorites: Array<any> = [];
  dishes: Array<any> = dishes;
  customerEmail: string;
  coll;
  constructor(
    private firestore: AngularFirestore,
    private cartService: CartService
  ) {
    this.google = JSON.parse(localStorage.getItem('user'));
    console.log(this.google);
    if (this.google != null) {
      if (this.google.isAnonymous === true) {
        this.customerEmail = localStorage.getItem('customerEmail');
      } else {
        for (let i = 0; i < this.google.providerData.length; i++) {
          //console.log(this.google.providerData[i].email)
          if (this.google.email == null) {
            this.customerEmail = this.google.providerData[i].email;
          } else {
            this.customerEmail = this.google.email;
          }
        }
      }
    }
  }
  google;
  item;
  itemCollection;
  read_Students(name, value) {
    console.log(name);
    this.item = this.firestore
      .collection(
        '/menuItem',
        (ref) =>
          ref.where('businessName', '==', name).where('category', '==', value)
        // .limit(20)
      )
      .snapshotChanges();
    return this.item;
  }

  readnompa(name, value) {
    this.itemCollection = this.firestore.collection(`menuItem`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('businessName', '==', name)
        .where('category', '==', value);
      // .limit(20)
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  getRecommendations(name) {
    this.itemCollection = this.firestore.collection(
      `Recommendations`,
      (ref) => {
        return ref.where('businessName', '==', name);
      }
    );
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  read_CartList() {
    let email = this.cartService.returnEmail();

    console.log(email);
    this.coll = this.firestore
      .collection('/LocalBookletCart', (ref) => ref.where('email', '==', email))
      .snapshotChanges();

    return this.coll;
  }

  create_NewStudent(record) {
    return this.firestore.collection('menuItem').add(record);
  }

  update_Student(recordID, record) {
    this.firestore.doc('menuItem/' + recordID).update(record);
  }
  update_Cart(recordID, record) {
    this.firestore.doc('LocalBookletCart/' + recordID).update(record);
  }

  delete_Student(record_id) {
    this.firestore.doc('menuItem/' + record_id).delete();
  }

  findAll() {
    return this.dishes;
  }

  findById(id) {
    return Promise.resolve(this.dishes[id - 1]);
  }

  getItem(id) {
    for (let i = 0; i < this.dishes.length; i++) {
      if (this.dishes[i].id === parseInt(id)) {
        return this.dishes[i];
      }
    }
    return null;
  }

  getFavorites() {
    return Promise.resolve(this.favorites);
  }

  favorite(dish) {
    this.favoriteCounter = this.favoriteCounter + 1;
    // this.favoriteCounter += 1;
    this.favorites.push({ id: this.favoriteCounter, dish: dish });
    return Promise.resolve();
  }

  unfavorite(favorite) {
    const ind = this.favorites.indexOf(favorite);
    if (ind > -1) {
      this.favorites.splice(ind, 1);
    }
    return Promise.resolve();
  }
}

<ion-content>

  <ion-slides pager="true" dir="ltr" options="slideOpts">
    <ion-slide *ngFor="let slide of slideList" class="bg-profile">
      <div padding>
        <img [src]="slide.image" alt="foodIonic icons" margin-bottom class="slide-image animated fadeInDown slow">
        <h3 class="slide-title text-white animated fadeIn" [innerHTML]="slide.title"></h3>
        <ion-text color="light">
          <p [innerHTML]="slide.description" class="animated fadeIn"></p>
        </ion-text>

        <ion-button icon-left expand="full" shape="round" color="secondary" margin-top (click)="openHome()">
          {{'app.walkthrough.next' | translate }}
          <ion-icon name="arrow-round-forward"></ion-icon>
        </ion-button>
      </div>

      <!-- <div padding>

      </div> -->
    </ion-slide>
    <ion-slide class="bg-profile">
      <div class="w100" padding>
        <h2 class="slide-title text-white" margin-bottom>{{'app.walkthrough.ready' | translate }}</h2>

        <hr>
        <!--
        <ion-button expand="full" shape="round" color="secondary" margin-top (click)="openLoginPage()">
          Sign In / Sign Up
        </ion-button>-->
        <ion-button expand="full" shape="round" color="dark" margin-top (click)="openHome()">
          <!-- routerLink="/home" -->
          {{'app.walkthrough' | translate }}
        </ion-button>
      </div>

    </ion-slide>
  </ion-slides>



</ion-content>
<!--
<ion-footer>
  <ion-button expand="full" (click)="add_to_home()" >Install</ion-button>

</ion-footer>
-->
import { Component, OnInit } from '@angular/core';
import { RestaurantService } from 'src/app/providers';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { FormControlName } from '@angular/forms';
import * as XLSX from 'xlsx';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-report2',
  templateUrl: './report2.component.html',
  styleUrls: ['./report2.component.scss'],
})
export class Report2Component implements OnInit {
  list;
  list123;
  length;
  fromdate;
  todate;
  contentload: boolean = true;
  defaultbiz = '--ALL--';
  getactivebiz;
  bizz;
  amountcharged;
  fullrefunds;
  partialrefunds;
  withoutprocessingfee;
  applieddiscount: number;
  fileName = 'ExcelSheet.xlsx';
  deliverycharges = 0;
  public checkin = {
    date: new Date().toISOString(),
  };
  format = moment().subtract(30, 'days').format('YYYY-MM-DD');
  tip = 0;
  constructor(
    private rservice: RestaurantService,
    public menuCtrl: MenuController,

    private location: Location
  ) {}
  back() {
    this.location.back();
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  from(z) {
    const date = new Date(z.detail.value);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    this.fromdate = firebase.firestore.Timestamp.fromDate(new Date(date));
    this.filter(this.todate, this.fromdate, this.bizz);
  }
  to(z) {
    const date = new Date(z.detail.value);
    date.setHours(24);
    date.setMinutes(0);
    date.setSeconds(0);
    this.todate = firebase.firestore.Timestamp.fromDate(new Date(date));
    this.filter(this.todate, this.fromdate, this.bizz);
  }

  rateChange(x) {
    console.log(x);
  }
  ngOnInit() {
    this.menuCtrl.enable(false);

    this.defaultbiz = '--ALL--';

    this.rservice
      .getBusiness()
      .valueChanges()
      .subscribe((res) => {
        this.getactivebiz = res;
      });

    const date = new Date(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const lastThirty = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    console.log(date);
    this.fromdate = firebase.firestore.Timestamp.fromDate(new Date(lastThirty));
    this.bizz = 0;

    const dateNow = new Date();
    const toUtc = Date.UTC(
      dateNow.getUTCFullYear(),
      dateNow.getUTCMonth(),
      dateNow.getUTCDate(),
      dateNow.getUTCHours(),
      dateNow.getUTCMinutes(),
      dateNow.getUTCSeconds()
    );

    this.todate = firebase.firestore.Timestamp.fromDate(new Date(toUtc));
    this.list = this.rservice
      .getadminfilterforIB(this.todate, this.fromdate, 0)
      .valueChanges();
    this.rservice
      .getadminfilterforIB(this.todate, this.fromdate, 0)
      .valueChanges()
      .subscribe((res) => {
        this.amountcharged = 0;
        this.fullrefunds = 0;
        this.partialrefunds = 0;
        this.withoutprocessingfee = 0;
        this.contentload = false;
        this.applieddiscount = 0;
        console.log(res);

        for (let i = 0; i < res.length; i++) {
          this.withoutprocessingfee =
            this.withoutprocessingfee + res[i].everycent;
          this.amountcharged =
            this.amountcharged +
            (2.9 / 100) * res[i].everycent +
            res[i].everycent;
          if (res[i].cancelled) {
            this.fullrefunds = this.fullrefunds + res[i].everycent;
          }
          if (res[i].difference) {
            this.partialrefunds = this.partialrefunds + res[i].difference;
          }

          if (res[i].deliverycharges && res[i].deliverycharges != null) {
            this.deliverycharges =
              this.deliverycharges + res[i].deliverycharges;
          }
          if (res[i].tip) {
            this.tip = this.tip + res[i].tip;
          }
        }
        this.length = res.length;
      });
  }
  filter(to, from, biz) {
    this.list = this.rservice.getadminfilterforIB(to, from, biz).valueChanges();
    this.rservice
      .getadminfilterforIB(to, from, biz)
      .valueChanges()
      .subscribe((res) => {
        this.amountcharged = 0;
        this.fullrefunds = 0;
        this.partialrefunds = 0;
        this.withoutprocessingfee = 0;
        this.contentload = false;
        this.applieddiscount = 0;
        this.tip = 0;
        this.deliverycharges = 0;
        console.log(res);

        for (let i = 0; i < res.length; i++) {
          this.withoutprocessingfee =
            this.withoutprocessingfee + res[i].everycent;
          this.amountcharged =
            this.amountcharged +
            (2.9 / 100) * res[i].everycent +
            res[i].everycent;
          if (res[i].cancelled) {
            this.fullrefunds = this.fullrefunds + res[i].everycent;
          }
          if (res[i].difference) {
            this.partialrefunds = this.partialrefunds + res[i].difference;
          }
          if (res[i].deliverycharges) {
            this.deliverycharges =
              this.deliverycharges + res[i].deliverycharges;
          }
          if (res[i].tip) {
            this.tip = this.tip + res[i].tip;
          }
        }
        this.length = res.length;
      });
  }

  emitbiz(k) {
    console.log(k.detail.value);
    this.filter(this.todate, this.fromdate, k.detail.value);
  }

  checkbox(id) {
    console.log(id);
  }
}

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
      ></ion-icon>
    </ion-buttons>
    <ion-title>Report</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <button printTitle="MyTitle" printSectionId="ex" ngxPrint class="button">
    Print Report
  </button>

  &nbsp;&nbsp;&nbsp;&nbsp;
  <a style="cursor: pointer" (click)="exportexcel()"> Download excel file </a>
  <br />
  <br /><br />

  <!-- <div *ngFor="let item of list123 | async" >
  <ion-label>
    {{item?.BusinessName}}
  
  </ion-label>
  
   </div> -->

  <!-- 
   <tr *ngFor='let song of list|async;let i=index'>

    <td><input type="checkbox" (change)="checkbox(song.id)"></td>
    <td>{{song.id}}</td>
    <td>{{song.guestname}}</td>
    <td>{{song.ordertype}}</td>
    <td>{{song.subtotaltax}}</td>


</tr> -->

  <div id="ex">
    <table id="excel-table">
      <tr>
        <th>Placed On</th>
        <th>Guest Name</th>
        <th>Email</th>
        <th>Amount</th>
      </tr>
      <tr *ngFor="let song of report; let i = index">
        <td style="padding: 5px 10px 5px 5px">
          {{ song.timestamp.toDate() | date: 'MM/dd h:mm a' }}
        </td>
        <td style="padding: 5px 10px 5px 5px">{{ song.name }}</td>
        <td style="padding: 5px 10px 5px 5px">{{ song.email }}</td>
        <td style="padding: 5px 10px 5px 5px">{{ song.amount }}</td>
      </tr>
    </table>
  </div>
</ion-content>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { CartService } from '../../providers/cart.service';
import { FairService } from '../../providers/fair.service';
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  items = [];
  subTotal;
  serviceTax;
  totalAmount;
  summaryCoupon;
  couponCode;
  isCouponApplied = false;
  couponAmount;
  eventForm: FormGroup;
  allEmails = [];
  bizEmail;

  constructor(
    private cartService: CartService,
    private modalCtrl: ModalController,
    private router: Router,
    private eventsService: FairService,
    private toastCtrl: ToastController,
    private formBuilder: FormBuilder
  ) {
    if (localStorage.getItem('nyCart') !== null) {
      this.items = JSON.parse(localStorage.getItem('nyCart'));
      this.calculateTotal(this.items);
    }

    this.eventsService.getEventDetails().subscribe((res) => {
      this.summaryCoupon = res[0].summaryCoupon;
      this.bizEmail = res[0].businessEmail;
    });
    this.eventForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
    });
    const email = localStorage.getItem('customerEmail');
    if (email !== null) {
      this.eventForm.patchValue({
        email,
      });
    }
  }

  private calculateTotal(items) {
    this.subTotal = 0;
    this.totalAmount = 0;
    items.forEach((res) => {
      this.subTotal += parseInt(res.qty) * parseInt(res.price);
    });
    this.serviceTax = (5 / 100) * this.subTotal;
    this.totalAmount = this.subTotal + this.serviceTax;
  }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss();
  }
  remove(item) {
    this.cartService.updateCart(item, -item.qty);
  }
  navigateToPayment() {
    this.allEmails.push(this.bizEmail, this.eventForm.value.email);
    const newYearData = {
      data: this.items,
      allEmails: this.allEmails,
      subTotal: this.subTotal,
      serviceTax: this.serviceTax,
      totalAmount: this.totalAmount,
      email: this.eventForm.value.email,
      number: this.eventForm.value.phoneNumber,
      guestName: this.eventForm.value.name,
      isCouponApplied: this.isCouponApplied,
      couponCode: this.summaryCoupon.name,
      couponPercentage: this.summaryCoupon.percentage,
    };
    console.log(JSON.stringify(newYearData));
    this.modalCtrl.dismiss();
    localStorage.setItem('newYearAmount', JSON.stringify(this.totalAmount));
    localStorage.setItem('newYearData', JSON.stringify(newYearData));
    this.router.navigate([`payment/${this.totalAmount}`, { isNewYear: true }]);
  }
  useCode() {
    if (this.couponCode.toUpperCase() === this.summaryCoupon.name) {
      this.applyCoupon(this.summaryCoupon.percentage);
    } else {
      this.toast('Invalid Coupon');
    }
  }

  applyCoupon(percentage) {
    this.isCouponApplied = true;
    this.couponAmount = (percentage / 100) * this.subTotal;
    const amountExcludingCoupon = this.subTotal - this.couponAmount;
    this.serviceTax = (5 / 100) * amountExcludingCoupon;
    this.totalAmount = amountExcludingCoupon + this.serviceTax;
    this.toast(`coupon ${this.summaryCoupon.name} applied`);
  }

  toast(msg) {
    this.toastCtrl
      .create({
        message: msg,
        duration: 500,
        position: 'middle',
        cssClass: 'my-custom-class',
        color: 'secondary',
      })
      .then((obj) => {
        obj.present();
      });
  }
}

import { AngularFirestore, } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/router";
export class CartService {
    constructor(firestore, db, router) {
        this.firestore = firestore;
        this.db = db;
        this.router = router;
        this.orderCounter = 0;
        this.orders = [];
        this.google = JSON.parse(localStorage.getItem('user'));
        if (this.google != null) {
            if (this.google.isAnonymous === true) {
                this.customerEmail = localStorage.getItem('customerEmail');
                localStorage.setItem('isAnonymous', 'true');
            }
            else {
                for (let i = 0; i < this.google.providerData.length; i++) {
                    localStorage.setItem('isAnonymous', 'false');
                    //console.log(this.google.providerData[i].email)
                    if (this.google.email == null) {
                        this.customerEmail = this.google.providerData[i].email;
                    }
                    else {
                        this.customerEmail = this.google.email;
                    }
                }
            }
        }
    }
    returnCustomerEmail() {
        return this.customerEmail;
    }
    refunds(squareformat, taxplustotal, squaretotal, subtotaltax, id, email, guestname, paymentid, difference) {
        let status = '';
        return this.firestore.doc(`businessownerrefunds/${id}`).set({
            squareformat,
            taxplustotal,
            squaretotal,
            subtotaltax,
            id,
            email,
            guestname,
            paymentid,
            difference,
            status,
        });
    }
    requestpaymentthroughlink(paymentthroughlinkstatus, squareformat, taxplustotal, squaretotal, subtotaltax, id, email, guestname, difference) {
        return this.firestore.doc(`requestpaymentthroughlink/${id}`).set({
            paymentthroughlinkstatus,
            squareformat,
            taxplustotal,
            squaretotal,
            subtotaltax,
            id,
            email,
            guestname,
            difference,
        });
    }
    returnEmail() {
        this.google = JSON.parse(localStorage.getItem('user'));
        if (this.google != null) {
            if (this.google.isAnonymous === true) {
                this.customerEmail = localStorage.getItem('customerEmail');
            }
            else {
                for (let i = 0; i < this.google.providerData.length; i++) {
                    //console.log(this.google.providerData[i].email)
                    if (this.google.email == null) {
                        this.customerEmail = this.google.providerData[i].email;
                    }
                    else {
                        this.customerEmail = this.google.email;
                    }
                }
            }
        }
        return this.customerEmail;
    }
    storeCustomerAddresses(email, address, distanceinmeters, latitude, longitude) {
        return this.firestore.doc(`CustomerAddresses/${email}`).set({
            email,
            address,
            distanceinmeters,
            latitude,
            longitude,
        });
    }
    getCustomerAddress(email) {
        this.itemCollection = this.firestore.collection(`CustomerAddresses`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('email', '==', email);
        });
        this.itemCollection.valueChanges();
        return this.itemCollection;
    }
    placeOrder(businessemail, handlebarsubtotal, handlebartotal, taxplustotal, taxp, email, squareformat, items, squaretotal, nonce, ordertype, guestname, tablenumber, guestmobile, checkintime, noteinreciept, guestcount, checkintimeincst, businessName, subtotaltax, paymentType, tip, id, timestamp, status, order_id, payment_id, email2, handlebartax, alternatebrands, tiptaxtotal, handlebartip, handlebartiptaxtotal, deliverycharges, address, houseno, landmark, taxdelivery, handlebardeliverycharges, businessType, everycent, handlebarformat, applieddiscount, discountpercentage, discountbool) {
        if (guestcount == null) {
            guestcount = '';
        }
        let k = {
            businessemail,
            handlebarsubtotal,
            handlebartotal,
            taxplustotal,
            taxp,
            email,
            squareformat,
            items,
            squaretotal,
            nonce,
            ordertype,
            guestname,
            tablenumber,
            guestmobile,
            checkintime,
            noteinreciept,
            guestcount,
            businessName,
            subtotaltax,
            paymentType,
            tip,
            id,
            timestamp,
            status,
            order_id,
            payment_id,
        };
        return this.firestore
            .doc(`CartList/${id}`)
            .set({
            businessemail,
            handlebarsubtotal,
            handlebartotal,
            taxplustotal,
            taxp,
            email,
            squareformat,
            items,
            squaretotal,
            nonce,
            ordertype,
            guestname,
            tablenumber,
            guestmobile,
            checkintime,
            noteinreciept,
            guestcount,
            checkintimeincst,
            businessName,
            subtotaltax,
            paymentType,
            tip,
            id,
            timestamp,
            status,
            order_id,
            payment_id,
            email2,
            handlebartax,
            alternatebrands,
            tiptaxtotal,
            handlebartip,
            handlebartiptaxtotal,
            deliverycharges,
            address,
            houseno,
            landmark,
            taxdelivery,
            handlebardeliverycharges,
            businessType,
            everycent,
            handlebarformat,
            applieddiscount,
            discountpercentage,
            discountbool,
        })
            .then(() => this.goconfrm(id));
    }
    goconfrm(id) {
        console.log('gone to page');
        this.router.navigate(['order-confirmation/' + id + '']);
    }
    getReviews(id) {
        let itemCollection = this.firestore.collection(`Reviews`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('businessName', '==', id);
        });
        itemCollection.valueChanges();
        return itemCollection;
    }
    getReviewsByOrderId(id) {
        let itemCollection = this.firestore.collection(`Reviews`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('orderId', '==', id);
        });
        itemCollection.valueChanges();
        return itemCollection;
    }
    updateReview(record, id) {
        this.firestore.doc('/Reviews/' + id).update(record);
    }
    getRefunds(id) {
        let itemCollection = this.firestore.collection(`Refunds`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('businessName', '==', id).orderBy('timestamp', 'desc');
        });
        itemCollection.valueChanges();
        return itemCollection;
    }
    refundconfirm(id) {
        let itemCollection = this.firestore.collection(`Refunds`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('firestoreid', '==', id);
        });
        itemCollection.valueChanges();
        return itemCollection;
    }
    afterLogin(businessName, name, amount, quantity, img, menuid, ins) {
        const Id = this.firestore.createId();
        var email;
        this.google = JSON.parse(localStorage.getItem('user'));
        for (let i = 0; i < this.google.providerData.length; i++) {
            //console.log(this.google.providerData[i].email)
            if (this.google.email == null) {
                email = this.google.providerData[i].email;
            }
            else {
                email = this.google.email;
            }
        }
        return this.firestore.doc(`LocalBookletCart/${Id}`).set({
            businessName,
            name,
            amount,
            quantity,
            img,
            Id,
            email,
            ins,
            menuid,
        });
    }
    afterLoginwithtax(businessName, name, amount, quantity, img, menuid, ins, tax, category, email, addOnString) {
        console.log(email);
        if (!addOnString) {
            addOnString = '';
        }
        const Id = this.firestore.createId();
        return this.firestore.doc(`LocalBookletCart/${Id}`).set({
            businessName,
            name,
            amount,
            quantity,
            img,
            menuid,
            email,
            ins,
            tax,
            Id,
            category,
            addOnString,
        });
    }
    getorders(songId) {
        this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
            return ref.where('email', '==', songId).orderBy('timestamp', 'desc');
        });
        this.itemCollection.valueChanges();
        return this.itemCollection;
    }
    send(total) {
        this.amount = total;
    }
    addtoCart(qtd) {
        this.orderCounter = this.orderCounter + 1;
        this.orders.push({ id: this.orderCounter, qtd: qtd });
        return Promise.resolve();
    }
    getOrders() {
        return Promise.resolve(this.orders);
    }
    removefromCart(order) {
        const index = this.orders.indexOf(order);
        if (index > -1) {
            this.orders.splice(index, 1);
        }
        return Promise.resolve();
    }
    editQtdOrder(order, op) {
        for (const i in this.orders) {
            if (this.orders[i].id === order.id) {
                if (op === 'minus') {
                    this.orders[i].qtd--;
                    break;
                }
                if (op === 'plus') {
                    this.orders[i].qtd++;
                    break;
                }
            }
        }
        return Promise.resolve();
    }
    cleanCart() {
        this.orders = [];
    }
}
CartService.ngInjectableDef = i0.defineInjectable({ factory: function CartService_Factory() { return new CartService(i0.inject(i1.AngularFirestore), i0.inject(i1.AngularFirestore), i0.inject(i2.Router)); }, token: CartService, providedIn: "root" });

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Platform, NavController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { take } from 'rxjs/operators';
import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute, } from '@angular/router';
import { HomeLocationPage } from './pages/home-location/home-location.page';
import { RestaurantService } from './providers';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
export class AppComponent {
    constructor(platform, splashScreen, statusBar, translate, translateService, provider, navCtrl, afAuth, router, home, swUpdate, rservice, firestore, loadingCtrl, route) {
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.translate = translate;
        this.translateService = translateService;
        this.provider = provider;
        this.navCtrl = navCtrl;
        this.afAuth = afAuth;
        this.router = router;
        this.home = home;
        this.swUpdate = swUpdate;
        this.rservice = rservice;
        this.firestore = firestore;
        this.loadingCtrl = loadingCtrl;
        this.route = route;
        this.display = true;
        this.picture = false;
        this.showdelbutton = false;
        this.showorders = false;
        // showdelivery:boolean=false
        this.showdelivery = new Subject();
        this.platformAdmin = false;
        this.appPages = [
            {
                title: 'app.pages.menu.home',
                url: '/home-results',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Orders',
                url: '/latest-orders',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.About',
                url: '/about',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Support',
                url: '/support',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.App Settings',
                url: '/appsettings',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Terms',
                url: '/terms-of-service',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Privacy',
                url: '/privacy-policy',
                direct: 'forward',
            },
            // {
            //   title: 'Gift Cards',
            //   url: '/workin-progress',
            //   direct: 'forward',
            //   // icon: 'cog'
            // },
            {
                title: 'app.pages.menu.Rewards',
                url: '/workin-progress',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Reviews',
                url: '/workin-progress',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Favourites',
                url: '/workin-progress',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Location',
                url: '/workin-progress',
                direct: 'forward',
            },
            {
                title: 'app.pages.menu.Business Owner',
                url: '/edit-profile',
                direct: 'forward',
            },
        ];
        this.initializeApp();
    }
    customerOrders() {
        this.navCtrl.navigateRoot('customer-orders');
    }
    login() {
        this.router.navigate(['/login']);
    }
    ionViewWillEnter() { }
    changelan() {
        this.translateService.use('de');
    }
    initializeApp() {
        this.platform
            .ready()
            .then(() => {
            this.statusBar.styleDefault();
            setTimeout(() => {
                this.splashScreen.hide();
            }, 1000);
            // this.splashScreen.hide();
            // Set language of the app.
            this.translateService.setDefaultLang('en');
            // this.translateService.use(environment.language);
            // this.translateService.getTranslation(environment.language).subscribe(translations => {
            //   this.translate.setTranslations(translations);
            // });
        })
            .catch(() => {
            // Set language of the app.
            this.translateService.setDefaultLang(environment.language);
            // this.translateService.use(environment.language);
            // this.translateService.getTranslation(environment.language).subscribe(translations => {
            //   this.translate.setTranslations(translations);
            // });
        });
    }
    onClick() {
        myTest();
    }
    goToEditProgile() {
        this.navCtrl.navigateForward('edit-profile');
    }
    // ionViewWillenter(){
    // }
    ngOnInit() {
        firebase.analytics();
        const user = JSON.parse(localStorage.getItem('user'));
        if (JSON.parse(localStorage.getItem('user')) != null)
            this.rservice
                .getOwners()
                .valueChanges()
                .subscribe((res) => {
                this.owners = res;
                let item = this.owners.find((p) => p.email == user.email);
                console.log(item);
                if (item == undefined) {
                    this.showorders = false;
                }
                else {
                    this.showorders = true;
                    if (item.role == 'platform owner') {
                        this.platformAdmin = true;
                    }
                    this.rservice.resname(item.business);
                    localStorage.setItem('businessName', item.business);
                }
            });
        {
            if (user) {
                this.rservice
                    .getdeliveryagentguard()
                    .valueChanges()
                    .subscribe((res) => {
                    // console.log(res)
                    this.deliveryagents = res;
                    let item = this.deliveryagents.find((p) => p.email == user.email);
                    // console.log(item)
                    if (item == undefined) {
                        this.showdelbutton = false;
                    }
                    else {
                        this.showdelbutton = true;
                    }
                });
            }
        }
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                window.location.reload();
            });
        }
        this.provider.getEmitter().subscribe((customObject) => {
            this.displayName = customObject;
            console.log(customObject);
            this.picture = true;
        });
        this.provider.getEmitter2().subscribe((customObject2) => {
            this.photoUrl = customObject2;
        });
        this.provider.getEmitter3().subscribe((customObject3) => {
            if (customObject3 == 'localbookletdev@gmail.com') {
                this.admin = true;
            }
        });
        if (JSON.parse(localStorage.getItem('user'))) {
            // if(JSON.parse(localStorage.getItem('user')).email=="localbookletdev@gmail.com"){
            // this.admin=true
            //}
        }
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            this.subscriptiond = this.rservice
                .getCartList()
                .valueChanges()
                .pipe(take(1))
                .subscribe((res) => {
                console.log(res);
                if (res.length) {
                    for (let i = 0; i < res.length; i++) {
                        this.firestore
                            .doc('LocalBookletCart/' + res[i].Id)
                            .delete()
                            .then(() => {
                            console.log('deleted ' + res[i]);
                        });
                    }
                }
            });
            this.loader = yield this.loadingCtrl
                .create({
                message: 'Logging out...',
                duration: 3000,
            })
                .then((res) => {
                res.present();
                res.onDidDismiss().then((dis) => __awaiter(this, void 0, void 0, function* () {
                    localStorage.removeItem('favourite22');
                    localStorage.removeItem('favourite');
                    yield this.afAuth.auth.signOut();
                    localStorage.removeItem('user');
                    this.router.navigate(['home-results']);
                    this.display = false;
                    this.picture = false;
                }));
            });
        });
    }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateProvider } from 'src/app/providers';
import { EventsService } from '../../providers/events.service';
import { AlertController, MenuController } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  registeredList: Array<any>;
  total = 0;
  eventDetails: any;
  enableRegistration: boolean;
  infoFlag = false;
  cme = ['Yes', 'No'];
  eventForm: FormGroup;
  isAapiMember: boolean;
  becomeMember: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private eventsService: EventsService,
    private location: Location,
    private translateService: TranslateProvider,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private menuCtrl: MenuController
  ) {
    this.menuCtrl.enable(false);
    this.eventForm = this.formBuilder.group({
      isMember: [''],
      becomeAapiMember: [''],
    });
    this.eventsService.returnEventList().subscribe((res) => {
      this.total = 0;
      this.registeredList = res;
      console.log(JSON.stringify(res));
      if (res && res.length) {
        res.forEach((data) => {
          this.total += data.price;
        });
      }
    });

    this.eventsService.getEventDetails().subscribe((res) => {
      console.log(res);
      this.eventDetails = res[0];
      this.enableRegistration = res[0].enableRegistration;
    });

    console.log(JSON.parse(localStorage.getItem('user')));
  }
  ngOnInit() {}
  moreInfo(): void {
    this.infoFlag = !this.infoFlag;
  }
  remove(index: number): void {
    this.eventsService.removeUser(index);
  }
  navigateToForm(): void {
    // this.showPrompt();
    if (this.translateService.isLoggedIn === true) {
      this.router.navigate([`form`]);
    } else {
      this.router.navigate(['login', { callBackUrl: 'form' }]);
    }
    // if (this.translateService.isLoggedIn === true) {
    //   this.router.navigate([`tickets-booking`]);
    // } else {
    //   this.router.navigate(['login', { callBackUrl: 'tickets-booking' }]);
    // }
  }

  nonAapiPayment() {
    if (this.translateService.isLoggedIn === true) {
      this.router.navigate(['form', { show: 'true' }]);
    } else {
      this.router.navigate(['login', { callBackUrl: 'form' }]);
    }
  }

  memberEvent(event: any) {
    // console.log(event.detail.value);
    this.isAapiMember = event.detail.value === 'Yes' ? true : false;
  }
  becomeAapiMember(event: any) {
    // console.log(event.detail.value);
    this.becomeMember = event.detail.value === 'Yes' ? true : false;
  }
  showPrompt() {
    this.alertController
      .create({
        header: 'Registrations Closed!',
        message:
          'Dear Colleague/Guest,<br> We are sorry for unable to register you. We are fully registered for the event. We will miss you at the event.  You may still donate for the Free for Life International Nashville based organization fighting Human trafficking.<br><br>Regards,<br>AAPI- TN',
        backdropDismiss: false,
        cssClass: 'secondary',

        buttons: [
          {
            text: 'Cancel',
            handler: (data: any) => {
              console.log('Canceled', data);
            },
          },
          {
            text: 'Done!',
            handler: (data: any) => {
              console.log('Saved Information', data);
            },
          },
        ],
      })
      .then((res: any) => {
        res.present();
      });
  }
  navigateToPayment(): void {
    this.translateService.isLoggedIn === true
      ? this.router.navigate([`payment-methods`])
      : this.router.navigate(['login', { callBackUrl: 'aapifall21' }]);
  }
  back(): void {
    this.location.back();
  }

  bookATable(): void {
    if (this.translateService.isLoggedIn === true) {
      this.router.navigate(['table-booking']);
    } else {
      this.router.navigate(['login', { callBackUrl: 'table-booking' }]);
    }
  }
  sponsor(): void {
    if (this.translateService.isLoggedIn === true) {
      this.router.navigate(['donate']);
    } else {
      this.router.navigate(['login', { callBackUrl: 'donate' }]);
    }
  }
}

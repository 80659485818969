<ion-header>
  <ion-toolbar color="success">
    <h3 style="color: white; text-align: center">
      Thank you for your order in SwypeLocal
    </h3>

    <ion-buttons slot="end"> </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <img class="image" src="assets/img/order-successful.png" />
    <h1 style="text-align: center">
      Please check your Email inbox for your Tickets.
    </h1>
  </ion-card>
</ion-content>

<ion-footer lines="none">
  <div style="text-align: center">
    <ion-button (click)="home()" size="large" color="secondary"
      >Go To Home</ion-button
    >
  </div>
</ion-footer>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./walkthrough.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./walkthrough.page";
import * as i7 from "@angular/router";
var styles_WalkthroughPage = [i0.styles];
var RenderType_WalkthroughPage = i1.ɵcrt({ encapsulation: 0, styles: styles_WalkthroughPage, data: {} });
export { RenderType_WalkthroughPage as RenderType_WalkthroughPage };
function View_WalkthroughPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-slide", [["class", "bg-profile"]], null, null, null, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(1, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "div", [["padding", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "foodIonic icons"], ["class", "slide-image animated fadeInDown slow"], ["margin-bottom", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h3", [["class", "slide-title text-white animated fadeIn"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ion-text", [["color", "light"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(6, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "p", [["class", "animated fadeIn"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "ion-button", [["color", "secondary"], ["expand", "full"], ["icon-left", ""], ["margin-top", ""], ["shape", "round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(9, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], expand: [1, "expand"], shape: [2, "shape"] }, null), (_l()(), i1.ɵted(10, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "ion-icon", [["name", "arrow-round-forward"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(13, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_2 = "light"; _ck(_v, 6, 0, currVal_2); var currVal_4 = "secondary"; var currVal_5 = "full"; var currVal_6 = "round"; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = "arrow-round-forward"; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); var currVal_3 = _v.context.$implicit.description; _ck(_v, 7, 0, currVal_3); var currVal_7 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("app.walkthrough.next")); _ck(_v, 10, 0, currVal_7); }); }
export function View_WalkthroughPage_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { slides: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 14, "ion-slides", [["dir", "ltr"], ["options", "slideOpts"], ["pager", "true"]], null, null, null, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(4, 49152, [[1, 4]], 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef], { options: [0, "options"], pager: [1, "pager"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WalkthroughPage_1)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 10, "ion-slide", [["class", "bg-profile"]], null, null, null, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(8, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 8, "div", [["class", "w100"], ["padding", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "h2", [["class", "slide-title text-white"], ["margin-bottom", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "ion-button", [["color", "dark"], ["expand", "full"], ["margin-top", ""], ["shape", "round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], expand: [1, "expand"], shape: [2, "shape"] }, null), (_l()(), i1.ɵted(16, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slideOpts"; var currVal_1 = "true"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.slideList; _ck(_v, 6, 0, currVal_2); var currVal_4 = "dark"; var currVal_5 = "full"; var currVal_6 = "round"; _ck(_v, 15, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("app.walkthrough.ready")); _ck(_v, 11, 0, currVal_3); var currVal_7 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("app.walkthrough")); _ck(_v, 16, 0, currVal_7); }); }
export function View_WalkthroughPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-walkthrough", [], null, null, null, View_WalkthroughPage_0, RenderType_WalkthroughPage)), i1.ɵdid(1, 114688, null, 0, i6.WalkthroughPage, [i3.NavController, i3.MenuController, i7.Router, i3.Platform, i3.ToastController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WalkthroughPageNgFactory = i1.ɵccf("app-walkthrough", i6.WalkthroughPage, View_WalkthroughPage_Host_0, {}, {}, []);
export { WalkthroughPageNgFactory as WalkthroughPageNgFactory };

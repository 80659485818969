import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, IonSlides, MenuController, ToastController } from '@ionic/angular';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.page.html',
  styleUrls: ['./walkthrough.page.scss'],
})

export class WalkthroughPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  showSkip = true;
  showBtn: boolean = false;

  slideOpts = {
    effect: 'flip',
    speed: 1000
  };
  dir: String = 'ltr';
  deferredPrompt;

  slideList: Array<any> = [
    {
      // title: '<strong>Swy<span class="text-secondary">lo</span></strong>?',
      // description: 'SwypeLocal is an online Local Marketplace that makes getting great food, groceries from your favorite local restaurants, retail & grocery stores.',
      image: 'assets/img/small.png',
    },
    // {
    //   title: 'Why <strong>Swype<span class="text-secondary">Local</span> </strong>?',
    //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque maximus, dui accumsan cursus lacinia, nisl risus.',
    //   image: 'assets/img/pizza01.png',
    // },
    // {
    //   title: '<strong>Your delicious dish is coming!</strong>',
      
    //   image: 'assets/img/pasta01.png',
    // }
  ];

  constructor(
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    public router: Router,
    public Platform:Platform,
    public toastCtrl: ToastController,

  ) {
  }

  ionViewWillEnter() {
  // console.log(this.Platform.is('mobileweb'))
  // console.log(this.Platform.platforms());
  this.menuCtrl.enable(false);



   

    

    // this.func()

  }
//   async func(){

//     if( this.Platform.is('mobileweb')){

//     const toast =  await this.toastCtrl.create({
//       message: 'install this webapp on your iPhone: Tap the share icon below and then select Add to home screen',

//       position: 'bottom',
      
      
//   });

//   toast.present()

// }

//   }

  ngOnInit() {
  }

  onSlideNext() {
    this.slides.slideNext(1000, false);
  }

	onSlidePrev() {
    this.slides.slidePrev(300);
  }

  // onLastSlide() {
  // 	this.slides.slideTo(3, 300)
  // }

  
  openHome() {
    this.navCtrl.navigateForward('/home-location');
  }

}
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { OrdersService } from "src/app/providers";
import { CartService } from "src/app/providers";
enum COLORS {
  GREY = "#808080",
  YELLOW = "#FFD700  ",
  RED = "#DD2C00",
}
@Component({
  selector: "app-review",
  templateUrl: "./review.page.html",
  styleUrls: ["./review.page.scss"],
})
export class ReviewPage implements OnInit, OnChanges {
  shoprating: any;
  delrating: any;
  @Input() orderId: string;
  rating;
  star4;
  overAllRating = null;
  shoppingRating = null;
  deliveryRating = null;
  feedback = "";
  ratingValue;
  updateReview = false;
  constructor(
    public modalController: ModalController,
    public ordersService: OrdersService,
    public cartService: CartService
  ) {}
  ngOnInit() {
    this.cartService
      .getReviewsByOrderId(this.orderId)
      .valueChanges()
      .subscribe((res: Array<any>) => {
        if (res && res.length) {
          this.updateReview = true;
          this.overAllRating = res[0].overallRating;
          this.deliveryRating = res[0].deliveryRating;
          this.shoppingRating = res[0].shoppingRating;
          this.feedback = res[0].feedback;
        }
      });
  }
  ngOnChanges() {}
  closeModal() {
    this.modalController.dismiss();
  }

  postReview() {
    console.log("review");
    let customerEmail = this.cartService.customerEmail;
    let guestName = localStorage.getItem("guestname");
    let businessName = localStorage.getItem("resname");
    this.ordersService.postReview(
      this.orderId,
      this.overAllRating,
      this.shoppingRating,
      this.deliveryRating,
      this.feedback,
      customerEmail,
      businessName,
      guestName
    );
    this.closeModal();
  }
  updateRevw() {
    let record = {};
    record["overallRating"] = this.overAllRating;
    record["shoppingRating"] = this.shoppingRating;
    record["deliveryRating"] = this.deliveryRating;
    record["feedback"] = this.feedback;
    this.cartService.updateReview(record, this.orderId);
    this.closeModal();
  }

  overAllRatingGetColor(index: number) {
    if (this.overAllRatingIsAboveRating(index)) {
      return COLORS.GREY;
    }
    switch (this.overAllRating) {
      case 1:
        return COLORS.YELLOW;
      case 2:
        return COLORS.YELLOW;
      case 3:
        return COLORS.YELLOW;
      case 4:
        return COLORS.YELLOW;
      case 5:
        return COLORS.YELLOW;
      default:
        return COLORS.GREY;
    }
  }

  overAllRatingIsAboveRating(index: number): boolean {
    return index > this.overAllRating;
  }

  overAllRate(index: number) {
    console.log(index);
    this.overAllRating = index;
  }

  //shopping rating

  shoppingGetColor(index: number) {
    if (this.shoppingIsAboveRating(index)) {
      return COLORS.GREY;
    }
    switch (this.shoppingRating) {
      case 1:
        return COLORS.YELLOW;
      case 2:
        return COLORS.YELLOW;
      case 3:
        return COLORS.YELLOW;
      case 4:
        return COLORS.YELLOW;
      case 5:
        return COLORS.YELLOW;
      default:
        return COLORS.GREY;
    }
  }

  shoppingIsAboveRating(index: number): boolean {
    return index > this.shoppingRating;
  }

  shoppingRate(index: number) {
    this.shoppingRating = index;
  }

  // delivery rating

  deliveryGetColor(index: number) {
    if (this.deliveryisAboveRating(index)) {
      return COLORS.GREY;
    }
    switch (this.deliveryRating) {
      case 1:
        return COLORS.YELLOW;
      case 2:
        return COLORS.YELLOW;
      case 3:
        return COLORS.YELLOW;
      case 4:
        return COLORS.YELLOW;
      case 5:
        return COLORS.YELLOW;
      default:
        return COLORS.GREY;
    }
  }

  deliveryisAboveRating(index: number): boolean {
    return index > this.deliveryRating;
  }

  deliveryRate(index: number) {
    console.log(index);
    this.deliveryRating = index;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DishService, RestaurantService } from 'src/app/providers';

@Component({
  selector: 'app-adminmenudet',
  templateUrl: './adminmenudet.page.html',
  styleUrls: ['./adminmenudet.page.scss'],
})
export class AdminmenudetPage implements OnInit {

  constructor(private rservice: RestaurantService,
    public route: ActivatedRoute,
    public modalCtrl: ModalController,
    public dishservice: DishService,
    public restaurantService:RestaurantService

  ) { }
  song

  isEdit = true;
  editamount
  editdescription
  editimg
  edititem
  editisActive
  editdisplay
  editpercentage
  editorder;
  load
  true = "true"
  @Input() value: string;
 resname:string
 firestoreId

  ngOnInit() {
    console.log(this.resname)
    console.log(this.value)

    this.rservice.getadminmenudoc(this.value).valueChanges().subscribe(res => {
      console.log(res)
      this.load = res
      // console.log(res[0].amount)

      this.editamount = res.amount
      this.edititem = res.item
      this.editisActive = res.isActive
      this.editdescription = res.description
      this.editpercentage = res.tax
      this.editdisplay = res.display 
      this.firestoreId=res.Id

      console.log(this.editisActive)
      console.log(this.editdisplay)


    });

  }
  back() {
    this.modalCtrl.dismiss();
  }

  UpdateRecord() {
    let k = "false"
   
    let record = {};
    record['amount'] = this.editamount;
    record['description'] = this.editdescription;
    record['item'] = this.edititem;
    record['isActive'] = JSON.parse(this.editisActive)
    record['display'] = JSON.parse(this.editdisplay)
    record['tax'] = Number(this.editpercentage)
    record['Id'] = this.value
    console.log(record)
    this.restaurantService.updateRecordAlgo(record,this.resname)

    this.dishservice.update_Student(this.value, record);
    this.back()

  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private firestore: AngularFirestore) {}
  orderCounter = 0;
  orders: Array<object> = [];
  csv_rec: any[] = [];
  header = false;
  itemCollection: any;
  saveOrder(order, total, orderNumber) {
    this.orderCounter = this.orderCounter + 1;
    this.orders.push({
      id: this.orderCounter,
      order: order,
      total: total,
      onumber: orderNumber,
    });
    return Promise.resolve();
  }

  postReview(
    orderId,
    overallRating,
    shoppingRating,
    deliveryRating,
    feedback,
    email,
    businessName,
    guestname
  ) {
    const Id = orderId;
    let guestEmail = email;
    let guestName = guestname;
    return this.firestore.doc(`Reviews/${Id}`).set({
      orderId,
      overallRating,
      shoppingRating,
      deliveryRating,
      feedback,
      guestEmail,
      businessName,
      guestName,
    });
  }
  sendEmail(firstName, lastName, senderEmail, receiverEmail, referalCode) {
    const isUsed = false;
    const id = this.firestore.createId();
    this.firestore.doc(`Refer-a-friend/${id}`).set({
      id,
      firstName,
      lastName,
      receiverEmail,
      senderEmail,
      referalCode,
      isUsed,
    });
  }
  checkIfReceiverEmailExistsInCartList(email) {
    this.itemCollection = this.firestore.collection(`CartList`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('email', '==', email);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }

  checkIfReceiverEmailExistsInReferAFriend(email) {
    this.itemCollection = this.firestore.collection(`Refer-a-friend`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('receiverEmail', '==', email);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  checkIfLatLongsExist(lat, lng) {
    this.itemCollection = this.firestore.collection(
      `CustomerAddresses`,
      (ref) => {
        // Compose a query using multiple .where() methods
        return ref.where('latitude', '==', lat).where('longitude', '==', lng);
      }
    );
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getSenderReferalCode(code, email) {
    this.itemCollection = this.firestore.collection(`Refer-a-friend`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('referalCode', '==', code)
        .where('receiverEmail', '==', email);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getReferredList(email) {
    this.itemCollection = this.firestore.collection(`Refer-a-friend`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref.where('senderEmail', '==', email);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
  getReceiverReferalCode(code, senderEmail) {
    this.itemCollection = this.firestore.collection(`Refer-a-friend`, (ref) => {
      // Compose a query using multiple .where() methods
      return ref
        .where('referalCode', '==', code)
        .where('isUsed', '==', false)
        .where('senderEmail', '==', senderEmail);
    });
    this.itemCollection.valueChanges();
    return this.itemCollection;
  }
}

<ion-header >
  <ion-toolbar color="primary" >
      <ion-title color="light">coupon</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="light">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
<ion-card>
  <ion-card-content>
    <ion-item *ngFor='let item of temp ; let i=index  '>
      <ion-label>{{item.couponname}}</ion-label>
      <ion-checkbox color="dark" (ionChange)="selectMember(item.percentage, $event.target.checked,i)" [checked]='item.isChecked' [disabled]='!item.isActive'></ion-checkbox>
    </ion-item>  </ion-card-content>
  <!-- <ion-label></ion-label> -->
</ion-card>

<!-- <ion-button size="small" expand="full" (click)="getcoup()" >
  get array  </ion-button>-->
</ion-content> 
<ion-footer>
  <ion-button size="small" expand="full" (click)="closeModal()" >
apply  </ion-button>
</ion-footer>

<ion-app>
  <ion-split-pane>
    <ion-menu>
      <ion-header>
        <ion-toolbar color="dark" class="user-profile">
          <ion-item margin-bottom>
            <ion-avatar slot="start" class="user-avatar" *ngIf="picture">
              <img [src]="photoUrl" />
            </ion-avatar>
            <ion-label>
              <ion-text color="tertiary">
                <h1>
                  <strong> {{ 'app.home.name' | translate }} </strong>
                </h1>
              </ion-text>
              <ion-text color="secondary" *ngIf="display">
                <h3>
                  {{ displayName }}
                </h3>
              </ion-text>
              <ion-menu-toggle class="mto" auto-hide="false">
                <!-- <a class="text08" tappable (click)="goToEditProgile()">
                  <ion-text color="tertiary" *ngIf="picture">
                    <ion-icon name="contact"></ion-icon>
                    <strong>Profile</strong>
                  </ion-text>
                </a> <ion-text color="secondary"> | </ion-text>  -->
                <a class="text08" tappable (click)="logout()" *ngIf="picture">
                  <ion-button color="tertiary">
                    <ion-icon name="log-out"></ion-icon>
                    <strong>{{ 'app.pages.menu.logout' | translate }}</strong>
                  </ion-button>
                </a>
                <a class="text08" tappable (click)="login()" *ngIf="!picture">
                  <ion-button color="tertiary">
                    <ion-icon name="log-in"></ion-icon>
                    <strong>{{ 'app.pages.menu.login' | translate }}</strong>
                  </ion-button>
                </a>
              </ion-menu-toggle>
            </ion-label>
          </ion-item>
        </ion-toolbar>
      </ion-header>

      <ion-content class="bg-profile">
        <!-- <div>
  <hot-table [settings]="settings" [data]="data" [colHeaders]="true" [rowHeaders]="true" [width]="600" [height]="300" licenseKey='non-commercial-and-evaluation'></hot-table>

  </div> -->
        <ion-list>
          <ion-list-header color="dark">
            <ion-label>{{ 'app.pages.menu.menu' | translate }}</ion-label>
          </ion-list-header>
          <!--  <ion-menu-toggle class="mto" auto-hide="false" *ngIf='admin'>
          <ion-item  color="primary"  (click)="customerOrders()">
              <ion-label >
               Customer Orders
              </ion-label>
              </ion-item>
            </ion-menu-toggle>-->
          <!-- <ion-button (click)="onClick()"></ion-button>-->

          <ion-menu-toggle auto-hide="false">
            <ion-item color="primary" routerLink="/home-results">
              <ion-icon
                slot="start"
                name="home"
                src="assets/img/home.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.home' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/latest-orders">
              <ion-icon
                name="order"
                slot="start"
                src="assets/img/order.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.Orders' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/about">
              <ion-icon
                name="about"
                slot="start"
                src="assets/img/about.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.about' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/support">
              <ion-icon
                name="support"
                slot="start"
                src="assets/img/support.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.support' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/appsettings">
              <ion-icon
                name="settings"
                slot="start"
                src="assets/img/settings.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.App Settings' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/terms-of-service">
              <ion-icon
                name="terms"
                slot="start"
                src="assets/img/terms12.svg"
              ></ion-icon>
              <ion-label> Terms & Conditions </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/privacy-policy">
              <ion-icon
                name="privacy"
                slot="start"
                src="assets/img/privacy.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.Privacy' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/refer-afriend">
              <ion-icon
                name="rewards"
                slot="start"
                src="assets/img/refer-a-friend.svg"
              ></ion-icon>
              <ion-label> Refer a Friend </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/workin-progress">
              <ion-icon
                name="rewards"
                slot="start"
                src="assets/img/rewards12.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.Rewards' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/workin-progress">
              <ion-icon
                name="reviews"
                slot="start"
                src="assets/img/reviews12.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.Reviews' | translate }}
              </ion-label>
            </ion-item>
            <ion-item color="primary" routerLink="/workin-progress">
              <ion-icon
                name="favourite"
                slot="start"
                src="assets/img/favourite.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.Favourites' | translate }}
              </ion-label>
            </ion-item>

            <ion-item color="primary" routerLink="/edit-profile">
              <ion-icon
                name="people"
                slot="start"
                src="assets/img/people12.svg"
              ></ion-icon>
              <ion-label>
                {{ 'app.pages.menu.Business Owner' | translate }}
              </ion-label>
            </ion-item>
            <ion-item
              color="primary"
              (click)="customerOrders()"
              *ngIf="showorders"
            >
              <ion-icon
                name="terms"
                slot="start"
                src="assets/img/terms12.svg"
              ></ion-icon>
              <ion-label> Customer Orders </ion-label>
            </ion-item>
            <ion-item
              color="primary"
              routerLink="/deliveries"
              *ngIf="showdelbutton"
            >
              <ion-icon
                name="bicycle-outline"
                slot="start"
                src="assets/img/deliveries.svg"
              ></ion-icon>
              <ion-label> Deliveries </ion-label>
            </ion-item>
            <ion-item
              color="primary"
              routerLink="/platform-access"
              *ngIf="platformAdmin"
            >
              <ion-icon
                name="terms"
                slot="start"
                src="assets/img/terms12.svg"
              ></ion-icon>
              <ion-label> Platform Access </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet
      main
      swipeGesture="false"
      animated="false"
    ></ion-router-outlet>
  </ion-split-pane>
</ion-app>

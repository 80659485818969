<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
      ></ion-icon>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-icon name="share" size="large" (click)="share()"></ion-icon>
    </ion-buttons>
    <hr />
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="!imgload" class="firebasespinner">
    <ion-spinner name="circles"></ion-spinner>
  </div>
  <!-- <span class="card-img-price   text-white" *ngIf='description'>
   <strong>{{ (song|async)?.description }}</strong> 
  </span> -->
  <!-- <span class="card-img-price   text-white" *n>
    $ <strong>{{ (song|async)?.description }}</strong> PPU
   </span> -->
  <ion-card pager="true" *ngIf="loadbc&&image">
    <img
      [src]="defaultimg"
      class="ionimage"
      (load)="onimgload()"
      (error)="errorHandler($event)"
    />
  </ion-card>
  <ion-card pager="true" *ngIf="loadib&&IBimage">
    <img
      [src]="IBimage"
      (load)="onimgload()"
      class="ionimage"
      (error)="errorHandler($event)"
    />
  </ion-card>
  <ion-card no-margin class="spacerFab bg-white dotted" *ngIf="imgload">
    <ion-card-content>
      <ion-grid no-padding>
        <ion-row text-center>
          <ion-col>
            <span>
              <strong>
                <b>{{(song )?.item }} </b>
              </strong>
            </span>
          </ion-col>
        </ion-row>
        <ion-row margin-bottom>
          <ion-col text-center>
            <ion-icon
              name="remove-circle"
              class="text25"
              tappable
              (click)="minusQtd()"
              color="secondary"
            ></ion-icon>
          </ion-col>
          <ion-col width-10 text-center>
            <h4 ion-text color="light">
              {{'app.dishdetail.quantity' | translate }}:
            </h4>
            <h2 ion-text>{{ qtd }}</h2>
          </ion-col>
          <ion-col width-10 text-center>
            <ion-icon
              name="add-circle"
              class="text25"
              tappable
              (click)="plusQtd()"
              color="secondary"
            ></ion-icon>
          </ion-col>
        </ion-row>

        <ion-row text-center>
          <ion-col>
            <span>
              <i>"{{ (song)?.description }}"</i>
            </span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col text-center>
            <ion-button
              color="secondary"
              (click)="addcart(qtd)"
              [disabled]="!enable1"
            >
              <ion-icon slot="start" name="cart" color="light"></ion-icon>
              <ion-text color="light"
                >{{'app.dishdetail.add' | translate}} ({{ (song)?.amount * qtd |
                currency }})
              </ion-text>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
  <div *ngIf="showRecommendations && recommendations.length">
    <h5 style="margin-left: 11px; font-weight: bold">Recommended Items</h5>

    <ion-grid class="bg-white">
      <ion-row>
        <ion-col size="1">
          <span
            class="slider-nav arrow-prev"
            (click)="slidePrev(sliderTwo,slideWithNav2)"
          >
            <div
              class="prev-icon-custom custon-nav"
              [class.disabled]="sliderTwo.isBeginningSlide"
            ></div>
          </span>
        </ion-col>
        <ion-col size="10">
          <ion-slides
            pager="false"
            [options]="slideOptsTwo"
            #slideWithNav2
            (ionSlideDidChange)="SlideDidChange(sliderTwo,slideWithNav2)"
          >
            <ion-slide *ngFor="let s of recommendations">
              <ion-card
                style="height: 140px; width: 150px; border: 1px solid red"
                (click)="openModal(s.Id,s.item, s.businessName)"
              >
                <ion-card-content style="padding: 0px">
                  <ion-label class="amountSale">$ {{s.amount}}</ion-label>

                  <!-- <img
                    src="https://firebasestorage.googleapis.com/v0/b/localbooklet.appspot.com/o/{{song.businessName}}%2F{{song.item}}.jpg?alt=media&token"
                  /> -->
                  <img
                    [src]="s.img"
                    style="height: 84px"
                    (error)="errorHandler($event)"
                  />
                </ion-card-content>
                <ion-footer
                  style="font-size: 12px; color: black; font-weight: 600"
                >
                  <ion-label>{{s.item}}</ion-label>
                </ion-footer>
              </ion-card>
            </ion-slide>
          </ion-slides>
        </ion-col>
        <ion-col size="1">
          <span
            class="slider-nav arrow-next"
            (click)="slideNext(sliderTwo,slideWithNav2)"
          >
            <div
              class="next-icon-custom custon-nav"
              [class.disabled]="sliderTwo.isEndSlide"
            ></div>
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>

<ion-footer> </ion-footer>

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon name="arrow-round-back" size="large" (click)="back()" color="light"></ion-icon>
    </ion-buttons>
    <ion-title>Update Item</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <ion-item>
    <ion-input placeholder="Enter img" [(ngModel)]="item.editimg"></ion-input>
  </ion-item> -->
  <ion-card *ngIf="load">
    <ion-item>
      <ion-label class="labelbold">Amount:</ion-label>
      <ion-input placeholder="Enter amount" [(ngModel)]="editamount"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label class="labelbold">Name:</ion-label>

      <ion-input placeholder="Enter item name" [(ngModel)]="edititem"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label class="labelbold">Description:</ion-label>

      <ion-input placeholder="Enter description" [(ngModel)]="editdescription"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label class="labelbold">Tax Percentage:</ion-label>

      <ion-input placeholder="Enter Percentage" [(ngModel)]="editpercentage"></ion-input>
    </ion-item>


    <ion-item>
      <ion-label class="labelbold">Choose False to out of stock</ion-label>
      <ion-select [(ngModel)]="editisActive" [selectedText]="editisActive">
        <ion-select-option value="true">True</ion-select-option>
        <ion-select-option value="false">False</ion-select-option>
      </ion-select>

    </ion-item>

    <ion-item>
      <ion-label class="labelbold">Choose False to Remove</ion-label>
      <ion-select [(ngModel)]="editdisplay" [selectedText]="editdisplay">
        <ion-select-option value="true">True</ion-select-option>
        <ion-select-option value="false">False</ion-select-option>
      </ion-select>

    </ion-item>
  </ion-card>




  <!-- <ion-item>
          <ion-label>{{ 'app.admin.hide' | translate }}</ion-label>
          <ion-select [(ngModel)]="editdisplay">
            <ion-select-option value="true">{{ 'app.admin.true' | translate }}</ion-select-option>
            <ion-select-option value="false">{{ 'app.admin.false' | translate }}</ion-select-option>
          </ion-select>
         </ion-item> -->


  <!-- <ion-item *ngIf="order">
          <ion-input placeholder="Enter enable boolean" [(ngModel)]="editorder" ></ion-input>
         </ion-item> -->
</ion-content>
<ion-footer>
  <ion-button size="large" expand="full" (click)=UpdateRecord()>Update Record</ion-button>
</ion-footer>
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit {

  isHovering: boolean;
  private defaultColDef;

  files: File[] = [];
  columnDefs = [
    {headerName: 'Make', field: 'make'},
    {headerName: 'Model', field: 'model' },
    {headerName: 'Price', field: 'price'}
];

rowData = [
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxter', price: 72000 }
];
  // dataset: any[] = [
  //   {id: 1, name: 'Ted Right', address: 'Wall Street'},
  //   {id: 2, name: 'Frank Honest', address: 'Pennsylvania Avenue'},
  //   {id: 3, name: 'Joan Well', address: 'Broadway'},
  //   {id: 4, name: 'Gail Polite', address: 'Bourbon Street'},
  //   {id: 5, name: 'Michael Fair', address: 'Lombard Street'},
  //   {id: 6, name: 'Mia Fair', address: 'Rodeo Drive'},
  //   {id: 7, name: 'Cora Fair', address: 'Sunset Boulevard'},
  //   {id: 8, name: 'Jack Right', address: 'Michigan Avenue'},
  // ];
  constructor(private location:Location,
    public menuCtrl: MenuController,
) { }

  ngOnInit() {
    this.menuCtrl.enable(false);
    this.defaultColDef = {
      flex: 1,
      minWidth: 110,
      editable: true,
      resizable: true,
    };

  }

  onCellValueChanged(e){
    console.log(e)
  }
  toggleHover(event: boolean) {
    this.isHovering = event;
  }
  onGridReady(d){
console.log(d)
  }
  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }


  back(){
    this.location.back()
  }
}

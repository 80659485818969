/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./extras.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./extras.page";
import * as i7 from "../../../providers/restaurant/restaurant.service";
import * as i8 from "@angular/fire/firestore";
import * as i9 from "../../../providers/dish/dish.service";
import * as i10 from "../../../providers/cart/cart.service";
var styles_ExtrasPage = [i0.styles];
var RenderType_ExtrasPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ExtrasPage, data: {} });
export { RenderType_ExtrasPage as RenderType_ExtrasPage };
function View_ExtrasPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-item", [["lines", "none"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], { lines: [0, "lines"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(4, 0, ["", " \u00A0\u00A0 $", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "ion-radio", [["slot", "start"]], null, [[null, "ionBlur"], [null, "ionSelect"]], function (_v, en, $event) { var ad = true; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionSelect" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9)._handleIonSelect($event.target.checked) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_IonRadio_0, i2.RenderType_IonRadio)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioValueAccessor]), i1.ɵdid(8, 49152, null, 0, i3.IonRadio, [i1.ChangeDetectorRef, i1.ElementRef], { checked: [0, "checked"], value: [1, "value"] }, null), i1.ɵdid(9, 16384, null, 0, i3.RadioValueAccessor, [i1.ElementRef], null, null)], function (_ck, _v) { var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_3 = (_v.context.index === 0); var currVal_4 = _v.context.$implicit; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.type)); var currVal_2 = _v.context.$implicit.price; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_ExtrasPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-radio-group", [], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleChangeEvent($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.emitSize($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonRadioGroup_0, i2.RenderType_IonRadioGroup)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(2, 49152, null, 0, i3.IonRadioGroup, [i1.ChangeDetectorRef, i1.ElementRef], null, null), i1.ɵdid(3, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-list-header", [], null, null, null, i2.View_IonListHeader_0, i2.RenderType_IonListHeader)), i1.ɵdid(5, 49152, null, 0, i3.IonListHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, [" Choose size "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExtrasPage_3)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.price; _ck(_v, 8, 0, currVal_0); }, null); }
function View_ExtrasPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-item", [["lines", "none"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], { lines: [0, "lines"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "ion-radio", [["slot", "start"]], null, [[null, "ionBlur"], [null, "ionSelect"]], function (_v, en, $event) { var ad = true; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionSelect" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleIonSelect($event.target.checked) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_IonRadio_0, i2.RenderType_IonRadio)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioValueAccessor]), i1.ɵdid(7, 49152, null, 0, i3.IonRadio, [i1.ChangeDetectorRef, i1.ElementRef], { checked: [0, "checked"], value: [1, "value"] }, null), i1.ɵdid(8, 16384, null, 0, i3.RadioValueAccessor, [i1.ElementRef], null, null)], function (_ck, _v) { var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_2 = (_v.context.index === 0); var currVal_3 = _v.context.$implicit; _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.type; _ck(_v, 4, 0, currVal_1); }); }
function View_ExtrasPage_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(1, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(2, 0, [" $ ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.price; _ck(_v, 2, 0, currVal_0); }); }
function View_ExtrasPage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExtrasPage_6)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "ion-checkbox", [["slot", "end"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleIonChange($event.target.checked) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.context.$implicit.isChecked = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ionChange" === en)) {
        var pd_3 = (_co.emitAddOn($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_IonCheckbox_0, i2.RenderType_IonCheckbox)), i1.ɵdid(8, 16384, null, 0, i3.BooleanValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.BooleanValueAccessor]), i1.ɵdid(10, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(12, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(13, 49152, null, 0, i3.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef], { value: [0, "value"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.price; _ck(_v, 6, 0, currVal_1); var currVal_9 = _v.context.$implicit.isChecked; _ck(_v, 10, 0, currVal_9); var currVal_10 = _v.context.$implicit; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.val; _ck(_v, 4, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 12).ngClassValid; var currVal_7 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_ExtrasPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExtrasPage_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "ion-radio-group", [], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._handleChangeEvent($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.emitStyle($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonRadioGroup_0, i2.RenderType_IonRadioGroup)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(5, 49152, null, 0, i3.IonRadioGroup, [i1.ChangeDetectorRef, i1.ElementRef], null, null), i1.ɵdid(6, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "ion-list-header", [], null, null, null, i2.View_IonListHeader_0, i2.RenderType_IonListHeader)), i1.ɵdid(8, 49152, null, 0, i3.IonListHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, [" Choose styles "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExtrasPage_4)), i1.ɵdid(11, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "ion-list", [], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(13, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "ion-list-header", [], null, null, null, i2.View_IonListHeader_0, i2.RenderType_IonListHeader)), i1.ɵdid(15, 49152, null, 0, i3.IonListHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Wich Add-ons"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExtrasPage_5)), i1.ɵdid(18, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.price; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.styles; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.addOns; _ck(_v, 18, 0, currVal_2); }, null); }
export function View_ExtrasPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.UpperCasePipe, []), i1.ɵpid(0, i5.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 7, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(3, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(5, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(7, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "arrow-round-back"], ["size", "large"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 8, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(11, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "div", [["style", "margin-left: 12px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExtrasPage_1)), i1.ɵdid(18, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 11, "ion-footer", [["style", "background: lightgray"]], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(20, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(21, 0, null, 0, 9, "ion-item", [["class", "plusminusgrid"], ["lines", "none"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(22, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], { lines: [0, "lines"] }, null), (_l()(), i1.ɵeld(23, 0, null, 0, 7, "ion-button", [["color", "secondary"], ["size", "medium"], ["style", "width: 100%"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addcart(_co.qtd) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(24, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(25, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "cart"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(26, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(27, 0, null, 0, 3, "ion-text", [["color", "light"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(28, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵted(29, 0, ["Add(", ")"])), i1.ɵppd(30, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 5, 0, currVal_0); var currVal_1 = "light"; var currVal_2 = "arrow-round-back"; var currVal_3 = "large"; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.displayExtras; _ck(_v, 18, 0, currVal_6); var currVal_7 = "none"; _ck(_v, 22, 0, currVal_7); var currVal_8 = "secondary"; var currVal_9 = "medium"; _ck(_v, 24, 0, currVal_8, currVal_9); var currVal_10 = "light"; var currVal_11 = "cart"; _ck(_v, 26, 0, currVal_10, currVal_11); var currVal_12 = "light"; _ck(_v, 28, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.item.item; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.item.description; _ck(_v, 16, 0, currVal_5); var currVal_13 = i1.ɵunv(_v, 29, 0, _ck(_v, 30, 0, i1.ɵnov(_v, 1), (_co.itemPrice * _co.qtd))); _ck(_v, 29, 0, currVal_13); }); }
export function View_ExtrasPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-extras", [], null, null, null, View_ExtrasPage_0, RenderType_ExtrasPage)), i1.ɵdid(1, 245760, null, 0, i6.ExtrasPage, [i3.ModalController, i7.RestaurantService, i3.AlertController, i8.AngularFirestore, i9.DishService, i10.CartService, i3.ToastController, i3.LoadingController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExtrasPageNgFactory = i1.ɵccf("app-extras", i6.ExtrasPage, View_ExtrasPage_Host_0, { item: "item", bizName: "bizName" }, {}, []);
export { ExtrasPageNgFactory as ExtrasPageNgFactory };

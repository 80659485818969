import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import * as firebase from 'firebase';
import { TranslateProvider } from 'src/app/providers';
import { environment } from 'src/environments/environment';
import { FairService } from '../../providers/fair.service';
@Component({
  selector: 'app-fair',
  templateUrl: './fair.component.html',
  styleUrls: ['./fair.component.scss'],
})
export class FairComponent implements OnInit {
  registeredList: Array<any>;
  total = 0;
  eventDetails: any;
  loader: any;
  allEmails = [];
  url;
  orderId;
  bizEmail;
  infoFlag = true;
  showFooter = false;
  enableRegistration: boolean;
  constructor(
    private router: Router,
    private eventsService: FairService,
    private location: Location,
    private loadingCtrl: LoadingController,
    private http: HttpClient,
    private translateService: TranslateProvider
  ) {
    this.eventsService.returnEventList().subscribe((res) => {
      this.allEmails = [];
      this.registeredList = res;
      console.log(JSON.stringify(res));
      if (res && res.length) {
        this.showFooter = true;
        res.forEach((data) => {
          this.total += data.price;
          this.allEmails.push(data.email);
        });
      } else {
        this.showFooter = false;
      }
    });

    this.eventsService.getEventDetails().subscribe((res) => {
      console.log(res);
      this.eventDetails = res[0];
      this.bizEmail = res[0].businessEmail;
      this.enableRegistration = res[0].enableRegistration;
    });
  }
  ngOnInit() {}

  remove(index: number): void {
    this.eventsService.removeUser(index);
  }
  navigateToForm(): void {
    if (this.translateService.isLoggedIn === true) {
      this.router.navigate(['NYP2022/form']);
    } else {
      this.router.navigate(['login', { callBackUrl: 'NYP2022/form' }]);
    }
  }
  async navigateToPayment() {
    if (this.translateService.isLoggedIn === true) {
      this.allEmails.push(this.bizEmail);
      let d = new Date();
      let date = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      this.orderId =
        '' +
        date +
        '' +
        month +
        '' +
        year +
        ' ' +
        Math.floor(Math.random() * 1000) +
        '';
      this.loader = await this.loadingCtrl.create({
        message: 'please wait...',
        duration: 60000,
      });

      this.loader.present();
      var that = this;
      await firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(async function (idToken) {
          that.loader.dismiss();
          that.eventsService.postOrders(
            JSON.parse(localStorage.getItem('fairRegisteredList')),
            that.orderId
          );
          that.url =
            'https://' + environment.url.link + '/eventsPayment/fairEmail';
          const allEmails = that.allEmails;
          const id = that.orderId;
          const eventData = JSON.parse(
            localStorage.getItem('fairRegisteredList')
          );
          let body = { allEmails, id, eventData };
          let headers = { Authorization: 'Bearer ' + idToken + '' };
          that.http.post(that.url, body, { headers }).subscribe((res) => {
            console.log(res);
          });
        });
    } else {
      this.router.navigate(['login', { callBackUrl: 'fair' }]);
    }
  }
  back(): void {
    this.location.back();
  }

  moreInfo(): void {
    this.infoFlag = !this.infoFlag;
  }

  donate() {
    if (this.translateService.isLoggedIn === true) {
      this.router.navigate(['donate']);
    } else {
      this.router.navigate(['login', { callBackUrl: 'donate' }]);
    }
  }
}

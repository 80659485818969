/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./placed-events.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./placed-events.component";
import * as i5 from "../../providers/event-orders.service";
import * as i6 from "../../../../providers/cart/cart.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
var styles_PlacedEventsComponent = [i0.styles];
var RenderType_PlacedEventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlacedEventsComponent, data: {} });
export { RenderType_PlacedEventsComponent as RenderType_PlacedEventsComponent };
export function View_PlacedEventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(5, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "arrow-round-back"], ["size", "large"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(7, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(9, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, [" Event orders "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(12, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null)], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "light"; var currVal_2 = "arrow-round-back"; var currVal_3 = "large"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PlacedEventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-placed-events", [], null, null, null, View_PlacedEventsComponent_0, RenderType_PlacedEventsComponent)), i1.ɵdid(1, 114688, null, 0, i4.PlacedEventsComponent, [i5.EventOrdersService, i6.CartService, i7.Router, i8.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlacedEventsComponentNgFactory = i1.ɵccf("app-placed-events", i4.PlacedEventsComponent, View_PlacedEventsComponent_Host_0, {}, {}, []);
export { PlacedEventsComponentNgFactory as PlacedEventsComponentNgFactory };

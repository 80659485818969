import { Component, OnInit } from '@angular/core';
import { FairService } from '../../providers/fair.service';
import { MenuController } from '@ionic/angular';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-fair-report',
  templateUrl: './fair-report.component.html',
  styleUrls: ['./fair-report.component.scss'],
})
export class FairReportComponent implements OnInit {
  list;
  fileName = 'ExcelSheet.xlsx';

  constructor(
    private fairService: FairService,
    public menuCtrl: MenuController
  ) {
    this.menuCtrl.enable(false);

    this.fairService.getRegisteredList().subscribe((res) => {
      console.log(res);
      this.list = res;
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  ngOnInit() {}
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { OrdersService } from 'src/app/providers';
import { AngularFireStorage, } from '@angular/fire/storage';
import { finalize, tap } from 'rxjs/operators';
import * as Papa from 'papaparse';
import { AngularFirestore } from '@angular/fire/firestore';
import { MenuController } from '@ionic/angular';
export class CsvuploadComponent {
    constructor(orderservice, firestore, menuCtrl, storage) {
        this.orderservice = orderservice;
        this.firestore = firestore;
        this.menuCtrl = menuCtrl;
        this.storage = storage;
        this.message = 'Uploading';
        this.showMessage = false;
        this.isUploading = false;
        this.csv_rec = [];
        this.count = 0;
        this.isUploading = false;
        this.isUploaded = false;
    }
    ngOnInit() {
        this.menuCtrl.enable(false);
    }
    startUpload(event) {
        const file = event.item(0);
        const path = 'csvup/test';
        const ref = this.storage.ref(path);
        // The main task
        this.task = this.storage.upload(path, file);
        // Progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(tap(console.log), 
        // The file's download URL
        finalize(() => __awaiter(this, void 0, void 0, function* () {
            console.log('done');
        })));
        // if(file.type.split('/')[1] !== 'csv') {
        //   console.log(file.type.split('/')[1])
        // console.error('Unsupported file type!!');
        // }
        this.isUploading = true;
        this.isUploaded = false;
        // this.process(file, 'data');
    }
    process(file, collection) {
        Papa.parse(file, {
            complete: (res) => {
                this.csv_rec = res;
                this.firethis(this.csv_rec['data'], collection);
            },
            header: true,
        });
    }
    firethis(json, collection) {
        console.log(json);
        var jsonfile = JSON.stringify(json);
        const path = 'csvup/test';
        const ref = this.storage.ref(path);
        // The main task
        this.task = this.storage.upload(path, jsonfile);
        // Progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(tap(console.log), 
        // The file's download URL
        finalize(() => __awaiter(this, void 0, void 0, function* () {
            console.log('done');
        })));
        // this.count=0
        //         json.forEach(element => {
        //           console.log(element)
        //           const Id = this.firestore.createId();
        //          this.firestore.collection(collection).doc(Id).set(element)
        //          .then(()=>{
        //            this.count++
        //            this.document=`document ${Id} successfully written`
        //           console.log(`document ${Id} successfully written`)})
        //           .catch((err)=>{
        //          this.document=`error writing document ${err}`
        //          console.log(`error writing document ${err}`)
        //           })
        //         })
    }
    toggleHover(event) {
        this.isHovering = event;
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { NavController, ToastController, Platform } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { SwUpdate } from '@angular/service-worker';
import { Location } from '@angular/common';
import { TranslateProvider } from 'src/app/providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
export class SettingsComponent {
    constructor(navCtrl, afAuth, swUpdate, toastCtrl, location, platform, translate, translateService
    //  private gplus:GooglePlus,
    ) {
        this.navCtrl = navCtrl;
        this.afAuth = afAuth;
        this.swUpdate = swUpdate;
        this.toastCtrl = toastCtrl;
        this.location = location;
        this.platform = platform;
        this.translate = translate;
        this.translateService = translateService;
        this.update = true;
        this.checkupdate = false;
        this.languages = [{
                language: 'hindi',
                value: 'hi'
            }, {
                language: 'english',
                value: 'en'
            }
        ];
        this.paymentMethods = ['Paypal', 'Credit Card'];
        this.currencies = ['USD', 'BRL', 'EUR'];
    }
    useLanguage(language) {
        console.log(language.detail.value);
        this.translateService.use(language.detail.value);
        // this.translateService.setDefaultLang(language.detail.value);
        // this.translate.setTranslations('de')
    }
    ngOnInit() {
    }
    ionViewWillEnter() {
        /*
       this.swUpdate.available.subscribe(event => {
         console.log('current version is', event.current);
         console.log('available version is', event.available);
         console.log('New update available');
       });*/
        this.swUpdate.available.subscribe(() => {
            if (confirm("New version available. Load New Version?")) {
                this.updateToLatest();
                this.toast1('loaded');
            }
        });
        /*
             this.swUpdate.available.subscribe(event => {
               console.log('New update available');
               this.updateToLatest();
             });*/
    }
    back() {
        this.location.back();
    }
    checkForUpdate() {
        this.update = false;
        this.checkupdate = true;
        if (this.swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate().then(() => {
                this.checkupdate = false;
                console.log('Checking for updates...');
            }).catch((err) => {
                console.error('Error when checking for update', err);
            });
        }
        else {
            this.toast1('App is upto date');
        }
    }
    toast1(msg) {
        return __awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastCtrl.create({
                message: msg,
                duration: 2000,
                position: 'top',
            });
            toast.present();
            this.checkupdate = false;
        });
    }
    updateToLatest() {
        console.log('Updating to latest version.');
        this.swUpdate.activateUpdate().then(() => document.location.reload());
    }
    editProfile() {
        this.navCtrl.navigateForward('edit-profile');
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.afAuth.auth.signOut();
            // if(this.platform.is('cordova')){
            //   this.gplus.logout()
            // }
            localStorage.removeItem('user');
            console.log("logged out" + JSON.stringify(localStorage.getItem('user')));
            this.navCtrl.navigateRoot('home-results');
        });
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateProvider } from '../providers';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LbguardGuard implements CanActivate {
  constructor(public service:TranslateProvider, 
              public navCtrl: NavController,
    ){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     if(this.service){ if(this.service.isLoggedIn !== true) {
        window.alert("You are not allowed to access this URL! Login First");
        this.navCtrl.navigateRoot('/login');
        localStorage.setItem('guard',state.url)
      }}

    return true;
  }
}

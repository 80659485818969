import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FairService } from '../../providers/fair.service';

@Component({
  selector: 'app-fair-confirmation',
  templateUrl: './fair-confirmation.component.html',
  styleUrls: ['./fair-confirmation.component.scss'],
})
export class FairConfirmationComponent implements OnInit {
  orderDetails;
  id;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fairService: FairService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.fairService.getOrderDetails(this.id).subscribe((res: any) => {
      this.orderDetails = res[0].event;
      console.log(this.orderDetails);
    });
  }

  donate() {
    this.router.navigate(['donate']);
  }
  ngOnInit() {}
}

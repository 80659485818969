import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { CartService } from 'src/app/providers';
import { EventOrdersService } from '../../providers/event-orders.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-events-admin',
  templateUrl: './events-admin.component.html',
  styleUrls: ['./events-admin.component.scss'],
})
export class EventsAdminComponent implements OnInit {
  orderDetails;
  totalTickets;
  fileName = 'ExcelSheet.xlsx';
  total = 0;
  constructor(
    private ordersService: EventOrdersService,
    private cartService: CartService,
    private router: Router,
    private location: Location,
    public menuCtrl: MenuController
  ) {
    this.menuCtrl.enable(false);
    this.ordersService.getAllOrders().subscribe((res: any) => {
      this.total = 0;
      this.orderDetails = res;
      this.totalTickets = this.orderDetails[0].event.length;
      res.forEach((x) => {
        this.total += parseInt(x.total);
      });
    });
  }
  ngOnInit() {}
  back() {
    this.location.back();
  }

  summary(id) {
    this.router.navigate([`events/event-confirmation/${id}`]);
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}

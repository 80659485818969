import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { RestaurantService } from '../providers';
@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  owners
  google
  admin:boolean=true
  constructor(private storage: AngularFireStorage, 
    private rservice:RestaurantService,private db: AngularFirestore) { }
businessName
  ngOnInit() {
    var email
    this.google= JSON.parse(localStorage.getItem('user'));
    for(let i=0;i<this.google.providerData.length;i++){
      //console.log(this.google.providerData[i].email)
      if(this.google.email==null){
          email=this.google.providerData[i].email
      }
      else{
      email=this.google.email
      }
      console.log(email)
    }
    console.log("load uptask")
    this.rservice.getOwners().valueChanges().subscribe(res=>{
      console.log(res)
      this.owners=res
      let item = this.owners.find(p =>   p.email == email)
  if(item==undefined){
    this.admin=false
  }
  else{
    console.log(item.business)
    this.businessName=item.business
    this.startUpload();

  }
    })
  }

  startUpload() {

    // The storage path
    let name=this.businessName

    const path = ''+this.businessName+'/'+this.file.name+'';
console.log(this.businessName)
    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        this.db.collection('Images').add( { downloadURL: this.downloadURL, path, filename:this.file.name,businessName:this.businessName });
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }


}

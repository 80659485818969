
<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon name="arrow-round-back" size="large" (click)="closeModal()" color="light"></ion-icon>
    </ion-buttons>
    <ion-title> Rating & Review
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div>
    <ion-item lines="none">
      <ion-label color="primary" class="label1">Overall Experience:&nbsp;&nbsp;&nbsp;&nbsp;
      </ion-label>
      <ion-icon name="star" *ngFor = "let num of [1,2,3,4,5]" (click)="overAllRate(num)" [ngStyle]="{'color':overAllRatingGetColor(num)}">  </ion-icon>

    </ion-item>

    <ion-item lines="none">
      <ion-label color="primary" class="label1">Shopping Experience:
      </ion-label>
      <ion-icon name="star" *ngFor = "let num of [1,2,3,4,5]" (click)="shoppingRate(num)" [ngStyle]="{'color':shoppingGetColor(num)}">  </ion-icon>

    </ion-item>
    <ion-item lines="none">
      <ion-label color="primary" class="label1">Delivery Experience:&nbsp;&nbsp;
      </ion-label>
      <ion-icon name="star" *ngFor = "let num of [1,2,3,4,5]" (click)="deliveryRate(num)" [ngStyle]="{'color':deliveryGetColor(num)}">  </ion-icon>

    </ion-item>
  </div>
  <ion-item lines="none">
    <ion-label color="primary" class="label1">Please provide your feedback.</ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-textarea class="textArea" class="google-input" [(ngModel)]="feedback">
    </ion-textarea>
  </ion-item><br>
  <ion-button class="button" color="success" expand="block" (click)="updateReview? updateRevw(): postReview()">Done
  </ion-button>
</ion-content>
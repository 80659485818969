import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-table-booking',
  templateUrl: './table-booking.component.html',
  styleUrls: ['./table-booking.component.scss'],
})
export class TableBookingComponent implements OnInit {
  eventForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private toastCtrl: ToastController
  ) {
    this.eventForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      seats: ['', Validators.required],
    });
  }

  ngOnInit() {}
  back() {
    this.router.navigate(['/aapifalltn2022']);
  }

  async bookSeats() {
    const toast = await this.toastCtrl.create({
      position: 'middle',
      message: 'Table Booked successfully!',
      duration: 1500,
      color: 'primary',
    });
    toast.present();
    this.router.navigate(['/aapifalltn2022']);
  }
}

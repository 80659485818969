/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./csvupload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../dropzone.directive";
import * as i3 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./csvupload.component";
import * as i6 from "../../providers/orders/orders.service";
import * as i7 from "@angular/fire/firestore";
import * as i8 from "@angular/fire/storage";
var styles_CsvuploadComponent = [i0.styles];
var RenderType_CsvuploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsvuploadComponent, data: {} });
export { RenderType_CsvuploadComponent as RenderType_CsvuploadComponent };
export function View_CsvuploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "dropzone"], ["dropzone", ""]], [[2, "hovering", null]], [[null, "hovered"], [null, "dropped"], [null, "drop"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDragOver($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onDragLeave($event) !== false);
        ad = (pd_2 && ad);
    } if (("hovered" === en)) {
        var pd_3 = (_co.toggleHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("dropped" === en)) {
        var pd_4 = (_co.startUpload($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DropzoneDirective, [], null, { dropped: "dropped", hovered: "hovered" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drag and Drop a CSV file"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "label", [["class", "file-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["accept", ".csv"], ["style", "display: none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.startUpload($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["class", "file-cta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "file-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "file-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["or choose a file..."])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "ion-content", [], null, null, null, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(12, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "h2", [["class", "h2"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "h2", [["class", "h2"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, [" Total Records: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isHovering; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.document; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.count; _ck(_v, 17, 0, currVal_2); }); }
export function View_CsvuploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-csvupload", [], null, null, null, View_CsvuploadComponent_0, RenderType_CsvuploadComponent)), i1.ɵdid(1, 114688, null, 0, i5.CsvuploadComponent, [i6.OrdersService, i7.AngularFirestore, i4.MenuController, i8.AngularFireStorage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CsvuploadComponentNgFactory = i1.ɵccf("app-csvupload", i5.CsvuploadComponent, View_CsvuploadComponent_Host_0, {}, {}, []);
export { CsvuploadComponentNgFactory as CsvuploadComponentNgFactory };

import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { CartService } from 'src/app/providers';
import { EventsService } from '../../providers/events.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checked-in',
  templateUrl: './checked-in.component.html',
  styleUrls: ['./checked-in.component.scss'],
})
export class CheckedInComponent implements OnInit {
  verified = false;
  constructor(
    private menuCtrl: MenuController,
    private eventsService: EventsService,
    private cartService: CartService,
    private router: Router
  ) {
    this.menuCtrl.enable(true);

    if (
      localStorage.getItem('isAnonymous') &&
      localStorage.getItem('isAnonymous') === 'false'
    ) {
      this.eventsService.getUsers().subscribe((res: any) => {
        if (this.checkIfUserExist(res) === true) {
          this.verified = true;
        }
      });
    }
  }

  ngOnInit() {}

  private checkIfUserExist(data) {
    const email = this.cartService.returnCustomerEmail();
    const result = data.findIndex((res) => res.email === email);
    return result > -1;
  }

  goToAapi() {
    this.router.navigate(['aapifall21']);
  }
}

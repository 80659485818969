import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventsRoutingModule } from './events-routing.module';
import { EventsComponent } from './pages/events/events.component';
import { IonicModule } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { PaymentComponent } from './pages/payment/payment.component';
import { EventFormComponent } from './pages/event-form/event-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventConfirmationComponent } from './pages/event-confirmation/event-confirmation.component';
import { EventsAdminComponent } from './pages/events-admin/events-admin.component';
import { PlacedEventsComponent } from './pages/placed-events/placed-events.component';
import { NgxPrintModule } from 'ngx-print';
import { AdminUiComponent } from './pages/admin-ui/admin-ui.component';
import { SponsorComponent } from './pages/sponsor/sponsor.component';
import { SponsorReportComponent } from './pages/sponsor-report/sponsor-report.component';
import { RegistrationsReportComponent } from './pages/registrations-report/registrations-report.component';
import { DonationsReportComponent } from './pages/donations-report/donations-report.component';
import { NewyearReportComponent } from './pages/newyear-report/newyear-report.component';
import { CheckedInComponent } from './pages/checked-in/checked-in.component';
import { TicketsBookingComponent } from './pages/tickets-booking/tickets-booking.component';
import { TableBookingComponent } from './pages/table-booking/table-booking.component';

@NgModule({
  declarations: [
    EventsComponent,
    PaymentComponent,
    EventFormComponent,
    EventConfirmationComponent,
    EventsAdminComponent,
    PlacedEventsComponent,
    AdminUiComponent,
    SponsorComponent,
    SponsorReportComponent,
    RegistrationsReportComponent,
    DonationsReportComponent,
    NewyearReportComponent,
    CheckedInComponent,
    TicketsBookingComponent,
    TableBookingComponent,
  ],
  imports: [
    CommonModule,
    NgxPrintModule,
    EventsRoutingModule,
    IonicModule.forRoot(environment.config),
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class EventsModule {}

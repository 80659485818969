/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-task.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./upload-task.component";
import * as i7 from "@angular/fire/storage";
import * as i8 from "../providers/restaurant/restaurant.service";
import * as i9 from "@angular/fire/firestore";
var styles_UploadTaskComponent = [i0.styles];
var RenderType_UploadTaskComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadTaskComponent, data: {} });
export { RenderType_UploadTaskComponent as RenderType_UploadTaskComponent };
function View_UploadTaskComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "progress", [["max", "100"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "%\n"])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.ngIf)); _ck(_v, 2, 0, currVal_1); }); }
function View_UploadTaskComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "!"])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "a", [["rel", "noopener"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", "!"])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("app.upload.task.results")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.ngIf; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.ngIf; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("app.upload.task.download")); _ck(_v, 8, 0, currVal_3); }); }
function View_UploadTaskComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-card", [], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(2, 0, [" ", " ", " ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UploadTaskComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.task.pause() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.task.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.task.resume() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.downloadURL; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.bytesTransferred; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform("app.upload.task.of")); var currVal_2 = _v.context.ngIf.totalBytes; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = !_co.isActive(_v.context.ngIf); _ck(_v, 6, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("app.upload.task.pause")); _ck(_v, 7, 0, currVal_5); var currVal_6 = !_co.isActive(_v.context.ngIf); _ck(_v, 9, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("app.upload.task.cancel")); _ck(_v, 10, 0, currVal_7); var currVal_8 = !(((_v.context.ngIf == null) ? null : _v.context.ngIf.state) === "paused"); _ck(_v, 12, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("app.upload.task.resume")); _ck(_v, 13, 0, currVal_9); }); }
export function View_UploadTaskComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UploadTaskComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UploadTaskComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.percentage)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.snapshot)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_UploadTaskComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-task", [], null, null, null, View_UploadTaskComponent_0, RenderType_UploadTaskComponent)), i1.ɵdid(1, 114688, null, 0, i6.UploadTaskComponent, [i7.AngularFireStorage, i8.RestaurantService, i9.AngularFirestore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadTaskComponentNgFactory = i1.ɵccf("app-upload-task", i6.UploadTaskComponent, View_UploadTaskComponent_Host_0, { file: "file" }, {}, []);
export { UploadTaskComponentNgFactory as UploadTaskComponentNgFactory };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MenuController, LoadingController, ToastController, } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { RestaurantService, CartService } from '../providers';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
export class CustomerorderdetailComponent {
    constructor(route, rservice, location, menuCtrl, http, db, cartservice, loadingCtrl, toastCtrl, router) {
        this.route = route;
        this.rservice = rservice;
        this.location = location;
        this.menuCtrl = menuCtrl;
        this.http = http;
        this.db = db;
        this.cartservice = cartservice;
        this.loadingCtrl = loadingCtrl;
        this.toastCtrl = toastCtrl;
        this.router = router;
        this.squareformat = [];
        this.columnDefs = [
            { headerName: 'name', field: 'name' },
            { headerName: 'amount', field: 'amount' },
            { headerName: 'quantity', field: 'quantity' },
            { headerName: 'tax percentage', field: 'tax' },
        ];
        this.showloader = false;
        this.applieddiscount = 0;
        this.ins = false;
        this.unit = false;
        this.removedItems = [];
        this.details = [];
        this.menuresults = false;
        this.noResultsFound = false;
        this.favourite = [];
        this.temp = [];
        this.employees = [];
        this.taxplustotal = 0;
        this.taxc = 0;
        this.diffamount = 0;
        this.negative = false;
        this.positive = false;
        this.checkifdeleted = false;
        this.showupdatebutton = false;
        this.delivery = false;
        this.products = [];
        this.groupedarray = [];
    }
    ngOnInit() {
        this.rowSelection = 'multiple';
        this.favourite = [];
        this.menuCtrl.enable(false);
        const songId = this.route.snapshot.paramMap.get('id');
        this.db
            .collection('CartList')
            .doc(songId)
            .get()
            .subscribe((res) => {
            this.groupedarray = [];
            // console.log(res.data())
            this.paymentid = res.data().payment_id;
            this.storename = res.data().businessName;
            this.groupedlist = _.mapValues(_.groupBy(res.data().items, 'category'), (clist) => clist.map((items) => _.omit(items, 'category')));
            for (const cat of Object.keys(this.groupedlist)) {
                console.log(cat);
                console.log(this.groupedlist[cat]);
                this.products.push({
                    category: cat,
                    products: this.groupedlist[cat],
                });
            }
            console.log(this.products);
            // console.log(this.groupedlist)
            //  this.groupedarray.push(this.groupedlist)
            console.log(this.groupedarray);
            this.handlebartiptaxtotal = res.data().handlebartiptaxtotal;
            this.handlebartax = res.data().handlebartax;
            this.subtotal = res.data().squaretotal;
        });
        this.list2 = this.rservice.getCustomerOrdersById(songId).valueChanges();
        this.list2.subscribe((res) => {
            console.log(res);
            this.originaltaxplustotal = res[0].taxplustotal;
            this.discountpercentage = res[0].discountpercentage;
            this.discountbool = res[0].discountbool;
            this.tiptaxdel = res[0].tiptaxdelivery;
            this.results1 = res[0].squareformat;
            this.checkintime = res[0].checkintime;
            this.alternatebrands = res[0].alternatebrands;
            this.unit = res[0].houseno;
            this.ins = res[0].landmark;
            console.log(res[0].alternatebrands);
            console.log(res);
        });
        this.list1 = this.rservice.getCustomerOrdersById(songId).valueChanges();
        this.list1.subscribe((result) => {
            console.log(result);
            this.firestoreId = result[0].id;
            this.businessName = result[0].businessName;
            this.guestname = result[0].guestname;
            this.orderType = result[0].ordertype;
            this.servicetime = result[0].timestamp.toDate();
            this.unit = result[0].houseno;
            this.ins = result[0].landmark;
            this.email2 = result[0].email2;
            this.businessemail = result[0].businessemail;
            this.email = result[0].email;
            // this.refundamountsent=result[0].guestname
            if (result[0].houseno == 'null') {
                this.unit = false;
            }
            if (result[0].landmark == 'null') {
                this.ins = false;
            }
            if (result[0].ordertype == 'DELIVERY') {
                this.delivery = true;
            }
            console.log(result[0].squareformat);
            //  result[0].squareformat
            this.favourite = result[0].squareformat;
            console.log(this.favourite);
            for (let i = 0; i < 1; i++)
                this.details[i] = result[0];
            // console.log(this.details[i])}
            this.rowData = this.favourite;
            this.calculate(this.rowData);
        });
    }
    back() {
        this.location.back();
    }
    calculate(fresh) {
        this.taxc = 0;
        this.taxplustotal = 0;
        for (let j = 0; j < fresh.length; j++) {
            this.taxplustotal =
                this.taxplustotal +
                    (fresh[j].base_price_money.amount / 100) * parseInt(fresh[j].quantity) +
                    (((fresh[j].tax / 100) * fresh[j].base_price_money.amount) / 100) *
                        parseInt(fresh[j].quantity);
            this.taxc =
                this.taxc +
                    (fresh[j].tax / 100) *
                        ((fresh[j].base_price_money.amount / 100) *
                            parseInt(fresh[j].quantity));
        }
        if (this.discountbool == 'applied') {
            console.log('applied');
            console.log(this.discountpercentage);
            let discount = (this.discountpercentage / 100) * this.taxplustotal;
            this.taxplustotal =
                Math.round(this.taxplustotal * 100) - Math.round(discount * 100);
            this.applieddiscount = discount;
        }
        else {
            this.taxplustotal = Math.round(this.taxplustotal * 100);
        }
        this.taxc = Math.round(this.taxc * 100);
        this.diffamount = this.originaltaxplustotal - this.taxplustotal;
        console.log(this.originaltaxplustotal);
        console.log(this.taxplustotal);
        console.log(this.diffamount);
        if (this.diffamount > 0) {
            this.positive = false;
            this.negative = true;
        }
        else if (this.diffamount < 0) {
            this.negative = false;
            this.positive = true;
        }
        else if (this.diffamount == 0) {
            this.negative = false;
            this.positive = false;
        }
        this.difference = Math.abs(this.originaltaxplustotal - this.taxplustotal);
        if (this.difference == 0 && this.checkifdeleted) {
            this.showupdatebutton = true;
        }
    }
    additem(item, amount, tax, img) {
        return __awaiter(this, void 0, void 0, function* () {
            this.menuresults = false;
            this.taxplustotal = 0;
            this.taxc = 0;
            this.favourite.push({
                base_price_money: { amount: amount * 100, currency: 'USD' },
                img: img,
                name: item,
                quantity: '1',
                tax: tax,
                variation_name: '',
            });
            this.calculate(this.favourite);
        });
    }
    minusQtd(order, id, selectedItem) {
        return __awaiter(this, void 0, void 0, function* () {
            this.taxplustotal = 0;
            this.taxc = 0;
            this.favourite[order].quantity--;
            const checkIfItemExist = this.removedItems.findIndex((res) => res.name === selectedItem.name);
            if (checkIfItemExist > -1) {
                // (selectedItem.base_price_money.amount * selectedItem.quantity) / 100,
                const ref = this.removedItems[checkIfItemExist];
                ref.quantity++;
                ref.subtotal =
                    (selectedItem.base_price_money.amount * ref.quantity) / 100;
            }
            else {
                const item = {
                    base_price_money: selectedItem.base_price_money,
                    handlebaramount: selectedItem.handlebaramount,
                    img: selectedItem.img,
                    name: selectedItem.name,
                    quantity: 1,
                    subtotal: selectedItem.base_price_money.amount / 100,
                    tax: selectedItem.tax,
                    variation_name: selectedItem.variation_name,
                };
                this.removedItems.push(item);
            }
            console.log(this.removedItems);
            this.calculate(this.favourite);
        });
    }
    plusQtd(order, id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.taxplustotal = 0;
            this.taxc = 0;
            this.favourite[order].quantity++;
            console.log(this.favourite);
            this.calculate(this.favourite);
        });
    }
    removeOrder(order, id, menuid, selectedItem) {
        return __awaiter(this, void 0, void 0, function* () {
            const checkIfItemExist = this.removedItems.findIndex((res) => res.name === selectedItem.name);
            if (checkIfItemExist > -1) {
                const ref = this.removedItems[checkIfItemExist];
                ref.quantity += selectedItem.quantity;
                ref.subtotal = (ref.quantity * ref.base_price_money.amount) / 100;
            }
            else {
                this.removedItems.push(selectedItem);
            }
            console.log(this.removedItems);
            this.checkifdeleted = true;
            this.taxplustotal = 0;
            this.taxc = 0;
            this.favourite.splice(order, 1);
            this.calculate(this.favourite);
        });
    }
    sendreq() {
        return __awaiter(this, void 0, void 0, function* () {
            this.positive = false;
            this.loader = yield this.loadingCtrl.create({
                message: 'sending payment request...',
                duration: 4000,
            });
            this.loader.present();
            let squaretotal = this.taxplustotal - this.taxc;
            let subtotaltax = this.taxc / 100;
            console.log(squaretotal);
            console.log(subtotaltax);
            this.squareformat = this.favourite;
            let paymentthroughlinkstatus = 'PENDING';
            this.cartservice
                .requestpaymentthroughlink(paymentthroughlinkstatus, this.squareformat, this.taxplustotal, squaretotal, subtotaltax, this.firestoreId, this.email, this.guestname, this.difference)
                .then(() => __awaiter(this, void 0, void 0, function* () {
                this.loader.dismiss();
                const toast = yield this.toastCtrl.create({
                    // cssClass: 'bg-profile',
                    position: 'middle',
                    message: 'Payment request send',
                    duration: 1000,
                    color: 'primary',
                });
                toast.present();
            }));
        });
    }
    amountsmatchupdate() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingCtrl.create({
                message: 'Updating Order...',
                duration: 35000,
            });
            this.squareformat = this.favourite;
            let record = {};
            record['squareformat'] = this.squareformat;
            this.db
                .doc('CartList/' + this.firestoreId)
                .update(record)
                .then(() => __awaiter(this, void 0, void 0, function* () {
                this.loader.dismiss();
                const toast = yield this.toastCtrl.create({
                    // cssClass: 'bg-profile',
                    position: 'middle',
                    message: 'Update successfull',
                    duration: 1000,
                    color: 'success',
                });
                toast.present();
                this.showupdatebutton = false;
            }));
        });
    }
    sendref() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingCtrl.create({
                message: 'sending refund...',
                duration: 35000,
            });
            this.loader.present();
            this.negative = false;
            var that = this;
            yield firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(function (idToken) {
                // Send token to your backend via HTTPS
                // ...
                let difference = that.difference;
                console.log(difference);
                console.log(that.paymentid);
                let squaretotal = that.taxplustotal - that.taxc;
                let subtotaltax = that.taxc / 100;
                that.squareformat = that.favourite;
                const headers = { Authorization: 'Bearer ' + idToken + '' };
                const body = { id: that.paymentid, amount: difference };
                console.log(body);
                that.http
                    .post('https://' + environment.url.link + '/ref', body, { headers })
                    .subscribe((data) => {
                    that.stat = data;
                    console.log(that.stat);
                    if (that.stat.refund.status) {
                        console.log(that.stat.refund.status);
                        let record = {};
                        record['taxplustotal'] = that.taxplustotal;
                        record['squareformat'] = that.squareformat;
                        record['squaretotal'] = squaretotal;
                        record['subtotaltax'] = subtotaltax;
                        record['difference'] = that.difference;
                        record['refundid'] = that.stat.refund.id;
                        record['refundamount'] = that.difference;
                        record['removedItems'] = that.removedItems;
                        let recordemail = {};
                        recordemail['businessName'] = that.businessName;
                        recordemail['businessemail'] = that.businessemail;
                        recordemail['email2'] = that.email2;
                        recordemail['email'] = that.email;
                        recordemail['partialrefund'] = that.difference / 100;
                        recordemail['guestname'] = that.guestname;
                        recordemail['removedItems'] = that.removedItems;
                        that.db
                            .doc('PartialRefunds/' + that.firestoreId)
                            .set(recordemail);
                        that.db
                            .doc('CartList/' + that.firestoreId)
                            .update(record)
                            .then(() => __awaiter(this, void 0, void 0, function* () {
                            that.calculate(that.favourite);
                            that.loader.dismiss();
                            const toast = yield that.toastCtrl.create({
                                position: 'middle',
                                message: 'Refund successfully sent',
                                duration: 1000,
                                color: 'primary',
                            });
                            toast.present();
                        }));
                    }
                }, (error) => __awaiter(this, void 0, void 0, function* () {
                    console.log('oops', error);
                    if (error) {
                        that.loader.dismiss();
                        const toast = yield that.toastCtrl.create({
                            // cssClass: 'bg-profile',
                            position: 'middle',
                            message: 'Something went wrong',
                            duration: 1000,
                            color: 'primary',
                        });
                        toast.present();
                    }
                }));
            })
                .catch(function (error) {
                // Handle error
                console.log('err' + error);
            });
        });
    }
    search(value) {
        console.log(value);
        console.log(value.detail.value);
        //  console.log(value.detail.data)
        this.searchText = value.detail.value;
        console.log(this.searchText);
        if (this.searchText != '') {
            this.temp = this.employees.filter((res) => {
                console.log(res.item.toLowerCase().match(this.searchText.toLowerCase()));
                // return   (res.item).toLowerCase().startsWith((this.searchText).toLowerCase())
                console.log(this.searchText);
                return res.item.toLowerCase().match(this.searchText.toLowerCase());
                // return res.item.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
            });
        }
        else if (this.searchText == '') {
            this.employees = [];
            this.empl();
        }
    }
    closeResults() {
        this.menuresults = false;
    }
    inFocus() {
        this.showloader = true;
        this.menuresults = true;
        this.employees = [];
        this.empl();
    }
    empl() {
        const songId = this.route.snapshot.paramMap.get('id');
    }
    printorder() {
        window.print();
    }
}

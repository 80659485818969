var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { finalize, tap } from 'rxjs/operators';
import { RestaurantService } from '../providers';
export class UploadTaskComponent {
    constructor(storage, rservice, db) {
        this.storage = storage;
        this.rservice = rservice;
        this.db = db;
        this.admin = true;
    }
    ngOnInit() {
        var email;
        this.google = JSON.parse(localStorage.getItem('user'));
        for (let i = 0; i < this.google.providerData.length; i++) {
            //console.log(this.google.providerData[i].email)
            if (this.google.email == null) {
                email = this.google.providerData[i].email;
            }
            else {
                email = this.google.email;
            }
            console.log(email);
        }
        console.log("load uptask");
        this.rservice.getOwners().valueChanges().subscribe(res => {
            console.log(res);
            this.owners = res;
            let item = this.owners.find(p => p.email == email);
            if (item == undefined) {
                this.admin = false;
            }
            else {
                console.log(item.business);
                this.businessName = item.business;
                this.startUpload();
            }
        });
    }
    startUpload() {
        // The storage path
        let name = this.businessName;
        const path = '' + this.businessName + '/' + this.file.name + '';
        console.log(this.businessName);
        // Reference to storage bucket
        const ref = this.storage.ref(path);
        // The main task
        this.task = this.storage.upload(path, this.file);
        // Progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(tap(console.log), 
        // The file's download URL
        finalize(() => __awaiter(this, void 0, void 0, function* () {
            this.downloadURL = yield ref.getDownloadURL().toPromise();
            this.db.collection('Images').add({ downloadURL: this.downloadURL, path, filename: this.file.name, businessName: this.businessName });
        })));
    }
    isActive(snapshot) {
        return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
    }
}

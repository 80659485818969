import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';
import { CartService } from 'src/app/providers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/router";
import * as i3 from "../../../providers/cart/cart.service";
export class FairService {
    constructor(firestore, router, cartService) {
        this.firestore = firestore;
        this.router = router;
        this.cartService = cartService;
        this.$eventList = new BehaviorSubject(null);
        this.list = [];
        if (localStorage.getItem('fairRegisteredList') !== null) {
            this.$eventList.next(JSON.parse(localStorage.getItem('fairRegisteredList')));
            this.list = JSON.parse(localStorage.getItem('fairRegisteredList'));
        }
    }
    addUser(name, email, phoneNumber) {
        this.list.push({
            name,
            email,
            phoneNumber,
        });
        localStorage.setItem('fairRegisteredList', JSON.stringify(this.list));
        this.$eventList.next(this.list);
    }
    deleteAndAddUser(name, email, phoneNumber, id) {
        this.list.splice(id, 1);
        this.list.push({
            name,
            email,
            phoneNumber,
        });
        localStorage.setItem('fairRegisteredList', JSON.stringify(this.list));
        this.$eventList.next(this.list);
    }
    returnEventList() {
        return this.$eventList;
    }
    clean() {
        this.list = [];
        this.$eventList.next(null);
        localStorage.removeItem('fairRegisteredList');
    }
    removeUser(index) {
        this.list.splice(index, 1);
        localStorage.setItem('fairRegisteredList', JSON.stringify(this.list));
        this.$eventList.next(this.list);
    }
    getEventDetails() {
        return this.firestore
            .collection(`Business`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('BusinessName', '==', 'New year party 2022');
        })
            .valueChanges();
    }
    getEventDetailsAAPI() {
        return this.firestore
            .collection(`Business`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref.where('BusinessName', '==', 'AAPI Fall 2021');
        })
            .valueChanges();
    }
    getRegisteredList() {
        return this.firestore
            .collection(`FairOrders`, (ref) => {
            // Compose a query using multiple .where() methods
            return ref;
        })
            .valueChanges();
    }
    postOrders(event, id) {
        const email = this.cartService.returnCustomerEmail();
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        return this.firestore
            .doc(`FairOrders/${id}`)
            .set({
            id,
            email,
            event,
            timestamp,
        })
            .then(() => this.goconfrm(id));
    }
    goconfrm(id) {
        this.clean();
        this.router.navigate(['fair/fair-confirmation/' + id + '']);
    }
    getOrderDetails(id) {
        return this.firestore
            .collection(`FairOrders`, (ref) => {
            return ref.where('id', '==', id);
        })
            .valueChanges();
    }
    postDonations(email, name, amount) {
        const id = Math.floor(Math.random() * 1000);
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        return this.firestore.doc(`Donations/${id}`).set({
            email,
            name,
            timestamp,
            amount,
        });
    }
    postSponsor(email, name, amount) {
        const id = Math.floor(Math.random() * 1000);
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        return this.firestore.doc(`SponsorShip/${id}`).set({
            email,
            name,
            timestamp,
            amount,
        });
    }
    newYearEntry(data) {
        const id = Math.floor(Math.random() * 1000);
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        return this.firestore.doc(`NewYearParty/${id}`).set({
            id,
            timestamp,
            data,
        });
    }
}
FairService.ngInjectableDef = i0.defineInjectable({ factory: function FairService_Factory() { return new FairService(i0.inject(i1.AngularFirestore), i0.inject(i2.Router), i0.inject(i3.CartService)); }, token: FairService, providedIn: "root" });

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
      &nbsp; &nbsp;
      <h1>Registration</h1>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="eventDetails">
  <!-- <ion-card>
    <img
      class="image"
      src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/aapi%2Fregistration-top.JPG?alt=media&token=fbf70a5a-d754-410c-9459-d79abe978166"
  /></ion-card> -->
  <form [formGroup]="eventForm" class="m-l-10">
    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Attendee Name*
      </ion-label>
      <ion-input formControlName="name" class="google-input" type="text">
      </ion-input>
      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('name').touched &&
          eventForm.get('name').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>
    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Guest (if any)
      </ion-label>
      <ion-input formControlName="guest" class="google-input" type="text">
      </ion-input>
    </ion-item>

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Email*
      </ion-label>
      <ion-input formControlName="email" class="google-input" type="text">
      </ion-input>
    </ion-item>

    <p
      ion-text
      class="text08"
      *ngIf="
        eventForm.get('email').touched &&
        eventForm.get('email').hasError('required')
      "
    >
      <ion-text color="primary">
        *This field is required in correct format for notifications
      </ion-text>
    </p>

    <!-- <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Designation (MD, DO, FFLI member, Guest)
      </ion-label>
      <ion-input formControlName="designation" class="google-input" type="text">
      </ion-input>
      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('designation').touched &&
          eventForm.get('designation').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item> -->

    <!-- <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Address
      </ion-label>
      <ion-input formControlName="address" class="google-input" type="text">
      </ion-input>

      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('address').touched &&
          eventForm.get('address').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item> -->

    <ion-item class="available2" lines="none">
      <ion-label class="google-label" position="floating" color="dark">
        Phone Number*
      </ion-label>
      <ion-input formControlName="phoneNumber" class="google-input" type="text">
      </ion-input>

      <p
        ion-text
        class="text08"
        *ngIf="
          eventForm.get('phoneNumber').touched &&
          eventForm.get('phoneNumber').hasError('required')
        "
      >
        <ion-text color="primary"> *This field is required </ion-text>
      </p>
    </ion-item>
  </form>
  <br />
  <ion-item *ngIf="showPayment == 'true'">
    <ion-text>
      <strong>Note</strong>: For Non AAPI members, registration fees of $100
      will be collected. We will call you to take the information for payment.
    </ion-text>
  </ion-item>

  <!-- <ion-radio-group (ionChange)="emitDesignationType($event)">
    <ion-list-header>
      <h3 class="available">Choose Designation type</h3>
    </ion-list-header>

    <ion-item
      *ngFor="let item of eventDetails.designationType; let idx = index"
      lines="none"
    >
      <ion-label
        >{{ item.type }} &nbsp; &nbsp; |&nbsp; &nbsp; ${{
          item.price
        }}</ion-label
      >
      <ion-radio
        slot="start"
        [checked]="isAddMode ? idx === 0 : selectedDesignation === item.type"
        [value]="item"
      ></ion-radio>
    </ion-item>
  </ion-radio-group> -->
  <!-- 
  <div *ngIf="isPhysician">
    <ion-radio-group (ionChange)="emitCme($event)">
      <ion-list-header>
        <h3 class="available">Requesting CME Credits:</h3>
      </ion-list-header>

      <ion-item *ngFor="let item of cme; let idx = index" lines="none">
        <ion-label>{{ item }}</ion-label>
        <ion-radio
          slot="start"
          [checked]="isAddMode ? idx === 0 : cmeCredits === item"
          [value]="item"
        ></ion-radio>
      </ion-item>
    </ion-radio-group>

    <ion-item class="available2" lines="none" class="m-l-3">
      <ion-label class="google-label" position="floating" color="dark">
        NPI Number (required for CME)
      </ion-label>
      <ion-input class="google-input" type="text" [(ngModel)]="licenceNumber">
      </ion-input>
    </ion-item>

    <div>
      <h3 class="m-l-10">Choose State Practicing</h3>
      <ion-select
        placeholder="Select One"
        [(ngModel)]="selectedState"
        (ionChange)="emitState($event)"
      >
        <ion-select-option
          [value]="item.name"
          *ngFor="let item of statesData.default"
          >{{ item.name }}</ion-select-option
        >
      </ion-select>
    </div>
  </div> -->
  <!-- <ion-card>
    <img
      class="image"
      src="https://firebasestorage.googleapis.com/v0/b/local-booklet-101.appspot.com/o/aapi%2Fregistration-bottom.JPG?alt=media&token=d63227b0-c8a1-47e9-aadc-a90961b307f6"
  /></ion-card> -->
</ion-content>
<ion-footer>
  <ion-button
    size="large"
    expand="full"
    [disabled]="!eventForm.valid"
    (click)="saveRegistration()"
    color="success"
    *ngIf="showPayment != 'true'"
    >Continue</ion-button
  >

  <ion-button
    size="large"
    expand="full"
    [disabled]="!eventForm.valid"
    (click)="payment()"
    color="success"
    *ngIf="showPayment == 'true'"
    >Continue</ion-button
  >
  <!-- [disabled]="!eventForm.valid" -->
</ion-footer>

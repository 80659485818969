import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LoadingController,
  MenuController,
  ToastController,
} from '@ionic/angular';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { EventsService } from '../../providers/events.service';
import * as states from '../../states.json';
@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit {
  eventForm: FormGroup;
  registeredList: Array<any> = [];
  price: number;
  designationType = 'Physician';
  eventDetails: any;
  statesData: any = states;
  selectedState: string;
  selectedDesignation: string;
  isStateDirty = false;
  isDesDirty = false;
  eventName: string;
  isPhysician = true;
  cme = ['Yes', 'No'];
  cmeCredits = 'Yes';
  licenceNumber;
  isAddMode: boolean;
  id;
  orderId;
  loader: any;
  allEmails = [];
  showPayment;
  constructor(
    private formBuilder: FormBuilder,
    private menuCtrl: MenuController,
    private location: Location,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private router: Router,
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    let d = new Date();
    let date = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    this.orderId =
      '' +
      date +
      '' +
      month +
      '' +
      year +
      ' ' +
      Math.floor(Math.random() * 1000) +
      '';
    this.isAddMode = !this.id;
    this.selectedState = this.statesData.default[0].name;
    this.eventsService.getEventDetails().subscribe((res) => {
      this.eventDetails = res[0];
      this.eventName = res[0].eventName;
      this.allEmails.push(res[0].businessEmail);
    });

    this.showPayment = this.route.snapshot.paramMap.get('show');
    this.menuCtrl.enable(false);
    this.eventForm = this.formBuilder.group({
      name: ['', Validators.required],
      guest: [''],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
          ),
        ],
      ],
      // designation: [''],
      // address: [''],
      phoneNumber: ['', Validators.required],
    });
    const email = localStorage.getItem('customerEmail');
    if (email !== null) {
      this.eventForm.patchValue({
        email,
      });
    }

    if (!this.isAddMode) {
      const user = this.eventsService.$eventList.getValue()[this.id];
      console.log(this.eventsService.$eventList.getValue()[this.id]);
      this.eventForm.patchValue(user);
      this.selectedDesignation = user.selectedDesignation;
      this.licenceNumber = user.stateLicenceNumber;
      this.cmeCredits = user.cmeCredits;
      this.selectedState = user.state;
      this.selectedDesignation === 'Physician'
        ? (this.isPhysician = true)
        : (this.isPhysician = false);
      this.price = user.price;
    }
  }
  ngOnInit() {}

  continueRegistration(): void {
    if (this.isAddMode) {
      this.eventsService.addUser(
        this.eventForm.value.name,
        this.eventForm.value.email,
        this.eventForm.value.designation,
        this.eventForm.value.address,
        this.eventForm.value.phoneNumber
      );
    } else {
      this.eventsService.deleteAndAddUser(
        this.eventForm.value.name,
        this.eventForm.value.email,
        this.eventForm.value.designation,
        this.eventForm.value.address,
        this.eventForm.value.phoneNumber,
        this.selectedDesignation,
        this.cmeCredits,
        this.licenceNumber,
        this.selectedState,
        this.price,
        this.eventName,
        this.id
      );
    }
    this.location.back();
  }
  payment() {
    const isMember = false;
    this.saveRegistration(isMember);
    // localStorage.setItem('ticketsBookingAmount', '100');
    // const guest = this.eventForm.value.guest;
    // let isGuestOn = true;
    // if (guest.length === 0) {
    //   isGuestOn = false;
    // }
    // const obj = {
    //   name: this.eventForm.value.name,
    //   guest: this.eventForm.value.guest,
    //   email: this.eventForm.value.email,
    //   phoneNumber: this.eventForm.value.phoneNumber,
    //   orderId: this.orderId,
    //   isGuestOn: isGuestOn,
    // };
    // localStorage.setItem('eventRegisteredList', JSON.stringify(obj));
    // this.router.navigate([`payment`]);
  }
  back(): void {
    this.router.navigate(['/aapifalltn2022']);
  }

  emitDesignationType(event) {
    this.isDesDirty = true;
    console.log(event.detail.value);
    this.selectedDesignation = event.detail.value.type;
    this.selectedDesignation === 'Physician'
      ? (this.isPhysician = true)
      : (this.isPhysician = false);

    this.price = event.detail.value.price;
  }
  emitCme(event) {
    console.log(event.detail.value);
    this.cmeCredits = event.detail.value;
  }
  emitState(event) {
    this.isStateDirty = true;
    console.log(event.detail.value);
    this.selectedState = event.detail.value;
  }

  async saveRegistration(isMember = true) {
    this.loader = await this.loadingCtrl.create({
      message: 'please wait...',
      duration: 60000,
    });

    this.loader.present();
    const eventData = {
      name: this.eventForm.value.name,
      guest: this.eventForm.value.guest,
      email: this.eventForm.value.email,
      phone: this.eventForm.value.phoneNumber,
    };
    this.allEmails.push(this.eventForm.value.email);
    const allEmails = this.allEmails;
    const name = eventData.name;
    const id = this.orderId;
    const guest = this.eventForm.value.guest;
    let isGuestOn = true;
    if (guest.length === 0) {
      isGuestOn = false;
    }
    const body = { eventData, allEmails, name, id, guest, isGuestOn };
    var that = this;
    console.log(body);
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async function (idToken) {
        let headers = { Authorization: 'Bearer ' + idToken + '' };
        const url =
          'https://' +
          environment.url.link +
          '/eventsPayment/registrationEmail';
        that.http.post(url, body, { headers }).subscribe((res) => {
          console.log(res);
        });
        that.loader.dismiss();
        const toast = await that.toastCtrl.create({
          position: 'middle',
          message:
            'You have successfully registered to the AAPI event! you will recieve an email shortly.',
          duration: 5000,
          color: 'success',
        });
        toast.present();
        that.eventsService.postRegistrationDetails(
          that.eventForm.value.name,
          that.eventForm.value.guest,
          that.eventForm.value.email,
          that.eventForm.value.phoneNumber,
          that.orderId,
          isMember
        );
        that.router.navigate(['/aapifalltn2022']);
      });

    // this.router.navigate([`payment-methods`]);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./donations-report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "ngx-print";
import * as i6 from "./donations-report.component";
import * as i7 from "../../providers/events.service";
var styles_DonationsReportComponent = [i0.styles];
var RenderType_DonationsReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DonationsReportComponent, data: {} });
export { RenderType_DonationsReportComponent as RenderType_DonationsReportComponent };
function View_DonationsReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["style", "padding: 5px 10px 5px 5px"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [["style", "padding: 5px 10px 5px 5px"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [["style", "padding: 5px 10px 5px 5px"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [["style", "padding: 5px 10px 5px 5px"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.timestamp.toDate(), "MM/dd h:mm a")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.email; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.amount; _ck(_v, 9, 0, currVal_3); }); }
export function View_DonationsReportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "ion-header", [], null, null, null, i3.View_IonHeader_0, i3.RenderType_IonHeader)), i1.ɵdid(2, 49152, null, 0, i4.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 8, "ion-toolbar", [["color", "primary"]], null, null, null, i3.View_IonToolbar_0, i3.RenderType_IonToolbar)), i1.ɵdid(4, 49152, null, 0, i4.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "ion-buttons", [["slot", "start"]], null, null, null, i3.View_IonButtons_0, i3.RenderType_IonButtons)), i1.ɵdid(6, 49152, null, 0, i4.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "arrow-round-back"], ["size", "large"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(8, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "ion-title", [], null, null, null, i3.View_IonTitle_0, i3.RenderType_IonTitle)), i1.ɵdid(10, 49152, null, 0, i4.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Report"])), (_l()(), i1.ɵeld(12, 0, null, null, 24, "ion-content", [], null, null, null, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(13, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "button", [["class", "button"], ["ngxPrint", ""], ["printSectionId", "ex"], ["printTitle", "MyTitle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i5.NgxPrintDirective, [], { printSectionId: [0, "printSectionId"], printTitle: [1, "printTitle"] }, null), (_l()(), i1.ɵted(-1, null, [" Print Report "])), (_l()(), i1.ɵted(-1, 0, [" \u00A0\u00A0\u00A0\u00A0 "])), (_l()(), i1.ɵeld(18, 0, null, 0, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportexcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Download excel file "])), (_l()(), i1.ɵeld(20, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, 0, 13, "div", [["id", "ex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 12, "table", [["id", "excel-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 11, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Placed On"])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Guest Name"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Amount"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DonationsReportComponent_1)), i1.ɵdid(36, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "light"; var currVal_2 = "arrow-round-back"; var currVal_3 = "large"; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "ex"; var currVal_5 = "MyTitle"; _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_6 = _co.report; _ck(_v, 36, 0, currVal_6); }, null); }
export function View_DonationsReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-donations-report", [], null, null, null, View_DonationsReportComponent_0, RenderType_DonationsReportComponent)), i1.ɵdid(1, 114688, null, 0, i6.DonationsReportComponent, [i7.EventsService, i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DonationsReportComponentNgFactory = i1.ɵccf("app-donations-report", i6.DonationsReportComponent, View_DonationsReportComponent_Host_0, {}, {}, []);
export { DonationsReportComponentNgFactory as DonationsReportComponentNgFactory };

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
      ></ion-icon>
    </ion-buttons>
    <ion-title> Payment </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <div> -->
  <ion-item color="primary" class="labelpay">
    <ion-label color="light"> Wallet </ion-label>
  </ion-item>
  <div id="google-pay-button"></div>

  <!-- 
  <ion-item>
    <button id="sq-google-pay" class="button-google-pay"></button>
  </ion-item> -->
  <ion-item color="primary" class="labelpay">
    <ion-label color="light"> Credit card </ion-label> </ion-item
  ><br />
  <ion-spinner
    class="spinner"
    name="crescent"
    color="primary"
    *ngIf="elements"
  ></ion-spinner>
  <!-- <div class="bringittop">
      <div id="form-container">
        <div id="sq-card-number"></div>
        <div class="third" id="sq-expiration-date"></div>
        <div class="third" id="sq-cvv"></div>
        <div class="third" id="sq-postal-code"></div>
        <ion-button
          id="creditcard"
          class="color"
          (click)="requestCardNonce($event)"
          expand="full"
          *ngIf="!elements"
        >
          pay {{ total | currency }}
        </ion-button>

        <br />
      </div>
    </div>
  </div>

  <br /><br />
  <br /><br /> -->

  <form id="payment-form" class="m-t-20">
    <div id="card-container" class="m-t-20"></div>
    <ion-button
      id="card-button"
      type="button"
      expand="full"
      [disabled]="elements"
    >
      pay {{ selectedAmount | currency }}
    </ion-button>
  </form>

  <div id="payment-status-container"></div>
</ion-content>
<ion-footer>
  <div text-center>
    <img src="assets/img/download.png" />
  </div>
</ion-footer>

import { Component } from '@angular/core';
import { Platform, NavController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { take } from 'rxjs/operators';
import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { Pages } from './interfaces/pages';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  RouterStateSnapshot,
} from '@angular/router';
import { HomeLocationPage } from './pages/home-location/home-location.page';
import { RestaurantService } from './providers';
import { Subject, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
declare const myTest: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public appPages: Array<Pages>;
  public displayName;
  public photoUrl;
  public admin;
  mySubscription: any;
  display: boolean = true;
  picture: boolean = false;
  deliveryagents;
  showdelbutton: boolean = false;
  showorders: boolean = false;
  loader;
  owners;
  // showdelivery:boolean=false
  showdelivery = new Subject<boolean>();
  subscriptiond: Subscription;
  platformAdmin = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private translateService: TranslateService,
    private provider: TranslateProvider,
    public navCtrl: NavController,
    public afAuth: AngularFireAuth,
    public router: Router,
    private home: HomeLocationPage,
    private swUpdate: SwUpdate,
    private rservice: RestaurantService,
    private firestore: AngularFirestore,
    public loadingCtrl: LoadingController,
    private route: ActivatedRoute
  ) {
    this.appPages = [
      {
        title: 'app.pages.menu.home',
        url: '/home-results',
        direct: 'forward',
        // icon: 'home'
      },

      {
        title: 'app.pages.menu.Orders',
        url: '/latest-orders',
        direct: 'forward',
        // icon: 'list-box'
      },

      {
        title: 'app.pages.menu.About',
        url: '/about',
        direct: 'forward',
        // icon: 'information-circle-outline'
      },
      {
        title: 'app.pages.menu.Support',
        url: '/support',
        direct: 'forward',
        // icon: 'help-buoy'
      },

      {
        title: 'app.pages.menu.App Settings',
        url: '/appsettings',
        direct: 'forward',
        // icon: 'cog'
      },
      {
        title: 'app.pages.menu.Terms',
        url: '/terms-of-service',
        direct: 'forward',
        // icon: 'cog'
      },
      {
        title: 'app.pages.menu.Privacy',
        url: '/privacy-policy',
        direct: 'forward',
        // icon: 'cog'
      },
      // {
      //   title: 'Gift Cards',
      //   url: '/workin-progress',
      //   direct: 'forward',
      //   // icon: 'cog'
      // },
      {
        title: 'app.pages.menu.Rewards',
        url: '/workin-progress',
        direct: 'forward',
        // icon: 'cog'
      },
      {
        title: 'app.pages.menu.Reviews',
        url: '/workin-progress',
        direct: 'forward',
        // icon: 'cog'
      },
      {
        title: 'app.pages.menu.Favourites',
        url: '/workin-progress',
        direct: 'forward',
        // icon: 'cog'
      },
      {
        title: 'app.pages.menu.Location',
        url: '/workin-progress',
        direct: 'forward',
        // icon: 'cog'
      },
      {
        title: 'app.pages.menu.Business Owner',
        url: '/edit-profile',
        direct: 'forward',
        // icon: 'cog'
      },
      // {
      //   title: 'Restaurant Owner Signup',
      //   url: '/ownersignup',
      //   direct: 'forward',
      // },
      // {
      //   title: 'Restaurant Owner SignIn',
      //   url: '/login',
      //   direct: 'forward',
      //   icon: 'log-in-outline'
      // },
    ];

    this.initializeApp();
  }

  customerOrders() {
    this.navCtrl.navigateRoot('customer-orders');
  }
  login() {
    this.router.navigate(['/login']);
  }
  ionViewWillEnter() {}

  changelan() {
    this.translateService.use('de');
  }
  initializeApp() {
    this.platform
      .ready()
      .then(() => {
        this.statusBar.styleDefault();
        setTimeout(() => {
          this.splashScreen.hide();
        }, 1000);
        // this.splashScreen.hide();
        // Set language of the app.
        this.translateService.setDefaultLang('en');
        // this.translateService.use(environment.language);
        // this.translateService.getTranslation(environment.language).subscribe(translations => {
        //   this.translate.setTranslations(translations);
        // });
      })
      .catch(() => {
        // Set language of the app.
        this.translateService.setDefaultLang(environment.language);
        // this.translateService.use(environment.language);
        // this.translateService.getTranslation(environment.language).subscribe(translations => {
        //   this.translate.setTranslations(translations);
        // });
      });
  }
  onClick() {
    myTest();
  }

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  // ionViewWillenter(){

  // }
  ngOnInit() {
    firebase.analytics();
    const user = JSON.parse(localStorage.getItem('user'));
    if (JSON.parse(localStorage.getItem('user')) != null)
      this.rservice
        .getOwners()
        .valueChanges()
        .subscribe((res) => {
          this.owners = res;

          let item = this.owners.find((p) => p.email == user.email);
          console.log(item);
          if (item == undefined) {
            this.showorders = false;
          } else {
            this.showorders = true;
            if (item.role == 'platform owner') {
              this.platformAdmin = true;
            }

            this.rservice.resname(item.business);
            localStorage.setItem('businessName', item.business);
          }
        });

    {
      if (user) {
        this.rservice
          .getdeliveryagentguard()
          .valueChanges()
          .subscribe((res) => {
            // console.log(res)
            this.deliveryagents = res;
            let item = this.deliveryagents.find((p) => p.email == user.email);
            // console.log(item)
            if (item == undefined) {
              this.showdelbutton = false;
            } else {
              this.showdelbutton = true;
            }
          });
      }
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });
    }

    this.provider.getEmitter().subscribe((customObject) => {
      this.displayName = customObject;
      console.log(customObject);
      this.picture = true;
    });
    this.provider.getEmitter2().subscribe((customObject2) => {
      this.photoUrl = customObject2;
    });

    this.provider.getEmitter3().subscribe((customObject3) => {
      if (customObject3 == 'localbookletdev@gmail.com') {
        this.admin = true;
      }
    });

    if (JSON.parse(localStorage.getItem('user'))) {
      // if(JSON.parse(localStorage.getItem('user')).email=="localbookletdev@gmail.com"){
      // this.admin=true
      //}
    }
  }
  async logout() {
    this.subscriptiond = this.rservice
      .getCartList()
      .valueChanges()
      .pipe(take(1))
      .subscribe((res) => {
        console.log(res);
        if (res.length) {
          for (let i = 0; i < res.length; i++) {
            this.firestore
              .doc('LocalBookletCart/' + res[i].Id)
              .delete()
              .then(() => {
                console.log('deleted ' + res[i]);
              });
          }
        }
      });
    this.loader = await this.loadingCtrl
      .create({
        message: 'Logging out...',
        duration: 3000,
      })
      .then((res) => {
        res.present();

        res.onDidDismiss().then(async (dis) => {
          localStorage.removeItem('favourite22');
          localStorage.removeItem('favourite');

          await this.afAuth.auth.signOut();
          localStorage.removeItem('user');
          this.router.navigate(['home-results']);
          this.display = false;
          this.picture = false;
        });
      });
  }
}

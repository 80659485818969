<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-icon
        name="arrow-round-back"
        size="large"
        (click)="back()"
        color="light"
        class="backicon"
      ></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <!-- <div *ngIf="!enableRegistration">
    <div class="toolTip">
      <ion-label>"Severe Weather Alert ⚠️ : </ion-label>
      <ion-text
        >Due to inclement weather conditions as per forecast, the India fair
        event is cancelled on 19th September, until further notice. Further
        registration process will be on hold".</ion-text
      >
    </div>
    <br /><br />
  </div> -->
  <ion-card *ngIf="eventDetails">
    <div>
      <img class="image" [src]="eventDetails.landingImage" alt="ion" />
    </div>
    <ion-card-header>
      <div class="landingText">
        <ion-text
          >Shuddh Desi Radio in association with GAMT, GCA & TNMM invite you to
          NEW YEAR PARTY!! Get ready to usher in to new year with Music, friends
          and family.</ion-text
        >
      </div></ion-card-header
    >
    <ion-card-content class="fs-18">
      <!-- <ion-label>{{ eventDetails.registerNotes }}</ion-label>
      <ion-row>
        <ion-col>
          <button>
            <ion-icon name="time"></ion-icon>
            <div style="font-size: 18px">Expires {{ eventDetails.expiry }}</div>
          </button>
        </ion-col>
      </ion-row> -->

      <div>
        <label class="fw-700">Location: </label>
        <ion-text>Factory at Franklin.</ion-text>
      </div>
      <div>
        <label class="fw-700">Date and time: </label>
        <ion-text>Dec. 31st 2021, Time 7 Pm to Past Midnight.</ion-text>
      </div>
      <div>
        <label class="fw-700">Tickets: </label>
        <ion-text>Starting from $ as low as $20.</ion-text>
      </div>
      <div>
        <div *ngIf="infoFlag">
          <label class="fw-700">Highlights of the event: </label>
          <ion-text
            >Non stop DJ Bollywood.....
            <a (click)="moreInfo()" style="cursor: pointer">more info</a
            >.</ion-text
          >
        </div>

        <div *ngIf="!infoFlag">
          <label class="fw-700">Highlights of the event: </label><br />
          <label>
            • Non stop DJ Bollywood music, Dinner, Belly Dancers, Dhol,
            Hookah bar, Cash Bar, Midnight Toast, Ample Free parking<br />
            • On site child care -$35 per child for entire party time, Includes-
            Pizza, juice box, games and baby sitter<br />
            • *Group gets reserved table with Champagne bottle.
            <br />
            <!-- • Henna tattoos <br /> -->
            <!-- • Live DJ <br />
            • Food vendors offering authentic and exotic tastes from different
            parts of India <br />
            • Hands-on children’s activities, and so much more! <br />***An
            event for a cause - all the profits will go towards COVID 19 relief.
            Please register online here if you are attending the event so you do
            not have to get into the hassle of registering at the event. <br />
            <label class="fw-700">Participating Organizations:</label><br />
            India Association of Nashville <br />
            Nashville Gurdwara <br />
            Tennessee Marathi mandal <br />
            Kerala Association of Nashville <br />
            Hindu Swayamsevak Sangh <br />
            Tennessee Telugu Samithi <br />
            Gujarat Association Of Middle Tennessee <br />
            Jain Society Of Middle Tennessee <br />
            BAGN -Bengali Association of Greater Nashville <br />
            <label class="fw-700">Donate:</label> <br />
            All the donations go to Sewa International. Through Sewa, funds are
            utilized to support Covid orphaned children and widowed women and
            Covid affected patients. -->

            <a (click)="moreInfo()" style="cursor: pointer">show Less</a>.
          </label>
        </div>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card *ngFor="let item of registeredList; let i = index" class="userCard">
    <ion-row style="margin-left: 8px">
      <ion-col size="5">
        <ion-label>{{ item.name }}</ion-label>
      </ion-col>
      <ion-col size="3"> </ion-col>
      <ion-col size="2">
        <ion-icon
          name="create"
          size="large"
          routerLink="edit-form/{{ i }}"
        ></ion-icon>
      </ion-col>
      <ion-col size="2">
        <ion-icon name="close" size="large" (click)="remove(i)"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-card>
  <ion-button
    *ngIf="showFooter"
    (click)="navigateToForm()"
    size="small"
    class="addGuest"
    >Add Guest</ion-button
  >
</ion-content>

<ion-footer>
  <ion-footer>
    <ion-row>
      <ion-col center text-center>
        <ion-button color="primary" (click)="navigateToForm()">
          <ion-icon name="text"></ion-icon>
          <div>Book Now</div>
        </ion-button>
      </ion-col>
      <ion-col center text-center>
        <ion-button color="primary" (click)="donate()">
          <div>Donate Now</div>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</ion-footer>

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { FairService } from '../../providers/fair.service';
export class PaymentFairComponent {
    constructor(route, loadingCtrl, http, eventsService, location, fairService, router, toastCtrl) {
        this.route = route;
        this.loadingCtrl = loadingCtrl;
        this.http = http;
        this.eventsService = eventsService;
        this.location = location;
        this.fairService = fairService;
        this.router = router;
        this.toastCtrl = toastCtrl;
        this.elements = true;
        this.allEmails = [];
        this.boothName = '';
        this.applicationId = environment.swypelocal.appId;
        this.locationId = environment.swypelocal.locationId;
        this.isSponsor = this.route.snapshot.paramMap.get('isSponsor');
        console.log(this.isSponsor);
        this.isBooth = this.route.snapshot.paramMap.get('isBooth');
        this.isNewYear = this.route.snapshot.paramMap.get('isNewYear');
        this.boothName = this.route.snapshot.paramMap.get('boothName');
        this.eventsService.getEventDetailsAAPI().subscribe((res) => {
            this.allEmails = [];
            const guestEmail = localStorage.getItem('guestEmailAapi');
            this.bizEmail = res[0].businessEmail;
            this.allEmails.push(guestEmail, this.bizEmail);
            console.log(this.allEmails);
        });
        this.selectedAmount = JSON.parse(localStorage.getItem('donationAmount'));
        console.log(this.selectedAmount);
    }
    ngOnInit() {
        this.loadSquarePayment();
    }
    back() {
        this.location.back();
    }
    loadSquarePayment() {
        return __awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                this.elements = false;
            }, 3000);
            const payments = Square.payments(this.applicationId, this.locationId);
            const card = yield payments.card();
            let vm = this;
            this.loadGooglePay();
            yield card.attach('#card-container');
            function eventHandler(event) {
                return __awaiter(this, void 0, void 0, function* () {
                    event.preventDefault();
                    try {
                        const result = yield card.tokenize();
                        if (result.status === 'OK') {
                            console.log(`Payment token is ${result.token}`);
                            vm.sendSqPayment({ nonce: result.token });
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                });
            }
            const cardButton = document.getElementById('card-button');
            if (cardButton)
                cardButton.addEventListener('click', eventHandler);
        });
    }
    loadGooglePay() {
        return __awaiter(this, void 0, void 0, function* () {
            let googlePay;
            let vm = this;
            const payments = Square.payments(this.applicationId, this.locationId);
            try {
                googlePay = yield initializeGooglePay(payments);
            }
            catch (e) {
                console.error('Initializing Google Pay failed', e);
                // There are a number of reason why Google Pay may not be supported
                // (e.g. Browser Support, Device Support, Account). Therefore you
                // should handle initialization failures, while still loading other
                // applicable payment methods.
            }
            function buildPaymentRequest(payments) {
                return payments.paymentRequest({
                    countryCode: 'US',
                    currencyCode: 'USD',
                    total: {
                        amount: JSON.stringify(vm.selectedAmount),
                        label: 'Total',
                    },
                });
            }
            function initializeGooglePay(payments) {
                return __awaiter(this, void 0, void 0, function* () {
                    const paymentRequest = buildPaymentRequest(payments);
                    const googlePay = yield payments.googlePay(paymentRequest);
                    yield googlePay.attach('#google-pay-button');
                    return googlePay;
                });
            }
            function tokenize(paymentMethod) {
                return __awaiter(this, void 0, void 0, function* () {
                    const tokenResult = yield paymentMethod.tokenize();
                    if (tokenResult.status === 'OK') {
                        console.log(tokenResult.token);
                        vm.sendSqPayment({ nonce: tokenResult.token });
                    }
                });
            }
            if (googlePay !== undefined) {
                const googlePayButton = document.getElementById('google-pay-button');
                googlePayButton.addEventListener('click', function (event) {
                    return __awaiter(this, void 0, void 0, function* () {
                        // await handlePaymentMethodSubmission(event, googlePay);
                        const token = yield tokenize(googlePay);
                    });
                });
            }
        });
    }
    sendSqPayment(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingCtrl.create({
                message: 'please wait...',
                duration: 60000,
            });
            this.loader.present();
            var that = this;
            yield firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                return __awaiter(this, void 0, void 0, function* () {
                    that.url = 'https://' + environment.url.link + '/eventsPayment/';
                    const allEmails = that.allEmails;
                    const nonce = data.nonce;
                    const guestName = localStorage.getItem('guestNameAapi');
                    const guestEmail = localStorage.getItem('guestEmailAapi');
                    let isSponsor = false;
                    let donateEmail = false;
                    let isBooth = false;
                    let boothName = that.boothName;
                    let isNewYear = false;
                    if (that.isSponsor && that.isSponsor === 'true') {
                        isSponsor = true;
                    }
                    else if (that.isBooth && that.isBooth === 'true') {
                        isBooth = true;
                    }
                    else if (that.isNewYear && that.isNewYear === 'true') {
                        isBooth = true;
                    }
                    else {
                        donateEmail = true;
                    }
                    let body = {
                        totalAmount: parseInt(that.selectedAmount),
                        nonce,
                        allEmails,
                        guestName,
                        donateEmail,
                        isSponsor,
                        isBooth,
                        boothName,
                    };
                    if (that.isNewYear && that.isNewYear === 'true') {
                        body.totalAmount = parseInt(localStorage.getItem('newYearAmount'));
                    }
                    console.log(body);
                    let headers = { Authorization: 'Bearer ' + idToken + '' };
                    that.http
                        .post(that.url, body, { headers })
                        .subscribe((data) => __awaiter(this, void 0, void 0, function* () {
                        if (data.payment.status == 'COMPLETED') {
                            that.loader.dismiss();
                            if (that.isNewYear && that.isNewYear === 'false') {
                                that.toastCtrl
                                    .create({
                                    message: 'Donation Successful',
                                    duration: 500,
                                    position: 'middle',
                                    cssClass: 'my-custom-class',
                                    color: 'primary',
                                })
                                    .then((obj) => {
                                    obj.present();
                                });
                            }
                            if (that.isSponsor && that.isSponsor === 'true') {
                                const url = 'https://' +
                                    environment.url.link +
                                    '/eventsPayment/sponsorEmail';
                                that.http.post(url, body, { headers }).subscribe((res) => {
                                    console.log(res);
                                });
                                that.router.navigate(['aapifalltn2022']);
                                that.sponsorEntry(guestName, guestEmail);
                            }
                            else if (that.isBooth && that.isBooth === 'true') {
                                const url = 'https://' +
                                    environment.url.link +
                                    '/eventsPayment/boothEmail';
                                that.http.post(url, body, { headers }).subscribe((res) => {
                                    console.log(res);
                                });
                                that.router.navigate(['home-results']);
                                that.sponsorEntry(guestName, guestEmail);
                            }
                            else if (that.isNewYear && that.isNewYear === 'true') {
                                that.toastCtrl
                                    .create({
                                    message: 'Tickets Booked Successfully',
                                    duration: 500,
                                    position: 'middle',
                                    cssClass: 'my-custom-class',
                                    color: 'primary',
                                })
                                    .then((obj) => {
                                    obj.present();
                                });
                                const data = JSON.parse(localStorage.getItem('newYearData'));
                                const url = 'https://' +
                                    environment.url.link +
                                    '/eventsPayment/fairEmail';
                                that.http.post(url, data, { headers }).subscribe((res) => {
                                    console.log(res);
                                });
                                that.router.navigate(['ticket-confirmation']);
                                localStorage.removeItem('nyCart');
                                that.newYearEntry(data);
                            }
                            else {
                                const url = 'https://' +
                                    environment.url.link +
                                    '/eventsPayment/donationEmail';
                                that.toastCtrl
                                    .create({
                                    message: 'Donated Successfully, please check your email for more details',
                                    duration: 3000,
                                    position: 'middle',
                                    cssClass: 'my-custom-class',
                                    color: 'primary',
                                })
                                    .then((obj) => {
                                    obj.present();
                                });
                                that.http.post(url, body, { headers }).subscribe((res) => {
                                    console.log(res);
                                });
                                that.router.navigate(['aapifalltn2022']);
                                that.fairService.postDonations(guestEmail, guestName, that.selectedAmount);
                            }
                        }
                    }));
                });
            });
        });
    }
    dismissloader() {
        this.elements = false;
    }
    sponsorEntry(guestName, guestEmail) {
        this.fairService.postSponsor(guestEmail, guestName, this.selectedAmount);
    }
    newYearEntry(data) {
        this.fairService.newYearEntry(data);
    }
}

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button size="small" shape="round" (click)="closeModal()" color="light">
        <ion-icon slot="start" name="close"></ion-icon> Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <img [src]="value">
</ion-content>

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { EventOrdersService } from '../../providers/event-orders.service';
import { EventsService } from '../../providers/events.service';
declare var Square: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  paymentForm; //this is our payment form object
  url;
  headers;
  elements = true;
  total = 0;
  list;
  loader: any;
  allEmails = [];
  orderId;
  applicationId: string;
  locationId: string;
  constructor(
    private http: HttpClient,
    private eventsService: EventsService,
    private location: Location,
    private orderService: EventOrdersService,
    private loadingCtrl: LoadingController,
    private router: Router,
    private toastCtrl: ToastController
  ) {
    this.applicationId = environment.swypelocal.appId;
    this.locationId = environment.swypelocal.locationId;
    let d = new Date();
    this.eventsService.getEventDetails().subscribe((res) => {
      this.allEmails.push(res[0].businessEmail);
    });

    let date = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();

    this.orderId =
      '' +
      date +
      '' +
      month +
      '' +
      year +
      ' ' +
      Math.floor(Math.random() * 1000) +
      '';
    // this.eventsService.returnEventList().subscribe((res) => {
    //   this.total = 0;
    //   this.list = res;
    //   if (res && res.length) {
    //     res.forEach((data) => {
    //       this.total += data.price;
    //       this.allEmails.push(data.email);
    //     });
    //   }
    //   this.fairService.getEventDetailsAAPI().subscribe((res) => {
    //     this.allEmails.push(res[0].businessEmail);
    //     console.log(this.allEmails);
    //   });
    // });
    this.total = parseInt(localStorage.getItem('ticketsBookingAmount'));
  }

  ngOnInit() {
    this.loadSquarePayment();
  }

  async loadSquarePayment() {
    setTimeout(() => {
      this.elements = false;
    }, 3000);
    const payments = Square.payments(this.applicationId, this.locationId);
    const card = await payments.card();
    let vm = this;
    this.loadGooglePay();

    await card.attach('#card-container');
    async function eventHandler(event) {
      event.preventDefault();

      try {
        vm.bringLoader();
        const result = await card.tokenize();

        if (result.status === 'OK') {
          console.log(`Payment token is ${result.token}`);
          vm.sendSqPayment({ nonce: result.token });
        }
      } catch (e) {
        console.error(e);
      }
    }
    const cardButton = document.getElementById('card-button');
    if (cardButton) cardButton.addEventListener('click', eventHandler);
  }
  dismissloader() {
    this.elements = false;
  }

  async loadGooglePay() {
    let googlePay;
    let vm = this;
    const payments = Square.payments(this.applicationId, this.locationId);
    try {
      googlePay = await initializeGooglePay(payments);
    } catch (e) {
      console.error('Initializing Google Pay failed', e);
      // There are a number of reason why Google Pay may not be supported
      // (e.g. Browser Support, Device Support, Account). Therefore you
      // should handle initialization failures, while still loading other
      // applicable payment methods.
    }
    function buildPaymentRequest(payments) {
      return payments.paymentRequest({
        countryCode: 'US',
        currencyCode: 'USD',
        total: {
          amount: JSON.stringify(vm.total),
          label: 'Total',
        },
      });
    }
    async function initializeGooglePay(payments) {
      const paymentRequest = buildPaymentRequest(payments);

      const googlePay = await payments.googlePay(paymentRequest);
      await googlePay.attach('#google-pay-button');

      return googlePay;
    }

    async function tokenize(paymentMethod) {
      vm.bringLoader();
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === 'OK') {
        console.log(tokenResult.token);
        vm.sendSqPayment({ nonce: tokenResult.token });
      }
    }
    if (googlePay !== undefined) {
      const googlePayButton = document.getElementById('google-pay-button');
      googlePayButton.addEventListener('click', async function (event) {
        // await handlePaymentMethodSubmission(event, googlePay);
        const token = await tokenize(googlePay);
      });
    }
  }
  async bringLoader() {
    this.loader = await this.loadingCtrl.create({
      message: 'please wait...',
      duration: 60000,
    });

    this.loader.present();
  }
  async sendSqPayment(oneTime: any) {
    var that = this;
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async function (idToken) {
        that.url = 'https://' + environment.url.link + '/eventsPayment/';
        // const allEmails = that.allEmails;
        // const id = that.orderId;
        const nonce = oneTime.nonce;
        // const eventData = JSON.parse(
        //   localStorage.getItem('eventRegisteredList')
        // );
        const details = JSON.parse(localStorage.getItem('eventRegisteredList'));
        const name = details.name;
        const id = details.orderId;
        const guest = details.guest;
        const isGuestOn = details.isGuestOn;
        const allEmails = that.allEmails;
        const amountPaid = '100';
        const isNotMember = true;
        const aapiRegistration = true;
        let body = {
          totalAmount: that.total,
          nonce,
          allEmails,
          name,
          id,
          guest,
          isGuestOn,
          amountPaid,
          isNotMember,
        };
        let headers = { Authorization: 'Bearer ' + idToken + '' };
        that.http
          .post(that.url, body, { headers })
          .subscribe(async (data: any) => {
            if (data.payment.status == 'COMPLETED') {
              that.loader.dismiss();
              const toast = await that.toastCtrl.create({
                position: 'middle',
                message: 'You have successfully registered to the AAPI event!',
                duration: 2000,
                color: 'secondary',
              });
              toast.present();
            }

            if (data.payment.status == 'COMPLETED') {
              const url =
                'https://' +
                environment.url.link +
                '/eventsPayment/registrationEmail';
              that.http.post(url, body, { headers }).subscribe((res) => {
                console.log(res);
              });
              that.loader.dismiss();

              that.eventsService.postRegistrationDetails(
                details.name,
                details.guest,
                details.email,
                details.phoneNumber,
                details.orderId,
                false
              );
              that.router.navigate(['/aapifalltn2022']);
            }
          });
      });
  }

  back(): void {
    this.location.back();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-filter.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "./search-filter.page";
import * as i6 from "../../../providers/restaurant/restaurant.service";
var styles_SearchFilterPage = [i0.styles];
var RenderType_SearchFilterPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchFilterPage, data: {} });
export { RenderType_SearchFilterPage as RenderType_SearchFilterPage };
export function View_SearchFilterPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(5, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "ion-button", [["color", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(7, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(11, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, [" Search Filter "])), (_l()(), i1.ɵeld(13, 0, null, null, 20, "ion-content", [["padding", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(14, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 15, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(16, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 4, "ion-label", [["class", "no-margin-bottom"], ["stacked", ""]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(18, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(19, 0, null, 0, 2, "ion-text", [["class", "fw500"], ["color", "dark"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(20, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Radius:"])), (_l()(), i1.ɵeld(22, 0, null, 0, 8, "ion-range", [["class", "no-padding-top"], ["max", "100"], ["min", "1"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 25)._handleChangeEvent($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.click($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonRange_0, i2.RenderType_IonRange)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(24, 49152, null, 0, i3.IonRange, [i1.ChangeDetectorRef, i1.ElementRef], { min: [0, "min"], max: [1, "max"], value: [2, "value"] }, null), i1.ɵdid(25, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(26, 0, null, 0, 4, "ion-label", [["slot", "start"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(27, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(28, 0, null, 0, 2, "ion-text", [["color", "primary"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(29, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵted(30, 0, [" ", " Km(s)"])), (_l()(), i1.ɵeld(31, 0, null, 0, 2, "ion-button", [["color", "primary"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(32, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, ["Filter Results"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "primary"; _ck(_v, 7, 0, currVal_1); var currVal_2 = "light"; var currVal_3 = "close"; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = "dark"; _ck(_v, 20, 0, currVal_4); var currVal_5 = "1"; var currVal_6 = "100"; var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.volume, ""); _ck(_v, 24, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = "primary"; _ck(_v, 29, 0, currVal_8); var currVal_10 = "primary"; var currVal_11 = "full"; _ck(_v, 32, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.volume; _ck(_v, 30, 0, currVal_9); }); }
export function View_SearchFilterPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-filter", [], null, null, null, View_SearchFilterPage_0, RenderType_SearchFilterPage)), i1.ɵdid(1, 114688, null, 0, i5.SearchFilterPage, [i3.ModalController, i6.RestaurantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchFilterPageNgFactory = i1.ɵccf("app-search-filter", i5.SearchFilterPage, View_SearchFilterPage_Host_0, {}, {}, []);
export { SearchFilterPageNgFactory as SearchFilterPageNgFactory };
